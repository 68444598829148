import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import propTypes from "prop-types";
import Button from "../../../../0.Commun/Button/Button";
import Input from "../../../../0.Commun/Input/Input";
import InputImage from "../../../../0.Commun/InputImage/InputImage";
import { messages } from "../../../../0.Commun/Message/Message";
import Quill from "../../../../0.Commun/Quill/Quill";
import { checkName } from "../../../../0.Commun/Regex/Regex";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import Catch from "../../../0.Commun/Catch/Catch";
import "./AddParc.scss";
import PopupContainer from "../../../../0.Commun/PopupContainer/PopupContainer";

const API_URL = process.env.REACT_APP_API_URL;

function AddParc({ openModal, setOpenModal }) {
  const [nomParc, setNomParc] = useState("");
  const [descriptionParc, setDescriptionParc] = useState("");
  const [newImg, setNewImg] = useState([]);
  const history = useHistory();

  const handleNomParc = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setNomParc(e.target.value);
    }
  };

  const uploadImages = (parcId) => {
    const token = localStorage.getItem("TOKEN");
    for (let i = 0; i < newImg.length; i += 1) {
      const newImage = new FormData();
      newImage.append("file", newImg[i]);
      newImage.append("description_image", newImg[i].name);
      newImage.append("parc_id", parcId);
      axios
        .post(`${API_URL}/api/images/new`, newImage, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  const submitParc = () => {
    const token = localStorage.getItem("TOKEN");
    let parcId;
    if (!nomParc || !descriptionParc || newImg.length < 2) {
      notify(messages.missing.champAndImage, "error");
    } else {
      axios
        .post(
          `${API_URL}/api/parc`,
          {
            nom_parc: nomParc,
            description_parc: descriptionParc,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data)
        .then((data) => {
          parcId = data.id_parc;
        })
        .then(() => {
          uploadImages(parcId);
        })
        .then(() => {
          notify(messages.create.destination, "success");
          setNomParc("");
          setDescriptionParc("");
          setNewImg([]);
          setOpenModal(false);
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  return (
    <section>
      {openModal && (
        <PopupContainer setClose={setOpenModal}>
          <div className="addParc">
            <Input
              name="titreParc"
              placeholder="Nom de la destination"
              value={nomParc}
              handleChange={(e) => handleNomParc(e)}
              marginValue="30px 0"
            />
            <p>Description de la destination :</p>
            <Quill text={descriptionParc} setText={setDescriptionParc} />
            <p>
              Ajouter au minimum 2 images à cette destination en format paysage
            </p>
            <InputImage
              newImg={newImg}
              setNewImg={setNewImg}
              multiple
              marginValue="30px 0"
            />
            <Button text="Valider" handleClick={submitParc} />
          </div>
        </PopupContainer>
      )}
    </section>
  );
}

AddParc.propTypes = {
  openModal: propTypes.bool.isRequired,
  setOpenModal: propTypes.func.isRequired,
};

export default AddParc;
