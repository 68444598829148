import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Helmet from "react-helmet";
import ReactHtmlParser from "html-react-parser";
import Quill from "../../0.Commun/Quill/Quill";
import NavBar from "../0.Commun/NavBar/NavBar";
import "./Blog.scss";
import "react-quill/dist/quill.snow.css";
import verifyToken from "../0.Commun/VerifyToken/VerifyToken";
import { messages } from "../../0.Commun/Message/Message";
import Catch from "../0.Commun/Catch/Catch";
import { notify } from "../../0.Commun/ToastNotif/ToastNotif";
import AddButton from "../../0.Commun/AddButton/AddButton";
import Button from "../../0.Commun/Button/Button";
import CloseButton from "../../0.Commun/CloseButton/CloseButton";
import Input from "../../0.Commun/Input/Input";
import InputImage from "../../0.Commun/InputImage/InputImage";
import DeleteButton from "../../0.Commun/DeleteButton/DeleteButton";
import PopupContainer from "../../0.Commun/PopupContainer/PopupContainer";
import PopupConfirm from "../../0.Commun/PopupConfirm/PopupConfirm";
import { scrollLock } from "../../0.Commun/ScrollLock/ScrollLock";

const API_URL = process.env.REACT_APP_API_URL;

function Blog() {
  const [openModal, setOpenModal] = useState({
    addBlog: false,
  });
  const [newArticleTitle, setNewArticleTitle] = useState("");
  const [newArticle, setNewArticle] = useState("");
  const [keywords, setKeywords] = useState({});
  const [newKeyword, setNewKeyword] = useState("");
  const [keywordsSelected, setKeywordsSelected] = useState("");
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [isDisplayBtn, setIsDisplayBtn] = useState(false);
  const [newImg, setNewImg] = useState([]);
  const [articles, setArticles] = useState({});
  const [isLoadingArticles, setIsLoadingArticles] = useState(false);
  const [openModalModify, setOpenModalModify] = useState(false);
  const [articleToModify, setArticleToModify] = useState({});
  const [textArticleToModify, setTextArticleToModify] = useState("");
  const [images, setImages] = useState({});
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
  });
  const history = useHistory();

  useEffect(() => {
    scrollLock(openModal.addBlog);
  }, [openModal.addBlog]);

  useEffect(() => {
    scrollLock(openModalModify);
  }, [openModalModify]);

  const matchKeyword = (data) => {
    const articleWithKeywords = [];
    data.forEach((item) => {
      const articleExist = articleWithKeywords.find(
        (element) => element.id_blog === item.id_blog
      );
      const articleIndex = articleWithKeywords.findIndex(
        (element) => element.id_blog === item.id_blog
      );
      if (!articleExist) {
        articleWithKeywords.push({
          id_blog: item.id_blog,
          titre_blog: item.titre_blog,
          article: item.article,
          date: item.date,
          nom_image: item.nom_image,
          description_image: item.description_image,
          keywords: [
            {
              id: item.id_mots_cles,
              keyword: item.keywords,
            },
          ],
        });
      } else {
        articleWithKeywords[articleIndex].keywords.push({
          id: item.id_mots_cles,
          keyword: item.keywords,
        });
      }
    });
    setArticles(articleWithKeywords);
  };

  const getArticles = () => {
    axios
      .get(`${API_URL}/api/blogs/getAll`)
      .then((res) => res.data)
      .then((data) => {
        matchKeyword(data);
      })
      .then(() => {
        setIsLoadingArticles(true);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  useEffect(() => {
    verifyToken(history);
    getArticles();
  }, []);

  const openAddModal = () => {
    setOpenModal({ ...openModal, addBlog: !openModal.addBlog });
  };

  const listKeyword = (event) => {
    setNewKeyword(event.target.value);
    axios
      .get(`${API_URL}/api/blogs/getKeywords?keyword=%${event.target.value}%`)
      .then((res) => res.data)
      .then((data) => {
        if (data.length <= 0) {
          setIsDisplayBtn(true);
          setIsLoadingList(false);
        } else {
          setKeywords(data);
          setIsLoadingList(true);
          setIsDisplayBtn(false);
        }
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const addNewKeyword = (idKeyword, keywordSelected) => {
    const newKeywords = { idKeyword, keywordSelected };
    const array = [...keywordsSelected];
    const exist = array.find((item) => item.idKeyword === idKeyword);
    if (!exist) {
      notify(messages.create.keyword, "success");
      array.push(newKeywords);
      setKeywordsSelected(array);
      setNewKeyword("");
      setIsLoadingList(false);
      setIsDisplayBtn(false);
    } else {
      notify(messages.error.keywordUsed, "warn");
    }
  };

  const deleteNewKeyword = (idToDelete) => {
    const array = [...keywordsSelected];
    const find = array.find((item) => item.idKeyword === idToDelete);
    const index = array.indexOf(find);
    array.splice(index, 1);
    setKeywordsSelected(array);
  };

  const postNewKeyword = () => {
    const token = localStorage.getItem("TOKEN");
    axios
      .post(
        `${API_URL}/api/blogs/newKeyword`,
        {
          keywords: newKeyword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .then((data) => {
        addNewKeyword(data.id_mots_cles, data.keywords);
        setNewKeyword("");
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const newImage = (idBlog) => {
    const token = localStorage.getItem("TOKEN");
    const imageNew = new FormData();
    imageNew.append("file", newImg[0]);
    imageNew.append("description_image", newImg[0].name);
    imageNew.append("blog_id_blog", idBlog);
    axios
      .post(`${API_URL}/api/images/new`, imageNew, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        notify(messages.modify.success, "success");
        getArticles();
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  const joinKeywords = (blogId) => {
    const token = localStorage.getItem("TOKEN");
    for (let i = 0; i < keywordsSelected.length; i += 1) {
      axios
        .post(
          `${API_URL}/api/blogs/articleAndKeywords`,
          {
            blogId,
            keywordId: keywordsSelected[i].idKeyword,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data)
        .then(() => {
          getArticles();
        })
        .catch((err) => {
          notify(err.response.data.errorMessage, "error");
        });
    }
  };

  const createArticle = () => {
    const token = localStorage.getItem("TOKEN");
    if (
      !newArticleTitle ||
      !newArticle ||
      !keywordsSelected ||
      newImg.length === 0
    ) {
      notify(messages.missing.inputEmpty, "warn");
    } else {
      axios
        .post(
          `${API_URL}/api/blogs/newArticle`,
          {
            titre_blog: newArticleTitle,
            article: newArticle,
            date: new Date()
              .toLocaleDateString()
              .split("/")
              .reverse()
              .join("-"),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data)
        .then((data) => {
          newImage(data.id_blog);
          joinKeywords(data.id_blog);
        })
        .then(() => {
          setNewArticleTitle("");
          setNewArticle("");
          setKeywordsSelected("");
          setNewImg([]);
          setOpenModal(false);
        })
        .catch((err) => {
          notify(err.response.data.errorMessage, "error");
        });
    }
  };

  const matchKeywordOneArticle = (data2) => {
    const articleWithKeywords = [];
    data2.forEach((item) => {
      const articleExist = articleWithKeywords.find(
        (element) => element.id_blog === item.id_blog
      );
      const articleIndex = articleWithKeywords.findIndex(
        (element) => element.id_blog === item.id_blog
      );
      if (!articleExist) {
        articleWithKeywords.push({
          id_blog: item.id_blog,
          titre_blog: item.titre_blog,
          article: item.article,
          date: item.date,
          nom_image: item.nom_image,
          description_image: item.description_image,
          keywords: [
            {
              idKeyword: item.id_mots_cles,
              keywordSelected: item.keywords,
            },
          ],
        });
      } else {
        articleWithKeywords[articleIndex].keywords.push({
          idKeyword: item.id_mots_cles,
          keywordSelected: item.keywords,
        });
      }
    });
    setArticleToModify(articleWithKeywords[0]);
    setKeywordsSelected(articleWithKeywords[0].keywords);
  };

  const getImage = (idBlogImage) => {
    axios
      .get(`${API_URL}/api/images/getByTheme?blog=${idBlogImage}`)
      .then((res) => res.data)
      .then((data) => {
        setImages(data);
      })
      .then(() => {
        setIsLoadingImages(true);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const openModifyModal = (blogId) => {
    axios
      .get(`${API_URL}/api/blogs/getById/${blogId}`)
      .then((res) => res.data)
      .then((data) => {
        matchKeywordOneArticle(data);
        setTextArticleToModify(data[0].article);
        getImage(blogId);
      })
      .then(() => {
        setOpenModalModify(!openModalModify);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const askDeleteBlog = (idToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.blog,
      id: idToDelete,
    });
  };

  const deleteArticle = (blogId) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .delete(`${API_URL}/api/blogs/article/${blogId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        getArticles();
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const deleteOldKeyword = (keywordIdBlog) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .delete(`${API_URL}/api/blogs/keyword/${keywordIdBlog}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const modifyArticle = (idToModify) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .put(
        `${API_URL}/api/blogs/${idToModify}`,
        {
          titre_blog: articleToModify.titre_blog,
          article: textArticleToModify,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .then(() => {
        deleteOldKeyword(idToModify);
      })
      .then(() => {
        notify(messages.modify.success, "success");
        joinKeywords(idToModify);
        setOpenModalModify(!openModalModify);
        setKeywordsSelected("");
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const deleteImage = (idImage, blogModifying, imageName) => {
    const token = localStorage.getItem("TOKEN");
    if (images.length === 1 || images.length === 0) {
      notify(messages.error.addImageBeforeDelete, "warn");
    } else {
      axios
        .delete(`${API_URL}/api/images/${idImage}/${imageName}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          notify(messages.delete.success, "success");
          getImage(blogModifying);
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  const addImage = (blogIdNewImg) => {
    const token = localStorage.getItem("TOKEN");
    const imageModify = new FormData();
    imageModify.append("file", newImg[0]);
    imageModify.append("description_image", newImg[0].name);
    imageModify.append("blog_id", blogIdNewImg);
    axios
      .post(`${API_URL}/api/images/new`, imageModify, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        notify(messages.modify.success, "success");
        setNewImg([]);
        getImage(blogIdNewImg);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  return (
    <section>
      <Helmet>
        <title>Admin - Blog</title>
      </Helmet>
      <NavBar />
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={deleteArticle}
      />
      <div className="blogAdminGlobal">
        <h1 className="h1Admin">Blog</h1>
        <div>
          <AddButton
            title="Ajouter un article &#43;"
            handleClick={openAddModal}
          />
          {openModal.addBlog && (
            <PopupContainer setClose={setOpenModal}>
              <form className="containerAddNewArticle">
                <Input
                  name="articleTitle"
                  placeholder="Titre de l'article"
                  value={newArticleTitle}
                  handleChange={(e) => setNewArticleTitle(e.target.value)}
                />
                <Quill text={newArticle} setText={setNewArticle} />
                <div>
                  {keywordsSelected && (
                    <div>
                      {keywordsSelected.map((keywordSelected) => (
                        <button
                          className="keywordTag"
                          key={keywordSelected.idKeyword}
                          type="button"
                          onClick={() =>
                            deleteNewKeyword(keywordSelected.idKeyword)
                          }
                        >
                          {keywordSelected.keywordSelected} &times;
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <div className="containerAllKeyword">
                  <Input
                    name="keywords"
                    placeholder="Mots-clés"
                    placeholderDisplay={false}
                    value={newKeyword}
                    handleChange={(e) => listKeyword(e)}
                    widthvalue="300px"
                  />
                  <span>
                    {newKeyword && isLoadingList ? (
                      <div className="containerSuggestKeyword">
                        {keywords.map((keyword) => (
                          <button
                            key={keyword.id_mots_cles}
                            className="suggestKeyword"
                            type="button"
                            onClick={() =>
                              addNewKeyword(
                                keyword.id_mots_cles,
                                keyword.keywords
                              )
                            }
                          >
                            {keyword.keywords}
                          </button>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </span>
                  {isDisplayBtn && (
                    <Button
                      text="Ajouter"
                      handleClick={postNewKeyword}
                      marginValue="0 0 0 10px"
                    />
                  )}
                </div>
                <InputImage newImg={newImg} setNewImg={setNewImg} />
                <div className="containerBtnAddArticle">
                  <Button text="Créer un article" handleClick={createArticle} />
                </div>
              </form>
            </PopupContainer>
          )}
        </div>
        {isLoadingArticles && (
          <div className="containerMapArticles">
            {articles.map((article) => (
              <div className="containerArticle" key={article.id_blog}>
                <div className="dateAndKeyword">
                  <p className="dateArticle">
                    {new Date(article.date).toLocaleDateString()}
                  </p>
                  <div className="containerKeywords">
                    {article.keywords.map((item) => (
                      <p key={item.id} className="listKeywords">
                        #{item.keyword}
                      </p>
                    ))}
                  </div>
                </div>
                <img
                  className="imgArticle"
                  src={`${API_URL}/images/${article.nom_image}`}
                  alt={article.description_image}
                />
                <h2 className="h2Body titreBlog">{article.titre_blog}</h2>
                <div className="containerTextArticle">
                  {ReactHtmlParser(article.article)}
                </div>
                <div className="containerBtnModify">
                  <Button
                    text="Modifier"
                    handleClick={() => openModifyModal(article.id_blog)}
                    marginValue="0 5px 0 0"
                  />
                  <Button
                    text="Supprimer"
                    handleClick={() => askDeleteBlog(article.id_blog)}
                    marginValue="0 0 0 5px"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {openModalModify && (
          <PopupContainer setClose={setOpenModalModify}>
            <CloseButton setClose={setOpenModalModify} />
            <form className="containerModifyArticle">
              <Input
                name="articleTitle"
                placeholder="Titre de l'article"
                value={articleToModify.titre_blog}
                handleChange={(e) =>
                  setArticleToModify({
                    ...articleToModify,
                    titre_blog: e.target.value,
                  })
                }
              />
              <Quill
                text={textArticleToModify}
                setText={setTextArticleToModify}
              />
              <div>
                {keywordsSelected && (
                  <div>
                    {keywordsSelected.map((keywordSelected) => (
                      <button
                        className="keywordTag"
                        key={keywordSelected.idKeyword}
                        type="button"
                        onClick={() =>
                          deleteNewKeyword(keywordSelected.idKeyword)
                        }
                      >
                        {keywordSelected.keywordSelected} &times;
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <div className="containerAllKeyword">
                <Input
                  name="keywords"
                  placeholder="Mots-clés"
                  placeholderDisplay={false}
                  value={newKeyword}
                  handleChange={(e) => listKeyword(e)}
                  widthvalue="300px"
                />
                <span>
                  {newKeyword && isLoadingList ? (
                    <div className="containerSuggestKeyword">
                      {keywords.map((keyword) => (
                        <button
                          key={keyword.id_mots_cles}
                          className="suggestKeyword"
                          type="button"
                          onClick={() =>
                            addNewKeyword(
                              keyword.id_mots_cles,
                              keyword.keywords
                            )
                          }
                        >
                          {keyword.keywords}
                        </button>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </span>
                {isDisplayBtn && (
                  <Button
                    text="Ajouter"
                    handleClick={postNewKeyword}
                    marginValue="0 0 0 10px"
                  />
                )}
              </div>
              {isLoadingImages && (
                <div className="containerMapImagesModify">
                  {images.map((image) => (
                    <div key={image.id_image} className="containerImagesModify">
                      <img
                        className="imgArticleToModify"
                        src={`${API_URL}/images/${image.nom_image}`}
                        alt={image.description_image}
                      />
                      <DeleteButton
                        onClick={() =>
                          deleteImage(
                            image.id_image,
                            image.blog_id_blog,
                            image.nom_image
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
              <InputImage newImg={newImg} setNewImg={setNewImg} />
              <Button
                text="Ajouter une image"
                handleClick={() => addImage(articleToModify.id_blog)}
              />
              <div className="containerBtnAddArticle">
                <Button
                  text="Modifier"
                  handleClick={() => modifyArticle(articleToModify.id_blog)}
                />
              </div>
            </form>
          </PopupContainer>
        )}
      </div>
    </section>
  );
}

export default Blog;
