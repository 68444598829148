import React, { useEffect, useState } from "react";
import "./CalendrierPartenaire.scss";
import PropTypes from "prop-types";
import FormulaireCalendrier from "./FormulaireCalendrier/FormulaireCalendrier";
import PopupContainer from "../../../0.Commun/PopupContainer/PopupContainer";
import { axiosPartner } from "../../../0.Commun/Axios/Axios";
import Button from "../../../0.Commun/Button/Button";
import Input from "../../../0.Commun/Input/Input";
import { notify } from "../../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../../0.Commun/Message/Message";
import VisualisationCalendrier from "./VisualisationCalendrier/VisualisationCalendrier";

const CalendrierPartenaire = ({
  stateVisibility,
  calendarId,
  isPartenairePanel,
}) => {
  const [allEventDate, setAllEventDate] = useState([]);
  const [urlToRequest, setUrlToRequest] = useState("");
  const [calDisplay, setCalDisplay] = useState(false);
  const [refreshCal, setRefreshCal] = useState(false);

  const getExtCalendar = async () => {
    axiosPartner({
      method: "GET",
      url: `calendriers/calendarLink/${calendarId}`,
    }).then((data) => {
      if (data.calPartenaire_linkUrl !== null) {
        setUrlToRequest(data.calPartenaire_linkUrl);
      }
      setCalDisplay(true);
    });
  };

  useEffect(() => {
    getExtCalendar();
  }, []);

  const addExtCalendar = () => {
    axiosPartner({
      method: "POST",
      url: `calendriers/calendarLink/${calendarId}`,
      body: { calendarLink: urlToRequest },
    }).then(() => {
      getExtCalendar();
      notify(messages.create.externalCalendar, "success");
      setRefreshCal(!refreshCal);
    });
  };

  const removeExtCalendar = () => {
    axiosPartner({
      method: "DELETE",
      url: `calendriers/calendarLink/${calendarId}`,
    }).then(() => {
      getExtCalendar();
      setUrlToRequest("");
      notify(messages.delete.externalCalendar, "success");
      setRefreshCal(!refreshCal);
    });
  };

  return (
    <PopupContainer setClose={stateVisibility}>
      <div className="calendarPartner">
        <h2 className="h2Admin">Calendrier</h2>
        <div>
          <Input
            name="linkCalendar"
            placeholder="Lien calendrier externe"
            value={urlToRequest}
            handleChange={(e) => setUrlToRequest(e.target.value)}
            marginValue="30px 0"
          />
          <div className="btnDivCalLink">
            <Button text="Ajouter" handleClick={addExtCalendar} />
            <Button text="Retirer" handleClick={removeExtCalendar} />
          </div>
        </div>
        <div className="containerCalendar">
          {!urlToRequest && (
            <FormulaireCalendrier
              allEventDate={allEventDate}
              linkCalendar={urlToRequest}
              calendarId={calendarId}
              refreshCal={refreshCal}
              setRefreshCal={setRefreshCal}
            />
          )}
          {calDisplay && (
            <VisualisationCalendrier
              setAllEventDate={setAllEventDate}
              calendarId={calendarId}
              urlToRequest={urlToRequest}
              isPartenairePanel={isPartenairePanel}
              refreshCal={refreshCal}
              setRefreshCal={setRefreshCal}
            />
          )}
        </div>
      </div>
    </PopupContainer>
  );
};

CalendrierPartenaire.propTypes = {
  stateVisibility: PropTypes.func.isRequired,
  calendarId: PropTypes.number.isRequired,
  isPartenairePanel: PropTypes.bool,
};

CalendrierPartenaire.defaultProps = {
  isPartenairePanel: false,
};

export default CalendrierPartenaire;
