import React, { useState } from "react";
import PropTypes from "prop-types";
import Input from "../../../../0.Commun/Input/Input";
import { messages } from "../../../../0.Commun/Message/Message";
import {
  checkEmail,
  checkName,
  checkNumbers,
} from "../../../../0.Commun/Regex/Regex";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import AffichageRue from "../AffichageRue/AffichageRue";
import AffichageVille from "../AffichageVille/AffichageVille";
import "./Formulaire.scss";

function Formulaire({
  newGuest,
  setNewGuest,
  city,
  setCity,
  message,
  setMessage,
  address,
  setAddress,
}) {
  const [cityIsVisible, setCityIsVisible] = useState(false);
  const [addressIsVisible, setAddressIsVisible] = useState(false);
  const [cityCode, setCityCode] = useState("");

  const handleText = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setNewGuest({ ...newGuest, [e.target.name]: e.target.value });
    }
  };

  const handleAdress = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setAddress(e.target.value);
    }
    if (e.target.value.length > 5) {
      setAddressIsVisible(true);
    }
  };

  const handleMail = (e) => {
    const resultRegex = checkEmail(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setNewGuest({ ...newGuest, [e.target.name]: e.target.value });
    }
  };

  const handleNumber = (e) => {
    const resultRegex = checkNumbers(e.target.value);
    if (!resultRegex) {
      notify(messages.error.onlyNumbers, "error");
    } else {
      setNewGuest({ ...newGuest, [e.target.name]: e.target.value });
    }
    if (e.target.name === "cp" && e.target.value.length === 5) {
      setCityIsVisible(true);
    }
  };

  return (
    <section className="containerPreorder">
      <h2 className="h2Body">Vos informations</h2>
      <Input
        name="lastName"
        value={newGuest.lastName}
        placeholder="Nom"
        placeholderDisplay={false}
        widthvalue="60%"
        handleChange={handleText}
      />
      <Input
        name="firstName"
        value={newGuest.firstName}
        placeholder="Prénom"
        placeholderDisplay={false}
        widthvalue="60%"
        handleChange={handleText}
      />
      <Input
        name="company"
        value={newGuest.company}
        placeholder="Société"
        placeholderDisplay={false}
        widthvalue="60%"
        handleChange={handleText}
      />
      <Input
        name="clientEmail"
        type="email"
        value={newGuest.clientEmail}
        placeholder="Email"
        placeholderDisplay={false}
        widthvalue="60%"
        handleChange={handleMail}
      />
      <Input
        name="clientPhone"
        value={newGuest.clientPhone}
        placeholder="Téléphone"
        placeholderDisplay={false}
        widthvalue="60%"
        handleChange={handleNumber}
      />
      <div className="containerCp">
        <Input
          name="cp"
          value={newGuest.cp}
          placeholder="Code postal"
          placeholderDisplay={false}
          widthvalue="60%"
          handleChange={handleNumber}
        />
        {cityIsVisible && (
          <AffichageVille
            setCityIsVisible={setCityIsVisible}
            setCity={setCity}
            cp={newGuest.cp}
            setCityCode={setCityCode}
          />
        )}
      </div>
      <Input
        name="city"
        value={city}
        placeholder="Ville"
        placeholderDisplay={false}
        widthvalue="60%"
        handleChange={handleText}
        disabled
      />
      <div className="containerCp">
        <Input
          name="address"
          value={address}
          placeholder="Adresse"
          placeholderDisplay={false}
          widthvalue="60%"
          handleChange={handleAdress}
        />
        {addressIsVisible && (
          <AffichageRue
            setAddressIsVisible={setAddressIsVisible}
            setAddress={setAddress}
            address={address}
            cityCode={cityCode}
          />
        )}
      </div>
      <textarea
        className="inputMessage"
        name="message"
        value={message}
        rows="10"
        placeholder="Demande particulière"
        onChange={(e) => setMessage(e.target.value)}
      />
    </section>
  );
}

Formulaire.propTypes = {
  newGuest: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    company: PropTypes.string,
    clientEmail: PropTypes.string,
    clientPhone: PropTypes.string,
    cp: PropTypes.string,
    address: PropTypes.string,
  }).isRequired,
  setNewGuest: PropTypes.func.isRequired,
  city: PropTypes.string.isRequired,
  setCity: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  setAddress: PropTypes.func.isRequired,
};

export default Formulaire;
