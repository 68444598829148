import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

function verifyToken(history) {
  const token = localStorage.getItem("TOKEN");
  return axios
    .get(`${API_URL}/api/admins/getAll`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .catch(() => {
      history.push("/login-admin");
    });
}

export default verifyToken;
