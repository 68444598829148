import React from "react";
import PropTypes from "prop-types";
import "./HeaderModale.scss";
import CloseButton from "../../../../0.Commun/CloseButton/CloseButton";

const HeaderModale = ({ setOpenDiscover, title }) => {
  const handleCloseModale = () => {
    setOpenDiscover(false);
  };

  return (
    <div className="header-modale">
      <h4 className="h4Body">{title}</h4>
      <CloseButton setClose={handleCloseModale} />
    </div>
  );
};

HeaderModale.propTypes = {
  setOpenDiscover: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default HeaderModale;
