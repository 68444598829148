import React, { useState } from "react";
import propTypes from "prop-types";
import Navbar from "../../../Navbar/Navbar";
import CloseButton from "../../../../../../../../0.Commun/CloseButton/CloseButton";
import "./ModifyRoom.scss";
import HandleRoomInfos from "./Components/HandleRoomInfos/HandleRoomInfos";
import HandleImages from "../../../HandleImages/HandleImages";
import PriceList from "../PriceList/PriceList";

function ModifyRoom({ idAccomodation, idRoom, setCloseModal }) {
  const [idNavbar, setIdNavbar] = useState(1);

  return (
    <section className="ModifyRoomAccomodation">
      <div className="bgBlur">
        <div className="popupAdminOpen">
          <CloseButton setClose={setCloseModal} />
          <h2 className="h2Admin">Modifier une chambre</h2>
          <Navbar setIdNavbar={setIdNavbar} inRoom />
          {idNavbar === 1 && <HandleRoomInfos idRoom={idRoom} />}
          {idNavbar === 2 && <HandleImages idRoom={idRoom} />}
          {idNavbar === 3 && (
            <PriceList idRoom={idRoom} idAccomodation={idAccomodation} />
          )}
        </div>
      </div>
      <p>Hello</p>
    </section>
  );
}

ModifyRoom.propTypes = {
  idAccomodation: propTypes.number.isRequired,
  idRoom: propTypes.number.isRequired,
  setCloseModal: propTypes.func.isRequired,
};

export default ModifyRoom;
