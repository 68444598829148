import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Catch from "../../../../../../../0.Commun/Catch/Catch";
import "./PriceList.scss";
import AddPrice from "../AddPrice/AddPrice";
import { messages } from "../../../../../../../../0.Commun/Message/Message";
import ModifyPrice from "../ModifyPrice/ModifyPrice";
import { notify } from "../../../../../../../../0.Commun/ToastNotif/ToastNotif";
import PopupConfirm from "../../../../../../../../0.Commun/PopupConfirm/PopupConfirm";
import Button from "../../../../../../../../0.Commun/Button/Button";

const API_URL = process.env.REACT_APP_API_URL;

function PriceList({ idAccomodation, idRoom }) {
  const [prices, setPrices] = useState({});
  const [priceToModify, setPriceToModify] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [closeModalAdd, setCloseModalAdd] = useState(false);
  const [closeModalModify, setCloseModalModify] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
  });
  const history = useHistory();

  const getPrices = () => {
    let priceType = "getByHebergement";
    let id = idAccomodation;
    if (idRoom !== 0) {
      priceType = "getByChambre";
      id = idRoom;
    }
    axios
      .get(`${API_URL}/api/tarifs_hebergement/${priceType}/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setPrices(data);
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  useEffect(() => {
    getPrices();
  }, [closeModalAdd, closeModalModify]);

  const modifyPrice = (idPrice) => {
    setPriceToModify(idPrice);
    setCloseModalModify(true);
  };

  const askDeletePrice = (idToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.price,
      id: idToDelete,
    });
  };

  const deletePrice = (idPrice) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .delete(`${API_URL}/api/tarifs_hebergement/${idPrice}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        notify(messages.delete.success, "success");
        getPrices();
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  return (
    <section className="PriceListAccomodation">
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={deletePrice}
      />
      <AddPrice
        idRoom={idRoom}
        idAccomodation={idAccomodation}
        closeModal={closeModalAdd}
        setCloseModal={setCloseModalAdd}
      />
      {closeModalModify && (
        <ModifyPrice
          idPrice={priceToModify}
          setCloseModal={setCloseModalModify}
        />
      )}
      {isLoading && (
        <div className="containerPriceAccomodation">
          {prices.map((price) => (
            <div key={price.id_tarif} className="onePrice">
              <p>{price.nom_tarif}</p>
              <div className="containerBtnPrice">
                <Button
                  text="Modifier"
                  handleClick={() => modifyPrice(price.id_tarif)}
                  marginValue="5px"
                />
                <Button
                  text="Supprimer"
                  handleClick={() => askDeletePrice(price.id_tarif)}
                  marginValue="5px"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
}

PriceList.propTypes = {
  idAccomodation: propTypes.number,
  idRoom: propTypes.number,
};

PriceList.defaultProps = {
  idAccomodation: undefined,
  idRoom: 0,
};

export default PriceList;
