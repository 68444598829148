import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  messagePopup,
  openPopup,
  selectedParc,
  filteredAccomodation,
  selectedAccomodation,
  lactivite,
  chargementParcs,
} from "../../../../5.Redux/Action";
import "./InputsGroupe.scss";

const API_URL = process.env.REACT_APP_API_URL;

const InputsGroupe = ({
  parcName,
  groupSizeInput,
  setIsLoadingSearch,
  setActiveButtonId,
  setGroupFilter,
  groupFilter,
}) => {
  const [isLoadingParcs, setIsLoadingParcs] = useState(false);
  const [openInput, setOpenInput] = useState(false);
  const [groupSize, setGroupSize] = useState(0);
  const dispatch = useDispatch();
  const parcs = useSelector((state) => state.tasks.parcs);
  const parcId = useSelector((state) => state.tasks.selectedParc);

  const resetBasket = () => {
    dispatch(selectedParc(0));
    dispatch(selectedAccomodation(0));
    dispatch(lactivite([]));
    dispatch(filteredAccomodation(0));
  };

  const getParcs = () => {
    axios
      .get(`${API_URL}/api/parc/getAll`)
      .then((res) => res.data)
      .then((data) => {
        dispatch(chargementParcs(data));
        setIsLoadingParcs(true);
      })
      .catch((err) => {
        dispatch(messagePopup(err));
        dispatch(openPopup(true));
      });
  };

  useEffect(() => {
    resetBasket();
    getParcs();
  }, []);

  const handleParc = (event) => {
    setGroupFilter({ ...groupFilter, parc: parseInt(event.target.value, 10) });
  };

  const handleGroupSize = (event) => {
    let groupMinValue;
    switch (event.target.value) {
      case "petit":
        groupMinValue = "7";
        break;
      case "moyen":
        groupMinValue = "12";
        break;
      case "grand":
        groupMinValue = "25";
        break;
      default:
        groupMinValue = "";
        break;
    }
    setGroupSize(groupMinValue);
    setGroupFilter({
      ...groupFilter,
      taille_groupe: groupMinValue,
    });
  };

  const openModal = () => {
    setOpenInput(!openInput);
  };

  const groupSelector = [
    {
      id: "1",
      taille_groupe: "petit",
      nombre_personnes: "de 7 à 12 personnes",
      groupe_min: "7",
      groupe_max: "12",
    },
    {
      id: "2",
      taille_groupe: "moyen",
      nombre_personnes: "de 12 à 25 personnes",
      groupe_min: "12",
      groupe_max: "25",
    },
    {
      id: "3",
      taille_groupe: "grand",
      nombre_personnes: "de 25 à 50 personnes",
      groupe_min: "25",
      groupe_max: "50",
    },
  ];

  const handleSubmitGroupSearch = () => {
    if (!parcName) {
      dispatch(messagePopup("Veuillez sélectionner un parc"));
      dispatch(openPopup(true));
    } else if (!groupSize) {
      dispatch(messagePopup("Veuillez indiquer une taille de groupe"));
      dispatch(openPopup(true));
    } else {
      dispatch(filteredAccomodation(groupFilter));
      dispatch(selectedParc(parcId));
      setIsLoadingSearch(false);
      setActiveButtonId("lesHebergementsGroupe");
      setOpenInput(false);
    }
  };

  return (
    <>
      <div className="sectionInputs">
        <div className="rechercher2">
          <button className="rechercherLink2" onClick={openModal} type="button">
            {openInput ? "Fermer" : "Rechercher"}
          </button>
        </div>
        <form className={openInput ? "inputsForm" : "inputsClose"}>
          <select
            className="input-groupe input-parc-groupe"
            type="text"
            name="inputParc"
            onChange={handleParc}
          >
            <option hidden value="">
              {parcName}
            </option>
            {isLoadingParcs && (
              <>
                {parcs.map((item) => (
                  <option key={item.id_parc} value={item.id_parc}>
                    {item.nom_parc}
                  </option>
                ))}
              </>
            )}
          </select>
          <select
            className="input-groupe"
            type="text"
            name="inputGroupSize"
            onChange={handleGroupSize}
          >
            <option hidden value="">
              {groupSizeInput}
            </option>

            <>
              {groupSelector.map((selector) => (
                <option key={selector.id} value={selector.taille_groupe}>
                  {selector.nombre_personnes}
                </option>
              ))}
            </>
          </select>
          <div className="rechercher-groupe">
            <button
              className="rechercherLinkGroupe"
              onClick={handleSubmitGroupSearch}
              type="button"
            >
              Rechercher
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

InputsGroupe.propTypes = {
  parcName: PropTypes.string.isRequired,
  groupSizeInput: PropTypes.string.isRequired,
  setIsLoadingSearch: PropTypes.func.isRequired,
  setActiveButtonId: PropTypes.func.isRequired,
  setGroupFilter: PropTypes.func.isRequired,
  groupFilter: PropTypes.shape({
    parc: PropTypes.number,
    taille_groupe: PropTypes.string,
  }).isRequired,
};

export default InputsGroupe;
