import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import logo from "../../../../Assets/logoPartirauvertFONDtransparent.png";
import "../../../3.Admin/0.Commun/NavBar/NavBar.scss";

const menus = [
  {
    id: 0,
    title: "CALENDRIER",
    path: "/partenaire/calendrier",
  },
  {
    id: 1,
    title: "MON COMPTE",
    path: "/partenaire/mon-compte",
  },
];

function NavBarPartenaire() {
  const history = useHistory();

  const handleClick = () => {
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("userId");
    history.push("/login-partenaire");
  };

  return (
    <section className="navBarAdmin">
      <div>
        <div className="navBarAdminContainerLogo">
          <img
            className="navBarAdminLogo"
            src={logo}
            alt="logo partir au vert"
          />
        </div>
        <div className="navBarAdminContainerList">
          <ul className="navBarAdminLists">
            {menus.map((menu) => {
              return (
                <li key={menu.id} className="navBarAdminList">
                  <NavLink
                    to={menu.path}
                    className="navBarAdminLink"
                    activeClassName="navBarAdminSelected"
                  >
                    <p>{menu.title}</p>
                  </NavLink>
                </li>
              );
            })}
            <li className="navBarAdminList">
              <button
                className="navBarAdminBtnDeco"
                type="button"
                onClick={handleClick}
              >
                DECONNEXION
              </button>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default NavBarPartenaire;
