import React from "react";
import PropTypes from "prop-types";
import "./PopupContainer.css";
import CloseButton from "../CloseButton/CloseButton";

function PopupContainer({
  children,
  setClose,
  widthvalue,
  heightValue,
  disableClose,
  center,
  minHeightValue,
}) {
  const style = {
    width: `${widthvalue}%`,
    height: `${heightValue}%`,
    minHeight: minHeightValue,
    top: `${(100 - heightValue) / 2}%`,
    left: `${(100 - widthvalue) / 2}%`,
  };

  const defineStyle = () => {
    if (center) {
      const newStyle = {
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
      return newStyle;
    }
    return style;
  };

  return (
    <section className="popupContainer-bgBlur">
      <div className="popupContainer-popupOpen" style={defineStyle()}>
        {!disableClose && <CloseButton setClose={setClose} />}
        <div>{children}</div>
      </div>
    </section>
  );
}

PopupContainer.propTypes = {
  children: PropTypes.node.isRequired,
  setClose: PropTypes.func,
  widthvalue: PropTypes.number,
  heightValue: PropTypes.number,
  disableClose: PropTypes.bool,
  center: PropTypes.bool,
  minHeightValue: PropTypes.string,
};

PopupContainer.defaultProps = {
  setClose: () => {},
  widthvalue: 90,
  heightValue: 90,
  disableClose: false,
  center: false,
  minHeightValue: "auto",
};

export default PopupContainer;
