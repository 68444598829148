import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./PasswordForgotten.scss";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../../../Assets/logoPartirauvertFONDtransparent.png";
import Input from "../../../../0.Commun/Input/Input";
import { checkEmail, verifEmail } from "../../../../0.Commun/Regex/Regex";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../../../0.Commun/Message/Message";
import Button from "../../../../0.Commun/Button/Button";
import { axiosPrivate } from "../../../../0.Commun/Axios/Axios";

function PasswordForgotten() {
  const [email, setEmail] = useState("");
  const history = useHistory();

  const handleEmail = (e) => {
    const resultRegex = checkEmail(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setEmail(e.target.value);
    }
  };

  const sendNewPword = () => {
    const resultRegex = verifEmail(email);
    if (!email) {
      notify(messages.missing.email, "error");
    } else if (!resultRegex) {
      notify(messages.error.email, "warn");
    } else {
      axiosPrivate({
        method: "POST",
        url: "partenaires/passwordForgotten",
        body: { email },
      }).then(() => {
        notify(messages.modify.forgotPassword, "success");
        history.push("/login-partenaire");
      });
    }
  };

  return (
    <section className="forgotPwordSection">
      <Helmet>
        <title>Mot de passe oublié</title>
      </Helmet>
      <div className="forgotPwordForm">
        <img src={logo} alt="Partir au Vert" className="forgotPwordLogo" />
        <Input
          type="email"
          name="email"
          placeholder="Email"
          placeholderDisplay={false}
          value={email}
          handleChange={(e) => handleEmail(e)}
        />
        <Button
          text="Réinitialiser"
          variant="variant"
          handleClick={sendNewPword}
        />
        <Link to="/login-partenaire">Retour à la page de connexion</Link>
        <Link to="/">Retour à au site</Link>
      </div>
    </section>
  );
}

export default PasswordForgotten;
