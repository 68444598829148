import React from "react";
import Helmet from "react-helmet";
import NavBar from "../0.Commun/NavBar/NavBar";
import "./Admin.scss";

function Admin() {
  return (
    <div className="adminPage">
      <Helmet>
        <title>Admin - Accueil</title>
      </Helmet>
      <NavBar />
      <div className="adminWelcome">
        <p>Bienvenue !</p>
      </div>
    </div>
  );
}

export default Admin;
