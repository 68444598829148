export const checkEmail = (emailToCheck) => {
  const regexEmail = /<|>|\*|\(|\)|\{|\}|\[|\]/;
  const resultEmail = regexEmail.test(emailToCheck);
  if (resultEmail) {
    return false;
  }
  return true;
};

export const verifEmail = (emailToCheck) => {
  const verifMail = /^[^@\s]+@[^@\s]+\.[\w]+$/;
  const resultRegex = verifMail.test(emailToCheck);
  if (!resultRegex) {
    return false;
  }
  return true;
};

export const checkPassword = (passwordToCheck) => {
  const regexPassword = /<|>|\(|\)|\{|\}|\[|\]/;
  const resultPassword = regexPassword.test(passwordToCheck);
  if (resultPassword) {
    return false;
  }
  return true;
};

export const verifLink = (linkToCheck) => {
  const regexLink = /^(http|https):\/\/[^@\s]+\.[\w]+$/;
  const resultLink = regexLink.test(linkToCheck);
  if (!resultLink) {
    return false;
  }
  return true;
};

export const checkName = (nameToCheck) => {
  const regexEmail = /<|>|\*|\(|\)|\{|\}|\[|\]|@|#|!|&|"|\||!|\$|£|µ|%|\?/;
  const resultEmail = regexEmail.test(nameToCheck);
  if (resultEmail) {
    return false;
  }
  return true;
};

export const checkPhone = (phoneToCheck) => {
  // eslint-disable-next-line prettier/prettier
  const regexPhone =
    /<|>|\*|\(|\)|\{|\}|\[|\]|@|#|!|&|"|\||!|\$|£|µ|%|\?|[a-zA-Z]/;
  const resultPhone = regexPhone.test(phoneToCheck);
  if (resultPhone) {
    return false;
  }
  return true;
};

export const checkMessage = (emailToCheck) => {
  const regexEmail = /<|>|\*|\{|\}|\[|\]/;
  const resultEmail = regexEmail.test(emailToCheck);
  if (resultEmail) {
    return false;
  }
  return true;
};

export const checkNumbers = (numberToCheck) => {
  const regexNumber = /^[0-9]*$/;
  const resultNumber = regexNumber.test(numberToCheck);
  if (resultNumber) {
    return true;
  }
  return false;
};
