import React from "react";
import Helmet from "react-helmet";
import Navbar from "../0.Commun/Navbar/Navbar";
import HeaderContact from "../4.Contact/HeaderContact/HeaderContact";
import InfosPratiques from "./InfosPratiques/InfosPratiques";
import Footer from "../0.Commun/Footer/Footer";

function Infos() {
  return (
    <div>
      <Helmet>
        <title>Partir au Vert - Infos Pratiques</title>
      </Helmet>
      <Navbar />
      <HeaderContact />
      <InfosPratiques />
      <Footer />
    </div>
  );
}

export default Infos;
