import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import ReactHtmlParser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { openPopup, messagePopup } from "../../../../5.Redux/Action";
import HeaderModale from "../HeaderModale/HeaderModale";
import "./ModaleActivite.scss";
import Button from "../../../../0.Commun/Button/Button";

const API_URL = process.env.REACT_APP_API_URL;

const ModaleActivite = ({
  activiteId,
  openDiscover,
  setOpenDiscover,
  putActivity,
  activityInitialState,
}) => {
  const [initialModalState, setInitialModalState] = useState({
    activiteId: 0,
    indexSelected: "0",
  });
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [images, setImages] = useState({});
  const [indexSelected, setIndexSelected] = useState(0);
  const [activiteInfos, setActiviteInfos] = useState({});
  const [descriptionActivite, setDescriptionActivite] = useState("");
  const dispatch = useDispatch();
  const activiteFinal = useSelector((state) => state.tasks.lactivite);

  const translateData = (htmlData) => {
    const translatedData = ReactHtmlParser(htmlData);
    return translatedData;
  };
  const getActiviteInfos = () => {
    setActiviteInfos(activityInitialState);
    setDescriptionActivite(
      translateData(activityInitialState.description_activite)
    );
    setIsLoadingData(true);
  };

  const getActiviteImages = () => {
    axios
      .get(`${API_URL}/api/images/getByTheme?activite=${activiteId}`)
      .then((res) => res.data)
      .then((data) => {
        setImages(data);
        setIsLoadingImages(true);
      })
      .catch((err) => {
        dispatch(messagePopup(err));
        dispatch(openPopup(true));
      });
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    getActiviteInfos();
    getActiviteImages();
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  const increaseIndex = () => {
    if (indexSelected === images.length - 1) {
      setIndexSelected(0);
    } else {
      setIndexSelected(indexSelected + 1);
    }
  };

  const determineClass = (index) => {
    if (index === indexSelected) {
      return "active-image";
    }
    return "inactive-image";
  };

  useEffect(() => {
    if (images.length > 1) {
      const timer = setTimeout(increaseIndex, 10000);
      return () => clearTimeout(timer);
    }
    return () => setInitialModalState({ ...initialModalState });
  }, [indexSelected]);

  return (
    <div className={openDiscover ? "modaleGlobalOpened" : "modaleGlobalClosed"}>
      <HeaderModale setOpenDiscover={setOpenDiscover} title="Activité" />
      {isLoadingData && (
        <div className="containerCarousel">
          {isLoadingImages && (
            <div className="containerMapAccomodation">
              {images.map((image, index) => (
                <div
                  className="containerImagesMapAccomodation"
                  key={image.id_image}
                >
                  <img
                    className={`${determineClass(index)}`}
                    src={`${API_URL}/images/${image.nom_image}`}
                    alt={image.description_image}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div className="name-container">
        <h4 className="h4Body">{activiteInfos.nom_activite}</h4>
      </div>
      <div className="modale-body">
        {activiteFinal.find(
          (item) => item.idActivityCart === activiteInfos.id_activite
        ) ? (
          <Button
            text="Retirer"
            handleClick={() =>
              putActivity(
                activiteInfos.id_activite,
                activiteInfos.nom_activite,
                activiteFinal
              )
            }
          />
        ) : (
          <Button
            text="Choisir cette actvité"
            handleClick={() =>
              putActivity(
                activiteInfos.id_activite,
                activiteInfos.nom_activite,
                activiteFinal
              )
            }
          />
        )}
        <div className="description-container">{descriptionActivite}</div>
      </div>
    </div>
  );
};

ModaleActivite.propTypes = {
  activiteId: PropTypes.number.isRequired,
  openDiscover: PropTypes.bool.isRequired,
  setOpenDiscover: PropTypes.func.isRequired,
  putActivity: PropTypes.func.isRequired,
  activityInitialState: PropTypes.shape({
    description_activite: PropTypes.string.isRequired,
  }).isRequired,
};

export default ModaleActivite;
