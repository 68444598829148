import { messages } from "../../../0.Commun/Message/Message";

function verifyImages(imageToVerify) {
  if (
    imageToVerify.type !== "image/webp" &&
    imageToVerify.type !== "image/jpeg" &&
    imageToVerify.type !== "image/jpg" &&
    imageToVerify.type !== "image/png"
  ) {
    return { response: false, message: messages.error.wrongImgFormat };
  }
  return true;
}

export default verifyImages;
