import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  messagePopup,
  openPopup,
  selectedAccomodation,
} from "../../../../5.Redux/Action";
import ModaleHebergement from "../ModaleHebergement/ModaleHebergement";
import { matchImageAndPrice } from "../../../0.Commun/MatchImageAndPrice/MatchImageAndPrice";
import "./TabHebergements.scss";
import Button from "../../../../0.Commun/Button/Button";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import { GetPrice } from "../../../0.Commun/Calcul/GetPrice";
import { messages } from "../../../../0.Commun/Message/Message";

const API_URL = process.env.REACT_APP_API_URL;

const TabHebergements = ({
  accomodationId,
  setAccomodationId,
  setIsDisabled,
}) => {
  const [isLoadingAccomodations, setIsLoadingAccomodations] = useState(false);
  const [groupAccomodations, setGroupAccomodations] = useState({});
  const [openDiscover, setOpenDiscover] = useState(false);
  const dispatch = useDispatch();
  const filterRedux = useSelector((state) => state.tasks.filter);
  const bookedAccomodation = useSelector(
    (state) => state.tasks.selectedAccomodation
  );

  const getGroupAccomodationsByParc = () => {
    axios
      .get(
        `${API_URL}/api/hebergements/getFilteredGroupHebergementByParc/${filterRedux.parc}?groupSizeMin=${filterRedux.taille_groupe}`
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.length === 0) {
          notify(messages.info.noAccomodation);
          setGroupAccomodations([]);
          setIsDisabled(false);
        } else {
          setGroupAccomodations(matchImageAndPrice(data));
          setIsLoadingAccomodations(true);
          setIsDisabled(true);
        }
      })
      .catch((err) => {
        dispatch(messagePopup(err.response.data.errorMessage));
        dispatch(openPopup(true));
      });
  };

  useEffect(() => {
    getGroupAccomodationsByParc();
  }, [filterRedux]);

  const handleClick = (idAccomodation) => {
    setAccomodationId(parseInt(idAccomodation, 10));
    setOpenDiscover(true);
  };

  const handleBookAccomodation = (id) => {
    dispatch(selectedAccomodation(id));
    setOpenDiscover(false);
  };

  const handleRemoveAccomodation = () => {
    dispatch(selectedAccomodation(0));
  };

  return (
    <>
      {openDiscover && (
        <ModaleHebergement
          accomodationId={accomodationId}
          openDiscover={openDiscover}
          setOpenDiscover={setOpenDiscover}
          handleBookAccomodation={handleBookAccomodation}
          handleRemoveAccomodation={handleRemoveAccomodation}
        />
      )}
      <div className="lesHebergementsGroupe">
        {isLoadingAccomodations &&
          groupAccomodations.map((accomodation) => (
            <div
              key={accomodation.id_hebergement}
              className="UnHebergementGroupe"
            >
              <div className="zoneImage1Hebergement">
                <img
                  className="testImage"
                  src={`${API_URL}/images/${accomodation.imageHebergement[0].nom_image}`}
                  alt={accomodation.description_image}
                />
              </div>
              <div className="zoneText1Hebergement">
                <h3 className="h3Body titreDeChaqueHebergement">
                  {accomodation.nom_hebergement}
                </h3>
                <div className="infos-cart-container">
                  <p className="max-capacity">
                    Capacité maximum : {accomodation.groupe_nb_personnes}{" "}
                    personnes.
                  </p>
                  {bookedAccomodation === accomodation.id_hebergement ? (
                    <Button
                      text="Retirer"
                      handleClick={handleRemoveAccomodation}
                    />
                  ) : (
                    <Button
                      text="Choisir"
                      handleClick={() =>
                        handleBookAccomodation(accomodation.id_hebergement)
                      }
                    />
                  )}
                </div>
              </div>
              <div className="containerPrice">
                <p className="priceP">A partir de</p>
                <p className="priceP">{GetPrice(accomodation.prices)}€</p>
                <p className="priceP">/nuit</p>
                <Button
                  text="Découvrir"
                  handleClick={() => handleClick(accomodation.id_hebergement)}
                  variant="variant"
                />
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

TabHebergements.propTypes = {
  accomodationId: PropTypes.number.isRequired,
  setAccomodationId: PropTypes.func.isRequired,
  setIsDisabled: PropTypes.func.isRequired,
};

export default TabHebergements;
