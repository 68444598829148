import React from "react";
import PropTypes from "prop-types";
import InputSearch from "../../../0.Commun/InputSearch/InputSearch";

const FiltreActiviteAdmin = ({
  isCheckedFilter,
  reloadActivitesList,
  searchValue,
  setSearchValue,
}) => {
  return (
    <>
      <div className="commandeAdminFiltre">
        <p>Filtrer :</p>
        <form className="commandeAdminFormFiltre">
          <label htmlFor="filtre-groupe" className="filtre-label">
            <input
              type="checkbox"
              className="filtre-checkbox"
              id="filtre-groupe"
              checked={isCheckedFilter}
              value={isCheckedFilter}
              onChange={(event) => reloadActivitesList(event)}
            />
            <p>Pour groupe</p>
          </label>
          <InputSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder="Rechercher une activité"
          />
        </form>
      </div>
    </>
  );
};

FiltreActiviteAdmin.propTypes = {
  isCheckedFilter: PropTypes.bool.isRequired,
  reloadActivitesList: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
};
export default FiltreActiviteAdmin;
