const initialState = {
  parcs: {},
  types: {},
  selectedParc: 0,
  filter: 0,
  selectedAccomodation: 0,
  lactivite: [],
  loption: [],
  openPopupMessage: false,
  messagePopup: "",
  keyword: "",
  newDateBlog: "",
  panier: {},
  idAdmin: 0,
  idDispo: 0,
  sort: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHARGEMENT_PARCS":
      return { ...state, parcs: action.uploadParcs };
    case "SELECTED_PARC":
      return { ...state, selectedParc: action.parcSelected };
    case "CHARGEMENT_TYPE":
      return { ...state, types: action.uploadType };
    case "FILTER":
      return { ...state, filter: action.accomodationFiltered };
    case "SELECTED_ACCOMODATION":
      return { ...state, selectedAccomodation: action.accomodationSelected };
    case "LACTIVITE_EN_COURS":
      return { ...state, lactivite: action.lactiviteEnCours };
    case "LOPTION_EN_COURS":
      return { ...state, loption: action.loptionEnCours };
    case "OPEN_MODAL_POPUP":
      return { ...state, openPopupMessage: action.openModalPopup };
    case "MESSAGE_POPUP":
      return { ...state, messagePopup: action.messageModalPopup };
    case "KEYWORD":
      return { ...state, keyword: action.keywordSelected };
    case "NEW_DATE_BLOG":
      return { ...state, newDateBlog: action.newBlogDate };
    case "PANIER":
      return { ...state, panier: action.updateCart };
    case "ADMIN":
      return { ...state, idAdmin: action.getAdmin };
    case "ID_DISPO":
      return { ...state, idDispo: action.getIdDispo };
    case "SORT_PRICE":
      return { ...state, sort: action.makeSort };
    default:
      return state;
  }
};

export default reducer;
