import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactHtmlParser from "html-react-parser";
import Helmet from "react-helmet";
import Navbar from "../0.Commun/Navbar/Navbar";
import Inputs from "../0.Commun/Inputs/Inputs";
import HeaderConcept from "./HeaderConcept/HeaderConcept";
import Footer from "../0.Commun/Footer/Footer";
import "./Accueil.scss";
import MapParcs from "./mapParcs/MapParcs";
import VignettesHome from "./VignettesHome/VignettesHome";

const API_URL = process.env.REACT_APP_API_URL;

function Accueil() {
  const [descriptionConcept, setDescriptionConcept] = useState("");

  const parseHtml = (htmlData) => {
    const newData = ReactHtmlParser(htmlData);
    setDescriptionConcept(newData);
  };
  const concept = () => {
    axios
      .get(`${API_URL}/api/concept/1`)
      .then((res) => res.data)
      .then((data) => {
        parseHtml(data.description_concept);
      });
  };

  useEffect(() => {
    concept();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Partir au Vert</title>
        <meta
          name="description"
          content="Partir au Vert vous propose des séjours nature en région parisienne. Composez vous même votre séjour ou réservez votre week-end clés en main"
        />
        <meta
          name="keywords"
          content="partir au vert, week-end, weekend, vacances, nature, campagne, paris, parisienne, ile-de-France, idf, parc naturel, parc national, ecotourisme, famille, groupe, couple, Vexin, Gatinais, Oise, Chevreuse"
        />
      </Helmet>
      <Navbar />
      <HeaderConcept />
      <Inputs />
      <div id="concept" className="sectionConcept">
        <div className="containerConceptText">
          <div className="quillText">{descriptionConcept}</div>
        </div>
        <VignettesHome />
        <div className="zoneTextTrait">
          <h2 className="h2Body titleConceptAndAccomodation">
            Nos destinations
          </h2>
          <hr className="trait" />
        </div>
        <MapParcs />
      </div>
      <Footer />
    </div>
  );
}

export default Accueil;
