import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import propTypes from "prop-types";
import Input from "../../../../0.Commun/Input/Input";
import { messages } from "../../../../0.Commun/Message/Message";
import { checkName } from "../../../../0.Commun/Regex/Regex";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import Catch from "../../../0.Commun/Catch/Catch";
import "./ModifyParc.scss";
import Quill from "../../../../0.Commun/Quill/Quill";
import Button from "../../../../0.Commun/Button/Button";
import InputImage from "../../../../0.Commun/InputImage/InputImage";
import DeleteButton from "../../../../0.Commun/DeleteButton/DeleteButton";
import PopupContainer from "../../../../0.Commun/PopupContainer/PopupContainer";

const API_URL = process.env.REACT_APP_API_URL;

function ModifyParc({ openModify, setOpenModify, parcToModify }) {
  const [parcName, setParcName] = useState("");
  const [parcDescription, setParcDescription] = useState("");
  const [isLoadingParc, setIsLoadingParc] = useState(false);
  const [images, setImages] = useState({});
  const [setIsLoadingImages, setSetIsLoadingImages] = useState(false);
  const [newImg, setNewImg] = useState([]);
  const history = useHistory();

  const getInfos = () => {
    axios
      .get(`${API_URL}/api/parc/getById/${parcToModify}`)
      .then((res) => {
        setParcName(res.data.nom_parc);
        setParcDescription(res.data.description_parc);
        setIsLoadingParc(true);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  const getImages = () => {
    axios
      .get(`${API_URL}/api/images/getByTheme?parc=${parcToModify}`)
      .then((res) => {
        setImages(res.data);
        setSetIsLoadingImages(true);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  useEffect(() => {
    if (parcToModify !== 0) {
      getInfos();
      getImages();
    }
  }, [parcToModify]);

  const handleName = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setParcName(e.target.value);
    }
  };

  const submitModify = () => {
    const token = localStorage.getItem("TOKEN");
    if (!parcName || !parcDescription) {
      notify(messages.missing.inputEmpty, "error");
    } else {
      axios
        .put(
          `${API_URL}/api/parc/${parcToModify}`,
          {
            nom_parc: parcName,
            description_parc: parcDescription,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          notify(messages.modify.success, "success");
          setOpenModify(false);
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  const deleteImage = (id, imageName) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .delete(`${API_URL}/api/images/${id}/${imageName}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        notify(messages.delete.success, "success");
        getImages(parcToModify);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  const addImages = () => {
    const token = localStorage.getItem("TOKEN");
    for (let i = 0; i < newImg.length; i += 1) {
      const newImage = new FormData();
      newImage.append("file", newImg[i]);
      newImage.append("description_image", newImg[i].name);
      newImage.append("parc_id", parcToModify);
      axios
        .post(`${API_URL}/api/images/new`, newImage, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          getImages();
          setNewImg([]);
          notify(messages.success.image, "success");
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  return (
    <section className="modifyParc">
      {openModify && (
        <PopupContainer setClose={setOpenModify}>
          <div>
            <h2 className="h2Admin">Modifier</h2>
            {isLoadingParc && (
              <div className="infoParc">
                <Input
                  name="modifTitreParc"
                  placeholder="Nom de la destination"
                  value={parcName}
                  handleChange={(e) => handleName(e)}
                />
                <p>Description de la destination :</p>
                <Quill text={parcDescription} setText={setParcDescription} />
                <Button
                  text="Modifier"
                  handleClick={() => submitModify()}
                  marginValue="0 0 30px 0"
                />
              </div>
            )}
            {setIsLoadingImages && (
              <div>
                <div className="containerParcImages">
                  {images.map((image) => (
                    <div key={image.id_image} className="containerOneImg">
                      <img
                        src={`${API_URL}/images/${image.nom_image}`}
                        alt={image.description_image}
                        className="parcImg"
                      />
                      <DeleteButton
                        onClick={() =>
                          deleteImage(image.id_image, image.nom_image)
                        }
                        marginValue="10px 0"
                      />
                    </div>
                  ))}
                </div>
                <div className="parcAdminAddNewImage">
                  <InputImage
                    newImg={newImg}
                    setNewImg={setNewImg}
                    multiple
                    marginValue="30px 0"
                  />
                  <Button
                    text="Ajouter"
                    handleClick={() => addImages()}
                    marginValue="0 0 30px 0"
                  />
                </div>
              </div>
            )}
          </div>
        </PopupContainer>
      )}
    </section>
  );
}

ModifyParc.propTypes = {
  openModify: propTypes.bool.isRequired,
  setOpenModify: propTypes.func.isRequired,
  parcToModify: propTypes.number.isRequired,
};

export default ModifyParc;
