import React, { useState } from "react";
import Helmet from "react-helmet";
import NavBar from "../0.Commun/NavBar/NavBar";
import "./Commande.scss";
import Filter from "./components/Filter/Filter";
import ListeCommande from "./components/ListCommandes/ListeCommande";

function Profil() {
  const [cmdNumber, setCmdNumber] = useState("");
  const [cmdName, setCmdName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedPack, setIsCheckedPack] = useState(false);
  const [isCheckedGroup, setIsCheckedGroup] = useState(false);

  return (
    <section>
      <Helmet>
        <title>Admin - Commandes</title>
      </Helmet>
      <NavBar />
      <div className="commandeAdminGlobal">
        <h1 className="h1Admin">Liste des pré-commandes</h1>
        <Filter
          cmdNumber={cmdNumber}
          setCmdNumber={setCmdNumber}
          cmdName={cmdName}
          setCmdName={setCmdName}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          isCheckedPack={isCheckedPack}
          setIsCheckedPack={setIsCheckedPack}
          isCheckedGroup={isCheckedGroup}
          setIsCheckedGroup={setIsCheckedGroup}
        />
        <ListeCommande
          cmdNumber={cmdNumber}
          cmdName={cmdName}
          isChecked={isChecked}
          isCheckedPack={isCheckedPack}
          isCheckedGroup={isCheckedGroup}
        />
      </div>
    </section>
  );
}

export default Profil;
