import React from "react";
import PropTypes from "prop-types";
import "./PlusMinusButtons.css";

function PlusMinusButtons({ addOne, removeOne, marginValue, children }) {
  const style = {
    margin: marginValue,
  };

  return (
    <div className="plusMinusButtons-containerBtns" style={style}>
      <button
        type="button"
        onClick={removeOne}
        className="plusMinusButtons-addRemoveBtn"
      >
        &#8722;
      </button>
      {children}
      <button
        type="button"
        onClick={addOne}
        className="plusMinusButtons-addRemoveBtn"
      >
        &#43;
      </button>
    </div>
  );
}

PlusMinusButtons.propTypes = {
  addOne: PropTypes.func.isRequired,
  removeOne: PropTypes.func.isRequired,
  marginValue: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PlusMinusButtons.defaultProps = {
  marginValue: "",
};

export default PlusMinusButtons;
