import React, { useState } from "react";
import "./VisualisationCalendrier.scss";
import PropTypes from "prop-types";
import CalendarBooked from "../../../../0.Commun/Calendrier/CalendarBooked";
import PopupConfirm from "../../../../0.Commun/PopupConfirm/PopupConfirm";
import Input from "../../../../0.Commun/Input/Input";
import Button from "../../../../0.Commun/Button/Button";
import { messages } from "../../../../0.Commun/Message/Message";
import { axiosPartner } from "../../../../0.Commun/Axios/Axios";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import { checkName } from "../../../../0.Commun/Regex/Regex";

const VisualisationCalendrier = ({
  setAllEventDate,
  urlToRequest,
  calendarId,
  isPartenairePanel,
  refreshCal,
  setRefreshCal,
}) => {
  const [eventToModify, setEventToModify] = useState();
  const [emptyBooked, setEmptyBook] = useState(false);
  const [visibleModifyEvent, setVisibleModifyEvent] = useState(false);
  const [selectedTile, setSelectedTile] = useState();
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
  });

  const askDeleteBooking = (idToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.booking,
      id: idToDelete,
    });
  };

  const removeEvent = () => {
    const uidEvent = deleteDialog.id.split("").splice(1).join("");
    axiosPartner({
      method: "DELETE",
      url: `calendriers/event/${calendarId}/${uidEvent}`,
    }).then(() => {
      setEmptyBook(false);
      notify(messages.delete.booking, "success");
      setRefreshCal(!refreshCal);
    });
  };

  const transformDate = (myDate) => {
    const valueDate = new Date(myDate);
    const yearDate = valueDate.getFullYear();
    const monthDate = valueDate.getMonth();
    const dayDate = valueDate.getDate();
    const pad = (n) => {
      return n < 10 ? `0${n}` : n;
    };
    const currentDate = `${yearDate}-${pad(monthDate + 1)}-${pad(dayDate)}`;
    return currentDate;
  };

  const modifyEvent = async () => {
    const updateBooking = {
      uid: eventToModify.uid,
      startDate: transformDate(eventToModify.startDate),
      endDate: transformDate(eventToModify.endDate),
      summary: eventToModify.summary,
      description: eventToModify.description,
    };
    axiosPartner({
      method: "PUT",
      url: `calendriers/event/${calendarId}`,
      body: updateBooking,
    }).then(() => {
      setEventToModify("");
      setVisibleModifyEvent(false);
      setRefreshCal(!refreshCal);
      notify(messages.modify.option, "success");
    });
  };

  const openModifyPopup = (event) => {
    setEventToModify(event);
    setVisibleModifyEvent(true);
  };

  const handleDate = (e) => {
    setEventToModify({ ...eventToModify, [e.target.name]: e.target.value });
  };

  const handleInfos = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setEventToModify({ ...eventToModify, [e.target.name]: e.target.value });
    }
  };

  return (
    <div className="containerPreviewCalendar">
      <CalendarBooked
        isPartenairePanel={isPartenairePanel}
        setEmptyBook={setEmptyBook}
        setSelectedTile={setSelectedTile}
        setAllEventDate={setAllEventDate}
        urlToRequest={urlToRequest}
        calendarId={calendarId}
        refreshCal={refreshCal}
      />
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={removeEvent}
      />
      {emptyBooked &&
        selectedTile.map((event) => (
          <div key={`${event.uid}div`} className="selectedTile">
            <div className="previewBoxHead">
              <p>
                Du {event.startDate.split(",").join("/")} au{" "}
                {event.endDate.split(",").join("/")}
              </p>
              {urlToRequest === "" && (
                <div className="buttons">
                  <Button
                    text="Modifier"
                    handleClick={() => openModifyPopup(event)}
                    marginValue="15px 0"
                  />
                  <Button
                    text="Supprimer"
                    handleClick={() => askDeleteBooking(event.uid)}
                    marginValue="15px 0"
                  />
                </div>
              )}
              <p>{event.summary}</p>
              <div className="descriptionBooking">
                <p>Description :</p>
                <p>{event.description}</p>
              </div>
            </div>
            {visibleModifyEvent && (
              <div className="containerModify">
                <Input
                  name="startDate"
                  type="date"
                  value={new Date(eventToModify.startDate)
                    .toLocaleDateString()
                    .split("/")
                    .reverse()
                    .join("-")}
                  handleChange={(e) => handleDate(e)}
                  widthValue="350px"
                />
                <Input
                  name="endDate"
                  type="date"
                  value={new Date(eventToModify.endDate)
                    .toLocaleDateString()
                    .split("/")
                    .reverse()
                    .join("-")}
                  handleChange={(e) => handleDate(e)}
                  widthValue="350px"
                />
                <Input
                  name="summary"
                  value={eventToModify.summary}
                  handleChange={(e) => handleInfos(e)}
                  widthValue="350px"
                  placeholder="Nom"
                />
                <Input
                  name="description"
                  value={eventToModify.description}
                  handleChange={(e) => handleInfos(e)}
                  widthValue="350px"
                  placeholder="Description"
                />
                <Button text="Modifier" handleClick={modifyEvent} />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

VisualisationCalendrier.propTypes = {
  setAllEventDate: PropTypes.func.isRequired,
  urlToRequest: PropTypes.string.isRequired,
  calendarId: PropTypes.number.isRequired,
  isPartenairePanel: PropTypes.bool,
  refreshCal: PropTypes.bool.isRequired,
  setRefreshCal: PropTypes.func.isRequired,
};

VisualisationCalendrier.defaultProps = {
  isPartenairePanel: false,
};

export default VisualisationCalendrier;
