import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Helmet from "react-helmet";
import Quill from "../../0.Commun/Quill/Quill";
import NavBar from "../0.Commun/NavBar/NavBar";
import "./Concept.scss";
import verifyToken from "../0.Commun/VerifyToken/VerifyToken";
import "react-quill/dist/quill.snow.css";
import { notify } from "../../0.Commun/ToastNotif/ToastNotif";
import Catch from "../0.Commun/Catch/Catch";
import { messages } from "../../0.Commun/Message/Message";
import Button from "../../0.Commun/Button/Button";

const API_URL = process.env.REACT_APP_API_URL;

function InfosConcept() {
  const [text, setText] = useState("");
  const [textGroupe, setTextGroupe] = useState("");
  const history = useHistory();

  const getConcept = () => {
    const token = localStorage.getItem("TOKEN");
    axios
      .get(`${API_URL}/api/concept/getAll`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setText(data[0].description_concept);
        setTextGroupe(data[1].description_concept);
      });
  };

  useEffect(() => {
    verifyToken(history);
    getConcept();
  }, []);

  const handleSubmitConcept = () => {
    const token = localStorage.getItem("TOKEN");
    axios
      .put(
        `${API_URL}/api/concept/1`,
        {
          description_concept: text,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        notify(messages.modify.success, "success");
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  const handleSubmitGroup = () => {
    const token = localStorage.getItem("TOKEN");
    axios
      .put(
        `${API_URL}/api/concept/2`,
        {
          description_concept: textGroupe,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        notify(messages.modify.success, "success");
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  return (
    <div>
      <Helmet>
        <title>Admin - Concept/groupe</title>
      </Helmet>
      <NavBar />
      <div className="sectionAdminConcept">
        <h1 className="h1Admin">Concept</h1>
        <div className="containerFormConcept">
          <p className="quill-label">Description du concept :</p>
          <Quill text={text} setText={setText} />
          <Button text="Modifier" handleClick={handleSubmitConcept} />
        </div>
      </div>
      <div className="sectionAdminConcept">
        <h1 className="h1Admin">Concept Groupe</h1>
        <div className="containerFormConcept">
          <p className="quill-label">Description de la page groupe :</p>
          <Quill text={textGroupe} setText={setTextGroupe} />
          <Button text="Modifier" handleClick={handleSubmitGroup} />
        </div>
      </div>
    </div>
  );
}

export default InfosConcept;
