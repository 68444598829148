import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { HashLink } from "react-router-hash-link";
import { GiBasket } from "react-icons/gi";
import ptilogo from "../../../../Assets/logoPartirauvertFONDtransparent.png";
import Dropdown from "./Dropdown";
import {
  chargementParcs,
  chargementType,
  messagePopup,
  openPopup,
} from "../../../5.Redux/Action";
import ReservationForm from "../../7.Groupe/components/ReservationForm/ReservationForm";
import { notify } from "../../../0.Commun/ToastNotif/ToastNotif";

const API_URL = process.env.REACT_APP_API_URL;

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [isNotEmpty, setIsNotEmpty] = useState(false);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const [openBasket, setOpenBasket] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const bookedAccomodation = useSelector(
    (state) => state.tasks.selectedAccomodation
  );
  const activiteFinal = useSelector((state) => state.tasks.lactivite);

  const displayBasketOrNot = () => {
    if (
      location.pathname === "/blog/all" ||
      location.pathname === "/contact" ||
      location.pathname === "/infos-pratiques" ||
      location.pathname === "/precommande"
    ) {
      setIsDisplayed(false);
    } else {
      setIsDisplayed(true);
    }
  };

  const basketIsNotEmpty = () => {
    if (bookedAccomodation || activiteFinal.length !== 0) {
      setIsNotEmpty(true);
    } else {
      setIsNotEmpty(false);
    }
  };

  const getType = () => {
    axios
      .get(`${API_URL}/api/types_hebergement`)
      .then((res) => res.data)
      .then((data) => {
        dispatch(chargementType(data));
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  useEffect(() => {
    displayBasketOrNot();
    basketIsNotEmpty();
  }, [bookedAccomodation, activiteFinal, location]);

  useEffect(() => {
    getType();
    axios
      .get(`${API_URL}/api/parc/getAll`)
      .then((res) => res.data)
      .then((data) => {
        dispatch(chargementParcs(data));
      })
      .catch((err) => {
        dispatch(messagePopup(err));
        dispatch(openPopup(true));
      });
  }, []);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 767) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 767) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const openModal = () => {
    setDropdown(!dropdown);
  };

  const checkBasket = () => {
    setOpenBasket(!openBasket);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <HashLink to="/#" className="navbar-logo" onClick={closeMobileMenu}>
            <img src={ptilogo} className="leptitlogo" alt="Partir au Vert" />
          </HashLink>

          <button
            type="button"
            className="menu-icon"
            onClick={handleClick}
            aria-label="BurgerMenuMobil"
          >
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </button>
          <ul className={click ? "activeNavMenu" : "nav-menu"}>
            <button
              className="nav-itemBtn"
              type="button"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={openModal}
            >
              <p className="nav-links">Où allez-vous ?</p>
              {dropdown && <Dropdown setClick={setClick} />}
            </button>
            <li className="nav-item">
              <HashLink to="/blog/all" className="nav-links">
                Blog
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/contact#"
                // id="contactLink"
                className="nav-links2"
                onClick={closeMobileMenu}
              >
                Contact
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink to="/groupe" className="nav-links">
                Groupe
              </HashLink>
            </li>
          </ul>
        </div>
        <div className="cart-container">
          {openBasket ? (
            <div className="check-basket">
              <ReservationForm />
            </div>
          ) : null}

          {isDisplayed && isNotEmpty ? (
            <>
              <GiBasket
                className="basket is-not-empty"
                onClick={() => checkBasket()}
              />
              <span className="buy-list is-not-empty">!</span>
            </>
          ) : (
            isDisplayed && (
              <GiBasket className="basket" onClick={() => checkBasket()} />
            )
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
