import React, { useEffect } from "react";
import "./AffichageRue.scss";
import PropTypes from "prop-types";
import axios from "axios";

function AffichageRue({ setAddressIsVisible, setAddress, address, cityCode }) {
  const [addresses, setAddresses] = React.useState([]);

  const getAddresses = () => {
    const newAddress = address.replace(/\s/g, "+");
    axios
      .get(
        `https://api-adresse.data.gouv.fr/search/?q=${newAddress}&citycode=${cityCode}&limit=20&autocomplete=1`
      )
      .then((response) => {
        setAddresses(response.data.features);
      });
  };

  useEffect(() => {
    getAddresses();
  }, [address]);

  const handleClick = (street) => {
    setAddress(street.name);
    setAddressIsVisible(false);
  };

  return (
    <div className="containerListCities">
      {addresses?.map((street) => (
        <div className="listCities" key={street.properties.id}>
          <option
            onClick={() => {
              handleClick(street.properties);
            }}
          >
            {street.properties.name}
          </option>
        </div>
      ))}
    </div>
  );
}
AffichageRue.propTypes = {
  setAddressIsVisible: PropTypes.func.isRequired,
  setAddress: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  cityCode: PropTypes.string.isRequired,
};

export default AffichageRue;
