import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../../../../Assets/logoPartirauvertFONDtransparent.png";
import Button from "../../../../0.Commun/Button/Button";
import Input from "../../../../0.Commun/Input/Input";
import { messages } from "../../../../0.Commun/Message/Message";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import Catch from "../../../../3.Admin/0.Commun/Catch/Catch";
import "./ResetPassword.scss";

const API_URL = process.env.REACT_APP_API_URL;

function ResetPassword() {
  const [newPassword, setNewPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const location = useLocation();
  const history = useHistory();

  const checkToken = () => {
    const [id] = location.pathname.split("/").slice(2, 3);
    const token = location.pathname.split("/").pop();
    axios
      .get(`${API_URL}/api/partenaires/checkToken/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          idpartenaire: id,
        },
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  useEffect(() => {
    checkToken();
  }, []);

  const handlePword = (e) => {
    setNewPassword({ ...newPassword, [e.target.name]: e.target.value });
  };

  const resetPword = () => {
    const [id] = location.pathname.split("/").slice(2, 3);
    const token = location.pathname.split("/").pop();
    if (!newPassword.password || !newPassword.confirmPassword) {
      notify(messages.missing.imputEmpty, "warn");
    } else if (newPassword.password === newPassword.confirmPassword) {
      axios
        .put(
          `${API_URL}/api/partenaires/modifyPassword/${id}`,
          {
            newPassword: newPassword.password,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              idpartenaire: id,
            },
          }
        )
        .then(() => {
          notify(messages.modify.password, "success");
          history.push("/login-partenaire");
        });
    } else {
      notify(messages.error.passwordVerificationWrong, "warn");
    }
  };

  return (
    <section className="resetPasswordSection">
      <Helmet>
        <title>Réinitialisation mot de passe</title>
      </Helmet>
      <div className="resetPwordForm">
        <img src={logo} alt="Partir au Vert" className="resetPwordLogo" />
        <Input
          name="password"
          type="password"
          placeholder="Nouveau mot de passe"
          placeholderDisplay={false}
          value={newPassword.password}
          handleChange={(e) => handlePword(e)}
        />
        <Input
          name="confirmPassword"
          type="password"
          placeholder="Confirmation mot de passe"
          placeholderDisplay={false}
          value={newPassword.confirmPassword}
          handleChange={(e) => handlePword(e)}
        />
        <Button text="Modifier" handleClick={resetPword} variant="variant" />
      </div>
    </section>
  );
}

export default ResetPassword;
