export const chargementParcs = (uploadParcs) => {
  return {
    type: "CHARGEMENT_PARCS",
    uploadParcs,
  };
};

export const selectedParc = (parcSelected) => {
  return {
    type: "SELECTED_PARC",
    parcSelected,
  };
};

export const chargementType = (uploadType) => {
  return {
    type: "CHARGEMENT_TYPE",
    uploadType,
  };
};

export const filteredAccomodation = (accomodationFiltered) => {
  return {
    type: "FILTER",
    accomodationFiltered,
  };
};

export const selectedAccomodation = (accomodationSelected) => {
  return {
    type: "SELECTED_ACCOMODATION",
    accomodationSelected,
  };
};

export const lactivite = (lactiviteEnCours) => {
  return {
    type: "LACTIVITE_EN_COURS",
    lactiviteEnCours,
  };
};

export const loption = (loptionEnCours) => {
  return {
    type: "LOPTION_EN_COURS",
    loptionEnCours,
  };
};

export const openPopup = (openModalPopup) => {
  return {
    type: "OPEN_MODAL_POPUP",
    openModalPopup,
  };
};

export const messagePopup = (messageModalPopup) => {
  return {
    type: "MESSAGE_POPUP",
    messageModalPopup,
  };
};

export const keywordsSelected = (keywordSelected) => {
  return {
    type: "KEYWORD",
    keywordSelected,
  };
};

export const newDateBlog = (newBlogDate) => {
  return {
    type: "NEW_DATE_BLOG",
    newBlogDate,
  };
};

export const updatePanier = (updateCart) => {
  return {
    type: "PANIER",
    updateCart,
  };
};

export const getIdAdmin = (getAdmin) => {
  return {
    type: "ADMIN",
    getAdmin,
  };
};

export const getIdPartenaire = (getPartenaire) => {
  return {
    type: "PARTENAIRE",
    getPartenaire,
  };
};

export const getDispo = (getIdDispo) => {
  return {
    type: "ID_DISPO",
    getIdDispo,
  };
};

export const sortPrice = (makeSort) => {
  return {
    type: "SORT_PRICE",
    makeSort,
  };
};
