import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Helmet from "react-helmet";
import { IoIosPeople } from "react-icons/io";
import Quill from "../../0.Commun/Quill/Quill";
import NavBar from "../0.Commun/NavBar/NavBar";
import FiltreActiviteAdmin from "./components/FiltreActiviteAdmin";
import "./ActivitesAdmin.scss";
import verifyToken from "../0.Commun/VerifyToken/VerifyToken";
import "react-quill/dist/quill.snow.css";
import ModifyActivite from "./components/ModifyActivity/ModifyActivity";
import ToggleGroupActiviteAdmin from "./components/ToggleGroupActiviteAdmin/ToggleGroupActiviteAdmin";
import { messages } from "../../0.Commun/Message/Message";
import Catch from "../0.Commun/Catch/Catch";
import { notify } from "../../0.Commun/ToastNotif/ToastNotif";
import AddButton from "../../0.Commun/AddButton/AddButton";
import Button from "../../0.Commun/Button/Button";
import Input from "../../0.Commun/Input/Input";
import Select from "../../0.Commun/Select/Select";
import InputImage from "../../0.Commun/InputImage/InputImage";
import PopupContainer from "../../0.Commun/PopupContainer/PopupContainer";
import PopupConfirm from "../../0.Commun/PopupConfirm/PopupConfirm";
import { scrollLock } from "../../0.Commun/ScrollLock/ScrollLock";

const API_URL = process.env.REACT_APP_API_URL;

function ActivitesAdmin() {
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    modifyActiviteIL: false,
    parcIL: false,
  });
  const [isLoading2, setIsLoading2] = useState(false);
  const [infosRecupParc, setInfosRecupParc] = useState([]);
  const [openModal, setOpenModal] = useState({
    openModify: false,
    openAdd: false,
    openGlobal: false,
  });
  const [openModalModify, setOpenModalModify] = useState(false);
  const [selectedParc, setSelectedParc] = useState();
  const [infosRecupActivites, setInfosRecupActivites] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedFilter, setIsCheckedFilter] = useState(false);
  const [idToModify, setIdToModify] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [nomActivite, setNomActivite] = useState("");
  const [descriptionActivite, setDescriptionActivite] = useState("");
  const [tarifActivite1, setTarifActivite1] = useState("");
  const [tarifActivite2, setTarifActivite2] = useState("");
  const [tarifActivite3, setTarifActivite3] = useState("");
  const [tarifActivite4, setTarifActivite4] = useState("");
  const [tarifActivite5, setTarifActivite5] = useState("");
  const [tarifActivite6, setTarifActivite6] = useState("");
  const [tarifActiviteGroupe, setTarifActiviteGroupe] = useState("");
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
  });
  const [newImg, setNewImg] = useState([]);
  const history = useHistory();

  useEffect(() => {
    scrollLock(openModal.openAdd);
  }, [openModal.openAdd]);

  useEffect(() => {
    scrollLock(openModal.openModify);
  }, [openModal.openModify]);

  const recupParc = () => {
    axios
      .get(`${API_URL}/api/parc/getAll`)
      .then((res) => res.data)
      .then((data) => {
        setInfosRecupParc(data);
      })
      .then(() => {
        setIsLoading({ ...isLoading, parcIL: true });
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  const recupActivites = (id) => {
    axios
      .get(`${API_URL}/api/activites/getByParc/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setInfosRecupActivites(data);
        setIsLoading2(true);
        setOpenModal({ ...openModal, openAdd: false, openModify: false });
      });
  };

  const recupGroupActivites = () => {
    const token = localStorage.getItem("TOKEN");
    axios
      .get(`${API_URL}/api/activites/getByParc/${selectedParc}?isGroup=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setInfosRecupActivites(data);
        setIsLoading2(true);
        setOpenModal({ ...openModal, openAdd: false, openModify: false });
      });
  };

  const reloadActivitesList = (event) => {
    setIsCheckedFilter(event.target.checked);
    setSearchValue("");
    if (event.target.checked) {
      recupGroupActivites();
    } else {
      recupActivites(selectedParc);
    }
  };

  const handleParcSelectChange = (e) => {
    setSelectedParc(parseInt(e.target.value, 10));
    recupActivites(parseInt(e.target.value, 10));
    setOpenModal({ ...openModal, openGlobal: true });
  };

  useEffect(() => {
    verifyToken(history);
    recupParc();

    if (infosRecupParc) {
      setIsLoading({ ...isLoading, isLoading: true });
    }
  }, []);

  const uploadImages = (selectedActivite) => {
    const token = localStorage.getItem("TOKEN");
    const newImage = new FormData();
    newImage.append("file", newImg[0]);
    newImage.append("description_image", newImg[0].name);
    newImage.append("activite_id", selectedActivite);
    axios
      .post(`${API_URL}/api/images/new`, newImage, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setNewImg([]);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  const openPopupActivite = (activityId) => {
    setOpenModalModify(true);
    setIdToModify(activityId);
  };

  const submitActivite = () => {
    const token = localStorage.getItem("TOKEN");
    if (!nomActivite || !descriptionActivite || newImg.length === 0) {
      notify(messages.missing.inputEmpty, "warn");
    } else {
      let selectedActivite = 0;
      axios
        .post(
          `${API_URL}/api/activites/`,
          {
            nom_activite: nomActivite,
            description_activite: descriptionActivite,
            groupe_activite: isChecked ? "1" : "0",
            tarif_activite_1pax: tarifActivite1,
            tarif_activite_2pax: tarifActivite2,
            tarif_activite_3pax: tarifActivite3,
            tarif_activite_4pax: tarifActivite4,
            tarif_activite_5pax: tarifActivite5,
            tarif_activite_6pax: tarifActivite6,
            tarif_groupe: tarifActiviteGroupe,
            parc_id: selectedParc,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data)
        .then((data) => {
          selectedActivite = data.id_activite;
        })
        .then(() => {
          uploadImages(selectedActivite);
        })
        .then(() => {
          notify(messages.create.activity, "success");
          recupActivites(selectedParc);
          setOpenModal({ ...openModal, openAdd: false });
          setNomActivite("");
          setDescriptionActivite("");
          setIsChecked(false);
          setTarifActivite1();
          setTarifActivite2();
          setTarifActivite3();
          setTarifActivite4();
          setTarifActivite5();
          setTarifActivite6();
          setTarifActiviteGroupe();
        })
        .catch((err) => {
          notify(err.response.data.errorMessage, "error");
        });
    }
  };

  const askDeleteActivite = (idToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.activity,
      id: idToDelete,
    });
  };

  const deleteActivite = (id) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .delete(`${API_URL}/api/activites/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then(() => {
        notify(messages.delete.activity, "success");
        recupActivites(selectedParc);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const openAddModal = () => {
    setOpenModal({ ...openModal, openAdd: !openModal.openAdd });
  };

  return (
    <div className="adminActivitesPage">
      <Helmet>
        <title>Admin - Activités</title>
      </Helmet>
      <NavBar />
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={deleteActivite}
      />
      {openModalModify && (
        <ModifyActivite
          activiteId={idToModify}
          setOpenModal={setOpenModalModify}
          recupActivites={recupActivites}
          selectedParc={selectedParc}
        />
      )}
      <div className="sectionAdminActivites">
        <h1 className="h1Admin">Activités</h1>
        {isLoading.parcIL && (
          <div className="zoneChoixParcActivite">
            <Select
              datas={infosRecupParc}
              optionValue="nom_parc"
              onChange={(e) => handleParcSelectChange(e)}
              introValue="Veuillez choisir une destination"
            />
          </div>
        )}
        <div className="activiteDuParcChoisi">
          {selectedParc && (
            <>
              <AddButton
                title="Ajouter une activité &#43;"
                handleClick={openAddModal}
              />
            </>
          )}

          {openModal.openAdd ? (
            <PopupContainer setClose={openAddModal}>
              <form className="activiteAdminContainerLabel">
                <Input
                  name="titreActivite"
                  placeholder="Titre de l'activité"
                  value={nomActivite}
                  handleChange={(e) => setNomActivite(e.target.value)}
                />
                <ToggleGroupActiviteAdmin
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                />
                <p className="quill-label">Description de l&#39;activité :</p>
                <Quill
                  className="activiteAdminNewDescription"
                  text={descriptionActivite}
                  setText={setDescriptionActivite}
                />
                <div className="twoColumnsActivityPrice">
                  <div className="activiteAdminLabel">
                    <p>Tarif individuel</p>
                    <Input
                      name="pricePax1"
                      placeholder="Prix 1 pax"
                      value={tarifActivite1}
                      handleChange={(e) => setTarifActivite1(e.target.value)}
                      widthvalue="90px"
                    />
                    <Input
                      name="pricePax2"
                      placeholder="Prix 2 pax"
                      value={tarifActivite2}
                      handleChange={(e) => setTarifActivite2(e.target.value)}
                      widthvalue="90px"
                    />
                    <Input
                      name="pricePax3"
                      placeholder="Prix 3 pax"
                      value={tarifActivite3}
                      handleChange={(e) => setTarifActivite3(e.target.value)}
                      widthvalue="90px"
                    />
                    <Input
                      name="pricePax4"
                      placeholder="Prix 4 pax"
                      value={tarifActivite4}
                      handleChange={(e) => setTarifActivite4(e.target.value)}
                      widthvalue="90px"
                    />
                    <Input
                      name="pricePax5"
                      placeholder="Prix 5 pax"
                      value={tarifActivite5}
                      handleChange={(e) => setTarifActivite5(e.target.value)}
                      widthvalue="90px"
                    />
                    <Input
                      name="pricePax6"
                      placeholder="Prix 6 pax"
                      value={tarifActivite6}
                      handleChange={(e) => setTarifActivite6(e.target.value)}
                      widthvalue="90px"
                    />
                  </div>
                  <div className="activiteAdminLabel">
                    <p>Tarif groupe</p>
                    <Input
                      name="tarifGroupe"
                      placeholder="Groupe"
                      value={tarifActiviteGroupe}
                      handleChange={(e) => setTarifActiviteGroupe(e.target.value)}
                      widthvalue="90px"
                    />
                  </div>
                </div>
                <InputImage newImg={newImg} setNewImg={setNewImg} />
                <div className="activiteAdminContainerBtnAdd">
                  <Button
                    text="Ajouter l'activité"
                    handleClick={submitActivite}
                  />
                </div>
              </form>
            </PopupContainer>
          ) : (
            selectedParc && (
              <FiltreActiviteAdmin
                isCheckedFilter={isCheckedFilter}
                reloadActivitesList={reloadActivitesList}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            )
          )}
          {isLoading2 && infosRecupActivites &&
            infosRecupActivites
              .filter((filteredActivity) =>
                filteredActivity.nom_activite
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    searchValue
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  )
              )
              .map((activite) => (
                <div className="activiteAdminList" key={activite.id_activite}>
                  <p className="nomActivite">{activite.nom_activite}</p>
                  <div className="activiteAdminContainerBtn">
                    <span className="groupe-icon">
                      {activite.groupe_activite ? (
                        <IoIosPeople className="react-icon" />
                      ) : (
                        ""
                      )}
                    </span>
                    <Button
                      text="Modifier"
                      handleClick={() =>
                        openPopupActivite(activite.id_activite)
                      }
                      marginValue="5px"
                    />
                    <Button
                      text="Supprimer"
                      handleClick={() =>
                        askDeleteActivite(activite.id_activite)
                      }
                      marginValue="5px"
                    />
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}

export default ActivitesAdmin;
