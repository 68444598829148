import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  selectedAccomodation,
  messagePopup,
  openPopup,
} from "../../../5.Redux/Action";
import "./ListeHebergements.scss";
import { matchImageAndPrice } from "../../0.Commun/MatchImageAndPrice/MatchImageAndPrice";

const API_URL = process.env.REACT_APP_API_URL;

function ListeHebergements() {
  const dispatch = useDispatch();
  const [accomodations, setAccomodations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const filter = useSelector((state) => state.tasks.filter);
  const idParc = useSelector((state) => state.tasks.selectedParc);
  const getSort = useSelector((state) => state.tasks.sort);

  const getAccomodation = () => {
    if (filter.type !== undefined) {
      axios
        .get(
          `${API_URL}/api/hebergements/getFiltered?typeHebergement=${filter.type}&parcId=${filter.parc}&nbPersonne=${filter.nbPersonnes}`
        )
        .then((res) => res.data)
        .then((data) => {
          setAccomodations(matchImageAndPrice(data));
        })
        .then(() => {
          setIsLoading(true);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    } else if (filter.parc) {
      axios
        .get(
          `${API_URL}/api/hebergements/getFiltered?parcId=${filter.parc}&nbPersonne=${filter.nbPersonnes}`
        )
        .then((res) => res.data)
        .then((data) => {
          setAccomodations(matchImageAndPrice(data));
        })
        .then(() => {
          setIsLoading(true);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    } else {
      axios
        .get(`${API_URL}/api/hebergements/getByParcWithImages/${idParc}`)
        .then((res) => res.data)
        .then((data) => {
          setAccomodations(matchImageAndPrice(data));
        })
        .then(() => {
          setIsLoading(true);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    }
  };

  useEffect(() => {
    getAccomodation();
  }, [idParc, filter, getSort]);

  const handleClick = (idAccomodation) => {
    dispatch(selectedAccomodation(parseInt(idAccomodation, 10)));
  };

  return (
    <div>
      <div className="lesHebergements">
        {isLoading &&
          accomodations
            .sort((a, b) =>
              getSort === 1
                ? b.prixDappel - a.prixDappel
                : a.prixDappel - b.prixDappel
            )
            .map((accomodation) => (
              <div key={accomodation.id_hebergement} className="UnHebergement">
                <Link
                  to={`/hebergement/${
                    accomodation.id_hebergement
                  }/${accomodation.nom_hebergement.replace(/\s/g, "_")}`}
                  onClick={() => handleClick(accomodation.id_hebergement)}
                >
                  <div className="zoneImage1Hebergement">
                    <img
                      className="testImage"
                      src={`${API_URL}/images/${accomodation.imageHebergement[0].nom_image}`}
                      alt={accomodation.description_image}
                    />
                  </div>
                </Link>
                <div className="zoneText1Hebergement">
                  <h3 className="h3Body titreDeChaqueHebergement">
                    {accomodation.nom_hebergement}
                  </h3>
                </div>
                <div className="containerPrice">
                  <p className="priceP">A partir de </p>
                  <p className="priceP">{accomodation.prixDappel}€</p>
                  <p className="priceP">/nuit</p>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}
export default ListeHebergements;
