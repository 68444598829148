import axios from "axios";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";
import Quill from "../../0.Commun/Quill/Quill";
import NavBar from "../0.Commun/NavBar/NavBar";
import "./Package.scss";
import { checkEmail } from "../../0.Commun/Regex/Regex";
import HandlePackage from "./HandlePackage/HandlePackage";
import { messages } from "../../0.Commun/Message/Message";
import Catch from "../0.Commun/Catch/Catch";
import { notify } from "../../0.Commun/ToastNotif/ToastNotif";
import AddButton from "../../0.Commun/AddButton/AddButton";
import Button from "../../0.Commun/Button/Button";
import Input from "../../0.Commun/Input/Input";
import Select from "../../0.Commun/Select/Select";
import InputImage from "../../0.Commun/InputImage/InputImage";
import PopupContainer from "../../0.Commun/PopupContainer/PopupContainer";
import PopupConfirm from "../../0.Commun/PopupConfirm/PopupConfirm";
import { scrollLock } from "../../0.Commun/ScrollLock/ScrollLock";

const API_URL = process.env.REACT_APP_API_URL;

function Package() {
  const [packages, setPackages] = useState({});
  const [isLoadingPackage, setIsLoadingPackage] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [newPackageDescription, setNewPackageDescription] = useState("");
  const [newPackage, setNewPackage] = useState({
    name: "",
    accomodation: "",
    parc: "",
  });
  const [newImage, setNewImage] = useState([]);
  const [parcs, setParcs] = useState({});
  const [accomodations, setAccomodations] = useState({});
  const [isLoadingAccomodation, setIsLoadingAccomodation] = useState(false);
  const [idToModify, setIdToModify] = useState({});
  const [openModify, setOpenModify] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
  });
  const history = useHistory();

  const getPackages = () => {
    axios
      .get(`${API_URL}/api/packages/getAll`)
      .then((res) => res.data)
      .then((data) => {
        setPackages(data);
        setIsLoadingPackage(true);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  useEffect(() => {
    getPackages();
    scrollLock(openModify);
  }, [openModify]);

  useEffect(() => {
    scrollLock(openAdd);
  }, [openAdd]);

  const openAddModal = () => {
    axios
      .get(`${API_URL}/api/parc/getAll`)
      .then((res) => res.data)
      .then((data) => {
        setParcs(data);
        setOpenAdd(!openAdd);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const handleNewName = (event) => {
    const resultCheck = checkEmail(event.target.value);
    if (!resultCheck) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setNewPackage({ ...newPackage, name: event.target.value });
    }
  };

  const chooseParc = (event) => {
    axios
      .get(`${API_URL}/api/hebergements/getByParc/${event.target.value}`)
      .then((res) => res.data)
      .then((data) => {
        setAccomodations(data);
        setNewPackage({ ...newPackage, parc: event.target.value });
        setIsLoadingAccomodation(true);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const chooseAccomodation = (event) => {
    setNewPackage({ ...newPackage, accomodation: event.target.value });
  };

  const addPackage = () => {
    const token = localStorage.getItem("TOKEN");
    if (
      !newPackage.name ||
      !newPackage.accomodation ||
      !newPackage.parc ||
      newImage.length === 0
    ) {
      notify(messages.missing.inputEmpty, "warn");
    } else {
      let idPackage = 0;
      axios
        .post(
          `${API_URL}/api/packages/package`,
          {
            nom_package: newPackage.name,
            description_package: newPackageDescription,
            hebergement_id: newPackage.accomodation,
            parc_id: newPackage.parc,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data)
        .then((data) => {
          getPackages();
          setNewPackageDescription("");
          setNewPackage({
            name: "",
            accomodation: "",
            parc: "",
          });
          setOpenAdd(false);
          idPackage = data.id_package;
        })
        .then(() => {
          const newImg = new FormData();
          newImg.append("file", newImage[0]);
          newImg.append("description_image", newPackage.name);
          newImg.append("package_id", idPackage);
          axios
            .post(`${API_URL}/api/images/new`, newImg, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then(() => {
              notify(messages.create.package, "success");
              setNewImage([]);
            })
            .catch((err) => {
              Catch(err, history);
            });
        })
        .catch((err) => {
          notify(err.response.data.errorMessage, "error");
        });
    }
  };

  const askDeletePackage = (idToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.package,
      id: idToDelete,
    });
  };

  const deletePackage = (idToDelete) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .delete(`${API_URL}/api/packages/package/${idToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        getPackages();
        notify(messages.delete.package, "success");
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const openModifyModal = (id) => {
    setIdToModify(id);
    setOpenModify(true);
  };

  return (
    <section>
      <Helmet>
        <title>Admin - Packages</title>
      </Helmet>
      <NavBar />
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={deletePackage}
      />
      <div className="containerGlobalAdminPackage">
        <h1 className="h1Admin">Package</h1>
        <div>
          <AddButton
            title="Créer un package &#43;"
            handleClick={openAddModal}
          />
        </div>
        {openAdd && (
          <PopupContainer setClose={openAddModal}>
            <div className="containerAddPackage">
              <form className="containerFormAdminPackage">
                <Input
                  name="namePackage"
                  placeholder="Nom package"
                  value={newPackage.name}
                  handleChange={(e) => handleNewName(e)}
                />
                <div className="containerSelect">
                  <Select
                    datas={parcs}
                    optionValue="nom_parc"
                    onChange={(e) => chooseParc(e)}
                    introValue="Veuillez choisir une destination"
                  />
                </div>
                {isLoadingAccomodation && (
                  <div className="containerSelect">
                    <Select
                      datas={accomodations}
                      optionValue="nom_hebergement"
                      onChange={(e) => chooseAccomodation(e)}
                      introValue="Veuillez choisir un hébergement"
                    />
                  </div>
                )}
                <p className="quill-label">Description du package :</p>
                <Quill
                  text={newPackageDescription}
                  setText={setNewPackageDescription}
                />
                <InputImage newImg={newImage} setNewImg={setNewImage} />
                <Button
                  text="Créer"
                  handleClick={addPackage}
                  marginValue="10px"
                />
              </form>
            </div>
          </PopupContainer>
        )}
        <h2 className="h2Admin">Liste des packages</h2>
        {isLoadingPackage && (
          <div>
            {packages.map((pack) => (
              <div key={pack.id_package} className="packageAdminList">
                <p className="packageAdminListName">{pack.nom_package}</p>
                <div className="packageAdminContainerBtn">
                  <Button
                    text="Modifier"
                    handleClick={() => openModifyModal(pack.id_package)}
                    marginValue="5px"
                  />
                  <Button
                    text="Supprimer"
                    handleClick={() => askDeletePackage(pack.id_package)}
                    marginValue="5px"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {openModify && (
          <HandlePackage
            openModify={openModify}
            setOpenModify={setOpenModify}
            idToModify={idToModify}
          />
        )}
      </div>
    </section>
  );
}

export default Package;
