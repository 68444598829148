import React from "react";
import PropTypes from "prop-types";
import "./ToggleGroupActiviteAdmin.scss";

const ToggleGroupActiviteAdmin = ({ isChecked, setIsChecked }) => {
  const toggleGroupValue = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div>
      <div className="toggle-groupe-container">
        <label className="filtre-label" htmlFor="groupe">
          <input
            className="filtre-checkbox"
            type="checkbox"
            name="groupe"
            checked={isChecked}
            value={isChecked}
            onChange={(event) => toggleGroupValue(event)}
          />
          Activité praticable en groupe ?
        </label>
      </div>
    </div>
  );
};

ToggleGroupActiviteAdmin.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  setIsChecked: PropTypes.func.isRequired,
};

export default ToggleGroupActiviteAdmin;
