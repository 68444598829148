import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { axiosPartner } from "../../0.Commun/Axios/Axios";
import CalendrierPartenaire from "../0.Commun/CalendrierPartenaire/CalendrierPartenaire";
import NavBarPartenaire from "../0.Commun/Navbar/NavBarPartenaire";
import "./Calendrier.scss";

function Calendrier() {
  const [accomodations, setAccomodations] = useState([]);
  const [calendarIsVisible, setCalendarIsVisible] = useState(false);
  const [calendarId, setCalendarId] = useState(null);

  const getAccomodations = () => {
    const userId = localStorage.getItem("userId");
    axiosPartner({ method: "GET", url: `calendriers/userCal/${userId}` }).then(
      (data) => {
        setAccomodations(data);
      }
    );
  };

  useEffect(() => {
    getAccomodations();
  }, []);

  const openCalendar = (idCalendar) => {
    setCalendarIsVisible(true);
    setCalendarId(idCalendar);
  };

  return (
    <section className="calendrierPro">
      <NavBarPartenaire />
      <Helmet>
        <title>Mes calendriers</title>
      </Helmet>
      <div className="containerCalendrier">
        <h1 className="h1Admin">Mes calendriers</h1>
        <div className="containerCards">
          {accomodations.length > 0 ? (
            accomodations?.map((accomodation) => (
              <button
                type="button"
                className="cardAccomodation"
                key={accomodation.calPartenaire_id}
                onClick={() => openCalendar(accomodation.calPartenaire_id)}
              >
                <h3 className="h3Body">
                  {accomodation.calPartenaire_hebergement_name}
                </h3>
              </button>
            ))
          ) : (
            <p>
              Aucun hébergement disponible, veuillez contacter Partir au vert.
            </p>
          )}
        </div>
      </div>
      {calendarIsVisible && (
        <CalendrierPartenaire
          stateVisibility={setCalendarIsVisible}
          calendarId={calendarId}
          isPartenairePanel
        />
      )}
    </section>
  );
}

export default Calendrier;
