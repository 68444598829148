import React, { useEffect } from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGa from "react-ga4";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import reducer from "./components/5.Redux/Reducer";
import Accueil from "./components/1.Public/1.Accueil/Accueil";
import Parc from "./components/1.Public/2.Parc/Parc";
import Hebergement from "./components/1.Public/3.1.Hebergements/Hebergement";
import PackagePage from "./components/1.Public/3.2.Packages/PackagePage";
import PreCommande from "./components/1.Public/3.3.Precommande/PreCommande";
import ContactForm from "./components/1.Public/4.Contact/Contact";
import InfosPratiques from "./components/1.Public/5.Infos_Pratiques/Infos";
import Blog from "./components/1.Public/6.Blog/Blog";
import OneArticle from "./components/1.Public/6.Blog/OneArticle/OneArticle";
import Groupe from "./components/1.Public/7.Groupe/Groupe";
import LogAdmin from "./components/2.Login/LogAdmin/LogAdmin";
import LogPartenaire from "./components/2.Login/LogPartenaire/LogPartenaire";
import Admin from "./components/3.Admin/1.Accueil/Admin";
import Profil from "./components/3.Admin/2.Profil/Profil";
import Commandes from "./components/3.Admin/3.Commande/Commande";
import BlogAdmin from "./components/3.Admin/4.Blog/Blog";
import ParcAdmin from "./components/3.Admin/5.Parc/Parc";
import HebergementsAdmin from "./components/3.Admin/6.Hebergement/Accomodation";
import Package from "./components/3.Admin/7.Package/Package";
import ActivitesAdmin from "./components/3.Admin/8.Activites/ActivitesAdmin";
import OptionsAdmin from "./components/3.Admin/9.Options/OptionsAdmin";
import Partenaire from "./components/3.Admin/10.Partenaire/Partenaire";
import TarifFixe from "./components/3.Admin/11.Tarif_Fixe/TarifFixe";
import Concept from "./components/3.Admin/12.Concept/Concept";
import InfosPra from "./components/3.Admin/13.InfosPratiques/InfosPra";
import Calendrier from "./components/4.Pro/1.Calendrier/Calendrier";
import MonCompte from "./components/4.Pro/2.Compte/MonCompte";
import PasswordForgotten from "./components/2.Login/LogPartenaire/components/PasswordForgotten/PasswordForgotten";
import ResetPassword from "./components/2.Login/LogPartenaire/components/ResetPassword/ResetPassword";
import Page404 from "./components/1.Public/8.Page404/Page404";

const rootReducer = combineReducers({
  tasks: reducer,
});

export const store = createStore(
  rootReducer /* ,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() */
);

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGa.initialize("G-Z23H9S0FWR");
      ReactGa.send("pageview");
    }
  }, []);

  return (
    <Provider store={store}>
      <ToastContainer autoClose={5000} />
      <Router>
        <Switch>
          <Route exact path="/" component={Accueil} />
          <Route path="/parc/:id/:name" component={Parc} />
          <Route path="/hebergement/:id/:name" component={Hebergement} />
          <Route path="/package/:id/:name" component={PackagePage} />
          <Route path="/contact" component={ContactForm} />
          <Route path="/precommande" component={PreCommande} />
          <Route path="/infos-pratiques" component={InfosPratiques} />
          <Route path="/blog/all" component={Blog} />
          <Route path="/groupe" component={Groupe} />
          <Route path="/blog/:id/:name" component={OneArticle} />
          <Route path="/login-admin" component={LogAdmin} />
          <Route path="/admin/admin" component={Admin} />
          <Route path="/admin/profil" component={Profil} />
          <Route path="/admin/commandes" component={Commandes} />
          <Route path="/admin/blog" component={BlogAdmin} />
          <Route path="/admin/parc" component={ParcAdmin} />
          <Route path="/admin/hebergements" component={HebergementsAdmin} />
          <Route path="/admin/package" component={Package} />
          <Route path="/admin/activites" component={ActivitesAdmin} />
          <Route path="/admin/options" component={OptionsAdmin} />
          <Route path="/admin/partenaire" component={Partenaire} />
          <Route path="/admin/tarif_fixe" component={TarifFixe} />
          <Route path="/admin/concept" component={Concept} />
          <Route path="/admin/infos" component={InfosPra} />
          <Route path="/login-partenaire" component={LogPartenaire} />
          <Route path="/partenaire/calendrier" component={Calendrier} />
          <Route path="/partenaire/mon-compte" component={MonCompte} />
          <Route path="/forgot-password" component={PasswordForgotten} />
          <Route path="/reset-password/:id/:token" component={ResetPassword} />
          <Route path="/admin/options" component={OptionsAdmin} />
          <Route path="*" component={Page404} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
