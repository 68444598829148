import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IoIosPeople } from "react-icons/io";
import Catch from "../0.Commun/Catch/Catch";
import NavBar from "../0.Commun/NavBar/NavBar";
import "./Accomodation.scss";
import AddAccomodation from "./components/AddAccomodation/AddAccomodation";
import FilterAccomodation from "./components/Filter/FilterAccomodation";
import { scrollLock } from "../../0.Commun/ScrollLock/ScrollLock";
import { messages } from "../../0.Commun/Message/Message";
import ModifyAccomodation from "./components/ModifyAccomodation/ModifyAccomodation";
import PopupConfirm from "../../0.Commun/PopupConfirm/PopupConfirm";
import Select from "../../0.Commun/Select/Select";
import Button from "../../0.Commun/Button/Button";
import AddButton from "../../0.Commun/AddButton/AddButton";
import { notify } from "../../0.Commun/ToastNotif/ToastNotif";

const API_URL = process.env.REACT_APP_API_URL;

function Accomodation() {
  const [parcs, setParcs] = useState({});
  const [isLoadingParcs, setIsLoadingParcs] = useState(false);
  const [selectedParc, setSelectedParc] = useState(0);
  const [accomodations, setAccomodations] = useState({});
  const [isLoadingAccomodation, setIsLoadingAccomodation] = useState(false);
  const [openAddAccomodation, setOpenAddAccomodation] = useState(false);
  const [accomodationTypes, setAccomodationTypes] = useState({});
  const [isGroupFilter, setIsGroupFilter] = useState(0);
  const [searchedAccomodation, setSearchedAccomodation] = useState("");
  const [filteredType, setFilteredType] = useState(0);
  const [openModifyAccomodation, setOpenModifyAccomodation] = useState(false);
  const [idToModify, setIdToModify] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
  });
  const history = useHistory();

  const getParcs = () => {
    axios
      .get(`${API_URL}/api/parc/getAll`)
      .then((res) => res.data)
      .then((data) => {
        setParcs(data);
        setIsLoadingParcs(true);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  const getTypes = () => {
    axios
      .get(`${API_URL}/api/types_hebergement`)
      .then((res) => res.data)
      .then((data) => setAccomodationTypes(data))
      .catch((err) => {
        Catch(err, history);
      });
  };

  useEffect(() => {
    getParcs();
    getTypes();
  }, []);

  const destiRequest = (id) => {
    axios
      .get(`${API_URL}/api/hebergements/getByParc/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setAccomodations(data);
        setIsLoadingAccomodation(true);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setAccomodations([]);
        }
        Catch(err, history);
      });
  };

  const chooseParc = (e) => {
    const id = parseInt(e.target.value, 10);
    setSelectedParc(id);
    destiRequest(id);
  };

  useEffect(() => {
    if (selectedParc !== 0 && !openAddAccomodation) {
      destiRequest(selectedParc);
    }
    scrollLock(openAddAccomodation);
  }, [openAddAccomodation]);

  const openModalAdd = () => {
    setOpenAddAccomodation(true);
  };

  useEffect(() => {
    if (selectedParc !== 0 && !openModifyAccomodation) {
      destiRequest(selectedParc);
    }
    scrollLock(openModifyAccomodation);
  }, [openModifyAccomodation]);

  const openModalModify = (id) => {
    setOpenModifyAccomodation(true);
    setIdToModify(id);
  };

  const askDeleteAccomodation = (idToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.accomodation,
      id: idToDelete,
    });
  };

  const deleteAccomodation = (id) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .delete(`${API_URL}/api/hebergements/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        destiRequest(selectedParc);
        notify(messages.delete.accomodation, "success");
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  return (
    <section>
      <Helmet>
        <title>Admin - Hébergements</title>
      </Helmet>
      <NavBar />
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={deleteAccomodation}
      />
      {openAddAccomodation && (
        <AddAccomodation
          setCloseModal={setOpenAddAccomodation}
          idParc={selectedParc}
        />
      )}
      {openModifyAccomodation && (
        <ModifyAccomodation
          setCloseModal={setOpenModifyAccomodation}
          idAccomodation={idToModify}
        />
      )}
      <div className="accomodationAdminGlobal">
        <h1 className="h1Admin">Hébergements</h1>
        <div className="containerSelect">
          {isLoadingParcs && (
            <Select
              datas={parcs}
              optionValue="nom_parc"
              onChange={(e) => chooseParc(e)}
              introValue="Veuillez choisir une destination"
            />
          )}
        </div>
        {selectedParc !== 0 && (
          <AddButton
            title="Ajouter un hébergement +"
            handleClick={openModalAdd}
          />
        )}
        {isLoadingAccomodation && (
          <div>
            <FilterAccomodation
              setIsGroup={setIsGroupFilter}
              isGroup={isGroupFilter}
              setSearchValue={setSearchedAccomodation}
              searchValue={searchedAccomodation}
              setFilteredType={setFilteredType}
              accomodationTypes={accomodationTypes}
            />
            <div className="listAccomodation">
              {accomodations
                .filter(
                  (accomodationFiltered) =>
                    !filteredType ||
                    accomodationFiltered.type_hebergement_id === filteredType
                )
                .filter((accomodationFiltered) =>
                  accomodationFiltered.nom_hebergement
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .includes(
                      searchedAccomodation
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                    )
                )
                .filter(
                  (groupAccomodation) =>
                    !isGroupFilter ||
                    groupAccomodation.groupe_hebergement === isGroupFilter
                )
                .map((accomodation) => (
                  <div key={accomodation.id_hebergement} className="oneCard">
                    <p className="accomodationName">
                      {accomodation.nom_hebergement}
                    </p>
                    <div className="cardBtn">
                      {accomodation.groupe_hebergement ? (
                        <IoIosPeople className="groupIcon" />
                      ) : (
                        ""
                      )}
                      <Button
                        text="Modifier"
                        handleClick={() =>
                          openModalModify(accomodation.id_hebergement)
                        }
                        marginValue="5px"
                      />
                      <Button
                        text="Supprimer"
                        handleClick={() =>
                          askDeleteAccomodation(accomodation.id_hebergement)
                        }
                        marginValue="5px"
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Accomodation;
