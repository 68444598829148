/* eslint-disable no-param-reassign */
import axios from "axios";
import { messages } from "../Message/Message";
import { notify } from "../ToastNotif/ToastNotif";

const API_URL = process.env.REACT_APP_API_URL;

export const axiosPrivate = async ({ method = "", url = "", body = null }) => {
  const axiosInstance = axios.create({
    baseURL: API_URL,
  });

  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("TOKEN");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  try {
    const response = await axiosInstance({
      method,
      url: `/api/${url}`,
      data: body,
    });
    if (response.status === 204) {
      return [];
    }
    return response.data;
  } catch (err) {
    let errorMessage;
    if (err.response.status === 401) {
      localStorage.removeItem("TOKEN");
      errorMessage = messages.session.expired;
      setTimeout(() => {
        window.location.href = "/";
      }, 2500);
    } else if (!err.response.data.errorMessage) {
      errorMessage = messages.error.globalError;
    } else {
      errorMessage = err.response.data.errorMessage;
    }
    notify(errorMessage, "error");
    return Promise.reject(errorMessage);
  }
};

export const axiosPartner = async ({ method = "", url = "", body = null }) => {
  const axiosInstance = axios.create({
    baseURL: API_URL,
  });

  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("TOKEN");
    const idPartner = localStorage.getItem("userId");
    if (token && idPartner) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.idpartenaire = idPartner;
    }
    return config;
  });

  try {
    const response = await axiosInstance({
      method,
      url: `/api/${url}`,
      data: body,
    });
    return response.data;
  } catch (err) {
    let errorMessage;
    if (err.response.status === 401) {
      localStorage.removeItem("TOKEN");
      errorMessage = messages.session.expired;
      setTimeout(() => {
        window.location.href = "/";
      }, 2500);
    } else if (err.response.status === 204) {
      return "No content";
    } else {
      errorMessage = err.response.data.errorMessage;
    }
    notify(errorMessage, "error");
    return Promise.reject(errorMessage);
  }
};
