import React, { useState } from "react";
import PropTypes from "prop-types";
import "./FormulaireCalendrier.scss";
import { axiosPartner } from "../../../../0.Commun/Axios/Axios";
import Input from "../../../../0.Commun/Input/Input";
import Button from "../../../../0.Commun/Button/Button";
import { checkName } from "../../../../0.Commun/Regex/Regex";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../../../0.Commun/Message/Message";

const FormulaireCalendrier = ({
  allEventDate,
  calendarId,
  refreshCal,
  setRefreshCal,
}) => {
  const [newBooking, setNewBooking] = useState({
    startDate: "",
    endDate: "",
    name: "",
    description: "",
  });

  const handleInfos = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setNewBooking({ ...newBooking, [e.target.name]: e.target.value });
    }
  };

  const transformDate = (myDate) => {
    const valueDate = new Date(myDate);
    const yearDate = valueDate.getFullYear();
    const monthDate = valueDate.getMonth();
    const dayDate = valueDate.getDate();
    const pad = (n) => {
      return n < 10 ? `0${n}` : n;
    };
    const currentDate = `${yearDate}-${pad(monthDate + 1)}-${pad(dayDate)}`;
    return currentDate;
  };

  const calculateIntervall = (debut, fin) => {
    const dtStart = debut.split(",").join("-");
    const dtEnd = fin.split(",").join("-");

    const nowDateSt = new Date(dtStart);
    const dates = [];

    while (nowDateSt < new Date(dtEnd)) {
      dates.push(new Date(nowDateSt));
      nowDateSt.setDate(nowDateSt.getDate() + 1);
    }
    return dates;
  };

  const isEmptyDate = (array, start, end) => {
    if (array.length > 0) {
      const arrDate = [];
      array.forEach((item) => {
        arrDate.push(
          calculateIntervall(item.date_debut_ical, item.date_fin_ical)
        );
      });
      let spreaDtList = [];
      arrDate.map((reservRange) => {
        reservRange.forEach((item) => {
          const newDateFormat = transformDate(item);
          spreaDtList = spreaDtList.concat(newDateFormat);
        });
        return spreaDtList;
      });
      const newEventDuration = calculateIntervall(start, end);
      const updateDtNewEvent = newEventDuration.map((item) => {
        const newDate = transformDate(item);
        return newDate;
      });
      const arrBoolExist = [];
      updateDtNewEvent.forEach((date) => {
        const ifExist = spreaDtList.includes(date);
        return arrBoolExist.push(ifExist);
      });
      const resultBool = arrBoolExist.includes(true);
      if (resultBool === true) {
        return false;
      }
      return true;
    }
    return true;
  };

  const handleCreateEvent = () => {
    const emptyDate = isEmptyDate(
      allEventDate,
      newBooking.startDate,
      newBooking.endDate
    );
    if (emptyDate === true) {
      if (newBooking.endDate < newBooking.startDate) {
        notify(messages.error.dateIncoherent, "error");
      } else {
        axiosPartner({
          method: "PUT",
          url: `calendriers/newEvent/${calendarId}`,
          body: newBooking,
        }).then(() => {
          setNewBooking({
            startDate: "",
            endDate: "",
            name: "",
            description: "",
          });
          notify(messages.create.booking, "success");
          setRefreshCal(!refreshCal);
        });
      }
    } else {
      notify(messages.error.dateBooked, "error");
    }
  };

  return (
    <div className="containerFormCal">
      <div className="addEvents">
        <h3 className="h3Admin">Ajouter un évènement</h3>
        <Input
          name="startDate"
          placeholder="Début"
          value={newBooking.startDate}
          handleChange={(e) => handleInfos(e)}
          type="date"
          marginValue="30px 0 0 0"
        />
        <Input
          name="endDate"
          placeholder="Fin"
          value={newBooking.endDate}
          handleChange={(e) => handleInfos(e)}
          type="date"
          marginValue="30px 0 0 0"
        />
        <Input
          name="name"
          placeholder="Nom"
          value={newBooking.name}
          handleChange={(e) => handleInfos(e)}
          marginValue="30px 0 0 0"
        />
        <Input
          name="description"
          placeholder="Description"
          value={newBooking.description}
          handleChange={(e) => handleInfos(e)}
          marginValue="30px 0 0 0"
        />
        <Button
          text="Enregistrer cet évènement"
          handleClick={handleCreateEvent}
          marginValue="30px 0"
        />
      </div>
    </div>
  );
};

FormulaireCalendrier.propTypes = {
  allEventDate: PropTypes.arrayOf(
    PropTypes.shape({
      date_debut_ical: PropTypes.string.isRequired,
      date_fin_ical: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
    })
  ).isRequired,
  calendarId: PropTypes.number.isRequired,
  refreshCal: PropTypes.bool.isRequired,
  setRefreshCal: PropTypes.func.isRequired,
};

export default FormulaireCalendrier;
