import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import ReactHtmlParser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { openPopup, messagePopup } from "../../../../5.Redux/Action";
import HeaderModale from "../HeaderModale/HeaderModale";
import "./ModaleHebergement.scss";
import Button from "../../../../0.Commun/Button/Button";

const API_URL = process.env.REACT_APP_API_URL;

const ModaleHebergement = ({
  accomodationId,
  openDiscover,
  setOpenDiscover,
  handleBookAccomodation,
  handleRemoveAccomodation,
}) => {
  const [initialModalState, setInitialModalState] = useState({
    accomodationId: 0,
    indexSelected: "0",
  });
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [images, setImages] = useState({});
  const [indexSelected, setIndexSelected] = useState(0);
  const [accomodationInfos, setAccomodationInfos] = useState({});
  const [descriptionHebergement, setDescriptionHeberement] = useState("");
  const dispatch = useDispatch();
  const bookedAccomodation = useSelector(
    (state) => state.tasks.selectedAccomodation
  );
  const translateData = (htmlData) => {
    const translatedData = ReactHtmlParser(htmlData);
    setDescriptionHeberement(translatedData);
  };

  const getAccomodationInfos = () => {
    axios
      .get(`${API_URL}/api/hebergements/getById/${accomodationId}`)
      .then((res) => res.data)
      .then((data) => {
        setAccomodationInfos(data);
        setIsLoadingData(true);
        translateData(data.description_hebergement);
      })
      .catch((err) => {
        dispatch(messagePopup(err));
        dispatch(openPopup(true));
      });
  };

  const getAccomodationImages = () => {
    axios
      .get(`${API_URL}/api/images/getByTheme?hebergement=${accomodationId}`)
      .then((res) => res.data)
      .then((data) => {
        setImages(data);
        setIsLoadingImages(true);
      })
      .catch((err) => {
        dispatch(messagePopup(err));
        dispatch(openPopup(true));
      });
  };

  useEffect(() => {
    getAccomodationInfos();
    getAccomodationImages();
  }, []);

  const increaseIndex = () => {
    if (indexSelected === images.length - 1) {
      setIndexSelected(0);
    } else {
      setIndexSelected(indexSelected + 1);
    }
  };

  const determineClass = (index) => {
    if (index === indexSelected) {
      return "active-image";
    }
    return "inactive-image";
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    const timer = setTimeout(increaseIndex, 10000);
    return () => {
      document.body.style.overflow = "visible";
      setInitialModalState({ ...initialModalState });
      clearTimeout(timer);
    };
  }, [indexSelected]);

  return (
    <div className={openDiscover ? "modaleGlobalOpened" : "modaleGlobalClosed"}>
      <HeaderModale setOpenDiscover={setOpenDiscover} title="Hébergement" />
      {isLoadingData && (
        <div className="containerCarousel">
          {isLoadingImages && (
            <div className="containerMapAccomodation">
              {images.map((image, index) => (
                <div
                  className="containerImagesMapAccomodation"
                  key={image.id_image}
                >
                  <img
                    className={`${determineClass(index)}`}
                    src={`${API_URL}/images/${image.nom_image}`}
                    alt={image.description_image}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div className="name-container">
        <h4 className="h4Body">{accomodationInfos.nom_hebergement}</h4>
      </div>
      <div className="modale-body">
        {bookedAccomodation === accomodationInfos.id_hebergement ? (
          <Button text="Retirer" handleClick={handleRemoveAccomodation} />
        ) : (
          <Button
            text="Choisir cet hébergement"
            handleClick={() =>
              handleBookAccomodation(accomodationInfos.id_hebergement)
            }
          />
        )}
        <div className="description-container">{descriptionHebergement}</div>
      </div>
    </div>
  );
};

ModaleHebergement.propTypes = {
  accomodationId: PropTypes.number.isRequired,
  openDiscover: PropTypes.bool.isRequired,
  setOpenDiscover: PropTypes.func.isRequired,
  handleBookAccomodation: PropTypes.func.isRequired,
  handleRemoveAccomodation: PropTypes.func.isRequired,
};

export default ModaleHebergement;
