import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { isWithinInterval } from "date-fns";
import "./CalendarBooked.scss";
import { axiosPartner } from "../Axios/Axios";

const API_URL = process.env.REACT_APP_API_URL;

function CalendarBooked({
  urlToRequest,
  isPartenairePanel,
  setAllEventDate,
  calendarId,
  setSelectedTile,
  setEmptyBook,
  refreshCal,
  onChangeBasic,
}) {
  const [dateValue, setDateValue] = useState(new Date());
  const [booked, setBooked] = useState([]);

  const getInfo = () => {
    if (urlToRequest !== "") {
      axiosPartner({
        method: "GET",
        url: `calendriers?urlRequest=${urlToRequest}`,
      }).then((data) => {
        if (data === "No content") {
          setBooked([]);
        } else {
          setBooked(data);
        }
        setAllEventDate(data);
      });
    } else {
      axiosPartner({
        method: "GET",
        url: `calendriers?urlRequest=${API_URL}/api/calendriers/download/${calendarId}`,
      }).then((data) => {
        if (data === "No content") {
          setBooked([]);
        } else {
          setBooked(data);
        }
        setAllEventDate(data);
      });
    }
  };

  useEffect(() => {
    getInfo();
  }, [refreshCal]);

  const transformDate = (myDate) => {
    const valueDate = new Date(myDate);
    const yearDate = valueDate.getFullYear();
    const monthDate = valueDate.getMonth();
    const dayDate = valueDate.getDate();
    const pad = (n) => {
      return n < 10 ? `0${n}` : n;
    };
    const currentDate = `${yearDate},${pad(monthDate + 1)},${pad(dayDate)}`;
    return currentDate;
  };

  const calculateIntervall = (debut, fin) => {
    const dtStart = debut.split(",").join("-");
    const dtEnd = fin.split(",").join("-");

    const nowDateSt = new Date(dtStart);
    const dates = [];

    while (nowDateSt < new Date(dtEnd)) {
      dates.push(new Date(nowDateSt));
      nowDateSt.setDate(nowDateSt.getDate() + 1);
    }
    return dates;
  };

  const handleChange = (dateVal) => {
    setDateValue(dateVal);
  };

  const disabledRanges = [];

  const isWithinRange = (dateRange, range) => {
    return isWithinInterval(dateRange, { start: range[0], end: range[1] });
  };

  const isWithinRanges = (dateRanges, ranges) => {
    return ranges.some((range) => isWithinRange(dateRanges, range));
  };

  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      booked.forEach((newBooked) => {
        const dateFin = new Date(newBooked.date_fin_ical);
        const data = [
          new Date(newBooked.date_debut_ical),
          dateFin.setDate(dateFin.getDate() - 1),
        ];
        disabledRanges.push(data);
      });
    }
    return isWithinRanges(date, disabledRanges);
  };

  const handleInfoDay = (value) => {
    const oneLineEventArr = [];
    const currentDate = transformDate(value);
    const arrayOfEvent = booked.map((day) => {
      const intervalArr = calculateIntervall(
        day.date_debut_ical,
        day.date_fin_ical
      );
      const updateInfoEvent = intervalArr.map((item) => {
        const objectEvent = {
          date: transformDate(item),
          summary: day.summary,
          description: day.description,
          uid: day.uid,
          startDate: day.date_debut_ical,
          endDate: day.date_fin_ical,
        };
        return objectEvent;
      });
      return updateInfoEvent;
    });
    arrayOfEvent.forEach((periode) => {
      periode.forEach((day) => {
        oneLineEventArr.push(day);
      });
    });
    const filterDate = oneLineEventArr.filter(
      (day) => currentDate === day.date
    );

    const finalResult = () => {
      setSelectedTile(filterDate);
      if (filterDate.length === 0) {
        setEmptyBook(false);
      } else {
        setEmptyBook(true);
      }
    };
    return finalResult();
  };

  const createEventTileType = () => {
    const arrDate = [];
    const finalDateArr = [];
    if (booked.length !== 0) {
      booked.forEach((newBooked) => {
        arrDate.push(
          calculateIntervall(newBooked.date_debut_ical, newBooked.date_fin_ical)
        );
      });
    }

    const startEvent = arrDate.map((reservRange) => {
      const arrayBegin = [];
      arrayBegin.push(reservRange[0]);
      return arrayBegin;
    });

    startEvent.map((item) => {
      const modifyItem = transformDate(item);
      return finalDateArr.push({
        itemDate: modifyItem,
        type: "tilesColor",
      });
    });

    let betweenEvent = [];
    arrDate.map((reservRange) => {
      const splitArr = reservRange.filter((element, index) => index > 0);
      splitArr.forEach((array) => {
        betweenEvent = betweenEvent.concat(array);
      });
      return betweenEvent;
    });

    betweenEvent.map((items) => {
      const modifyItem = transformDate(items);
      return finalDateArr.push({
        itemDate: modifyItem,
        type: "tilesAfterColor",
      });
    });
    return finalDateArr;
  };

  const typeofTile = (array, date) => {
    const tiledate = transformDate(date);
    const result = array.filter((day) => day.itemDate === tiledate);
    if (
      new Date(tiledate).toLocaleDateString() ===
      new Date().toLocaleDateString()
    ) {
      return "today";
    }
    return result.length > 0 ? result[0].type : "defaultTile";
  };

  const tilesColor = ({ date, view }) => {
    let tileTypeArr;
    if (view === "month") {
      tileTypeArr = createEventTileType();
    }
    return typeofTile(tileTypeArr, date);
  };

  return (
    <div>
      {isPartenairePanel === true ? (
        <Calendar
          onChange={handleChange}
          value={dateValue}
          minDate={new Date()}
          selectRange={false}
          onClickDay={handleInfoDay}
          tileClassName={tilesColor}
        />
      ) : (
        <Calendar
          onChange={onChangeBasic}
          minDate={new Date()}
          selectRange
          tileDisabled={tileDisabled}
        />
      )}
    </div>
  );
}

CalendarBooked.propTypes = {
  urlToRequest: PropTypes.string,
  isPartenairePanel: PropTypes.bool,
  setAllEventDate: PropTypes.func,
  calendarId: PropTypes.number,
  setSelectedTile: PropTypes.func,
  setEmptyBook: PropTypes.func,
  refreshCal: PropTypes.bool,
  onChangeBasic: PropTypes.func,
};

CalendarBooked.defaultProps = {
  isPartenairePanel: undefined,
  setAllEventDate: undefined,
  urlToRequest: undefined,
  calendarId: undefined,
  setSelectedTile: undefined,
  setEmptyBook: undefined,
  refreshCal: undefined,
  onChangeBasic: undefined,
};
export default CalendarBooked;
