import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { messagePopup, openPopup } from "../../../5.Redux/Action";
import "./ListArticles.scss";
import Button from "../../../0.Commun/Button/Button";

const API_URL = process.env.REACT_APP_API_URL;

function ListArticles() {
  const [articles, setArticles] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [moreArticle, setMoreArticle] = useState(3);
  const [displayBtn, setDisplayBtn] = useState(true);
  const keywordFilter = useSelector((state) => state.tasks.keyword);
  const newDateBlog = useSelector((state) => state.tasks.newDateBlog);
  const dispatch = useDispatch();

  const matchKeyword = (data) => {
    const articleWithKeywords = [];
    data.forEach((item) => {
      const articleExist = articleWithKeywords.find(
        (element) => element.id_blog === item.id_blog
      );
      const articleIndex = articleWithKeywords.findIndex(
        (element) => element.id_blog === item.id_blog
      );
      if (!articleExist) {
        articleWithKeywords.push({
          id_blog: item.id_blog,
          titre_blog: item.titre_blog,
          article: item.article,
          date: item.date,
          nom_image: item.nom_image,
          description_image: item.description_image,
          keywords: [
            {
              id: item.id_mots_cles,
              keyword: item.keywords,
            },
          ],
        });
      } else {
        articleWithKeywords[articleIndex].keywords.push({
          id: item.id_mots_cles,
          keyword: item.keywords,
        });
      }
    });
    const articleLength = articleWithKeywords.length;
    for (let i = 0; i < articleLength; i += 1) {
      if (i > moreArticle - 1) {
        articleWithKeywords.pop();
      }
    }
    if (articleLength === articleWithKeywords.length) {
      setDisplayBtn(false);
    }
    setArticles(articleWithKeywords);
  };

  const getArticles = () => {
    if (keywordFilter) {
      axios
        .get(`${API_URL}/api/blogs/getAll?keywords=${keywordFilter}`)
        .then((res) => res.data)
        .then((data) => {
          matchKeyword(data);
        })
        .then(() => {
          setIsLoading(true);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    } else if (newDateBlog) {
      const month = newDateBlog.monthToCall + 1;
      let newDate = "";
      if (month < 10) {
        newDate = `${newDateBlog.yearSaved}-0${newDateBlog.monthToCall + 1}`;
      } else {
        newDate = `${newDateBlog.yearSaved}-${newDateBlog.monthToCall + 1}`;
      }
      axios
        .get(`${API_URL}/api/blogs/getAll?date=%${newDate}%`)
        .then((res) => res.data)
        .then((data) => {
          matchKeyword(data);
        })
        .then(() => {
          setIsLoading(true);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    } else {
      axios
        .get(`${API_URL}/api/blogs/getAll`)
        .then((res) => res.data)
        .then((data) => {
          matchKeyword(data);
        })
        .then(() => {
          setIsLoading(true);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    }
  };

  useEffect(() => {
    getArticles();
  }, [keywordFilter, newDateBlog, moreArticle]);

  const moreArticles = () => {
    setMoreArticle(moreArticle + 3);
  };

  return (
    <section className="sectionBlog">
      {isLoading && (
        <div className="containerMapBlogArticles">
          {articles.map((article) => (
            <div key={article.id_blog} className="containerBlogArticle">
              <div className="dateAndKeywords">
                <p className="dateBlogArticle">
                  {new Date(article.date).toLocaleDateString()}
                </p>
                <div className="containerBlogKeyword">
                  {article.keywords.map((keyword) => (
                    <p key={keyword.id} className="blogKeywords">
                      #{keyword.keyword}
                    </p>
                  ))}
                </div>
              </div>
              <div className="containerBlogImg">
                <img
                  className="imageBlogArticle"
                  src={`${API_URL}/images/${article.nom_image}`}
                  alt={article.description_image}
                />
              </div>
              <h2 className="h2Body">{article.titre_blog}</h2>
              <div className="articleBlogPublic">
                {ReactHtmlParser(article.article)}
              </div>
              <div className="containerBlogReadMore">
                <Link
                  to={`/blog/${article.id_blog}/${article.titre_blog.replace(
                    /\s/g,
                    "_"
                  )}`}
                  className="buttonGlobal btnBlogReadMore"
                >
                  <p>Lire la suite</p>
                </Link>
              </div>
            </div>
          ))}
          <div
            className={
              displayBtn
                ? "containerBtnMoreArticlesTrue"
                : "containerBtnMoreArticlesFalse"
            }
          >
            <Button
              text="Voir plus d&#39;articles"
              handleClick={moreArticles}
            />
          </div>
        </div>
      )}
    </section>
  );
}

export default ListArticles;
