export const TotalPrice = (prices, datein, dateout) => {
  const tarifs = [];
  const debut = datein;
  const fin = dateout;

  if (!prices.priceSunday) {
    for (let day = debut; day <= fin; ) {
      const debutTarif = prices.debut_periode;
      const finTarif = prices.fin_periode;
      const date = day.toLocaleDateString().split("/").reverse().join("-");
      if (date >= debutTarif && date <= finTarif) {
        // ajouter dans un tableau les tarifs
        switch (day.getDay()) {
          case 0:
            tarifs.push(prices.tarif_dimanche);
            break;
          case 1:
            tarifs.push(prices.tarif_lundi);
            break;
          case 2:
            tarifs.push(prices.tarif_mardi);
            break;
          case 3:
            tarifs.push(prices.tarif_mercredi);
            break;
          case 4:
            tarifs.push(prices.tarif_jeudi);
            break;
          case 5:
            tarifs.push(prices.tarif_vendredi);
            break;
          case 6:
            tarifs.push(prices.tarif_samedi);
            break;
          default:
            break;
        }
      }
      // }
      const dayPlusOne = day;
      dayPlusOne.setDate(day.getDate() + 1);
      day = dayPlusOne;
    }
  } else {
    for (let day = debut; day <= fin; ) {
      const debutTarif = prices.priceDatein;
      const finTarif = prices.priceDateout;
      const date = day.toLocaleDateString().split("/").reverse().join("-");

      if (date >= debutTarif && date <= finTarif) {
        // ajouter dans un tableau les tarifs
        switch (day.getDay()) {
          case 0:
            tarifs.push(prices.priceSunday);
            break;
          case 1:
            tarifs.push(prices.priceMonday);
            break;
          case 2:
            tarifs.push(prices.priceTuesday);
            break;
          case 3:
            tarifs.push(prices.priceWednesday);
            break;
          case 4:
            tarifs.push(prices.priceThursday);
            break;
          case 5:
            tarifs.push(prices.priceFriday);
            break;
          case 6:
            tarifs.push(prices.priceSaturday);
            break;
          default:
            break;
        }
      }
      const dayPlusOne = day;
      dayPlusOne.setDate(day.getDate() + 1);
      day = dayPlusOne;
    }
  }

  return tarifs;
};
