import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  messagePopup,
  openPopup,
  keywordsSelected,
} from "../../../../../5.Redux/Action";
import "./Keywords.scss";

const API_URL = process.env.REACT_APP_API_URL;

function Keywords() {
  const [keywords, setKeywords] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const getKeywords = () => {
    axios
      .get(`${API_URL}/api/blogs/getKeywords`)
      .then((res) => res.data)
      .then((data) => {
        setKeywords(data);
        setIsLoading(true);
      })
      .catch((err) => {
        dispatch(messagePopup(err));
        dispatch(openPopup(true));
      });
  };

  useEffect(() => {
    getKeywords();
  }, []);

  const selectKeyword = (keywordSelected) => {
    dispatch(keywordsSelected(keywordSelected));
    document.documentElement.scrollTop = 0;
  };

  return (
    <section className="containerKeywords">
      {isLoading && (
        <div className="containerBlogKeys">
          {keywords.map((keyword) => (
            <button
              key={keyword.keywords}
              type="button"
              onClick={() => selectKeyword(keyword.keywords)}
              className="blogKeyword"
            >
              #{keyword.keywords}
            </button>
          ))}
        </div>
      )}
    </section>
  );
}

export default Keywords;
