import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import differenceInDays from "date-fns/differenceInDays";
import Helmet from "react-helmet";
import { isWithinInterval } from "date-fns";
import ReactHtmlParser from "html-react-parser";
import { Calendar } from "react-calendar";
import {
  updatePanier,
  lactivite,
  loption,
  selectedAccomodation,
  selectedParc,
  openPopup,
  messagePopup,
  filteredAccomodation,
} from "../../5.Redux/Action";
import Navbar from "../0.Commun/Navbar/Navbar";
import Footer from "../0.Commun/Footer/Footer";
import "./Hebergement.scss";
import Button from "../../0.Commun/Button/Button";
import PopupMessage from "../../0.Commun/PopupMessage/PopupMessage";
import { messages } from "../../0.Commun/Message/Message";
import PlusMinusButtons from "../../0.Commun/PlusMinusButtons/PlusMinusButtons";
import { notify } from "../../0.Commun/ToastNotif/ToastNotif";
import { TotalPrice } from "../0.Commun/Calcul/TotalPrice";
import { matchImageAndPrice } from "../0.Commun/MatchImageAndPrice/MatchImageAndPrice";
import CalendarBooked from "../../0.Commun/Calendrier/CalendarBooked";

const API_URL = process.env.REACT_APP_API_URL;

function Hebergement() {
  const [prixTotalDesOptions, setPrixTotalDesOptions] = useState(0);
  const [accomodation, setAccomodation] = useState({});
  const [images, setImages] = useState({});
  const [prices, setPrices] = useState({});
  const [isLoadingAccomodation, setIsLoadingAccomodation] = useState(false);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [isLoadingRooms, setIsLoadingRooms] = useState(false);
  const [indexSelected, setIndexSelected] = useState(0);
  const [activities, setActivities] = useState([]);
  const [option, setOption] = useState([]);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [parcName, setParcName] = useState("");
  const [infoAccomodation, setInfoAccomodation] = useState({});
  const activiteFinal = useSelector((state) => state.tasks.lactivite);
  const optionFinal = useSelector((state) => state.tasks.loption);
  const accomodationSelected = useSelector(
    (state) => state.tasks.selectedAccomodation
  );
  const idParc = useSelector((state) => state.tasks.selectedParc);
  const parcRedux = useSelector((state) => state.tasks.parcs);
  const filter = useSelector((state) => state.tasks.filter);
  const prixActivites = useSelector((state) => state.tasks.lactivite);
  const prixOptions = useSelector((state) => state.tasks.loption);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [activeButtonId, setActiveButtonId] = useState("lesActivites");
  const [activeButtonOption, setActiveButtonOption] = useState("LesOptions");
  const history = useHistory();
  const [prixTotalDesActivites, setPrixTotalDesActivites] = useState(0);
  const [prixTotal, setPrixTotal] = useState(0);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [type, setType] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [tarifFixe, setTarifFixe] = useState();
  const [nameTarifFixe, setNameTarifFixe] = useState();
  const [isLoadingName, setIsLoadingName] = useState(false);
  const [openDescription, setOpenDescription] = useState(false);
  const [descriptionClicked, setDescriptionClicked] = useState();
  const [minNight, setMinNight] = useState(false);
  const [optionExist, setOptionExist] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [calendar, setCalendar] = useState([]);
  const [isLoadingCal, setIsLoadingCal] = useState(false);
  const [isLoadingVirginCal, setIsLoadingVirginCal] = useState(false);
  const [allEventDate, setAllEventDate] = useState([]);

  const handleChangeTab = (sectionToDisplay) => {
    setActiveButtonId(sectionToDisplay);
  };

  const parcPanier = () => {
    for (let i = 0; i < parcRedux.length; i += 1) {
      if (idParc === parcRedux[i].id_parc) {
        setParcName(parcRedux[i].nom_parc);
      }
    }
  };

  const calculateCoutFixe = (sousTotal) => {
    let resultCalcul = 0;
    const name = [];
    for (let i = 0; i < tarifFixe.length; i += 1) {
      if (
        sousTotal <= tarifFixe[i].montant_max &&
        sousTotal > tarifFixe[i].montant_min
      ) {
        resultCalcul += tarifFixe[i].tarif;
        name.push(tarifFixe[i].nom_tarif_fixe);
      } else if (tarifFixe[i].montant_min === null) {
        resultCalcul += tarifFixe[i].tarif;
        name.push(tarifFixe[i].nom_tarif_fixe);
      }
    }
    setIsLoadingName(true);
    setPrixTotal(sousTotal + resultCalcul);
    setNameTarifFixe(name);
  };
  // faire prix final
  const fairePrixFinal = () => {
    if (filter.date) {
      if (type !== 1 && infoAccomodation.nomChambre) {
        for (let j = 0; j < prices.length; j += 1) {
          if (prices[j].id_chambre === infoAccomodation.priceId) {
            for (let k = 0; k < prices[j].prices.length; k += 1) {
              if (
                isWithinInterval(new Date(filter.date[0]), {
                  start: new Date(prices[j].prices[k].priceDatein),
                  end: new Date(prices[j].prices[k].priceDateout),
                })
              ) {
                const nbJours = differenceInDays(
                  filter.date[1],
                  filter.date[0]
                );
                let prixDesOption = 0;
                for (let i = 0; i < optionFinal.length; i += 1) {
                  prixDesOption = prixTotalDesOptions;
                }
                const datein = new Date(filter.date[0]);
                const dateout = new Date(filter.date[1]);
                const pricesSubTotal = prices[j].prices[k];
                const prixSejour = TotalPrice(
                  pricesSubTotal,
                  datein,
                  dateout
                ).reduce((a, b) => {
                  return a + b;
                });
                const prixTaxe =
                  nbJours * filter.nbPersonnes * prices[j].prices[k].tax;
                const prixFinal =
                  prixTotalDesActivites + prixDesOption + prixSejour + prixTaxe;
                calculateCoutFixe(prixFinal);
              }
            }
          }
        }
      } else {
        for (let i = 0; i < prices.length; i += 1) {
          if (
            isWithinInterval(new Date(filter.date[0]), {
              start: new Date(prices[i].debut_periode),
              end: new Date(prices[i].fin_periode),
            })
          ) {
            let prixDesOption = 0;
            for (let j = 0; j < optionFinal.length; j += 1) {
              prixDesOption = prixTotalDesOptions;
            }
            const datein = new Date(filter.date[0]);
            const dateout = new Date(filter.date[1]);
            const prixSejour = TotalPrice(prices[i], datein, dateout).reduce(
              (a, b) => {
                return a + b;
              }
            );
            const nbJours = differenceInDays(filter.date[1], filter.date[0]);
            const prixTaxe =
              nbJours * filter.nbPersonnes * prices[i].taxe_sejour;
            const prixFinal =
              prixTotalDesActivites + prixDesOption + prixSejour + prixTaxe;
            calculateCoutFixe(prixFinal);
          }
        }
      }
    }
  };

  const getPrice = (idAccomodation) => {
    if (idAccomodation !== 1) {
      axios
        .get(
          `${API_URL}/api/chambre/getAccomodationPrice/${
            accomodationSelected || id
          }`
        )
        .then((res) => res.data)
        .then((data) => {
          setPrices(matchImageAndPrice(data));
        })
        .then(() => {
          setIsLoadingRooms(true);
          setIsLoadingAccomodation(true);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    } else {
      axios
        .get(
          `${API_URL}/api/tarifs_hebergement/getByHebergement/${
            accomodationSelected || id
          }`
        )
        .then((res) => res.data)
        .then((data) => {
          setPrices(data);
        })
        .then(() => {
          fairePrixFinal();
        })
        .then(() => {
          setIsLoadingAccomodation(true);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    }
  };

  const matchImage = (data) => {
    const activitiesWithImages = [];
    data.forEach((item) => {
      const activityExist = activitiesWithImages.find(
        (element) => element.idActivity === item.id_activite
      );
      const activityIndex = activitiesWithImages.findIndex(
        (element) => element.idActivity === item.id_activite
      );
      if (!activityExist) {
        activitiesWithImages.push({
          idActivity: item.id_activite,
          nomActivity: item.nom_activite,
          descriptionActivity: item.description_activite,
          tarif1pax: item.tarif_activite_1pax,
          tarif2pax: item.tarif_activite_2pax,
          tarif3pax: item.tarif_activite_3pax,
          tarif4pax: item.tarif_activite_4pax,
          tarif5pax: item.tarif_activite_5pax,
          tarif6pax: item.tarif_activite_6pax,
          tarifGroupe: item.tarif_groupe,
          imageActivity: [
            {
              nomImage: item.nom_image,
              descriptionImage: item.description_image,
            },
          ],
        });
      } else {
        activitiesWithImages[activityIndex].imageActivity.push({
          nomImage: item.nom_image,
          descriptionImage: item.descriptionImage,
        });
      }
    });
    setActivities(activitiesWithImages);
  };

  const getActivities = (idFromReload) => {
    if (idParc === 0) {
      axios
        .get(`${API_URL}/api/activites/getByParcImg/${idFromReload}`)
        .then((res) => res.data)
        .then((data) => {
          matchImage(data);
        })
        .then(() => {
          setIsLoadingActivities(true);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    } else {
      axios
        .get(`${API_URL}/api/activites/getByParcImg/${idParc}`)
        .then((res) => res.data)
        .then((data) => {
          matchImage(data);
        })
        .then(() => {
          setIsLoadingActivities(true);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    }
  };

  const getOption = async (idFromReload) => {
    if (idParc === 0) {
      await axios
        .get(`${API_URL}/api/options/accomodationLinkedByParc/${idFromReload}`)
        .then((res) => res.data)
        .then((data) => {
          setOption(data);
        })
        .then(() => {
          setIsLoadingOptions(true);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    } else {
      await axios
        .get(`${API_URL}/api/options/accomodationLinkedByParc/${idParc}`)
        .then((res) => res.data)
        .then((data) => {
          setOption(data);
        })
        .then(() => {
          setIsLoadingOptions(true);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    }
  };

  const VerifyOption = async () => {
    const options = option.filter(
      (filteredOption) =>
        filteredOption.hebergement_id === accomodation.id_hebergement
    );
    if (options.length === 0 || options === undefined) {
      setOptionExist(false);
    } else {
      setOptionExist(true);
    }
  };

  const getParcName = (idFromAccomodation) => {
    axios
      .get(`${API_URL}/api/parc/getById/${idFromAccomodation}`)
      .then((res) => res.data)
      .then((data) => {
        setParcName(data.nom_parc);
      })
      .catch((err) => {
        dispatch(messagePopup(err));
        dispatch(openPopup(true));
      });
  };

  const getAccomodation = () => {
    if (accomodationSelected === 0) {
      axios
        .get(`${API_URL}/api/hebergements/getById/${id}`)
        .then((res) => res.data)
        .then(async (data) => {
          setAccomodation(data);
          dispatch(selectedAccomodation(parseInt(id, 10)));
          dispatch(selectedParc(parseInt(data.parc_id, 10)));
          getPrice(data.type_hebergement_id);
          setType(data.type_hebergement_id);
          getActivities(data.parc_id);
          getParcName(data.parc_id);
          await getOption(data.parc_id);
          if (data.type_hebergement_id === 1) {
            axios
              .get(`${API_URL}/api/calendriers/publicCal/AC${id}`)
              .then((res) => res.data)
              .then((cal) => {
                setCalendar(cal);
                setIsLoadingCal(true);
              })
              .catch((err) => {
                if (err.response.status === 404) {
                  setIsLoadingVirginCal(true);
                }
              });
          }
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    } else {
      axios
        .get(`${API_URL}/api/hebergements/getById/${accomodationSelected}`)
        .then((res) => res.data)
        .then(async (data) => {
          setAccomodation(data);
          getPrice(data.type_hebergement_id);
          setType(data.type_hebergement_id);
          getActivities();
          await getOption();
          if (data.type_hebergement_id === 1) {
            axios
              .get(`${API_URL}/api/calendriers/publicCal/AC${id}`)
              .then((res) => res.data)
              .then((cal) => {
                setCalendar(cal);
                setIsLoadingCal(true);
              })
              .catch((err) => {
                if (err.response.status === 404) {
                  setIsLoadingVirginCal(true);
                }
              });
          }
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    }
  };

  useEffect(() => {
    VerifyOption();
  }, [option]);

  const getImages = () => {
    if (accomodationSelected === 0) {
      axios
        .get(`${API_URL}/api/images/getByTheme?hebergement=${id}`)
        .then((res) => res.data)
        .then((data) => {
          setImages(data);
          setIsLoadingImages(true);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    } else {
      axios
        .get(
          `${API_URL}/api/images/getByTheme?hebergement=${accomodationSelected}`
        )
        .then((res) => res.data)
        .then((data) => {
          setImages(data);
          setIsLoadingImages(true);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    }
  };

  const getTarifFixe = () => {
    axios
      .get(`${API_URL}/api/tarifs_fixe/getAll`)
      .then((res) => res.data)
      .then((data) => {
        setTarifFixe(data);
      })
      .catch((err) => {
        dispatch(messagePopup(err));
        dispatch(openPopup(true));
      });
  };

  useEffect(() => {
    getAccomodation();
    getImages();
    getTarifFixe();
    if (!filter.nbPersonnes) {
      dispatch(filteredAccomodation({ ...filter, nbPersonnes: 2 }));
    }
  }, []);

  useEffect(() => {
    fairePrixFinal();
  }, [
    filter,
    prixActivites,
    prices,
    infoAccomodation,
    prixTotalDesActivites,
    prixTotalDesOptions,
  ]);

  useEffect(() => {
    if (type !== 1) {
      setActiveButtonId("lesChambres");
      setActiveButtonOption("LesOptions");
    } else {
      setActiveButtonId("lesActivites");
      setActiveButtonOption("LesOptions");
    }
  }, [type]);

  const increaseIndex = () => {
    if (indexSelected === images.length - 1) {
      setIndexSelected(0);
    } else {
      setIndexSelected(indexSelected + 1);
    }
  };

  const determineClass = (index) => {
    if (index === indexSelected) {
      return "active";
    }
    return "unactive";
  };

  useEffect(() => {
    const timer = setTimeout(increaseIndex, 5000);
    return () => clearTimeout(timer);
  }, [indexSelected]);

  const updateActivitiesPrice = () => {
    let newPrice = 0;
    for (let i = 0; i < prixActivites.length; i += 1) {
      for (let j = 0; j < activities.length; j += 1) {
        if (prixActivites[i].idActivityCart === activities[j].idActivity) {
          const tarif = [
            { id: 1, amount: activities[j].tarif1pax },
            { id: 2, amount: activities[j].tarif2pax },
            { id: 3, amount: activities[j].tarif3pax },
            { id: 4, amount: activities[j].tarif4pax },
            { id: 5, amount: activities[j].tarif5pax },
            { id: 6, amount: activities[j].tarif6pax },
          ];
          if (activities[j].tarifGroupe !== null) {
            newPrice += activities[j].tarifGroupe;
            setPrixTotalDesActivites(
              prixTotalDesActivites + activities[i].tarifGroupe
            );
          } else {
            for (let k = 0; k < tarif.length; k += 1) {
              if (filter.nbPersonnes === tarif[k].id) {
                newPrice += tarif[k].amount;
                setPrixTotalDesActivites(
                  prixTotalDesActivites + tarif[k].amount
                );
              }
            }
          }
          setPrixTotalDesActivites(newPrice);
        }
      }
    }
  };

  const updateOptionPrice = () => {
    let newPrice = 0;
    let nbJours;
    if (filter.date !== undefined) {
      nbJours = differenceInDays(filter.date[1], filter.date[0]);
    }
    for (let i = 0; i < prixOptions.length; i += 1) {
      if (prixOptions[i].groupe === 1) {
        newPrice += prixOptions[i].tarif * filter.nbPersonnes * nbJours;
      } else {
        newPrice += prixOptions[i].tarif;
      }
    }
    setPrixTotalDesOptions(newPrice);
  };

  useEffect(() => {
    updateActivitiesPrice();
    updateOptionPrice();
  }, [filter.nbPersonnes, prixOptions, filter.date]);

  const calculateActivityPricePlus = (idToFind) => {
    for (let i = 0; i < activities.length; i += 1) {
      if (activities[i].idActivity === idToFind) {
        const tarif = [
          { id: 1, amount: activities[i].tarif1pax },
          { id: 2, amount: activities[i].tarif2pax },
          { id: 3, amount: activities[i].tarif3pax },
          { id: 4, amount: activities[i].tarif4pax },
          { id: 5, amount: activities[i].tarif5pax },
          { id: 6, amount: activities[i].tarif6pax },
        ];
        if (activities[i].tarifGroupe) {
          setPrixTotalDesActivites(
            prixTotalDesActivites + activities[i].tarifGroupe
          );
        } else {
          for (let j = 0; j < tarif.length; j += 1) {
            if (filter.nbPersonnes === tarif[j].id) {
              setPrixTotalDesActivites(prixTotalDesActivites + tarif[j].amount);
            }
          }
        }
      }
    }
  };

  const calculateActivityPriceMinus = (idToFind) => {
    for (let i = 0; i < activities.length; i += 1) {
      if (activities[i].idActivity === idToFind) {
        const tarif = [
          { id: 1, amount: activities[i].tarif1pax },
          { id: 2, amount: activities[i].tarif2pax },
          { id: 3, amount: activities[i].tarif3pax },
          { id: 4, amount: activities[i].tarif4pax },
          { id: 5, amount: activities[i].tarif5pax },
          { id: 6, amount: activities[i].tarif6pax },
        ];
        if (activities[i].tarifGroupe) {
          setPrixTotalDesActivites(
            prixTotalDesActivites - activities[i].tarifGroupe
          );
        } else {
          for (let j = 0; j < tarif.length; j += 1) {
            if (filter.nbPersonnes === tarif[j].id) {
              setPrixTotalDesActivites(prixTotalDesActivites - tarif[j].amount);
            }
          }
        }
      }
    }
  };

  const putActivity = (idActivityCart, name, final) => {
    const newActivite = { idActivityCart, name };
    const activitySelected = [...final];
    const exist = final.find((item) => item.name === name);
    const activityIndex = activitySelected.findIndex(
      (item) => item.name === name
    );
    if (exist) {
      activitySelected.splice(activityIndex, 1);
      dispatch(lactivite(activitySelected));
      calculateActivityPriceMinus(idActivityCart);
    } else {
      calculateActivityPricePlus(idActivityCart);
      const newPanier = [...final, newActivite];
      dispatch(lactivite(newPanier));
    }
  };

  const putOptions = (idOptionsCart, name, groupe, tarif, final) => {
    const newOptions = { idOptionsCart, name, groupe, tarif };
    const optionSelected = [...final];
    const exist = final.find((item) => item.name === name);
    const optionIndex = optionSelected.findIndex((item) => item.name === name);
    if (exist) {
      optionSelected.splice(optionIndex, 1);
      dispatch(loption(optionSelected));
    } else {
      const newPanier = [...final, newOptions];
      dispatch(loption(newPanier));
    }
    updateOptionPrice();
  };

  const AddAccomodation = (nomHebergement, idPrice, nomChambre, taxeSejour) => {
    if (!nomChambre) {
      setInfoAccomodation({
        nomHebergement,
        priceId: idPrice,
      });
    } else {
      setInfoAccomodation({
        nomHebergement,
        nomChambre,
        priceId: idPrice,
        taxeSejour,
      });
      axios
        .get(`${API_URL}/api/calendriers/publicCal/RO${idPrice}`)
        .then((res) => res.data)
        .then((cal) => {
          setCalendar(cal);
          setIsLoadingCal(true);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setIsLoadingVirginCal(true);
          }
        });
    }
  };

  const removeOnePax = () => {
    if (filter.nbPersonnes === 1) {
      notify(messages.error.guestMin, "warn");
    } else {
      dispatch(
        filteredAccomodation({
          ...filter,
          nbPersonnes: filter.nbPersonnes - 1,
        })
      );
    }
  };

  const addOnePax = () => {
    if (filter.nbPersonnes >= accomodation.nb_personnes_hebergement) {
      notify(messages.error.guestMax, "warn");
    } else {
      dispatch(
        filteredAccomodation({
          ...filter,
          nbPersonnes: filter.nbPersonnes + 1,
        })
      );
    }
  };

  const updatingCart = () => {
    if (!filter || !accomodation.nom_hebergement) {
      setModalError(true);
      setModalErrorMessage(messages.missing.inputEmpty);
    } else if (!filter.date) {
      setModalError(true);
      setModalErrorMessage(messages.missing.date);
    } else if (isLoadingRooms && !infoAccomodation.nomChambre) {
      setModalError(true);
      setModalErrorMessage(messages.missing.room);
    } else {
      const newPanier = {
        dateDebut: filter.date[0],
        dateFin: filter.date[1],
        parc: parcName,
        hebergement: accomodation.nom_hebergement,
        typeHebergement: accomodation.nom_type_hebergement,
        chambre: infoAccomodation.nomChambre,
        nbPersonnes: filter.nbPersonnes,
        activites: prixActivites,
        options: prixOptions,
        pack: 0,
        prix: prixTotal,
      };
      dispatch(updatePanier(newPanier));
      history.push("/precommande");
    }
  };

  const openCalendar = () => {
    setCalendarIsOpen(!calendarIsOpen);
  };

  const calculateIntervall = (debut, fin) => {
    const dtStart = debut.split(",").join("-");
    const dtEnd = fin.split(",").join("-");

    const nowDateSt = new Date(dtStart);
    const dates = [];

    while (nowDateSt < new Date(dtEnd)) {
      dates.push(new Date(nowDateSt));
      nowDateSt.setDate(nowDateSt.getDate() + 1);
    }
    return dates;
  };

  const transformDate = (myDate) => {
    const valueDate = new Date(myDate);
    const yearDate = valueDate.getFullYear();
    const monthDate = valueDate.getMonth();
    const dayDate = valueDate.getDate();
    const pad = (n) => {
      return n < 10 ? `0${n}` : n;
    };
    const currentDate = `${yearDate}-${pad(monthDate + 1)}-${pad(dayDate)}`;
    return currentDate;
  };

  const isEmptyDate = (array, start, end) => {
    if (array.length > 0) {
      const arrDate = [];
      array.forEach((item) => {
        arrDate.push(
          calculateIntervall(item.date_debut_ical, item.date_fin_ical)
        );
      });
      let spreaDtList = [];
      arrDate.map((reservRange) => {
        reservRange.forEach((item) => {
          const newDateFormat = transformDate(item);
          spreaDtList = spreaDtList.concat(newDateFormat);
        });
        return spreaDtList;
      });
      const newEventDuration = calculateIntervall(start, end);
      const updateDtNewEvent = newEventDuration.map((item) => {
        const newDate = transformDate(item);
        return newDate;
      });
      const arrBoolExist = [];
      updateDtNewEvent.forEach((date) => {
        const ifExist = spreaDtList.includes(date);
        return arrBoolExist.push(ifExist);
      });
      const resultBool = arrBoolExist.includes(true);
      if (resultBool === true) {
        return false;
      }
      return true;
    }
    return true;
  };

  const setupDate = (date) => {
    const newDate = new Date(date)
      .toLocaleDateString()
      .split("/")
      .reverse()
      .join(",");
    return newDate;
  };

  const changeDates = (dateVal) => {
    const nbJours = differenceInDays(dateVal[1], dateVal[0]);
    const emptyDate = isEmptyDate(
      allEventDate,
      setupDate(dateVal[0]),
      setupDate(dateVal[1])
    );
    if (emptyDate === true) {
      if (type === 1 && nbJours < 2) {
        setMinNight(true);
        setCalendarIsOpen(false);
      } else {
        setMinNight(false);
        const newPanier2 = dateVal;
        dispatch(filteredAccomodation({ ...filter, date: newPanier2 }));
        setCalendarIsOpen(false);
      }
    } else {
      notify(messages.error.dateBooked, "warn");
    }
  };

  const clickText = () => {
    setClicked(!clicked);
  };

  useEffect(() => {
    parcPanier();
  }, [activiteFinal, optionFinal]);

  const openModalDescription = (idClicked) => {
    setOpenDescription(!openDescription);
    setDescriptionClicked(idClicked);
  };

  // Permet de définir si le bouton affiche "retirer" ou "ajouter"
  const determineBtnName = (idOption) => {
    for (let i = 0; i < optionFinal.length; i += 1) {
      if (optionFinal[i].idOptionsCart === idOption) {
        return "Retirer";
      }
    }
    return "Ajouter";
  };

  return (
    <section className="containerAccomodationGlobal">
      <Helmet>
        <title>{accomodation.nom_hebergement}</title>
      </Helmet>
      <PopupMessage
        openModal={modalError}
        setOpenModal={setModalError}
        message={modalErrorMessage}
      />
      <Navbar />
      {isLoadingAccomodation ||
      isLoadingActivities ||
      isLoadingImages ||
      isLoadingOptions ? (
        <div>
          {isLoadingAccomodation && (
            <div>
              {/* Header, carousel */}
              <div className="handleAccomodationImages">
                {isLoadingImages && (
                  <div>
                    {images.map((image, index) => (
                      <div className="containerImagesMap" key={image.id_image}>
                        <img
                          className={`${determineClass(index)}`}
                          src={`${API_URL}/images/${image.nom_image}`}
                          alt={image.description_image}
                        />
                        <img
                          className={`${determineClass(index)}`}
                          src={`${API_URL}/images/${image.nom_image}`}
                          alt={image.description_image}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Titre principal */}
              <div className="containerTitre">
                <h4 className="h4Body">{accomodation.nom_hebergement}</h4>
              </div>
              <div className="containerAccomodationDescription">
                <div
                  className={
                    clicked
                      ? "quillText paraphAccomodation"
                      : "quillText paraphAccomodationHidden"
                  }
                >
                  {ReactHtmlParser(accomodation.description_hebergement)}
                </div>
                <button
                  type="button"
                  className="clickTextBtn"
                  onClick={clickText}
                >
                  {clicked
                    ? "Moins de détails..."
                    : "Plus de détails sur votre hébergement..."}
                </button>
              </div>

              {/* Recap commande et choix activites/chambres */}
              <div className="containerAccomodationGeneral">
                {/* Choix activites/chambres */}
                <div className="containerRoomsAndActivities">
                  {/* container des titres */}
                  <div className="lesTitres">
                    {type !== 1 && (
                      <button
                        id="lesChambres"
                        onClick={() => handleChangeTab("lesChambres")}
                        type="button"
                        className={
                          activeButtonId === "lesChambres"
                            ? "selected"
                            : "unselected"
                        }
                      >
                        <p>Choisissez</p>
                        <h2 className="h2Body">votre chambre</h2>
                      </button>
                    )}
                    <button
                      id="lesActivites"
                      onClick={() => handleChangeTab("lesActivites")}
                      type="button"
                      className={
                        activeButtonId === "lesActivites"
                          ? "selected"
                          : "unselected"
                      }
                    >
                      <p>Choisissez</p>
                      <h2 className="h2Body">Vos activités</h2>
                    </button>
                    {optionExist && (
                      <button
                        id="LesOptions"
                        onClick={() => handleChangeTab("LesOptions")}
                        type="button"
                        className={
                          activeButtonId === "LesOptions"
                            ? "selected"
                            : "unselected"
                        }
                      >
                        <p>Choisissez</p>
                        <h2 className="h2Body">Vos Options</h2>
                      </button>
                    )}
                  </div>

                  {/* Container des listes */}
                  <div className="roomsAndActivities">
                    {/* Liste des chambres */}
                    {isLoadingRooms ? (
                      <div
                        className={
                          activeButtonId === "lesChambres"
                            ? "listeChambre"
                            : "displayNone"
                        }
                      >
                        {activeButtonId === "lesChambres" && (
                          <>
                            {prices.map((price) => (
                              <div
                                key={price.id_chambre}
                                className="zoneChambre"
                              >
                                <div>
                                  {price.imageHebergement[0].nom_image && (
                                    <div className="containerImg">
                                      <img
                                        className="testImage2"
                                        src={`${API_URL}/images/${price.imageHebergement[0].nom_image}`}
                                        alt={
                                          price.imageHebergement[0]
                                            .description_image
                                        }
                                      />
                                    </div>
                                  )}
                                  <div className="containerTitleRoom">
                                    <h3 className="h3Body roomName">
                                      {price.nom_chambre}
                                    </h3>
                                  </div>
                                  <div className="quillText">
                                    {ReactHtmlParser(price.description_chambre)}
                                  </div>
                                  <div className="containerRoomPriceAndBtnAdd">
                                    <div className="containerRoomPrice">
                                      <div className="roomPrice">
                                        A partir de {price.prixDappel}€
                                      </div>
                                      /<p>nuit</p>
                                    </div>
                                    <Button
                                      text="Ajouter"
                                      handleClick={() =>
                                        AddAccomodation(
                                          accomodation.nom_hebergement,
                                          price.id_chambre,
                                          price.nom_chambre
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    ) : (
                      <div />
                    )}

                    {/* Liste activites */}
                    {isLoadingActivities && (
                      <div
                        className={
                          activeButtonId === "lesActivites"
                            ? "sectionActivites"
                            : "displayNone"
                        }
                      >
                        {activeButtonId === "lesActivites" && (
                          <>
                            {activities.map((activite) => (
                              <div
                                className="containerActivities"
                                key={activite.idActivity}
                              >
                                <div>
                                  <div className="containerImg">
                                    <img
                                      className="testImage2"
                                      src={`${API_URL}/images/${activite.imageActivity[0].nomImage}`}
                                      alt={activite.descriptionImage}
                                    />
                                  </div>
                                  <div className="zoneTitre">
                                    <h3 className="h3Body titreUneActivite">
                                      {activite.nomActivity}
                                    </h3>
                                  </div>
                                  <div className="containerBtnDescriptionActivity">
                                    <button
                                      type="button"
                                      className="btnDescriptionActivity"
                                      onClick={() =>
                                        openModalDescription(
                                          activite.idActivity
                                        )
                                      }
                                    >
                                      Descriptition de l&#39;activité
                                    </button>
                                  </div>
                                  {openDescription &&
                                  descriptionClicked === activite.idActivity ? (
                                    <div className="quillText">
                                      {ReactHtmlParser(
                                        activite.descriptionActivity
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="containerBtnAndPrice">
                                    <p className="paraphUneActivitePrice">
                                      {activite.tarif1pax
                                        ? `à partir de ${activite.tarif1pax}€`
                                        : `${activite.tarifGroupe}€ par groupe`}
                                    </p>
                                    <Button
                                      text="Ajouter"
                                      handleClick={() =>
                                        putActivity(
                                          activite.idActivity,
                                          activite.nomActivity,
                                          activiteFinal
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    )}

                    {/* container options */}
                    {isLoadingOptions && (
                      <div
                        className={
                          activeButtonId === "LesOptions"
                            ? "sectionActivites"
                            : "displayNone"
                        }
                      >
                        {activeButtonOption === "LesOptions" && (
                          <>
                            {option
                              .filter(
                                (filteredOption) =>
                                  filteredOption.hebergement_id ===
                                  accomodation.id_hebergement
                              )
                              .map((op) => (
                                <div
                                  className="containerActivities"
                                  key={op.option_id}
                                >
                                  <div>
                                    <div className="containerImg">
                                      <img
                                        className="testImage2"
                                        src={`${API_URL}/images/${op?.image_option}`}
                                        alt="option"
                                      />
                                    </div>
                                  </div>
                                  <div className="zoneTitre">
                                    <h3 className="h3Body titreUneActivite">
                                      {op.nom_option}
                                    </h3>
                                  </div>
                                  <div className="containerBtnDescriptionActivity">
                                    <button
                                      type="button"
                                      className="btnDescriptionActivity"
                                      onClick={() =>
                                        openModalDescription(op?.option_id)
                                      }
                                    >
                                      Descriptition de l&#39;option
                                    </button>
                                  </div>
                                  {openDescription &&
                                  descriptionClicked === op.option_id ? (
                                    <div className="quillText">
                                      {ReactHtmlParser(op.description_option)}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="containerBtnAndPrice">
                                    <p className="paraphUneActivitePrice">
                                      {op.tarif_groupe === 1
                                        ? `${op.tarif_option}€/Personne`
                                        : `${op.tarif_option}€`}
                                    </p>
                                    <Button
                                      text={determineBtnName(op.id_option)}
                                      handleClick={() =>
                                        putOptions(
                                          op.option_id,
                                          op.nom_option,
                                          op.tarif_groupe,
                                          op.tarif_option,
                                          optionFinal
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {/* Container reservation */}
                <div className="containerBooking">
                  <div className="reservation">
                    <h2 className="h2Body votreReserve">VOTRE RéSERVATION</h2>
                  </div>
                  <div className="tableauRecap">
                    <div className="ligneDate">
                      <h3 className="h3Body">Dates</h3>
                      {accomodation.type_hebergement_id !== 1 ? (
                        <>
                          {infoAccomodation.nomChambre ? (
                            <>
                              <p className="resultatTableau">
                                {filter.date
                                  ? `Du ${new Date(
                                      filter.date[0]
                                    ).toLocaleDateString()} au ${new Date(
                                      filter.date[1]
                                    ).toLocaleDateString()}`
                                  : "Veuillez choisir vos dates"}
                              </p>
                              <Button
                                text="Modifier"
                                handleClick={() => openCalendar()}
                              />
                            </>
                          ) : (
                            <p className="resultatTableau">
                              {" "}
                              Veuillez sélectioner une chambre{" "}
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          <p className="resultatTableau">
                            {filter.date
                              ? `Du ${new Date(
                                  filter.date[0]
                                ).toLocaleDateString()} au ${new Date(
                                  filter.date[1]
                                ).toLocaleDateString()}`
                              : "Veuillez choisir vos dates"}
                          </p>
                          <Button
                            text="Modifier"
                            handleClick={() => openCalendar()}
                          />
                        </>
                      )}

                      <span
                        className={
                          calendarIsOpen ? "calendarIsOpen" : "calendarClosed"
                        }
                      >
                        {isLoadingCal &&
                          (calendar.calPartenaire_linkUrl === null ? (
                            <CalendarBooked
                              urlToRequest=""
                              calendarId={calendar.calPartenaire_id}
                              onChangeBasic={changeDates}
                              setAllEventDate={setAllEventDate}
                            />
                          ) : (
                            <CalendarBooked
                              urlToRequest={calendar.calPartenaire_linkUrl}
                              onChangeBasic={changeDates}
                              setAllEventDate={setAllEventDate}
                            />
                          ))}
                        {isLoadingVirginCal && (
                          <Calendar
                            minDate={new Date()}
                            selectRange
                            onChange={changeDates}
                          />
                        )}
                      </span>
                      <p
                        className={minNight ? "blockMinNight" : "noneMinNight"}
                      >
                        Un minimum de 2 nuits est requis pour réserver en gîte
                      </p>
                    </div>
                    <div className="dividerCart" />
                    <div className="containerCartParc">
                      <h3 className="h3Body">Destination</h3>
                      <p className="resultatTableau">{parcName}</p>
                    </div>
                    <div className="dividerCart" />
                    <div className="containerCartParc">
                      <h3 className="h3Body">Hébergement</h3>
                      <p className="resultatTableau">
                        {accomodation.nom_hebergement}
                      </p>
                    </div>
                    <div className="dividerCart" />
                    {infoAccomodation.nomChambre && (
                      <>
                        <div className="containerCartParc">
                          <h3 className="h3Body">Chambre</h3>
                          <p className="resultatTableau">
                            {infoAccomodation.nomChambre}
                          </p>
                        </div>
                        <div className="dividerCart" />
                      </>
                    )}
                    <div className="containerCartParc">
                      <h3 className="h3Body">Nombre de personnes</h3>
                      <div className="containerCartAddRemovePax">
                        <PlusMinusButtons
                          addOne={addOnePax}
                          removeOne={removeOnePax}
                        >
                          <p className="resultatTableauPackage">
                            {filter.nbPersonnes}
                          </p>
                        </PlusMinusButtons>
                      </div>
                    </div>
                    <div className="dividerCart" />
                    {activiteFinal.length !== 0 && (
                      <>
                        <div className="containerCartParc">
                          <h3 className="h3Body">Activités</h3>
                          <div className="resultatTableau">
                            {activiteFinal.map((activitefin) => (
                              <p key={activitefin.idActivityCart}>
                                {activitefin.name}
                              </p>
                            ))}
                          </div>
                        </div>
                        <div className="dividerCart" />
                      </>
                    )}
                    {optionFinal.length !== 0 && (
                      <>
                        <div className="containerCartParc">
                          <h3 className="h3Body">Options</h3>
                          <div className="resultatTableau">
                            {optionFinal.map((optionfin) => (
                              <p key={optionfin.idOptionsCart}>
                                {optionfin.name}
                              </p>
                            ))}
                          </div>
                        </div>
                        <div className="dividerCart" />
                      </>
                    )}
                    {isLoadingName && (
                      <>
                        <div className="containerCartParc">
                          <h3 className="h3Body">Inclus</h3>
                          {nameTarifFixe.map((name, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={index}>
                              <p>{name}</p>
                            </div>
                          ))}
                        </div>
                        <div className="dividerCart" />
                      </>
                    )}
                    <div className="containerCartParc">
                      <h3 className="h3Body">Total</h3>
                      <p className="resultatTableau finalPrice">
                        {prixTotal > 10 ? `${prixTotal} €` : ""}
                      </p>
                    </div>
                    <Button text="Valider" handleClick={updatingCart} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div />
      )}
      <Footer />
    </section>
  );
}

export default Hebergement;
