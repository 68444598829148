import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./InputImage.css";
import verifyImages from "../../3.Admin/0.Commun/VerifyImages/VerifyImages";
import { notify } from "../ToastNotif/ToastNotif";
import { messages } from "../Message/Message";

function InputImage({ newImg, setNewImg, multiple, marginValue }) {
  const style = {
    margin: marginValue,
  };

  const handleImage = (e) => {
    const imgArray = [];
    try {
      for (let i = 0; i < e.target.files.length; i += 1) {
        if (verifyImages(e.target.files[i]).response === false) {
          notify(verifyImages(e.target.files[i]).message, "warn");
        } else {
          imgArray.push(e.target.files[i]);
        }
      }
    } catch {
      notify(messages.error.globalError, "error");
    } finally {
      setNewImg(imgArray);
    }
  };

  const defineText = () => {
    if (newImg.length === 0 && multiple) {
      return "Veuillez choisir vos images";
    }
    if (newImg.length === 0 && !multiple) {
      return "Veuillez choisir une image";
    }
    if (newImg.length === 1) {
      return "1 image choisie";
    }
    if (newImg.length > 1) {
      return `${newImg.length} images choisies`;
    }
    return "N/A";
  };

  useEffect(() => {
    defineText();
  }, [newImg]);

  return (
    <label htmlFor="newImg" className="inputImage-label" style={style}>
      {defineText()}
      <input
        type="file"
        id="newImg"
        name="newImg"
        accept="image/*"
        onChange={(e) => handleImage(e)}
        multiple={multiple}
        className="inputImage-input"
      />
    </label>
  );
}

InputImage.propTypes = {
  newImg: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ).isRequired,
  setNewImg: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  marginValue: PropTypes.string,
};

InputImage.defaultProps = {
  multiple: false,
  marginValue: "",
};

export default InputImage;
