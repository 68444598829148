import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Helmet from "react-helmet";
import Quill from "../../0.Commun/Quill/Quill";
import NavBar from "../0.Commun/NavBar/NavBar";
import "./InfosPra.scss";
import verifyToken from "../0.Commun/VerifyToken/VerifyToken";
import { notify } from "../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../0.Commun/Message/Message";
import Button from "../../0.Commun/Button/Button";
import Input from "../../0.Commun/Input/Input";

const API_URL = process.env.REACT_APP_API_URL;

function InfosPra() {
  const [infosRecupQui, setInfosRecupQui] = useState([]);
  const [titreQui, setTitreQui] = useState("");
  const [descriptionQui, setDescriptionQui] = useState("");
  const [infosRecupConditions, setInfosRecupConditions] = useState([]);
  const [titreConditions, setTitreConditions] = useState("");
  const [descriptionConditions, setDescriptionConditions] = useState("");
  const [infosRecupMentions, setInfosRecupMentions] = useState([]);
  const [titreMentions, setTitreMentions] = useState("");
  const [descriptionMentions, setDescriptionMentions] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const recupQui = () => {
    const token = localStorage.getItem("TOKEN");
    axios
      .get(`${API_URL}/api/footer/getById/1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setInfosRecupQui(data);
        setTitreQui(data.titre_section_footer);
        setDescriptionQui(data.section_footer);
      });
  };

  const recupConditions = () => {
    const token = localStorage.getItem("TOKEN");
    axios
      .get(`${API_URL}/api/footer/getById/2`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setInfosRecupConditions(data);
        setTitreConditions(data.titre_section_footer);
        setDescriptionConditions(data.section_footer);
      });
  };

  const recupMentions = () => {
    const token = localStorage.getItem("TOKEN");
    axios
      .get(`${API_URL}/api/footer/getById/3`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setInfosRecupMentions(data);
        setTitreMentions(data.titre_section_footer);
        setDescriptionMentions(data.section_footer);
      });
  };

  useEffect(() => {
    verifyToken(history);
    recupQui();
    recupConditions();
    recupMentions();

    if (infosRecupQui && infosRecupConditions && infosRecupMentions) {
      setIsLoading(true);
    }
  }, []);

  const handleSubmit1 = (event) => {
    event.preventDefault();
    const token = localStorage.getItem("TOKEN");
    axios
      .put(
        `${API_URL}/api/footer/1`,
        {
          titre_section_footer: titreQui,
          section_footer: descriptionQui,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .then(() => {
        notify(messages.modify.success, "success");
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const handleSubmit2 = (event) => {
    event.preventDefault();
    const token = localStorage.getItem("TOKEN");
    axios
      .put(
        `${API_URL}/api/footer/2`,
        {
          titre_section_footer: titreConditions,
          section_footer: descriptionConditions,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .then(() => {
        notify(messages.modify.success, "success");
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const handleSubmit3 = (event) => {
    event.preventDefault();
    const token = localStorage.getItem("TOKEN");
    axios
      .put(
        `${API_URL}/api/footer/3`,
        {
          titre_section_footer: titreMentions,
          section_footer: descriptionMentions,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .then(() => {
        notify(messages.modify.success, "success");
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const handleTitreQui = (event) => {
    setTitreQui(event.target.value);
  };

  const handleTitreConditions = (event) => {
    setTitreConditions(event.target.value);
  };

  const handleTitreMentions = (event) => {
    setTitreMentions(event.target.value);
  };

  return (
    <div className="adminInfosPage">
      <Helmet>
        <title>Admin - Infos Pratiques</title>
      </Helmet>
      <NavBar />
      <div className="sectionAdminInfos">
        <h1 className="h1Admin">Informations Pratiques</h1>
        <div className="containerForm">
          <div className="containerZoneTextInfos">
            {isLoading && (
              <>
                <Input
                  name="quiSommesNous"
                  placeholder="Titre"
                  placeholderDisplay={false}
                  value={titreQui}
                  handleChange={handleTitreQui}
                  widthvalue="700px"
                />
                <Quill text={descriptionQui} setText={setDescriptionQui} />
              </>
            )}
          </div>
          <Button text="Modifier" handleClick={handleSubmit1} />
        </div>
        <div className="containerForm">
          <div className="containerZoneTextInfos">
            {isLoading && (
              <>
                <Input
                  name="cgv"
                  placeholder="Titre"
                  placeholderDisplay={false}
                  value={titreConditions}
                  handleChange={handleTitreConditions}
                  widthvalue="700px"
                />
                <Quill
                  text={descriptionConditions}
                  setText={setDescriptionConditions}
                />
              </>
            )}
          </div>
          <Button text="Modifier" handleClick={handleSubmit2} />
        </div>
        <div className="containerForm">
          <div className="containerZoneTextInfos">
            {isLoading && (
              <>
                <Input
                  name="mentions"
                  placeholder="Titre"
                  placeholderDisplay={false}
                  value={titreMentions}
                  handleChange={handleTitreMentions}
                  widthvalue="700px"
                />
                <Quill
                  text={descriptionMentions}
                  setText={setDescriptionMentions}
                />
              </>
            )}
          </div>
          <Button
            text="Modifier"
            handleClick={handleSubmit3}
            marginValue="0 0 50px 0"
          />
        </div>
      </div>
    </div>
  );
}

export default InfosPra;
