import React from "react";
import "./page404.scss";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import NavBar from "../0.Commun/Navbar/Navbar";
import Footer from "../0.Commun/Footer/Footer";

function Page404() {
  return (
    <div className="Container_404">
      <Helmet>
        <title>Cette page n&#39;existe pas</title>
      </Helmet>
      <NavBar />
      <section className="block_err">
        <div className="background_glasmo">
          <h2 className="Titre_404">404</h2>
          <p className="Paragraphe_err">
            Une erreur est survenue ! Il semblerait que la page que vous
            demandez ne soit pas accessible..
          </p>
        </div>
        <HashLink to="/#" className="buttonGlobal but_acceuil">
          Revenir à l&#39;accueil
        </HashLink>
      </section>
      <Footer />
    </div>
  );
}

export default Page404;
