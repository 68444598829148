import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import Recaptcha from "react-recaptcha";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { messagePopup, openPopup } from "../../../5.Redux/Action";
import "./ContactForm.scss";
import Loader from "../../0.Commun/Loader/Loader";
import {
  checkEmail,
  checkName,
  checkPhone,
} from "../../../0.Commun/Regex/Regex";
import PopupMessage from "../../../0.Commun/PopupMessage/PopupMessage";
import Button from "../../../0.Commun/Button/Button";
import Input from "../../../0.Commun/Input/Input";
import { messages } from "../../../0.Commun/Message/Message";
import { notify } from "../../../0.Commun/ToastNotif/ToastNotif";

const API_URL = process.env.REACT_APP_API_URL;

function Email({ loader, setLoader }) {
  const [clientInfo, setClientInfo] = useState({
    name: "",
    firstName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [isVerified, setIsVerified] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const onClickHandle = (event) => {
    event.preventDefault();
    setLoader(true);
    if (isVerified === false) {
      setOpenModal(true);
      setMessage("Veuillez vérifier que vous n'êtes pas un robot");
      setLoader(false);
    } else if (
      !clientInfo.name ||
      !clientInfo.firstName ||
      !clientInfo.email ||
      !clientInfo.phone ||
      !clientInfo.message
    ) {
      setOpenModal(true);
      setMessage(messages.missing.inputEmpty);
    } else {
      axios
        .post(`${API_URL}/api/mails_contact`, {
          firstName: clientInfo.firstName,
          name: clientInfo.name,
          email: clientInfo.email,
          phone: clientInfo.phone,
          message: clientInfo.message,
        })
        .then((res) => res.data)
        .then(() => {
          notify(messages.success.mail, "success");
          setLoader(false);
          history.push("/", window.scrollTo(0, 0));
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    }
  };

  const handleText = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setClientInfo({ ...clientInfo, [e.target.name]: e.target.value });
    }
  };

  const handleEmail = (e) => {
    const resultRegex = checkEmail(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setClientInfo({ ...clientInfo, [e.target.name]: e.target.value });
    }
  };

  const handlePhone = (e) => {
    const resultRegex = checkPhone(e.target.value);
    if (!resultRegex) {
      notify(messages.error.onlyNumbers, "warn");
    } else {
      setClientInfo({ ...clientInfo, [e.target.name]: e.target.value });
    }
  };

  const callback = () => {
    // console.log("Done!!!!");
  };

  const verifyCallback = (/* response */) => {
    setIsVerified(true);
    // console.log(response);
  };

  return (
    <section className="sectionFormulaireContact">
      {loader && (
        <>
          <Loader />
        </>
      )}
      <PopupMessage
        openModal={openModal}
        setOpenModal={setOpenModal}
        message={message}
      />
      <div className="containerIntroContact">
        <p className="pBodyContact">Une question ? Un commentaire ?</p>
        <p className="pBodyContact">
          N’hésitez pas à nous contacter, nous vous répondrons au plus vite !
        </p>
        <h2 className="h2Body h1BodyContact">Contactez-Nous</h2>
        <div className="traitDiviseur" />
      </div>
      <div className="containerContact">
        <form className="formulaireContact" onSubmit={onClickHandle}>
          <div className="identite">
            <Input
              name="name"
              value={clientInfo.name}
              handleChange={(e) => handleText(e)}
              placeholder="Nom"
              widthvalue="500px"
              heightValue="50px"
              borderColor="greenLight"
            />
            <Input
              name="firstName"
              value={clientInfo.firstName}
              handleChange={(e) => handleText(e)}
              placeholder="Prénom"
              widthvalue="500px"
              heightValue="50px"
              borderColor="greenLight"
            />
          </div>
          <div className="ligne">
            <Input
              name="email"
              value={clientInfo.email}
              handleChange={(e) => handleEmail(e)}
              placeholder="Email"
              widthvalue="500px"
              heightValue="50px"
              borderColor="greenLight"
            />
            <Input
              name="phone"
              value={clientInfo.phone}
              handleChange={(e) => handlePhone(e)}
              placeholder="Téléphone"
              widthvalue="500px"
              heightValue="50px"
              borderColor="greenLight"
            />
          </div>
          <textarea
            className="inputMessage"
            type="text"
            name="message"
            id="message"
            placeholder="Votre Message"
            value={clientInfo.message}
            onChange={(e) => handleText(e)}
          />
          <Recaptcha
            sitekey="6Lc3z5MaAAAAANwfmbYC7EPM_AOCg9QADc5lsk5w"
            render="explicit"
            verifyCallback={verifyCallback}
            onloadCallback={callback}
            hl="fr"
          />
          <div className="couloirBouton">
            <Button
              text="Envoyer"
              handleClick={onClickHandle}
              marginValue="30px 0 0 0"
            />
          </div>
        </form>
        <div className="containerContactAnnexe">
          <div className="cadreContact">
            <h5 className="h5Body h1CadreContact">PAR TELEPHONE</h5>
            <p className="pBodyContact">06 31 79 37 34</p>
            <h5 className="h5Body h1CadreContact">PAR EMAIL</h5>
            <p className="pBodyContact">contact@partirauvert.fr</p>
          </div>
        </div>
      </div>
    </section>
  );
}

Email.propTypes = {
  loader: PropTypes.bool.isRequired,
  setLoader: PropTypes.func.isRequired,
};

export default Email;
