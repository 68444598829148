import axios from "axios";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import NavBar from "../0.Commun/NavBar/NavBar";
import "./TarifFixe.scss";
import { checkName, checkPhone } from "../../0.Commun/Regex/Regex";
import { notify } from "../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../0.Commun/Message/Message";
import AddButton from "../../0.Commun/AddButton/AddButton";
import Button from "../../0.Commun/Button/Button";
import Input from "../../0.Commun/Input/Input";
import PopupContainer from "../../0.Commun/PopupContainer/PopupContainer";
import PopupConfirm from "../../0.Commun/PopupConfirm/PopupConfirm";
import { scrollLock } from "../../0.Commun/ScrollLock/ScrollLock";

const API_URL = process.env.REACT_APP_API_URL;

function TarifFixe() {
  const [tarifs, setTarifs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalModify, setOpenModalModify] = useState(false);
  const [newTarif, setNewTarif] = useState({
    nom_tarif: "",
    tarif: "",
    montantMin: "",
    montantMax: "",
  });
  const [tarifModify, setTarifModify] = useState({});
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
  });

  useEffect(() => {
    scrollLock(openModalAdd || openModalModify);
  }, [openModalAdd, openModalModify]);

  const getTarif = () => {
    axios
      .get(`${API_URL}/api/tarifs_fixe/getAll`)
      .then((res) => res.data)
      .then((data) => {
        setTarifs(data);
        setIsLoading(true);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  useEffect(() => {
    getTarif();
  }, []);

  const openModal = () => {
    setOpenModalAdd(!openModalAdd);
  };

  const handleNameTarif = (event) => {
    const resultCheck = checkName(event.target.value);
    if (!resultCheck) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setNewTarif({ ...newTarif, nom_tarif: event.target.value });
    }
  };

  const handleTarif = (event) => {
    const resultCheck = checkPhone(event.target.value);
    if (!resultCheck) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setNewTarif({ ...newTarif, tarif: event.target.value });
    }
  };

  const handleMontantMin = (event) => {
    const resultCheck = checkPhone(event.target.value);
    if (!resultCheck) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setNewTarif({ ...newTarif, montantMin: event.target.value });
    }
  };

  const handleMontantMax = (event) => {
    const resultCheck = checkPhone(event.target.value);
    if (!resultCheck) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setNewTarif({ ...newTarif, montantMax: event.target.value });
    }
  };

  const handleModifyNameTarif = (event) => {
    const resultCheck = checkName(event.target.value);
    if (!resultCheck) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setTarifModify({ ...tarifModify, nom_tarif_fixe: event.target.value });
    }
  };

  const handleModifyTarif = (event) => {
    const resultCheck = checkPhone(event.target.value);
    if (!resultCheck) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setTarifModify({ ...tarifModify, tarif: event.target.value });
    }
  };

  const handleModifyMontantMin = (event) => {
    const resultCheck = checkPhone(event.target.value);
    if (!resultCheck) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setTarifModify({ ...tarifModify, montant_min: event.target.value });
    }
  };

  const handleModifyMontantMax = (event) => {
    const resultCheck = checkPhone(event.target.value);
    if (!resultCheck) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setTarifModify({ ...tarifModify, montant_max: event.target.value });
    }
  };

  const addNewTarif = () => {
    const token = localStorage.getItem("TOKEN");
    let min = null;
    let max = null;
    if (newTarif.montantMin !== "") {
      min = newTarif.montantMin;
      max = newTarif.montantMax;
    }
    axios
      .post(
        `${API_URL}/api/tarifs_fixe`,
        {
          nom_tarif_fixe: newTarif.nom_tarif,
          tarif: newTarif.tarif,
          montant_min: min,
          montant_max: max,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        getTarif();
        setNewTarif({
          nom_tarif: "",
          tarif: "",
          montantMin: "",
          montantMax: "",
        });
        setOpenModalAdd(false);
        notify(messages.create.price, "success");
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const askDeletePrice = (idToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.price,
      id: idToDelete,
    });
  };

  const deleteTarif = (idToDelete) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .delete(`${API_URL}/api/tarifs_fixe/${idToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        notify(messages.delete.price, "success");
        getTarif();
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const modifytarif = (idToModify) => {
    axios
      .get(`${API_URL}/api/tarifs_fixe/getById/${idToModify}`)
      .then((res) => res.data)
      .then((data) => {
        setOpenModalModify(!openModalModify);
        setTarifModify(data);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const modifyTarif = () => {
    const token = localStorage.getItem("TOKEN");
    axios
      .put(
        `${API_URL}/api/tarifs_fixe/${tarifModify.id_tarif_fixe}`,
        {
          nom_tarif_fixe: tarifModify.nom_tarif_fixe,
          tarif: tarifModify.tarif,
          montant_min: tarifModify.montant_min,
          montant_max: tarifModify.montant_max,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setOpenModalModify(false);
        notify(messages.modify.success, "success");
        getTarif();
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  return (
    <div className="containerGlobalTarifFixeAdmin">
      <Helmet>
        <title>Admin - Tarifs fixe</title>
      </Helmet>
      <NavBar />
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={deleteTarif}
      />
      <div className="sectionTarifFixeAdmin">
        <h1 className="h1Admin">Tarifs fixe</h1>
        <div>
          <AddButton
            title="Ajouter un tarif fixe &#43;"
            handleClick={openModal}
          />
          {openModalAdd && (
            <PopupContainer setClose={setOpenModalAdd}>
              <form className="tarifFixeAdminContainerForm">
                <Input
                  name="nomTarifFixe"
                  placeholder="Nom nouveau tarif fixe *"
                  value={newTarif.nom_tarif}
                  handleChange={(e) => handleNameTarif(e)}
                  widthvalue="300px"
                />
                <Input
                  name="tarifFixe"
                  placeholder="Montant tarif fixe *"
                  value={newTarif.tarif}
                  handleChange={(e) => handleTarif(e)}
                  widthvalue="300px"
                />
                <Input
                  name="montantMin"
                  placeholder="Fourchette basse"
                  value={newTarif.montantMin}
                  handleChange={(e) => handleMontantMin(e)}
                  widthvalue="300px"
                />
                <Input
                  name="montantMax"
                  placeholder="Fourchette haute"
                  value={newTarif.montantMax}
                  handleChange={(e) => handleMontantMax(e)}
                  widthvalue="300px"
                />
                <Button text="Ajouter" handleClick={addNewTarif} />
              </form>
            </PopupContainer>
          )}
          {isLoading && (
            <div>
              <h2 className="h2Admin">Liste des tarifs fixes</h2>
              {tarifs.map((tarif) => (
                <div
                  key={tarif.id_tarif_fixe}
                  className="tarifFixeAdminListTarif"
                >
                  <p>{tarif.nom_tarif_fixe}</p>
                  <div>
                    <Button
                      text="Modifier"
                      handleClick={() => modifytarif(tarif.id_tarif_fixe)}
                      marginValue="5px 5px 5px 0"
                    />
                    <Button
                      text="Supprimer"
                      handleClick={() => askDeletePrice(tarif.id_tarif_fixe)}
                      marginValue="5px"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          {openModalModify && (
            <PopupContainer setClose={setOpenModalModify}>
              <form className="tarifFixeAdminContainerForm">
                <Input
                  name="nomTarifFixe"
                  placeholder="Nom tarif fixe"
                  value={tarifModify.nom_tarif_fixe}
                  handleChange={(e) => handleModifyNameTarif(e)}
                  widthvalue="300px"
                />
                <Input
                  name="tarifFixe"
                  placeholder="Montant tarif fixe"
                  value={tarifModify.tarif}
                  handleChange={(e) => handleModifyTarif(e)}
                  widthvalue="300px"
                />
                <Input
                  name="montantMin"
                  placeholder="Fourchette basse"
                  value={tarifModify.montant_min}
                  handleChange={(e) => handleModifyMontantMin(e)}
                  widthvalue="300px"
                />
                <Input
                  name="montantMax"
                  placeholder="Fourchette haute"
                  value={tarifModify.montant_max}
                  handleChange={(e) => handleModifyMontantMax(e)}
                  widthvalue="300px"
                />
                <Button
                  text="Modifier"
                  handleClick={modifyTarif}
                  marginValue="0 0 30px 0"
                />
              </form>
            </PopupContainer>
          )}
        </div>
      </div>
    </div>
  );
}

export default TarifFixe;
