import React from "react";
import "./HeaderConcept.scss";
import logo from "../../../../Assets/LogoPARTIRauVERT.jpg";

function HeaderConcept() {
  return (
    <div className="containerHeaderConcept">
      <div className="sectionHeaderConcept">
        <img src={logo} alt="Logo Partir au Vert" className="logoPartirHome" />
      </div>
    </div>
  );
}

export default HeaderConcept;
