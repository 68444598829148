import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Catch from "../../../../../../../0.Commun/Catch/Catch";
import { messages } from "../../../../../../../../0.Commun/Message/Message";
import { notify } from "../../../../../../../../0.Commun/ToastNotif/ToastNotif";
import "./RoomList.scss";
import AddRoom from "../AddRoom/AddRoom";
import ModifyRoom from "../ModifyRoom/ModifyRoom";
import { configAxios } from "../../../../../../../0.Commun/ConfigAxios/ConfigAxios";
import PopupConfirm from "../../../../../../../../0.Commun/PopupConfirm/PopupConfirm";
import Button from "../../../../../../../../0.Commun/Button/Button";

const API_URL = process.env.REACT_APP_API_URL;

function RoomList({ idAccomodation }) {
  const [rooms, setRooms] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalModify, setOpenModalModify] = useState(false);
  const [roomToModify, setRoomToModify] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
  });
  const history = useHistory();

  const getRooms = () => {
    axios
      .get(`${API_URL}/api/chambre/getByAccomodation/${idAccomodation}`)
      .then((res) => res.data)
      .then((data) => {
        setRooms(data);
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  useEffect(() => {
    getRooms();
  }, [openModalAdd, openModalModify]);

  const modifyRoom = (idRoom) => {
    setOpenModalModify(true);
    setRoomToModify(idRoom);
  };

  const askDeleteRoom = (idToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.room,
      id: idToDelete,
    });
  };

  const deleteRoom = (idChambre) => {
    axios
      .delete(`${API_URL}/api/chambre/${idChambre}`, configAxios())
      .then(() => {
        notify(messages.delete.room, "success");
        getRooms();
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  return (
    <section className="RoomListAdmin">
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={deleteRoom}
      />
      <AddRoom
        idAccomodation={idAccomodation}
        closeModal={openModalAdd}
        setCloseModal={setOpenModalAdd}
      />
      {openModalModify && (
        <ModifyRoom
          idAccomodation={idAccomodation}
          idRoom={roomToModify}
          setCloseModal={setOpenModalModify}
        />
      )}
      {isLoading && (
        <div className="list">
          {rooms.map((room) => (
            <div className="oneRoom" key={room.id_chambre}>
              <p>{room.nom_chambre}</p>
              <div className="containerBtnRoom">
                <Button
                  text="Modifier"
                  handleClick={() => modifyRoom(room.id_chambre)}
                  marginValue="5px"
                />
                <Button
                  text="Supprimer"
                  handleClick={() => askDeleteRoom(room.id_chambre)}
                  marginValue="5px"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
}

RoomList.propTypes = {
  idAccomodation: propTypes.number.isRequired,
};

export default RoomList;
