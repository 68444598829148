import React from "react";
import PropTypes from "prop-types";
import { FaTrash } from "react-icons/fa";
import "./DeleteButton.css";

function DeleteButton({ onClick, marginValue }) {
  const style = {
    margin: marginValue,
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className="deleteButton-button"
      style={style}
    >
      <FaTrash />
    </button>
  );
}

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  marginValue: PropTypes.string,
};

DeleteButton.defaultProps = {
  marginValue: "",
};

export default DeleteButton;
