import React, { useState, useEffect } from "react";
import axios from "axios";
import "./MaintenanceImage.scss";

function MaintenanceImage() {
  const API_URL = process.env.REACT_APP_API_URL;

  const [findImgNotUse, setFindImgNotUse] = useState();
  const [findImgNotWebp, setFindImgNotWebp] = useState();
  const [findImgToBig, setFindImgToBig] = useState();

  const [verifImgNotUse, setverifImgNotUse] = useState(false);
  const [verifImgNotWebp, setverifImgNotWebp] = useState(false);
  const [verifImgToBig, setverifImgToBig] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
    },
  };

  const callDeleteNotUse = () => {
    axios
      .get(`${API_URL}/api/imageSettings/infoDelnotuse`, config)
      .then((res) => {
        setFindImgNotUse(res.data);
      });
  };

  const callConvertToWebp = () => {
    axios
      .get(`${API_URL}/api/imageSettings/infoConvert`, config)
      .then((res) => {
        setFindImgNotWebp(res.data);
      });
  };
  const callConvertToBig = () => {
    axios
      .get(`${API_URL}/api/imageSettings/infoCompress`, config)
      .then((res) => {
        setFindImgToBig(res.data);
      });
  };

  const deleteImageNotUse = () => {
    axios.delete(`${API_URL}/api/imageSettings/delnotuse`, config);
  };
  const convertImageNotWebp = () => {
    axios.get(`${API_URL}/api/imageSettings/convert`, config);
  };
  const compressImageToBig = () => {
    axios.get(`${API_URL}/api/imageSettings/compress`, config);
  };

  useEffect(() => {
    callDeleteNotUse();
    callConvertToWebp();
    callConvertToBig();
  }, []);

  useEffect(() => {
    if (verifImgNotUse === true) {
      if (findImgNotUse !== 0) {
        setTimeout(callDeleteNotUse, 1000);
      } else {
        callConvertToWebp();
        callConvertToBig();
        setverifImgNotUse(false);
      }
    }
  }, [verifImgNotUse, findImgNotUse]);

  useEffect(() => {
    if (verifImgNotWebp === true) {
      if (findImgNotWebp !== 0) {
        setTimeout(callConvertToWebp, 1000);
      } else {
        callDeleteNotUse();
        callConvertToBig();
        setverifImgNotWebp(false);
      }
    }
  }, [verifImgNotWebp, findImgNotWebp]);

  useEffect(() => {
    if (verifImgToBig === true) {
      if (findImgToBig !== 0) {
        setTimeout(callConvertToBig, 1000);
      } else {
        callDeleteNotUse();
        callConvertToWebp();
        setverifImgToBig(false);
      }
    }
  }, [verifImgToBig, findImgToBig]);

  const handleDelete = () => {
    deleteImageNotUse();
    setverifImgNotUse(true);
  };

  const handleConvert = () => {
    convertImageNotWebp();
    setverifImgNotWebp(true);
  };
  const handleCompress = () => {
    compressImageToBig();
    setverifImgToBig(true);
  };

  return (
    <div>
      <h2 className="h2Admin alignTitle">Maintenance des images</h2>
      <div className="flexPos">
        <div className="buttonCard">
          <p>Images non utilisées dans la base de données</p>
          <h2 className="h2Admin">{findImgNotUse}</h2>
          <button className="buttonGlobal" type="button" onClick={handleDelete}>
            Supprimer
          </button>
        </div>
        <div className="buttonCard">
          <p>Images non webp dans la base de données</p>
          <h2 className="h2Admin">{findImgNotWebp}</h2>
          {findImgNotUse === 0 ? (
            <button
              className="buttonGlobal"
              type="button"
              onClick={handleConvert}
            >
              Convertir
            </button>
          ) : (
            <button disabled className="buttonGlobalDisabled" type="button">
              Convertir
            </button>
          )}
        </div>
        <div className="buttonCard">
          <p>Images supérieur à 1Mo dans la base de données</p>
          <h2 className="h2Admin">{findImgToBig}</h2>
          {findImgNotUse === 0 ? (
            <button
              className="buttonGlobal"
              type="button"
              onClick={handleCompress}
            >
              Compresser
            </button>
          ) : (
            <button disabled className="buttonGlobalDisabled" type="button">
              Compresser
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default MaintenanceImage;
