import React from "react";
import PropTypes from "prop-types";
import "./Button.css";

function Button({ text, handleClick, variant, marginValue }) {
  const style = { margin: marginValue };
  return (
    <button
      type="button"
      style={style}
      className={`button-${variant}`}
      onClick={handleClick}
    >
      {text}
    </button>
  );
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["normal", "variant", "delete", "noHover"]),
  marginValue: PropTypes.string,
};

Button.defaultProps = {
  variant: "normal",
  marginValue: "",
};

export default Button;
