import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Select.css";

function Select({
  datas,
  optionValue,
  introValue,
  onChange,
  widthValue,
  heightValue,
  fontSizeValue,
  introHidden,
  marginValue,
}) {
  const [idName, setIdName] = useState("");

  const getObjectId = () => {
    if (datas.length > 0) {
      const id = Object.keys(datas[0]).filter((data) => data.includes("id_"));
      setIdName(id);
    }
  };

  useEffect(() => {
    getObjectId();
  }, []);

  const style = {
    width: widthValue,
    height: heightValue,
    fontSize: fontSizeValue,
    margin: marginValue,
  };

  return (
    <select onChange={onChange} className="select-select" style={style}>
      {introValue !== "" && (
        <option value="" hidden={introHidden}>
          {introValue}
        </option>
      )}
      {idName !== "" &&
        datas.map((data) => (
          <option key={data[idName]} value={data[idName]}>
            {data[optionValue]}
          </option>
        ))}
    </select>
  );
}

Select.propTypes = {
  datas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  optionValue: PropTypes.string.isRequired,
  introValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  widthValue: PropTypes.string,
  heightValue: PropTypes.string,
  fontSizeValue: PropTypes.string,
  introHidden: PropTypes.bool,
  marginValue: PropTypes.string,
};

Select.defaultProps = {
  introValue: "",
  widthValue: "400px",
  heightValue: "40px",
  fontSizeValue: "inherit",
  introHidden: true,
  marginValue: "",
};

export default Select;
