import React, { useState } from "react";
import propTypes from "prop-types";
import "./AddPrice.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  checkName,
  checkNumbers,
} from "../../../../../../../../0.Commun/Regex/Regex";
import { messages } from "../../../../../../../../0.Commun/Message/Message";
import Catch from "../../../../../../../0.Commun/Catch/Catch";
import { notify } from "../../../../../../../../0.Commun/ToastNotif/ToastNotif";
import AddButton from "../../../../../../../../0.Commun/AddButton/AddButton";
import PopupContainer from "../../../../../../../../0.Commun/PopupContainer/PopupContainer";
import Input from "../../../../../../../../0.Commun/Input/Input";
import Button from "../../../../../../../../0.Commun/Button/Button";

const API_URL = process.env.REACT_APP_API_URL;

function AddPrice({ idAccomodation, idRoom, closeModal, setCloseModal }) {
  const [newPrice, setNewPrice] = useState({
    name: "",
    start: "",
    end: "",
    tax: "",
    tarif_lundi: "",
    tarif_mardi: "",
    tarif_mercredi: "",
    tarif_jeudi: "",
    tarif_vendredi: "",
    tarif_samedi: "",
    tarif_dimanche: "",
    tarif: "",
  });
  const [isChecked, setIsChecked] = useState(true);
  const history = useHistory();

  const openAddModal = () => {
    setCloseModal(true);
  };

  const handleText = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setNewPrice({ ...newPrice, [e.target.name]: e.target.value });
    }
  };

  const handleDate = (e) => {
    setNewPrice({ ...newPrice, [e.target.name]: e.target.value });
  };

  const handleNumber = (e) => {
    const resultRegex = checkNumbers(e.target.value);
    if (!resultRegex) {
      notify(messages.error.onlyNumbers, "error");
    } else {
      setNewPrice({ ...newPrice, [e.target.name]: e.target.value });
    }
  };

  const savePrice = () => {
    const token = localStorage.getItem("TOKEN");
    let priceToSend;
    if (!isChecked) {
      if (
        !newPrice.name ||
        !newPrice.start ||
        !newPrice.end ||
        !newPrice.tarif_lundi ||
        !newPrice.tax ||
        !newPrice.tarif_mardi ||
        !newPrice.tarif_mercredi ||
        !newPrice.tarif_jeudi ||
        !newPrice.tarif_vendredi ||
        !newPrice.tarif_samedi ||
        !newPrice.tarif_dimanche
      ) {
        notify(messages.missing.inputEmpty, "error");
      } else {
        priceToSend = {
          nom_tarif: newPrice.name,
          debut_periode: newPrice.start,
          fin_periode: newPrice.end,
          tarif_lundi: newPrice.tarif_lundi,
          taxe_sejour: newPrice.tax,
          hebergement_id: idAccomodation,
          tarif_mardi: newPrice.tarif_mardi,
          tarif_mercredi: newPrice.tarif_mercredi,
          tarif_jeudi: newPrice.tarif_jeudi,
          tarif_vendredi: newPrice.tarif_vendredi,
          tarif_samedi: newPrice.tarif_samedi,
          tarif_dimanche: newPrice.tarif_dimanche,
        };
        if (idRoom !== 0) {
          priceToSend = {
            nom_tarif: newPrice.name,
            debut_periode: newPrice.start,
            fin_periode: newPrice.end,
            tarif_lundi: newPrice.tarif_lundi,
            taxe_sejour: newPrice.tax,
            chambre_id: idRoom,
            hebergement_id: idAccomodation,
            tarif_mardi: newPrice.tarif_mardi,
            tarif_mercredi: newPrice.tarif_mercredi,
            tarif_jeudi: newPrice.tarif_jeudi,
            tarif_vendredi: newPrice.tarif_vendredi,
            tarif_samedi: newPrice.tarif_samedi,
            tarif_dimanche: newPrice.tarif_dimanche,
          };
        }
      }
    } else if (
      !newPrice.name ||
      !newPrice.start ||
      !newPrice.end ||
      !newPrice.tarif ||
      !newPrice.tax
    ) {
      notify(messages.missing.inputEmpty, "warn");
    } else {
      priceToSend = {
        nom_tarif: newPrice.name,
        debut_periode: newPrice.start,
        fin_periode: newPrice.end,
        tarif_lundi: newPrice.tarif,
        taxe_sejour: newPrice.tax,
        hebergement_id: idAccomodation,
        tarif_mardi: newPrice.tarif,
        tarif_mercredi: newPrice.tarif,
        tarif_jeudi: newPrice.tarif,
        tarif_vendredi: newPrice.tarif,
        tarif_samedi: newPrice.tarif,
        tarif_dimanche: newPrice.tarif,
      };
      if (idRoom !== 0) {
        priceToSend = {
          nom_tarif: newPrice.name,
          debut_periode: newPrice.start,
          fin_periode: newPrice.end,
          tarif_lundi: newPrice.tarif,
          taxe_sejour: newPrice.tax,
          chambre_id: idRoom,
          hebergement_id: idAccomodation,
          tarif_mardi: newPrice.tarif,
          tarif_mercredi: newPrice.tarif,
          tarif_jeudi: newPrice.tarif,
          tarif_vendredi: newPrice.tarif,
          tarif_samedi: newPrice.tarif,
          tarif_dimanche: newPrice.tarif,
        };
      }
    }

    axios
      .post(`${API_URL}/api/tarifs_hebergement`, priceToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setNewPrice({
          name: "",
          start: "",
          end: "",
          tarif_lundi: "",
          tax: "",
          tarif_mardi: "",
          tarif_mercredi: "",
          tarif_jeudi: "",
          tarif_vendredi: "",
          tarif_samedi: "",
          tarif_dimanche: "",
          tarif: "",
        });
        setCloseModal(false);
        setIsChecked(true);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  return (
    <section className="AddPriceAccomodation">
      <div className="containerBtnAddPrice">
        <AddButton
          title="Ajouter un tarif +"
          titleClass="small"
          handleClick={openAddModal}
        />
      </div>
      {closeModal && (
        <PopupContainer
          setClose={setCloseModal}
          widthvalue={40}
          heightValue={55}
        >
          <form className="formAddPrice">
            <Input
              name="name"
              widthvalue="250px"
              className="inputGeneralAdmin"
              value={newPrice.name}
              placeholder="Nom tarif"
              handleChange={(e) => {
                handleText(e);
              }}
            />
            <Input
              name="start"
              widthvalue="250px"
              type="date"
              className="inputGeneralAdmin"
              value={newPrice.start}
              handleChange={(e) => {
                handleDate(e);
              }}
            />
            <Input
              name="end"
              widthvalue="250px"
              type="date"
              className="inputGeneralAdmin"
              value={newPrice.end}
              handleChange={(e) => {
                handleDate(e);
              }}
            />
            {/* input avec checkbox permettant d'afficher une seule input ou toutes celles qui suivent */}
            <label htmlFor="group" className="labelHandleInfo">
              <input
                type="checkbox"
                className="inputCheckbox"
                checked={isChecked}
                value={isChecked}
                onChange={(event) => setIsChecked(event.target.checked)}
              />
              <p>Tarif unique</p>
            </label>
            {!isChecked ? (
              <>
                <Input
                  name="tarif_lundi"
                  widthvalue="250px"
                  className="inputGeneralAdmin"
                  value={newPrice.tarif_lundi}
                  placeholder="Tarif lundi"
                  handleChange={(e) => {
                    handleNumber(e);
                  }}
                />
                <Input
                  name="tarif_mardi"
                  widthvalue="250px"
                  className="inputGeneralAdmin"
                  value={newPrice.tarif_mardi}
                  placeholder="Tarif mardi"
                  handleChange={(e) => {
                    handleNumber(e);
                  }}
                />{" "}
                <Input
                  name="tarif_mercredi"
                  widthvalue="250px"
                  className="inputGeneralAdmin"
                  value={newPrice.tarif_mercredi}
                  placeholder="Tarif mercredi"
                  handleChange={(e) => {
                    handleNumber(e);
                  }}
                />{" "}
                <Input
                  name="tarif_jeudi"
                  widthvalue="250px"
                  className="inputGeneralAdmin"
                  value={newPrice.tarif_jeudi}
                  placeholder="Tarif jeudi"
                  handleChange={(e) => {
                    handleNumber(e);
                  }}
                />{" "}
                <Input
                  name="tarif_vendredi"
                  widthvalue="250px"
                  className="inputGeneralAdmin"
                  value={newPrice.tarif_vendredi}
                  placeholder="Tarif vendredi"
                  handleChange={(e) => {
                    handleNumber(e);
                  }}
                />{" "}
                <Input
                  name="tarif_samedi"
                  widthvalue="250px"
                  className="inputGeneralAdmin"
                  value={newPrice.tarif_samedi}
                  placeholder="Tarif samedi"
                  handleChange={(e) => {
                    handleNumber(e);
                  }}
                />{" "}
                <Input
                  name="tarif_dimanche"
                  widthvalue="250px"
                  className="inputGeneralAdmin"
                  value={newPrice.tarif_dimanche}
                  placeholder="Tarif dimanche"
                  handleChange={(e) => {
                    handleNumber(e);
                  }}
                />
              </>
            ) : (
              <>
                <Input
                  name="tarif"
                  widthvalue="250px"
                  className="inputGeneralAdmin"
                  value={newPrice.tarif}
                  placeholder="Tarif nuitée"
                  handleChange={(e) => {
                    handleNumber(e);
                  }}
                />
              </>
            )}
            <Input
              name="tax"
              widthvalue="250px"
              className="inputGeneralAdmin"
              value={newPrice.tax}
              placeholder="Taxe locale"
              handleChange={(e) => {
                handleNumber(e);
              }}
            />
            <Button text="Ajouter" handleClick={savePrice} />
          </form>
        </PopupContainer>
      )}
    </section>
  );
}

AddPrice.propTypes = {
  idAccomodation: propTypes.number.isRequired,
  idRoom: propTypes.number,
  closeModal: propTypes.bool.isRequired,
  setCloseModal: propTypes.func.isRequired,
};

AddPrice.defaultProps = {
  idRoom: 0,
};

export default AddPrice;
