import React from "react";
import "./PopupConfirm.css";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import PopupContainer from "../PopupContainer/PopupContainer";

const PopupConfirm = ({ dialog, setDialog, onDelete, image }) => {
  const confirm = () => {
    setDialog({ ...dialog, isVisible: false });
    if (image) {
      onDelete(dialog.id, dialog.name);
    } else {
      onDelete(dialog.id);
    }
  };

  const cancel = () => {
    setDialog({ ...dialog, isVisible: false });
  };

  return (
    <section>
      {dialog.isVisible && (
        <PopupContainer
          widthvalue={30}
          heightValue={20}
          minHeightValue="200px"
          disableClose
          center
        >
          <h1 className="PopupConfirm-title">{dialog.message}</h1>
          <div className="PopupConfirm-containerBtns">
            <Button
              text="Annuler"
              handleClick={cancel}
              variant="noHover"
              marginValue="0 20px"
            />
            <Button
              text="Confirmer"
              handleClick={confirm}
              variant="delete"
              marginValue="0 20px"
            />
          </div>
        </PopupContainer>
      )}
    </section>
  );
};

PopupConfirm.propTypes = {
  dialog: PropTypes.shape({
    isVisible: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }).isRequired,
  setDialog: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  image: PropTypes.bool,
};

PopupConfirm.defaultProps = {
  image: false,
};

export default PopupConfirm;
