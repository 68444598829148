import React, { useEffect, useState } from "react";
import "./HandlePackage.scss";
import PropTypes from "prop-types";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Quill from "../../../0.Commun/Quill/Quill";
import { checkEmail, checkPhone } from "../../../0.Commun/Regex/Regex";
import verifyImages from "../../0.Commun/VerifyImages/VerifyImages";
import Catch from "../../0.Commun/Catch/Catch";
import { messages } from "../../../0.Commun/Message/Message";
import { notify } from "../../../0.Commun/ToastNotif/ToastNotif";
import Button from "../../../0.Commun/Button/Button";
import AddButton from "../../../0.Commun/AddButton/AddButton";
import Input from "../../../0.Commun/Input/Input";
import Select from "../../../0.Commun/Select/Select";
import PopupContainer from "../../../0.Commun/PopupContainer/PopupContainer";
import PlusMinusButtons from "../../../0.Commun/PlusMinusButtons/PlusMinusButtons";
import PopupConfirm from "../../../0.Commun/PopupConfirm/PopupConfirm";

const API_URL = process.env.REACT_APP_API_URL;

function HandlePackage({ openModify, setOpenModify, idToModify }) {
  const [infos, setInfos] = useState({});
  const [description, setDescription] = useState("");
  const [imgPackage, setImgPackage] = useState("");
  const [isLoadingInfos, setIsLoadingInfos] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [parcs, setParcs] = useState({});
  const [accomodations, setAccomodations] = useState({});
  const [activities, setActivities] = useState({});
  const [option, setOption] = useState({});
  const [openAddActivity, setOpenAddActivity] = useState(false);
  const [openAddOption, setOpenAddOption] = useState(false);
  const [addActivity, setAddActivity] = useState("");
  const [addOption, setAddOption] = useState("");
  const [activitiesInfo, setActivitiesInfo] = useState({});
  const [optionInfo, setOptionInfo] = useState([]);
  const [openAddDispo, setOpenAddDispo] = useState(false);
  const [newDispo, setNewDispo] = useState({
    date_debut: "",
    date_fin: "",
    stock: "",
    tarif_package: "",
    nb_personnes_package: "",
    nb_repetition_semaine: "",
  });
  const [dispos, setDispos] = useState({});
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
    onDelete: () => {},
  });
  const history = useHistory();

  const stockToZero = (idStock) => {
    axios
      .put(`${API_URL}/api/packages/dispo/${idStock}`, {
        stock: 0,
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const unsetStock = (dataFromDispo) => {
    const newData = dataFromDispo;
    for (let i = 0; i < newData.length; i += 1) {
      if (
        new Date(newData[i].date_debut).getFullYear() < new Date().getFullYear()
      ) {
        stockToZero(newData[i].id_dispo);
        newData[i].stock = 0;
      } else if (
        new Date(newData[i].date_debut).getMonth() < new Date().getMonth() &&
        new Date(newData[i].date_debut).getFullYear() ===
          new Date().getFullYear()
      ) {
        stockToZero(newData[i].id_dispo);
        newData[i].stock = 0;
      } else if (
        new Date(newData[i].date_debut).getMonth() === new Date().getMonth() &&
        new Date(newData[i].date_debut).getDate() < new Date().getDate()
      ) {
        stockToZero(newData[i].id_dispo);
        newData[i].stock = 0;
      }
    }
    setDispos(newData);
    setIsLoadingInfos(true);
  };

  const getDispos = () => {
    axios
      .get(`${API_URL}/api/packages/getDisposAdmin/${idToModify}`)
      .then((res) => res.data)
      .then((data) => {
        unsetStock(data);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const getActivities = () => {
    axios
      .get(`${API_URL}/api/packages/getActivities/${idToModify}`)
      .then((res) => res.data)
      .then((data) => {
        if (!data) {
          setActivitiesInfo([]);
        } else {
          setActivitiesInfo(data);
          getDispos();
        }
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const getOptionsLinked = async () => {
    await axios
      .get(`${API_URL}/api/packages/getOptions/${idToModify}`)
      .then((res) => res.data)
      .then((data) => {
        if (!data) {
          setOptionInfo([]);
        } else {
          setOptionInfo(data);
          getDispos();
        }
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const getAccomodation = (idParc) => {
    axios
      .get(`${API_URL}/api/hebergements/getByParc/${idParc}`)
      .then((res) => res.data)
      .then((data) => {
        if (!data) {
          setAccomodations([]);
        } else {
          setAccomodations(data);
          getActivities();
          getOptionsLinked();
        }
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const getParc = (idParc) => {
    axios
      .get(`${API_URL}/api/parc/getAll`)
      .then((res) => res.data)
      .then((data) => {
        if (!data) {
          setParcs([]);
        } else {
          setParcs(data);
          getAccomodation(idParc);
        }
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const getActivity = (idParc) => {
    axios
      .get(`${API_URL}/api/activites/getByParc/${idParc}`)
      .then((res) => res.data)
      .then((data) => {
        if (!data) {
          setActivities([]);
        } else {
          setActivities(data);
        }
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const getOption = async (idParc) => {
    await axios
      .get(`${API_URL}/api/options/getByParc/${idParc}`)
      .then((res) => res.data)
      .then((data) => {
        if (!data) {
          setOption([]);
        } else {
          setOption(data);
        }
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const getInfos = () => {
    axios
      .get(`${API_URL}/api/packages/getById/${idToModify}`)
      .then((res) => res.data)
      .then((data) => {
        if (!data) {
          setInfos({});
        } else {
          setInfos(data);
          setDescription(data.description_package);
          getParc(data.parc_id);
          getActivity(data.parc_id);
          getOption(data.parc_id);
        }
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const getImage = () => {
    axios
      .get(`${API_URL}/api/images/getByTheme?pack=${idToModify}`)
      .then((res) => res.data)
      .then((data) => {
        if (!data) {
          setImgPackage("");
        } else {
          setImgPackage(data[0]);
          setIsLoadingImg(true);
        }
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  useEffect(() => {
    getInfos();
    getImage();
  }, []);

  const closeModal = () => {
    setOpenModify(!openModify);
  };

  const handleName = (event) => {
    const resultCheck = checkEmail(event.target.value);
    if (!resultCheck) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setInfos({ ...infos, nom_package: event.target.value });
    }
  };

  const chooseParc = (event) => {
    setInfos({ ...infos, parc_id: event.target.value });
    getAccomodation(event.target.value);
  };

  const chooseAccomodation = (event) => {
    setInfos({ ...infos, hebergement_id: event.target.value });
  };

  const modifyPackage = () => {
    const token = localStorage.getItem("TOKEN");
    axios
      .put(
        `${API_URL}/api/packages/package/${idToModify}`,
        {
          nom_package: infos.nom_package,
          description_package: description,
          hebergement_id: infos.hebergement_id,
          parc_id: infos.parc_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        notify(messages.success.modifyPackage, "success");
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const openActivity = () => {
    setOpenAddActivity(!openAddActivity);
  };

  // ouvrir modal option
  const openOption = () => {
    setOpenAddOption(!openAddOption);
  };

  const chooseActivity = (event) => {
    setAddActivity(event.target.value);
  };

  // selectionner l'option
  const chooseOption = (event) => {
    setAddOption(event.target.value);
  };

  const activityExist = () => {
    const exist = activitiesInfo.find(
      (element) => element.activite_id === parseInt(addActivity, 10)
    );
    if (exist) {
      return true;
    }
    return false;
  };

  // on vérifie si l'option existe avec son index
  const optionExist = () => {
    const exist = optionInfo.find(
      (e) => e.option_id === parseInt(addOption, 10)
    );
    if (exist) {
      return true;
    }
    // if (optionInfo.length === 0) {
    //   return true;
    // }
    return false;
  };

  const newActivity = () => {
    const token = localStorage.getItem("TOKEN");
    if (!addActivity) {
      notify(messages.missing.activityInPackage, "warn");
    } else if (activityExist()) {
      notify(messages.error.activityAllreadyInPackage, "warn");
    } else {
      axios
        .post(
          `${API_URL}/api/packages/activity`,
          {
            activite_id: addActivity,
            package_id: idToModify,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          notify(messages.modify.activityInPackage, "success");
          getInfos();
          setOpenAddActivity(false);
        })
        .catch((err) => {
          notify(err.response.data.errorMessage, "error");
        });
    }
  };

  const newOption = () => {
    const token = localStorage.getItem("TOKEN");
    if (!openAddOption) {
      notify(messages.error.askAddOptionToPackage, "warn");
    } else if (optionExist()) {
      notify(messages.error.optionAllreadyInPackage, "error");
    } else {
      axios
        .post(
          `${API_URL}/api/packages/options`,
          {
            option_id: addOption,
            package_id: idToModify,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          notify(messages.modify.optionInPackage, "success");
          getInfos();
          setOpenAddOption(false);
        })
        .catch((err) => {
          notify(err.response.data.errorMessage, "error");
        });
    }
  };

  const deleteActivity = (idToDelete) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .delete(
        `${API_URL}/api/packages/activity?activityId=${idToDelete}&packageId=${idToModify}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        notify(messages.delete.activityInPackage, "success");
        getInfos();
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const askDeleteActivity = (idToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.activity,
      id: idToDelete,
      onDelete: deleteActivity,
    });
  };

  const deleteOption = (idToDelete) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .delete(
        `${API_URL}/api/packages/options?optionId=${idToDelete}&packageId=${idToModify}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        notify(messages.delete.optionInPackage, "success");
        getInfos();
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const askDeleteOption = (idToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.option,
      id: idToDelete,
      onDelete: deleteOption,
    });
  };

  const openDispo = () => {
    setOpenAddDispo(!openAddDispo);
  };

  const handleStock = (event) => {
    const resultCheck = checkPhone(event.target.value);
    if (!resultCheck) {
      notify(messages.error.onlyNumbers, "warn");
    } else {
      setNewDispo({ ...newDispo, stock: event.target.value });
    }
  };

  const handlePrice = (event) => {
    const resultCheck = checkPhone(event.target.value);
    if (!resultCheck) {
      notify(messages.error.onlyNumbers, "warn");
    } else {
      setNewDispo({ ...newDispo, tarif_package: event.target.value });
    }
  };

  const handleGuest = (event) => {
    const resultCheck = checkPhone(event.target.value);
    if (!resultCheck) {
      notify(messages.error.onlyNumbers, "warn");
    } else {
      setNewDispo({ ...newDispo, nb_personnes_package: event.target.value });
    }
  };

  const handleRep = (event) => {
    const resultCheck = checkPhone(event.target.value);
    if (!resultCheck) {
      notify(messages.error.onlyNumbers, "warn");
    } else {
      setNewDispo({ ...newDispo, nb_repetition_semaine: event.target.value });
    }
  };

  const addNewDispo = () => {
    const token = localStorage.getItem("TOKEN");
    for (let i = 0; i < newDispo.nb_repetition_semaine; i += 1) {
      const dateDebut = new Date(newDispo.date_debut);

      const dateFin = new Date(newDispo.date_fin);
      const datedebut = new Date(dateDebut.setDate(dateDebut.getDate() + 7 * i))
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("-");
      const datefin = new Date(dateFin.setDate(dateFin.getDate() + 7 * i))
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("-");

      axios
        .post(
          `${API_URL}/api/packages/dispo`,
          {
            date_debut: datedebut,
            date_fin: datefin,
            stock: newDispo.stock,
            tarif_package: newDispo.tarif_package,
            package_id: idToModify,
            nb_personnes_package: newDispo.nb_personnes_package,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          notify(messages.modify.diponibilityPackage, "success");
          getInfos();
          setOpenAddDispo(false);
          setNewDispo({
            date_debut: "",
            date_fin: "",
            stock: "",
            tarif_package: "",
            nb_personnes_package: "",
            nb_repetition_semaine: "",
          });
        })
        .catch((err) => {
          notify(err.response.data.errorMessage, "error");
        });
    }
  };

  const deleteDispo = (idToDelete) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .delete(`${API_URL}/api/packages/dispo/${idToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        notify(messages.delete.disponibilityInPackage, "success");
        getInfos();
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const askDeleteDispo = (idToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.dispo,
      id: idToDelete,
      onDelete: deleteDispo,
    });
  };

  const handleNewImg = (event, idToDelete, imageName) => {
    const token = localStorage.getItem("TOKEN");
    const imgToImport = event.target.files[0];
    if (verifyImages(imgToImport).response === false) {
      notify(verifyImages(imgToImport).message, "warn");
    } else {
      const newImg = new FormData();
      newImg.append("file", imgToImport);
      newImg.append("description_image", infos.nom_package);
      newImg.append("package_id", idToModify);
      axios
        .post(
          `${API_URL}/api/images//replace/${idToDelete}/${imageName}`,
          newImg,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          notify(messages.modify.img, "success");
          getImage();
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  const addOne = (idDispo) => {
    axios
      .get(`${API_URL}/api/packages/getDispo/${idDispo}`)
      .then((res) => res.data)
      .then((data) => {
        const newStock = data.stock + 1;
        axios
          .put(`${API_URL}/api/packages/dispo/${idDispo}`, {
            stock: newStock,
          })
          .catch((err) => {
            notify(err.response.data.errorMessage, "error");
          });
      })
      .then(() => {
        getInfos();
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const removeOne = (idDispo) => {
    axios
      .get(`${API_URL}/api/packages/getDispo/${idDispo}`)
      .then((res) => res.data)
      .then((data) => {
        const newStock = data.stock - 1;
        axios
          .put(`${API_URL}/api/packages/dispo/${idDispo}`, {
            stock: newStock,
          })
          .catch((err) => {
            notify(err.response.data.errorMessage, "error");
          });
      })
      .then(() => {
        getInfos();
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  return (
    <PopupContainer setClose={closeModal}>
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={deleteDialog.onDelete}
      />
      <div className="containerGlobalPackageInfo">
        {isLoadingInfos && (
          <div>
            <h2 className="h2Admin titlePackageInfo">{infos.nom_package}</h2>
            <form className="containerFormPackageInfo">
              {isLoadingImg && (
                <div className="containerImgPackageInfo">
                  <img
                    src={`${API_URL}/images/${imgPackage.nom_image}`}
                    alt={imgPackage.description_image}
                    className="imgPackageInfo"
                  />
                  <label htmlFor="changeImg" className="containerNewImg">
                    &#10227;
                    <input
                      type="file"
                      id="changeImg"
                      name="changeImg"
                      accept="image/*"
                      onChange={(event) =>
                        handleNewImg(
                          event,
                          imgPackage.id_image,
                          imgPackage.nom_image
                        )
                      }
                      className="newImg"
                    />
                  </label>
                </div>
              )}
              <Input
                name="name"
                placeholder="Nom package"
                value={infos.nom_package}
                handleChange={(e) => handleName(e)}
              />
              <label htmlFor="parc" className="containerLabelPackageInfo">
                Destination :{" "}
                <Select
                  datas={parcs}
                  optionValue="nom_parc"
                  onChange={(e) => chooseParc(e)}
                  introValue={infos.nom_parc}
                  widthvalue="300px"
                />
              </label>
              <label
                htmlFor="accomodation"
                className="containerLabelPackageInfo"
              >
                Hébergement :{" "}
                <Select
                  datas={accomodations}
                  optionValue="nom_hebergement"
                  onChange={(e) => chooseAccomodation(e)}
                  introValue={infos.nom_hebergement}
                  widthvalue="300px"
                />
              </label>
              <p className="quill-label">Description du package :</p>
              <Quill text={description} setText={setDescription} />
              <div className="containerBtnModifyPackageInfo">
                <Button text="Modifier" handleClick={modifyPackage} />
              </div>
            </form>
            <div className="containerSpan">
              <span className="separatorHorizontal" />
            </div>
            <div className="containerTwoColumns">
              <div className="containerActivity">
                <h3 className="h3Admin titleTwoColumns">Activité</h3>
                <div className="containerBtn">
                  <AddButton
                    title="Ajouter une activité à ce package &#43;"
                    handleClick={openActivity}
                    titleClass="small"
                  />
                </div>
                {openAddActivity && (
                  <div className="containerSelect">
                    <Select
                      datas={activities}
                      optionValue="nom_activite"
                      onChange={(e) => chooseActivity(e)}
                      introValue="Choisissez une activité"
                      marginValue="15px 0"
                    />
                    <div className="containerBtnAdd">
                      <Button text="Ajouter" handleClick={newActivity} />
                    </div>
                  </div>
                )}
                {activitiesInfo.map((activity) => (
                  <div
                    key={activity.activite_id}
                    className="containerListActivities"
                  >
                    <button
                      type="button"
                      className="btnDeleteActivity"
                      onClick={() => askDeleteActivity(activity.activite_id)}
                    >
                      &#10007;
                    </button>
                    <p className="listActivities">{activity.nom_activite}</p>
                  </div>
                ))}
              </div>
              <div className="containerActivity">
                <h3 className="h3Admin titleTwoColumns">Disponibilité</h3>
                <div className="containerBtn">
                  <AddButton
                    title="Ajouter des disponibilités à ce package &#43;"
                    titleClass="small"
                    handleClick={openDispo}
                  />
                </div>
                {openAddDispo && (
                  <div>
                    <form className="containerDispoPackage">
                      <Input
                        name="dateDebut"
                        placeholder="Date début"
                        value={newDispo.date_debut}
                        handleChange={(e) =>
                          setNewDispo({
                            ...newDispo,
                            date_debut: e.target.value,
                          })
                        }
                        type="date"
                        dateMin={new Date()
                          .toLocaleDateString()
                          .split("/")
                          .reverse()
                          .join("-")}
                        widthvalue="200px"
                      />
                      <Input
                        name="dateFin"
                        placeholder="Date fin"
                        value={newDispo.date_fin}
                        handleChange={(e) =>
                          setNewDispo({
                            ...newDispo,
                            date_fin: e.target.value,
                          })
                        }
                        type="date"
                        dateMin={newDispo.date_debut}
                        widthvalue="200px"
                      />
                      <Input
                        name="stock"
                        placeholder="Stock"
                        value={newDispo.stock}
                        handleChange={(e) => handleStock(e)}
                        widthvalue="200px"
                      />
                      <Input
                        name="nbPersonnes"
                        placeholder="Nb de personnes"
                        value={newDispo.nb_personnes_package}
                        handleChange={(e) => handleGuest(e)}
                        widthvalue="200px"
                      />
                      <Input
                        name="tarif"
                        placeholder="Tarif package"
                        value={newDispo.tarif_package}
                        handleChange={(e) => handlePrice(e)}
                        widthvalue="200px"
                      />
                      <Input
                        name="semaine"
                        placeholder="Répétition semaine"
                        value={newDispo.nb_repetition_semaine}
                        handleChange={(e) => handleRep(e)}
                        widthvalue="200px"
                      />
                      <div className="containerBtnAdd">
                        <Button text="Ajouter" handleClick={addNewDispo} />
                      </div>
                    </form>
                  </div>
                )}
                {dispos.map((dispo) => (
                  <div key={dispo.id_dispo} className="containerListDispo">
                    <button
                      type="button"
                      onClick={() => askDeleteDispo(dispo.id_dispo)}
                      className="btnDeleteDispo"
                    >
                      &#10007;
                    </button>
                    <p>
                      Du {new Date(dispo.date_debut).toLocaleDateString()} au{" "}
                      {new Date(dispo.date_fin).toLocaleDateString()}
                    </p>
                    <p>{dispo.nb_personnes_package} personnes</p>
                    <p>{dispo.tarif_package}€</p>
                    <PlusMinusButtons
                      addOne={() => addOne(dispo.id_dispo)}
                      removeOne={() => removeOne(dispo.id_dispo)}
                    >
                      <p>{`Stock : ${dispo.stock}`}</p>
                    </PlusMinusButtons>
                  </div>
                ))}
              </div>
            </div>
            <span className="separatorHorizontalOption" />
            <div className="containerOptions">
              <h3 className="h3Admin ">Option</h3>
              <div className="">
                <AddButton
                  title="Ajouter une option à ce package &#43;"
                  titleClass="small"
                  handleClick={openOption}
                />
              </div>
              {openAddOption && (
                <div className="containerOption2">
                  <Select
                    datas={option}
                    optionValue="nom_option"
                    onChange={(e) => chooseOption(e)}
                    introValue="Choisissez une option"
                    marginValue="15px 0"
                  />
                  <div className="containerBtnAdd">
                    <Button text="Ajouter" handleClick={newOption} />
                  </div>
                </div>
              )}
              {optionInfo.map((op) => (
                <div
                  key={op.id_option}
                  value={op.id_option}
                  className="containerListOptions"
                >
                  <button
                    type="button"
                    className="btnDeleteOptions"
                    onClick={() => askDeleteOption(op.id_option)}
                  >
                    &#10007;
                  </button>
                  <p className="listOptions">{op.nom_option}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </PopupContainer>
  );
}

HandlePackage.propTypes = {
  openModify: PropTypes.bool.isRequired,
  setOpenModify: PropTypes.func.isRequired,
  idToModify: PropTypes.number.isRequired,
};

export default HandlePackage;
