import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Quill from "../../../../0.Commun/Quill/Quill";
import verifyImages from "../../../0.Commun/VerifyImages/VerifyImages";
import "react-quill/dist/quill.snow.css";
import { checkName, checkNumbers } from "../../../../0.Commun/Regex/Regex";
import Catch from "../../../0.Commun/Catch/Catch";
import "./ModifyOption.scss";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../../../0.Commun/Message/Message";
import Button from "../../../../0.Commun/Button/Button";
import Input from "../../../../0.Commun/Input/Input";
import PopupContainer from "../../../../0.Commun/PopupContainer/PopupContainer";
import { axiosPrivate } from "../../../../0.Commun/Axios/Axios";

const API_URL = process.env.REACT_APP_API_URL;

function ModifyOption({ optionId, setOpenModal }) {
  const [optionToModify, setOptionToModify] = useState({});
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const getOptions = () => {
    axiosPrivate({ method: "GET", url: `options/getById/${optionId}` }).then(
      (data) => {
        setOptionToModify(data);
        setDescription(data.description_option);
        setIsLoading(true);
      }
    );
  };

  useEffect(() => {
    getOptions();
  }, []);

  const handleTitle = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setOptionToModify({
        ...optionToModify,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChecked = () => {
    setOptionToModify({
      ...optionToModify,
      tarif_groupe: !optionToModify.tarif_groupe,
    });
  };

  const modifyPrices = (e) => {
    const resultRegex = checkNumbers(e.target.value);
    if (!resultRegex) {
      notify(messages.error.onlyNumbers, "error");
    } else {
      setOptionToModify({
        ...optionToModify,
        [e.target.name]: parseInt(e.target.value, 10),
      });
    }
  };

  const submitModify = () => {
    axiosPrivate({
      method: "PUT",
      url: `options/${optionId}`,
      body: {
        nom_option: optionToModify.nom_option,
        description_option: description,
        tarif_option: optionToModify.tarif_option,
        parc_id: optionToModify.parc_id,
        tarif_groupe: optionToModify.tarif_groupe,
      },
    }).then(() => {
      notify(messages.success.modifyOption, "success");
      setOpenModal(false);
    });
  };

  const handleNewImg = async (e, imageName) => {
    const imgToImport = e.target.files[0];
    const token = localStorage.getItem("TOKEN");
    if (verifyImages(imgToImport).response === false) {
      notify(verifyImages(imgToImport).message, "error");
    } else {
      const newform = new FormData();
      newform.append("file", imgToImport);
      await axios
        .put(
          `${API_URL}/api/options/modifyImage/${optionId}/${imageName}`,
          newform,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          notify(messages.modify.img, "success");
          getOptions();
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  return (
    <PopupContainer setClose={setOpenModal}>
      {isLoading && (
        <section className="modifyOptionAdmin">
          <Input
            name="nom_option"
            placeholder="Titre de l'option"
            value={optionToModify.nom_option}
            handleChange={(e) => handleTitle(e)}
          />
          <p className="introQuill">Description de l&#39;option :</p>
          <Quill text={description} setText={setDescription} />
          <label className="labelCheckbox" htmlFor="prixGroupe">
            <p>Tarif par personne</p>
            <input
              type="checkbox"
              checked={optionToModify.tarif_groupe}
              value={optionToModify.tarif_groupe}
              onChange={(event) => handleChecked(event)}
            />
          </label>
          <Input
            name="tarifOption"
            placeholder={
              optionToModify.tarif_groupe
                ? "Tarif par personne"
                : "Tarif total de l'option"
            }
            value={optionToModify.tarif_option.toString()}
            handleChange={(e) => modifyPrices(e)}
            widthvalue="200px"
          />
          <Button
            text="Modifier"
            handleClick={submitModify}
            marginValue="10px"
          />
          <div className="containerImgOption">
            <img
              src={`${API_URL}/images/${optionToModify.nom_image}`}
              alt={optionToModify.description_image}
              className="imgOption"
            />
            <label htmlFor="newImg" className="labelNewImgOption">
              <div className="hoverBtn">Modifier l&#39;image</div>
              <p>&#10227;</p>
              <input
                type="file"
                id="newImg"
                name="newImg"
                accept="image/*"
                className="inputNewImg"
                onChange={(e) => handleNewImg(e, optionToModify.nom_image)}
              />
            </label>
          </div>
        </section>
      )}
    </PopupContainer>
  );
}

ModifyOption.propTypes = {
  optionId: PropTypes.number.isRequired,
  setOpenModal: PropTypes.func.isRequired,
};

export default ModifyOption;
