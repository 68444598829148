import React from "react";
import propTypes from "prop-types";
import "./PopupMessage.css";
import Button from "../Button/Button";

function PopupMessage({ openModal, setOpenModal, message }) {
  const handleClick = () => {
    setOpenModal(false);
  };

  return (
    <div className={openModal ? "popupMessage-open" : "popupMessage-none"}>
      <div className="popupMessage-containerMessage">
        <p className="popupMessage-messageText">{message}</p>
        <Button text="OK" handleClick={handleClick} />
      </div>
    </div>
  );
}

PopupMessage.propTypes = {
  openModal: propTypes.bool.isRequired,
  setOpenModal: propTypes.func.isRequired,
  message: propTypes.string.isRequired,
};

export default PopupMessage;
