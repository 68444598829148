import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ReactHtmlParser from "html-react-parser";
import Helmet from "react-helmet";
import Navbar from "../0.Commun/Navbar/Navbar";
import {
  updatePanier,
  openPopup,
  messagePopup,
  filteredAccomodation,
  getDispo,
} from "../../5.Redux/Action";
import "./PackagePage.scss";
import Footer from "../0.Commun/Footer/Footer";
import { notify } from "../../0.Commun/ToastNotif/ToastNotif";
import Button from "../../0.Commun/Button/Button";
import PopupMessage from "../../0.Commun/PopupMessage/PopupMessage";
import { messages } from "../../0.Commun/Message/Message";
import PlusMinusButtons from "../../0.Commun/PlusMinusButtons/PlusMinusButtons";

const API_URL = process.env.REACT_APP_API_URL;

function PackagePage() {
  const [infos, setInfos] = useState({});
  const [images, setImages] = useState({});
  const [activities, setActivities] = useState({});
  const [options, setOptions] = useState([]);
  const [dispos, setDispos] = useState({});
  const [typeHebergement, setTypeHebergement] = useState("");
  const [isLoadingPackage, setIsLoadingPackage] = useState(false);
  const [isLoadingActivities, setIsLoadingActivities] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [isLoadingDispos, setIsLoadingDispos] = useState(false);
  const [indexSelected, setIndexSelected] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [openAccomodation, setOpenAccomodation] = useState(false);
  const [openActivities, setOpenActivities] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [openDates, setOpenDates] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    start: "",
    end: "",
    price: "",
  });
  const [activitiesForCart, setActivitiesForCart] = useState({});
  const [optionsForCart, setOptionsForCart] = useState([]);
  const filter = useSelector((state) => state.tasks.filter);
  const [newTab, setNewTab] = useState([]);
  const [priceOptions, setPriceOptions] = useState(0);
  const [modalError, setModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const getImages = () => {
    const allImages = [];
    axios
      .get(
        `${API_URL}/api/images/getByTheme?hebergement=${infos.hebergement_id}`
      )
      .then((res) => res.data)
      .then((data) => {
        data.forEach((item) => {
          allImages.push({
            idImage: item.id_image,
            nomImage: item.nom_image,
            descriptionImage: item.description_image,
          });
        });
      })
      .then(() => {
        for (let i = 0; i < activities.length; i += 1) {
          axios
            .get(
              `${API_URL}/api/images/getByTheme?activite=${activities[i].activite_id}`
            )
            .then((res) => res.data)
            .then((data) => {
              data.forEach((item2) => {
                allImages.unshift({
                  idImage: item2.id_image,
                  nomImage: item2.nom_image,
                  descriptionImage: item2.description_image,
                });
              });
            })
            .then(() => {
              setImages(allImages);
              setIsLoadingImages(true);
            })
            .catch((err) => {
              notify(err.response.data.errorMessage, "error");
            });
        }
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  useEffect(() => {
    if (activities.length !== undefined) {
      getImages();
    }
  }, [activities]);

  const loadActivities = (dataFromActivity) => {
    const allActivities = [];
    dataFromActivity.forEach((item) => {
      allActivities.push({
        idActivityCart: item.activite_id,
        name: item.nom_activite,
      });
    });
    setActivitiesForCart(allActivities);
  };

  const getActivities = () => {
    axios
      .get(`${API_URL}/api/packages/getActivities/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setActivities(data);
        setIsLoadingActivities(true);
        loadActivities(data);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  // récupère les options
  const getOption = () => {
    axios
      .get(`${API_URL}/api/packages/getOptions/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setOptions(data);
        setIsLoadingOptions(true);
      })
      .catch((err) => {
        dispatch(messagePopup(err.response.data.errorMessage));
        dispatch(openPopup(true));
      });
  };

  const translateTypeHebergement = (idTypeHebergement) => {
    switch (idTypeHebergement) {
      case 1:
        setTypeHebergement("Gîte");
        break;
      case 2:
        setTypeHebergement("Chambre d'hôte");
        break;
      case 3:
        setTypeHebergement("Hôtel");
        break;
      default:
        setTypeHebergement("");
        break;
    }
  };

  const getInfos = () => {
    axios
      .get(`${API_URL}/api/packages/getById/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setInfos(data);
        setIsLoadingPackage(true);
        getActivities();
        getOption();
        translateTypeHebergement(data.type_hebergement_id);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const getDispos = () => {
    axios
      .get(`${API_URL}/api/packages/getDispos/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setDispos(data);
        setIsLoadingDispos(true);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  useEffect(() => {
    getInfos();
    getDispos();
    if (!filter.nbPersonnes) {
      dispatch(filteredAccomodation({ ...filter, nbPersonnes: 2 }));
    }
  }, []);

  const increaseIndex = () => {
    if (indexSelected === images.length - 1) {
      setIndexSelected(0);
    } else {
      setIndexSelected(indexSelected + 1);
    }
  };

  const determineClass = (index) => {
    if (index === indexSelected) {
      return "active";
    }
    return "unactive";
  };

  useEffect(() => {
    const timer = setTimeout(increaseIndex, 5000);
    return () => clearTimeout(timer);
  }, [indexSelected]);

  const clickText = () => {
    setClicked(!clicked);
  };

  const openModalAccomodation = () => {
    setOpenAccomodation(!openAccomodation);
  };

  const openModalActivities = () => {
    setOpenActivities(!openActivities);
  };

  // ouvrir le modal de l'option
  const openModalOptions = () => {
    setOpenOptions(!openOptions);
  };

  const openModalDates = () => {
    setOpenDates(!openDates);
  };

  const choosenDate = (event) => {
    for (let i = 0; i < dispos.length; i += 1) {
      if (dispos[i].id_dispo === parseInt(event.target.value, 10)) {
        setSelectedDate({
          start: dispos[i].date_debut,
          end: dispos[i].date_fin,
          price: dispos[i].tarif_package,
        });
        setOpenDates(false);
        dispatch(getDispo(dispos[i].id_dispo));
      }
    }
  };

  const removeOnePax = () => {
    if (filter.nbPersonnes === 1) {
      notify(messages.error.guestMin, "warn");
    } else {
      dispatch(
        filteredAccomodation({
          ...filter,
          nbPersonnes: filter.nbPersonnes - 1,
        })
      );
    }
  };

  const addOnePax = () => {
    if (filter.nbPersonnes >= dispos[0].nb_personnes_package) {
      notify(messages.error.guestMax, "warn");
    } else {
      dispatch(
        filteredAccomodation({
          ...filter,
          nbPersonnes: filter.nbPersonnes + 1,
        })
      );
    }
  };

  // card avec le panier du le côté
  const updatingCart = () => {
    if (selectedDate.start === "") {
      setModalError(true);
      setModalErrorMessage(messages.missing.date);
    } else {
      const newPanier = {
        dateDebut: selectedDate.start,
        dateFin: selectedDate.end,
        parc: infos.nom_parc,
        hebergement: infos.nom_hebergement,
        typeHebergement,
        nbPersonnes: filter.nbPersonnes,
        activites: activitiesForCart,
        options: optionsForCart,
        pack: 1,
        packName: infos.nom_package,
        prix: selectedDate.price + priceOptions,
      };
      dispatch(updatePanier(newPanier));
      history.push("/precommande");
    }
  };

  // fonction pour actualiser le prix final du package
  const getPriceOption = () => {
    let newprice = 0;
    if (newTab.length === 0) {
      setPriceOptions(0);
    } else {
      for (let i = 0; i < newTab.length; i += 1) {
        // plusieurs options individuelles
        if (newTab.groupe === 1 && newTab.length >= 1) {
          newprice += priceOptions + newTab[i].tarif * filter.nbPersonnes;
          // plusieurs options tarif groupé
        } else if (newTab.length > 1 && newTab.groupe === 0) {
          newprice += priceOptions + newTab[i]?.tarif;
          // une seule option tarif individuel
        } else if (newTab[i].groupe === 1) {
          newprice += newTab[i]?.tarif * filter.nbPersonnes;
          // une seule option tarif groupé
        } else {
          newprice += newTab[i]?.tarif;
        }
        setPriceOptions(newprice);
      }
    }
  };

  // useEffect pour actualiser le prix final du package dès que le nb de personne est actualisé
  useEffect(() => {
    getPriceOption();
  }, [filter.nbPersonnes]);

  // On push nos options dans un tableau pour récupérer les tarifs
  const goHandleCheck = (op) => {
    const tabTempo = newTab;
    const optionIndex = tabTempo.findIndex((item) => item.id === op.id_option);
    const optionExist = tabTempo.find((item) => item.id === op.id_option);
    if (!optionExist) {
      tabTempo.push({
        id: op.id_option,
        name: op.nom_option,
        tarif: op.tarif_option,
        groupe: op.tarif_groupe,
      });
    } else {
      tabTempo.splice(optionIndex, 1);
    }
    setOptionsForCart(tabTempo);
    setNewTab(tabTempo);
    getPriceOption();
  };

  // détermine le bouton afficher ou retirer
  const determineBtnName = (idOption) => {
    for (let i = 0; i < newTab.length; i += 1) {
      if (newTab[i].id === idOption) {
        return "retirer";
      }
    }
    return "ajouter";
  };

  return (
    <section className="containerGlobalPackagePage">
      <Helmet>
        <title>{infos.nom_package}</title>
      </Helmet>
      <PopupMessage
        openModal={modalError}
        setOpenModal={setModalError}
        message={modalErrorMessage}
      />
      <Navbar />
      {isLoadingPackage ||
      isLoadingActivities ||
      isLoadingOptions ||
      isLoadingImages ||
      isLoadingDispos ? (
        <div>
          {isLoadingPackage && (
            <div>
              {/* Header, carousel */}
              <div className="handleImages">
                {isLoadingImages && (
                  <div>
                    {images.map((image, index) => (
                      <div key={image.idImage} className="containerImagesMap">
                        <img
                          className={`${determineClass(index)}`}
                          src={`${API_URL}/images/${image.nomImage}`}
                          alt={image.descriptionImage}
                        />
                        <img
                          className={`${determineClass(index)}`}
                          src={`${API_URL}/images/${image.nomImage}`}
                          alt={image.descriptionImage}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Titre principal */}
              <div className="containerTitre">
                <h4 className="h4Body">{infos.nom_package}</h4>
              </div>
              <div className="containerDescriptionPackage">
                <div
                  className={
                    clicked
                      ? "quillText paraphAccomodation"
                      : "quillText paraphAccomodationHidden"
                  }
                >
                  {ReactHtmlParser(infos.description_package)}
                </div>
                <button
                  type="button"
                  className="clickTextBtn"
                  onClick={clickText}
                >
                  {clicked
                    ? "Moins de détails..."
                    : "Plus de détails sur votre séjour..."}
                </button>
              </div>

              {/* Descriptif package et recap commande */}
              <div className="containerGeneral">
                {/* Descriptif package */}
                <div className="containerPackageDescription">
                  <div>
                    <h2 className="h2Body">Détails de votre package</h2>
                  </div>
                  <div className="accomodationDescription">
                    <button
                      type="button"
                      className="btnOpenSpan"
                      onClick={openModalAccomodation}
                    >
                      Votre hébergement
                      <p
                        className={
                          openAccomodation ? "arrowOpen" : "arrowClose"
                        }
                      >
                        &#8249;
                      </p>
                    </button>
                    <span
                      className={
                        openAccomodation ? "displayBlock" : "displayNone"
                      }
                    >
                      <h3 className="h3Body titreInfos">
                        {infos.nom_hebergement}
                      </h3>
                      <div className="quillText description">
                        {ReactHtmlParser(infos.description_hebergement)}
                      </div>
                    </span>
                  </div>
                  {isLoadingActivities && (
                    <div className="accomodationDescription">
                      <button
                        type="button"
                        className="btnOpenSpan"
                        onClick={openModalActivities}
                      >
                        {activities.length > 1
                          ? "Vos activités"
                          : "Votre activité"}
                        <p
                          className={
                            openActivities ? "arrowOpen" : "arrowClose"
                          }
                        >
                          &#8249;
                        </p>
                      </button>
                      <span
                        className={
                          openActivities ? "displayBlock" : "displayNone"
                        }
                      >
                        {activities.map((activity) => (
                          <div key={activity.activite_id}>
                            <h3 className="h3Body titreInfos">
                              {activity.nom_activite}
                            </h3>
                            <div className="quillText description">
                              {ReactHtmlParser(activity.description_activite)}
                            </div>
                          </div>
                        ))}
                      </span>
                    </div>
                  )}

                  {isLoadingOptions && options.length > 0 && (
                    <div className="accomodationDescription">
                      <button
                        type="button"
                        className="btnOpenSpan"
                        onClick={openModalOptions}
                      >
                        {options.length > 1 ? "Vos options" : "Votre option"}
                        <p className={openOptions ? "arrowOpen" : "arrowClose"}>
                          &#8249;
                        </p>
                      </button>
                      <span
                        className={
                          openOptions ? "displayOption" : "displayNone"
                        }
                      >
                        {options.map((op) => (
                          <div className="containerOptions" key={op.id_option}>
                            <img
                              src={`${API_URL}/images/${op?.nom_image}`}
                              alt="option"
                              className="testImage2"
                            />

                            <label className="titreOption" htmlFor="button">
                              {op.nom_option}
                            </label>
                            <div className="quillText description">
                              {ReactHtmlParser(op.description_option)}
                            </div>
                            <p>
                              {op.tarif_groupe === 1
                                ? `${op.tarif_option}€/Personne`
                                : `${op.tarif_option}€`}
                            </p>
                            <div>
                              <Button
                                text={determineBtnName(op.id_option)}
                                handleClick={() => goHandleCheck(op)}
                              />
                            </div>
                          </div>
                        ))}
                      </span>
                    </div>
                  )}
                </div>

                {/* Recap commande */}
                <div className="containerBooking">
                  <div>
                    <h2 className="h2Body">Votre réservation</h2>
                  </div>
                  <div className="tableauRecapPackage">
                    {/* Date */}
                    <div className="ligneDatePackage">
                      <h3 className="h3Body">Dates</h3>
                      <p className="resultatTableauPackage">
                        {selectedDate.start !== ""
                          ? `Du ${new Date(
                              selectedDate.start
                            ).toLocaleDateString()} au ${new Date(
                              selectedDate.end
                            ).toLocaleDateString()}`
                          : "Veuillez choisir vos dates"}
                      </p>
                      <Button
                        text="Choisir vos dates"
                        handleClick={openModalDates}
                      />
                      {isLoadingDispos && (
                        <span
                          className={openDates ? "displayBlock" : "displayNone"}
                        >
                          <select
                            name="chooseDate"
                            id="chooseDate"
                            className="selectDate"
                            onChange={(event) => choosenDate(event)}
                          >
                            <option value="">Veuillez choisir vos dates</option>
                            {dispos
                              .sort((a, b) => {
                                return (
                                  new Date(a.date_debut) -
                                  new Date(b.date_debut)
                                );
                              })
                              .map((dispo) => (
                                <option
                                  key={dispo.id_dispo}
                                  value={dispo.id_dispo}
                                >
                                  {`Du ${new Date(
                                    dispo.date_debut
                                  ).toLocaleDateString()} au ${new Date(
                                    dispo.date_fin
                                  ).toLocaleDateString()} `}
                                  &#10140;
                                  {` ${dispo.tarif_package} €`}
                                </option>
                              ))}
                          </select>
                        </span>
                      )}
                    </div>
                    <div className="dividerCart" />
                    {/* Destination */}
                    <div>
                      <h3 className="h3Body">Destination</h3>
                      <p className="resultatTableauPackage">{infos.nom_parc}</p>
                    </div>
                    <div className="dividerCart" />
                    {/* Package */}
                    <div>
                      <h3 className="h3Body">Package</h3>
                      <p className="resultatTableauPackage">
                        {infos.nom_package}
                      </p>
                    </div>
                    <div className="dividerCart" />
                    {/* Hebergement */}
                    <div>
                      <h3 className="h3Body">Hébergement</h3>
                      <p className="resultatTableauPackage">
                        {infos.nom_hebergement}
                      </p>
                    </div>
                    <div className="dividerCart" />
                    {/* Activités */}
                    {isLoadingActivities && (
                      <div>
                        <h3 className="h3Body">
                          {activities.length > 1 ? "Activités" : "Activité"}
                        </h3>
                        <div className="resultatTableauPackage">
                          {activities.map((activity) => (
                            <p key={activity.activite_id}>
                              {activity.nom_activite}
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* Options */}
                    {isLoadingOptions && newTab.length > 0 && (
                      <>
                        <div className="dividerCart" />
                        <div>
                          <h3 className="h3Body">
                            {newTab.length > 1 ? "Options" : "Option"}
                          </h3>
                          <div className="resultatTableauPackage">
                            {newTab.map((op) => (
                              <p key={op.id}>{op.name}</p>
                            ))}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="dividerCart" />
                    {/* Nb personnes */}
                    <div>
                      <h3 className="h3Body">Nombre de personnes</h3>
                      <div className="addRemovePax">
                        <PlusMinusButtons
                          addOne={addOnePax}
                          removeOne={removeOnePax}
                        >
                          <p className="resultatTableauPackage">
                            {filter.nbPersonnes}
                          </p>
                        </PlusMinusButtons>
                      </div>
                    </div>
                    <div className="dividerCart" />
                    {/* Inclus */}
                    <div>
                      <h3 className="h3Body">Inclus</h3>
                      <div className="resultatTableauPackage">
                        <p>Frais de dossiers offerts</p>
                        <p>Panier produits locaux</p>
                      </div>
                    </div>
                    <div className="dividerCart" />
                    {/* Total */}
                    <div>
                      <h3 className="h3Body">Total</h3>
                      {selectedDate.price && (
                        <p className="resultatTableauPackage finalPrice">
                          {`
                            ${
                              parseInt(selectedDate.price, 10) +
                              parseInt(priceOptions, 10)
                            } €
                          `}
                        </p>
                      )}
                    </div>
                    {/* Bouton validation */}
                    <Button text="Valider" handleClick={updatingCart} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div />
      )}
      <Footer />
    </section>
  );
}

export default PackagePage;
