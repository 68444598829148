import React from "react";
import PropTypes from "prop-types";
import Input from "../../../../0.Commun/Input/Input";
import { messages } from "../../../../0.Commun/Message/Message";
import { checkName, checkNumbers } from "../../../../0.Commun/Regex/Regex";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import "./Filter.scss";
import Button from "../../../../0.Commun/Button/Button";

function Filter({
  cmdNumber,
  setCmdNumber,
  cmdName,
  setCmdName,
  isChecked,
  setIsChecked,
  isCheckedPack,
  setIsCheckedPack,
  isCheckedGroup,
  setIsCheckedGroup,
}) {
  const filterCmdNumb = (e) => {
    setCmdName("");
    const resultRegex = checkNumbers(e.target.value);
    if (e.target.value === "") {
      setCmdNumber("");
    } else if (!resultRegex) {
      notify(messages.error.onlyNumbers, "error");
    } else {
      setCmdNumber(e.target.value);
    }
  };

  const filterCmdName = (e) => {
    setCmdNumber("");
    const resultRegex = checkName(e.target.value);
    if (e.target.value === "") {
      setCmdName("");
    } else if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setCmdName(e.target.value);
    }
  };

  const resetBtn = () => {
    setCmdName("");
    setCmdNumber("");
    setIsChecked(false);
    setIsCheckedPack(false);
    setIsCheckedGroup(false);
  };
  return (
    <section className="filterCommandeAdminSection">
      <div className="commandeAdminFiltre">
        <p>Filtre :</p>
        <form className="commandeAdminFormFiltre">
          <Input
            name="numeroCommande"
            placeholder="N° commande"
            value={cmdNumber}
            handleChange={(e) => filterCmdNumb(e)}
            widthvalue="250px"
            placeholderDisplay={false}
            fontSizeValue="14px"
          />
          <Input
            name="nomClient"
            placeholder="Nom client"
            value={cmdName}
            handleChange={(e) => filterCmdName(e)}
            widthvalue="250px"
            placeholderDisplay={false}
            fontSizeValue="14px"
          />
        </form>
        <Button text="Réinitialiser" handleClick={resetBtn} />
      </div>
      <div className="commandeAdminFiltre">
        <form className="commandeAdminFormFiltre">
          <label
            htmlFor="commandeTraite"
            className="commandeAdminFiltreCommandeTraite"
          >
            <input
              type="checkbox"
              className="filtre-checkbox"
              checked={isChecked}
              value={isChecked}
              onChange={(event) => setIsChecked(event.target.checked)}
            />
            <p>Commandes traitées</p>
          </label>
          <label
            htmlFor="isPackage"
            className="commandeAdminFiltreCommandeTraite"
          >
            <input
              type="checkbox"
              className="filtre-checkbox"
              checked={isCheckedPack}
              value={isCheckedPack}
              onChange={(event) => setIsCheckedPack(event.target.checked)}
            />
            <p>Packages</p>
          </label>
          <label
            htmlFor="isGroup"
            className="commandeAdminFiltreCommandeTraite"
          >
            <input
              type="checkbox"
              className="filtre-checkbox"
              checked={isCheckedGroup}
              value={isCheckedGroup}
              onChange={(event) => setIsCheckedGroup(event.target.checked)}
            />
            <p>Commandes de groupe</p>
          </label>
        </form>
      </div>
    </section>
  );
}

Filter.propTypes = {
  cmdNumber: PropTypes.string,
  setCmdNumber: PropTypes.func.isRequired,
  cmdName: PropTypes.string,
  setCmdName: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  setIsChecked: PropTypes.func.isRequired,
  isCheckedPack: PropTypes.bool.isRequired,
  setIsCheckedPack: PropTypes.func.isRequired,
  isCheckedGroup: PropTypes.bool.isRequired,
  setIsCheckedGroup: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  cmdNumber: "",
  cmdName: "",
};

export default Filter;
