import React, { useEffect, useState } from "react";
import DateArticle from "./components/Date/DateArticle";
import Keywords from "./components/Keywords/Keywords";
import "./ListKeywords.scss";

function ListKeywords() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const determineClass = () => {
    const pageHeight = document.body.clientHeight;
    const windowHeight = window.screen.height;
    const max = pageHeight - windowHeight;
    if (scrollPosition > 450 && scrollPosition < max) {
      return "containerListKeywordsFixed";
    }
    if (scrollPosition > max) {
      return "containerListKeywordsBottom";
    }
    return "containerListKeywords";
  };

  const handleScroll = () => {
    const scroll = window.scrollY;
    setScrollPosition(scroll);
  };

  const listenScroll = () => {
    window.addEventListener("scroll", handleScroll);
  };

  const stopListenScroll = () => {
    window.removeEventListener("scroll", handleScroll);
  };

  useEffect(() => {
    listenScroll();
    return () => {
      stopListenScroll();
    };
  }, []);

  return (
    <section className={`${determineClass()}`}>
      <h2 className="h2Body titleBlogKeywords">Mots clés</h2>
      <Keywords />
      <DateArticle />
    </section>
  );
}

export default ListKeywords;
