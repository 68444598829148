import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavBar from "../0.Commun/NavBar/NavBar";
import "./Partenaire.scss";
import Catch from "../0.Commun/Catch/Catch";
import AddButton from "../../0.Commun/AddButton/AddButton";
import AddPartner from "./components/AddPartner/AddPartner";
import ListPartner from "./components/ListPartner/ListPartner";
import { scrollLock } from "../../0.Commun/ScrollLock/ScrollLock";

const API_URL = process.env.REACT_APP_API_URL;

function Partenaire() {
  const [partenaires, setPartenaires] = useState([]);
  const [openPopupAdd, setOpenPopupAdd] = useState(false);
  const history = useHistory();

  const getPartenaires = () => {
    axios
      .get(`${API_URL}/api/partenaires`)
      .then((res) => {
        setPartenaires(res.data);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  useEffect(() => {
    getPartenaires();
    scrollLock(openPopupAdd);
  }, [openPopupAdd]);

  const addPartenaireOpen = () => {
    setOpenPopupAdd(!openPopupAdd);
  };

  return (
    <section>
      <Helmet>
        <title>Admin - Partenaires</title>
      </Helmet>
      <NavBar />
      <div className="sectionPartnerAdmin">
        <h1 className="h1Admin">Partenaires</h1>
        <AddButton
          title="Ajouter un partenaire +"
          handleClick={addPartenaireOpen}
        />
        {openPopupAdd && <AddPartner setClose={setOpenPopupAdd} />}
        <h2 className="h2Admin">Gerer vos partenaires</h2>
        <ListPartner partners={partenaires} getPartners={getPartenaires} />
      </div>
    </section>
  );
}

export default Partenaire;
