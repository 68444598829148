import React, { useState } from "react";
import Helmet from "react-helmet";
import Navbar from "../0.Commun/Navbar/Navbar";
import HeaderContact from "./HeaderContact/HeaderContact";
import Email from "./ContactForm/ContactForm";
import Footer from "../0.Commun/Footer/Footer";
import Loader from "../0.Commun/Loader/Loader";

function Contact() {
  const [loader, setLoader] = useState(false);
  return (
    <div>
      <Helmet>
        <title>Partir au Vert - Contact</title>
      </Helmet>
      <Navbar />
      {loader && (
        <>
          <Loader />
        </>
      )}
      <HeaderContact />
      <Email loader={loader} setLoader={setLoader} />
      <Footer />
    </div>
  );
}

export default Contact;
