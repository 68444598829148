import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import "./HandleImages.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Catch from "../../../../../0.Commun/Catch/Catch";
import { messages } from "../../../../../../0.Commun/Message/Message";
import { notify } from "../../../../../../0.Commun/ToastNotif/ToastNotif";
import PopupConfirm from "../../../../../../0.Commun/PopupConfirm/PopupConfirm";
import DeleteButton from "../../../../../../0.Commun/DeleteButton/DeleteButton";
import InputImage from "../../../../../../0.Commun/InputImage/InputImage";
import Button from "../../../../../../0.Commun/Button/Button";
import { axiosPrivate } from "../../../../../../0.Commun/Axios/Axios";

const API_URL = process.env.REACT_APP_API_URL;

function HandleImages({ idAccomodation, idRoom }) {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newImg, setNewImg] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
  });
  const history = useHistory();

  const getImages = () => {
    let theme = "hebergement";
    let id = idAccomodation;
    if (idRoom) {
      theme = "chambre";
      id = idRoom;
    }
    axiosPrivate({
      method: "GET",
      url: `images/getByTheme?${theme}=${id}`,
    }).then((data) => {
      setImages(data);
      setIsLoading(true);
    });
  };

  useEffect(() => {
    getImages();
  }, []);

  const askDeleteImage = (idToDelete, nameToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.image,
      id: idToDelete,
      name: nameToDelete,
    });
  };

  const deleteImage = (idImage, nomImage) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .delete(`${API_URL}/api/images/${idImage}/${nomImage}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        notify(messages.delete.image, "success");
        getImages();
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  const addImages = () => {
    const token = localStorage.getItem("TOKEN");
    try {
      for (let i = 0; i < newImg.length; i += 1) {
        const newImage = new FormData();
        newImage.append("file", newImg[i]);
        newImage.append("description_image", newImg.name);
        if (idRoom) {
          newImage.append("chambre_id", idRoom);
        } else {
          newImage.append("hebergement_id", idAccomodation);
        }
        axios
          .post(`${API_URL}/api/images/new`, newImage, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => getImages())
          .catch((err) => {
            Catch(err, history);
          });
      }
    } catch (err) {
      Catch(err, history);
    } finally {
      notify(messages.create.image, "success");
      setNewImg([]);
    }
  };

  return (
    <section className="handleImageAccomodation">
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={deleteImage}
        image
      />
      {isLoading && (
        <div className="containerImages">
          {images?.map((image) => (
            <div className="oneImage" key={image.id_image}>
              <img
                src={`${API_URL}/images/${image.nom_image}`}
                alt={images.description_image}
                className="imgAccomodation"
              />
              <DeleteButton
                onClick={() => askDeleteImage(image.id_image, image.nom_image)}
              />
            </div>
          ))}
        </div>
      )}
      <InputImage
        newImg={newImg}
        setNewImg={setNewImg}
        multiple
        marginValue="30px"
      />
      <Button text="Ajouter" handleClick={addImages} />
    </section>
  );
}

HandleImages.propTypes = {
  idAccomodation: propTypes.number,
  idRoom: propTypes.number,
};

HandleImages.defaultProps = {
  idAccomodation: undefined,
  idRoom: undefined,
};

export default HandleImages;
