import React, { useState } from "react";
import propTypes from "prop-types";
import "./ListOption.scss";
import Button from "../../../../0.Commun/Button/Button";
import ModifyOption from "../ModifyOption/ModifyOption";
import { messages } from "../../../../0.Commun/Message/Message";
import { axiosPrivate } from "../../../../0.Commun/Axios/Axios";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import PopupConfirm from "../../../../0.Commun/PopupConfirm/PopupConfirm";

function ListOption({
  options,
  getOptions,
  searchValue,
  openModify,
  setOpenModify,
}) {
  const [idToModify, setIdToModify] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
  });

  const openModifyFn = (id) => {
    setIdToModify(id);
    setOpenModify(true);
  };

  const askDeleteOption = (idToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.option,
      id: idToDelete,
    });
  };

  const deleteOptions = (id) => {
    axiosPrivate({ method: "DELETE", url: `options/${id}` }).then(() => {
      notify(messages.delete.option, "success");
      getOptions();
    });
  };

  return (
    <section className="listOptionAdmin">
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={deleteOptions}
      />
      {openModify && (
        <ModifyOption optionId={idToModify} setOpenModal={setOpenModify} />
      )}
      {options
        ?.filter((option) =>
          option.nom_option
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              searchValue
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )
        )
        .map((option) => (
          <div key={option.id_option} className="oneCard">
            <div>{option.nom_option}</div>
            <div>
              <Button
                text="Modifier"
                marginValue="5px"
                handleClick={() => openModifyFn(option.id_option)}
              />
              <Button
                text="Supprimer"
                marginValue="5px"
                handleClick={() => askDeleteOption(option.id_option)}
              />
            </div>
          </div>
        ))}
    </section>
  );
}

ListOption.propTypes = {
  options: propTypes.arrayOf(
    propTypes.shape({
      id_option: propTypes.number.isRequired,
      nom_option: propTypes.string.isRequired,
    })
  ).isRequired,
  getOptions: propTypes.func.isRequired,
  searchValue: propTypes.string.isRequired,
  openModify: propTypes.bool.isRequired,
  setOpenModify: propTypes.func.isRequired,
};

export default ListOption;
