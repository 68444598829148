// fonction pour récuperer le prix le plus bas d'un hébergement en fonction de la date
export const GetPrice = (data) => {
  const lowData = data;

  const tarifs = [];
  for (let i = 0; i < lowData.length; i += 1) {
    const debut = lowData[i].priceDatein;
    const fin = lowData[i].priceDateout;
    const date = new Date().toLocaleDateString().split("/").reverse().join("-");
    if (date >= debut && date <= fin) {
      // ajouter dans un tableau les tarifs
      tarifs.push(lowData[i].priceMonday);
      tarifs.push(lowData[i].priceTuesday);
      tarifs.push(lowData[i].priceWednesday);
      tarifs.push(lowData[i].priceThursday);
      tarifs.push(lowData[i].priceFriday);
      tarifs.push(lowData[i].priceSaturday);
      tarifs.push(lowData[i].priceSunday);
    }
  }
  // trouver le plus petit tarif
  const min = Math.min(...tarifs);
  return min;
};
