import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "html-react-parser";
import Navbar from "../0.Commun/Navbar/Navbar";
import Footer from "../0.Commun/Footer/Footer";
import ListeHebergements from "./ListeHebergements/ListeHebergements";
import "./Parc.scss";
import Carousel from "./Carousel/Carousel";
import { selectedParc } from "../../5.Redux/Action";
import ListePackages from "./ListePackages/ListePackages";
import Filter from "./Filter/Filter";

const API_URL = process.env.REACT_APP_API_URL;

function Parc() {
  const activeParc = useSelector((state) => state.tasks.selectedParc);
  const [leParc, setLeParc] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  const getParc = () => {
    if (activeParc === 0) {
      axios
        .get(`${API_URL}/api/parc/getById/${id}`)
        .then((res) => res.data)
        .then((data) => {
          setLeParc(data);
          dispatch(selectedParc(id));
        })
        .then(() => {
          setIsLoading(true);
        });
    } else {
      axios
        .get(`${API_URL}/api/parc/getById/${activeParc}`)
        .then((res) => res.data)
        .then((data) => {
          setLeParc(data);
        })
        .then(() => {
          setIsLoading(true);
        });
    }
  };

  useEffect(() => {
    getParc();
  }, [activeParc]);

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <div>
      <Helmet>
        <title>{`Partir au vert dans le ${leParc.nom_parc}`}</title>
        <meta name="description" content={leParc.description_parc} />
        <meta
          name="keywords"
          content="partir au vert, vexin, weekend, week-end, campagne, nature"
        />
      </Helmet>
      <Navbar />
      <div className="containerCarouselAndTitle">
        <div className="containerParcTitle">
          <div className="parcTitle">
            <h1 className="h1Body titreDuParc">{leParc.nom_parc}</h1>
          </div>
        </div>
        {isLoading && <Carousel />}
      </div>
      {isLoading && (
        <div className={clicked ? "contientParc" : "contientParcHidden"}>
          <div className="zoneTextDuParc">
            <div className="quillText descriptionParc">
              {ReactHtmlParser(leParc.description_parc)}
            </div>
          </div>
        </div>
      )}
      <div className="containerBtnReadMore">
        <button type="button" onClick={handleClick} className="btnReadMoreParc">
          {clicked ? "Fermer" : "Lire plus..."}
        </button>
      </div>
      <Filter />
      <h2 className="h2Body nosHebergements">Nos packages</h2>
      <div className="zoneTrait">
        <hr className="trait" />
      </div>
      <div className="containerHebergement">
        {isLoading && <ListePackages />}
      </div>
      <h2 className="h2Body nosHebergements">Nos hébergements à la carte</h2>
      <div className="zoneTrait">
        <hr className="trait" />
      </div>
      <div className="containerHebergement">
        {isLoading && <ListeHebergements />}
      </div>
      <Footer />
    </div>
  );
}

export default Parc;
