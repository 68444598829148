import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Quill from "../../../../0.Commun/Quill/Quill";
import "react-quill/dist/quill.snow.css";
import { checkName, checkNumbers } from "../../../../0.Commun/Regex/Regex";
import Catch from "../../../0.Commun/Catch/Catch";
import "./ModifyActivity.scss";
import { messages } from "../../../../0.Commun/Message/Message";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import Button from "../../../../0.Commun/Button/Button";
import Input from "../../../../0.Commun/Input/Input";
import InputImage from "../../../../0.Commun/InputImage/InputImage";
import DeleteButton from "../../../../0.Commun/DeleteButton/DeleteButton";
import PopupContainer from "../../../../0.Commun/PopupContainer/PopupContainer";

const API_URL = process.env.REACT_APP_API_URL;

function ModifyActivity({
  activiteId,
  setOpenModal,
  recupActivites,
  selectedParc,
}) {
  const [activityToModify, setActivityToModify] = useState({});
  const [textActivity, setTextActivity] = useState("");
  const [images, setImages] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [newImg, setNewImg] = useState([]);
  const history = useHistory();

  const getImages = () => {
    axios
      .get(`${API_URL}/api/images/getByTheme?activite=${activiteId}`)
      .then((res) => res.data)
      .then((data) => {
        setImages(data);
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  const getActivite = () => {
    axios
      .get(`${API_URL}/api/activites/getById/${activiteId}`)
      .then((res) => res.data)
      .then((data) => {
        setActivityToModify(data);
        setTextActivity(data.description_activite);
        getImages();
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  useEffect(() => {
    getActivite();
    return () => recupActivites(selectedParc);
  }, []);

  const handleTitle = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "warn");
    } else {
      setActivityToModify({
        ...activityToModify,
        nom_activite: e.target.value,
      });
    }
  };

  const handleIsGroup = (e) => {
    setActivityToModify({
      ...activityToModify,
      groupe_activite: e.target.checked,
    });
  };

  const modifyPrices = (e) => {
    const resultRegex = checkNumbers(e.target.value);
    if (!resultRegex) {
      notify(messages.error.onlyNumbers, "warn");
    } else {
      setActivityToModify({
        ...activityToModify,
        [e.target.name]: parseInt(e.target.value, 10),
      });
    }
  };

  const submitModify = () => {
    const token = localStorage.getItem("TOKEN");
    axios
      .put(
        `${API_URL}/api/activites/${activiteId}`,
        {
          nom_activite: activityToModify.nom_activite,
          description_activite: textActivity,
          groupe_activite: activityToModify.groupe_activite ? "1" : "0",
          tarif_activite_1pax: activityToModify.tarif_activite_1pax,
          tarif_activite_2pax: activityToModify.tarif_activite_2pax,
          tarif_activite_3pax: activityToModify.tarif_activite_3pax,
          tarif_activite_4pax: activityToModify.tarif_activite_4pax,
          tarif_activite_5pax: activityToModify.tarif_activite_5pax,
          tarif_activite_6pax: activityToModify.tarif_activite_6pax,
          tarif_groupe: activityToModify.tarif_groupe,
          parc_id: activityToModify.parc_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .then(() => {
        notify(messages.modify.success, "success");
      })
      .then(() => {
        setOpenModal(false);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  const deleteImage = (id, imageName) => {
    const token = localStorage.getItem("TOKEN");
    if (images.length > 1) {
      axios
        .delete(`${API_URL}/api/images/${id}/${imageName}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          notify(messages.delete.success, "success");
          getImages(activiteId);
        })
        .catch((err) => {
          Catch(err, history);
        });
    } else {
      notify(messages.error.addImageBeforeDelete, "warn");
    }
  };

  const addOneImage = () => {
    const token = localStorage.getItem("TOKEN");
    if (newImg.length === 0) {
      notify(messages.missing.img, "warn");
    } else {
      const newImage = new FormData();
      newImage.append("file", newImg[0]);
      newImage.append("description_image", newImg[0].name);
      newImage.append("activite_id", activiteId);
      axios
        .post(`${API_URL}/api/images/new`, newImage, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          notify(messages.modify.success, "success");
          getImages();
          setNewImg([]);
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  return (
    <PopupContainer setClose={setOpenModal}>
      {isLoading && (
        <div className="containerGeneral">
          <h2 className="h2Admin title">Modifier</h2>
          {isLoading && (
            <form className="formModify">
              <Input
                name="activityTitle"
                placeholder="Titre de l'activité"
                value={activityToModify.nom_activite}
                handleChange={(e) => handleTitle(e)}
                widthvalue="600px"
              />
              <label htmlFor="isGroup" className="labelModifyCheckbox">
                Activité praticable en groupe
                <input
                  type="checkbox"
                  id="isGroup"
                  name="isGroup"
                  checked={activityToModify.groupe_activite}
                  value={activityToModify.groupe_activite}
                  onChange={(event) => handleIsGroup(event)}
                  className="inputCheckbox"
                />
              </label>
              <p className="subtitle">Description de l&#39;activité</p>
              <Quill text={textActivity} setText={setTextActivity} />
              <h3 className="h3Admin">Tarifs</h3>
              <div className="containerPriceAdmin">
                <div className="uniquePrice">
                  <Input
                    name="tarif_activite_1pax"
                    placeholder="Prix 1 pax"
                    value={activityToModify.tarif_activite_1pax !== null ? activityToModify.tarif_activite_1pax.toString() : ""}
                    handleChange={(e) => modifyPrices(e)}
                    widthvalue="90px"
                  />
                  <Input
                    name="tarif_activite_2pax"
                    placeholder="Prix 2 pax"
                    value={activityToModify.tarif_activite_2pax !== null ? activityToModify.tarif_activite_2pax.toString() : ""}
                    handleChange={(e) => modifyPrices(e)}
                    widthvalue="90px"
                  />
                  <Input
                    name="tarif_activite_3pax"
                    placeholder="Prix 3 pax"
                    value={activityToModify.tarif_activite_3pax !== null ? activityToModify.tarif_activite_3pax.toString() : ""}
                    handleChange={(e) => modifyPrices(e)}
                    widthvalue="90px"
                  />
                  <Input
                    name="tarif_activite_4pax"
                    placeholder="Prix 4 pax"
                    value={activityToModify.tarif_activite_4pax !== null ? activityToModify.tarif_activite_4pax.toString() : ""}
                    handleChange={(e) => modifyPrices(e)}
                    widthvalue="90px"
                  />
                  <Input
                    name="tarif_activite_5pax"
                    placeholder="Prix 5 pax"
                    value={activityToModify.tarif_activite_5pax !== null ? activityToModify.tarif_activite_5pax.toString() : ""}
                    handleChange={(e) => modifyPrices(e)}
                    widthvalue="90px"
                  />
                  <Input
                    name="tarif_activite_6pax"
                    placeholder="Prix 6 pax"
                    value={activityToModify.tarif_activite_6pax !== null ? activityToModify.tarif_activite_6pax.toString() : ""}
                    handleChange={(e) => modifyPrices(e)}
                    widthvalue="90px"
                  />
                </div>
                <div className="groupPrice">
                  <Input
                    name="tarif_groupe"
                    placeholder="Tarif groupe"
                    value={activityToModify.tarif_groupe !== null ? activityToModify.tarif_groupe.toString() : ""}
                    handleChange={(e) => modifyPrices(e)}
                    widthvalue="110px"
                  />
                </div>
              </div>
              <Button
                text="Modifier"
                handleClick={submitModify}
                marginValue="30px"
              />
              <div className="containerHandleImg">
                <div className="allImg">
                  {images.map((image) => (
                    <div className="oneImage" key={image.id_image}>
                      <img
                        src={`${API_URL}/images/${image.nom_image}`}
                        alt={image.description_image}
                        className="imgActivity"
                      />
                      <DeleteButton
                        onClick={() =>
                          deleteImage(image.id_image, image.nom_image)
                        }
                      />
                    </div>
                  ))}
                </div>
                <InputImage newImg={newImg} setNewImg={setNewImg} />
                <Button
                  text="Ajouter"
                  handleClick={addOneImage}
                  marginValue="30px 0 20px 0"
                />
              </div>
            </form>
          )}
        </div>
      )}
    </PopupContainer>
  );
}

ModifyActivity.propTypes = {
  activiteId: PropTypes.number.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  recupActivites: PropTypes.func.isRequired,
  selectedParc: PropTypes.number.isRequired,
};

export default ModifyActivity;
