import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";
import { getIdAdmin } from "../../5.Redux/Action";
import "./LogAdmin.scss";
import logo from "../../../Assets/logoPartirauvertFONDtransparent.png";
import { notify } from "../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../0.Commun/Message/Message";
import Button from "../../0.Commun/Button/Button";
import Input from "../../0.Commun/Input/Input";
import { checkEmail } from "../../0.Commun/Regex/Regex";

const API_URL = process.env.REACT_APP_API_URL;

function LogAdmin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const verifToken = () => {
    const token = localStorage.getItem("TOKEN");
    axios
      .get(`${API_URL}/api/admins/getAll`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        history.push("/admin/admin");
      });
  };

  useEffect(() => {
    verifToken();
  }, []);

  const handleEmail = (e) => {
    const resultRegex = checkEmail(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setEmail(e.target.value);
    }
  };

  const handleSubmit = () => {
    if (!email || !password) {
      notify(messages.missing.emailPassword, "warn", 2);
    } else {
      axios
        .post(`${API_URL}/api/admins/login`, { email, password })
        .then((res) => res.data)
        .then((data) => {
          notify(messages.session.connected, "success", 2);
          localStorage.setItem("TOKEN", data.token);
          localStorage.setItem("userId", data.idAdmin);
          history.push("/admin/admin");
          dispatch(getIdAdmin(data.idAdmin));
        })
        .catch((err) => {
          if (err.response.status === 400) {
            notify(messages.error.wrongEmailAndPassword, "error");
          } else {
            notify(err.message, "error");
          }
        });
    }
  };

  return (
    <section className="logAdminSection">
      <Helmet>
        <title>Partir au Vert - Login</title>
      </Helmet>
      <form className="logAdminForm" onSubmit={handleSubmit}>
        <img className="logAdminLogo" src={logo} alt="logo partir au vert" />
        <Input
          name="email"
          placeholder="Email"
          value={email}
          handleChange={(e) => handleEmail(e)}
          placeholderDisplay={false}
          type="email"
        />
        <Input
          name="password"
          placeholder="Mot de passe"
          value={password}
          handleChange={(e) => setPassword(e.target.value)}
          placeholderDisplay={false}
          type="password"
        />
        <Button text="Connexion" handleClick={handleSubmit} variant="variant" />
        <Link to="/">Retour au site</Link>
      </form>
    </section>
  );
}

export default LogAdmin;
