import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IoIosPeople } from "react-icons/io";
import { axiosPrivate } from "../../../../0.Commun/Axios/Axios";
import Button from "../../../../0.Commun/Button/Button";
import { messages } from "../../../../0.Commun/Message/Message";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import InfoCommande from "../InfoCommande/InfoCommande";
import "./ListeCommande.scss";
import PopupConfirm from "../../../../0.Commun/PopupConfirm/PopupConfirm";
import { scrollLock } from "../../../../0.Commun/ScrollLock/ScrollLock";

function ListeCommande({
  cmdNumber,
  cmdName,
  isChecked,
  isCheckedPack,
  isCheckedGroup,
}) {
  const [commandes, setCommandes] = useState([]);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [cmdId, setCmdId] = useState("");
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
  });

  const getCommande = () => {
    axiosPrivate({ method: "GET", url: "/commande/getAll" }).then((data) => {
      setCommandes(data);
    });
  };

  useEffect(() => {
    getCommande();
  }, []);

  useEffect(() => {
    scrollLock(openModalInfo);
  }, [openModalInfo]);

  const openInfo = (commandeId) => {
    setOpenModalInfo(true);
    setCmdId(commandeId);
  };

  const commandeTraite = (idCommande, indexCommande) => {
    axiosPrivate({
      method: "PUT",
      url: `/commande/changeTraite/${idCommande}`,
      body: { traite_commande: !commandes[indexCommande].traite_commande },
    }).then(() => {
      notify(messages.modify.success, "success");
    });
  };

  const deleteCommande = (id) => {
    axiosPrivate({ method: "DELETE", url: `commande/${id}` }).then(() => {
      notify(messages.delete.commande, "success");
      getCommande();
    });
  };

  const askDeleteCommande = (idCommande) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.partenaire,
      id: idCommande,
    });
  };

  return (
    <section className="listCommandeAdmin">
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={deleteCommande}
      />
      {openModalInfo && (
        <InfoCommande
          openModalInfo={openModalInfo}
          setOpenModalInfo={setOpenModalInfo}
          cmdId={cmdId}
        />
      )}
      <table className="commandeAdminList">
        <thead>
          <tr>
            <td>Traité</td>
            <td />
            <td>N°</td>
            <td>Date</td>
            <td>Nom</td>
            <td>Prénom</td>
            <td>Du</td>
            <td>Au</td>
            <td>Tarif</td>
          </tr>
        </thead>
        <tbody>
          {commandes
            .filter((commande) => !isChecked || commande.traite_commande === 1)
            .filter((commande) => !isCheckedPack || commande.package === 1)
            .filter(
              (commande) => !isCheckedGroup || commande.groupe_commande === 1
            )
            .filter(
              (commande) =>
                !cmdNumber || commande.id_commande === parseInt(cmdNumber, 10)
            )
            .filter((commande) =>
              commande.nom_client
                .toLowerCase()
                .replace(/[\u0300-\u036f]/g, "")
                .includes(cmdName.toLowerCase().replace(/[\u0300-\u036f]/g, ""))
            )
            ?.map((commande, index) => (
              <tr key={commande.id_commande}>
                <td>
                  <input
                    type="checkbox"
                    defaultChecked={commande.traite_commande}
                    onChange={() => commandeTraite(commande.id_commande, index)}
                  />
                </td>
                <td>
                  {commande.package === 1 ? <p>&#x2691;</p> : ""}
                  {commande.groupe_commande === 1 ? (
                    <IoIosPeople className="react-icon" />
                  ) : (
                    ""
                  )}
                </td>
                <td>{commande.id_commande}</td>
                <td>{new Date(commande.date_commande).toLocaleDateString()}</td>
                <td>{commande.nom_client}</td>
                <td>{commande.prenom_client}</td>
                <td>{new Date(commande.date_entree).toLocaleDateString()}</td>
                <td>{new Date(commande.date_sortie).toLocaleDateString()}</td>
                <td>{commande.tarif_final_admin}</td>
                <td>
                  <Button
                    text="Modifier"
                    handleClick={() => openInfo(commande.id_commande)}
                    marginValue="0 10px 0 0"
                  />
                  <Button
                    text="Supprimer"
                    handleClick={() => askDeleteCommande(commande.id_commande)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </section>
  );
}

ListeCommande.propTypes = {
  cmdNumber: PropTypes.string.isRequired,
  cmdName: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isCheckedPack: PropTypes.bool.isRequired,
  isCheckedGroup: PropTypes.bool.isRequired,
};

export default ListeCommande;
