import React, { useState } from "react";
import propTypes from "prop-types";
import axios from "axios";
import { useHistory } from "react-router-dom";
import PopupContainer from "../../../../0.Commun/PopupContainer/PopupContainer";
import Input from "../../../../0.Commun/Input/Input";
import Button from "../../../../0.Commun/Button/Button";
import { checkEmail, verifEmail } from "../../../../0.Commun/Regex/Regex";
import { messages } from "../../../../0.Commun/Message/Message";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import Catch from "../../../0.Commun/Catch/Catch";
import "./AddPartner.scss";

const API_URL = process.env.REACT_APP_API_URL;

function AddPartner({ setClose }) {
  const [email, setEmail] = useState("");
  const history = useHistory();

  const handleEmail = (e) => {
    const resultRegex = checkEmail(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setEmail(e.target.value);
    }
  };

  function createRandomPword(letters, numbers) {
    const chars = [
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
      "0123456789",
    ];
    return [letters, numbers]
      .map((len, i) => {
        return Array(len)
          .fill(chars[i])
          .map((x) => {
            return x[Math.floor(Math.random() * x.length)];
          })
          .join("");
      })
      .concat()
      .join("")
      .split("")
      .sort(() => {
        return 0.5 - Math.random();
      })
      .join("");
  }

  const handleSubmit = () => {
    const token = localStorage.getItem("TOKEN");
    if (!email) {
      notify(messages.missing.email, "error");
    } else {
      const resultRegex = verifEmail(email);
      if (!resultRegex) {
        notify(messages.error.email, "error");
      } else {
        const newPassword = createRandomPword(6, 4);
        axios
          .post(
            `${API_URL}/api/partenaires`,
            {
              email_partenaire: email,
              password_partenaire: newPassword,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            setEmail("");
            notify(messages.create.partenaire, "success");
            setClose(false);
          })
          .catch((err) => {
            Catch(err, history);
          });
      }
    }
  };

  return (
    <PopupContainer setClose={setClose} widthValue={40} heightValue={30} center>
      <div className="containerAddPartner">
        <Input
          name="email"
          placeholder="Email"
          value={email}
          handleChange={(e) => handleEmail(e)}
        />
        <Button text="Envoyer" handleClick={handleSubmit} />
      </div>
    </PopupContainer>
  );
}

AddPartner.propTypes = {
  setClose: propTypes.func.isRequired,
};

export default AddPartner;
