import React from "react";
import Helmet from "react-helmet";
import Footer from "../0.Commun/Footer/Footer";
import Navbar from "../0.Commun/Navbar/Navbar";
import Header from "./Header/Header";
import ListArticles from "./Liste Articles/ListArticles";
import ListKeywords from "./ListKeywords/ListKeywords";
import "./Blog.scss";

function Blog() {
  return (
    <section>
      <Helmet>
        <title>Partir au Vert - Blog</title>
      </Helmet>
      <Navbar />
      <Header />
      <div className="containerBlogs">
        <ListArticles />
        <ListKeywords />
      </div>
      <Footer />
    </section>
  );
}

export default Blog;
