import React, { useState } from "react";
import PropTypes from "prop-types";
import { axiosPrivate } from "../../../../0.Commun/Axios/Axios";
import Button from "../../../../0.Commun/Button/Button";
import Input from "../../../../0.Commun/Input/Input";
import InputImage from "../../../../0.Commun/InputImage/InputImage";
import { messages } from "../../../../0.Commun/Message/Message";
import PopupContainer from "../../../../0.Commun/PopupContainer/PopupContainer";
import Quill from "../../../../0.Commun/Quill/Quill";
import { checkName, checkNumbers } from "../../../../0.Commun/Regex/Regex";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import "./AddOption.scss";
import Select from "../../../../0.Commun/Select/Select";

function AddOption({ setClose, selectedParc }) {
  const [newOption, setNewOption] = useState({
    name: "",
    tarifOption: "",
    tarifGroupe: false,
    parc_id: selectedParc,
  });
  const [newDescription, setNewDescription] = useState("");
  const [newImg, setNewImg] = useState([]);
  const [accomodations, setAccomodations] = useState([]);
  const [packages, setPackages] = useState([]);
  const [linkTo, setLinkTo] = useState({
    accomodation: false,
    id: null,
  });

  const choice = [
    {
      id_choice: 1,
      name: "Package",
    },
    {
      id_choice: 2,
      name: "Hébergement",
    },
  ];

  const handleLink = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value === 1) {
      setAccomodations([]);
      axiosPrivate({
        method: "GET",
        url: `packages/getByParc/${selectedParc}`,
      }).then((data) => {
        setPackages(data);
      });
    }
    if (value === 2) {
      setPackages([]);
      axiosPrivate({
        method: "GET",
        url: `hebergements/getByParc/${selectedParc}`,
      }).then((data) => {
        setAccomodations(data);
      });
    }
  };

  const finalChoice = (e, from) => {
    if (from === "package") {
      setLinkTo({
        accomodation: false,
        id: parseInt(e.target.value, 10),
      });
    }
    if (from === "accomodation") {
      setLinkTo({
        accomodation: true,
        id: parseInt(e.target.value, 10),
      });
    }
  };

  const handleText = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setNewOption({ ...newOption, [e.target.name]: e.target.value });
    }
  };

  const handleNumber = (e) => {
    const resultRegex = checkNumbers(e.target.value);
    if (!resultRegex) {
      notify(messages.error.onlyNumbers, "error");
    } else {
      setNewOption({ ...newOption, [e.target.name]: e.target.value });
    }
  };

  const uploadImage = (optionId) => {
    const newImage = new FormData();
    newImage.append("file", newImg[0]);
    newImage.append("description", newImg[0].name);
    newImage.append("option_id", optionId);
    axiosPrivate({ method: "POST", url: "images/new", body: newImage }).then(
      () => {
        notify(messages.create.option, "success");
      }
    );
  };

  const updateLink = (optionId) => {
    let url;
    let data = {};
    if (linkTo.accomodation) {
      url = "options/linkOptionToAccomodation";
      data = {
        id_option: optionId,
        hebergement_id: linkTo.id,
      };
    } else {
      url = "packages/options";
      data = {
        option_id: optionId,
        package_id: linkTo.id,
      };
    }
    axiosPrivate({
      method: "POST",
      url,
      body: data,
    }).then(() => {
      setLinkTo({
        accomodation: false,
        id: null,
      });
    });
  };

  const submitOption = () => {
    const optionToCreate = {
      nom_option: newOption.name,
      description_option: newDescription,
      tarif_option: parseInt(newOption.tarifOption, 10),
      tarif_groupe: newOption.tarifGroupe,
      parc_id: newOption.parc_id,
    };
    if (!newOption.name || !newDescription || newImg.length === 0) {
      notify(messages.missing.optionAdd, "error");
    } else {
      axiosPrivate({
        method: "POST",
        url: "options",
        body: optionToCreate,
      }).then((data) => {
        uploadImage(data[0].id_option);
        if (linkTo.id !== null) {
          updateLink(data[0].id_option);
        }
        setClose(false);
        setNewOption({
          name: "",
          tarifOption: null,
          tarifGroupe: false,
          parc_id: selectedParc,
        });
      });
    }
  };

  return (
    <PopupContainer setClose={setClose}>
      <section className="addOptionAdmin">
        <Input
          name="name"
          placeholder="Titre de l'option"
          value={newOption.name}
          handleChange={(e) => handleText(e)}
        />
        <Select
          datas={choice}
          optionValue="name"
          introValue="Lien"
          onChange={(e) => handleLink(e)}
          marginValue="0 0 20px 0"
        />
        {accomodations.length > 0 && (
          <Select
            datas={accomodations}
            optionValue="nom_hebergement"
            introValue="Choix"
            onChange={(e) => finalChoice(e, "accomodation")}
            marginValue="0 0 20px 0"
          />
        )}
        {packages.length > 0 && (
          <Select
            datas={packages}
            optionValue="nom_package"
            introValue="Choix"
            onChange={(e) => finalChoice(e, "package")}
            marginValue="0 0 20px 0"
          />
        )}
        <p className="introQuill">Description de l&#39;option :</p>
        <Quill text={newDescription} setText={setNewDescription} />
        <label className="labelCheckbox" htmlFor="prixGroupe">
          <p>Tarif par personne</p>
          <input
            type="checkbox"
            value={newOption.tarifGroupe}
            onChange={(event) =>
              setNewOption({ ...newOption, tarifGroupe: event.target.checked })
            }
          />
        </label>
        <Input
          name="tarifOption"
          placeholder={
            newOption.tarifGroupe
              ? "Tarif par personne"
              : "Tarif total de l'option"
          }
          value={newOption.tarifOption}
          handleChange={(e) => handleNumber(e)}
          widthvalue="200px"
        />
        <InputImage newImg={newImg} setNewImg={setNewImg} />
        <Button
          text="Ajouter une option"
          handleClick={submitOption}
          marginValue="30px 0"
        />
      </section>
    </PopupContainer>
  );
}

AddOption.propTypes = {
  setClose: PropTypes.func.isRequired,
  selectedParc: PropTypes.number.isRequired,
};

export default AddOption;
