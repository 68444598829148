import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Calendar from "react-calendar";
import {
  openPopup,
  messagePopup,
  updatePanier,
  filteredAccomodation,
} from "../../../../5.Redux/Action";
import { checkNumbers } from "../../../../0.Commun/Regex/Regex";
import "./ReservationForm.scss";
import Button from "../../../../0.Commun/Button/Button";
import PopupMessage from "../../../../0.Commun/PopupMessage/PopupMessage";
import { messages } from "../../../../0.Commun/Message/Message";
import Input from "../../../../0.Commun/Input/Input";

const API_URL = process.env.REACT_APP_API_URL;

const ReservationForm = () => {
  const [parcName, setParcName] = useState("");
  const [isLoadingParcName, setIsLoadingParcName] = useState(true);
  const [bookedAccomodation, setBookedAccomodation] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isOpened, setIsOpened] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [groupNumber, setGroupNumber] = useState("");
  const [modalError, setModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const parcId = useSelector((state) => state.tasks.selectedParc);
  const activiteFinal = useSelector((state) => state.tasks.lactivite);
  const selectedAccomodation = useSelector(
    (state) => state.tasks.selectedAccomodation
  );
  const filterRedux = useSelector((state) => state.tasks.filter);

  const getParcName = () => {
    axios
      .get(`${API_URL}/api/parc/getById/${parcId}`)
      .then((res) => res.data)
      .then((data) => setParcName(data.nom_parc))
      .then(setIsLoadingParcName(false))
      .catch((err) => {
        dispatch(messagePopup(err));
        dispatch(openPopup(true));
      });
  };
  const getAccomodationFromSelector = () => {
    if (selectedAccomodation !== 0) {
      axios
        .get(`${API_URL}/api/hebergements/getById/${selectedAccomodation}`)
        .then((res) => res.data)
        .then((data) => {
          setBookedAccomodation(data);
          setIsLoadingData(false);
        })
        .catch((err) => {
          dispatch(messagePopup(err));
          dispatch(openPopup(true));
        });
    } else {
      setBookedAccomodation("");
    }
  };
  useEffect(() => {
    if (parcId !== 0) {
      getParcName();
    }
    getAccomodationFromSelector();
  }, [selectedAccomodation, parcId]);

  const openCalendar = () => {
    setIsOpened(!isOpened);
  };
  const handleChange = (dateValue) => {
    if (dateValue.length === undefined) {
      setSelectedDates([dateValue, dateValue]);
    } else {
      setSelectedDates([dateValue[0], dateValue[1]]);
    }
    setIsOpened(false);
  };

  const submitCart = () => {
    if (!bookedAccomodation && !activiteFinal) {
      setModalError(true);
      setModalErrorMessage(messages.missing.accomodation);
    } else if (groupNumber > bookedAccomodation.groupe_nb_personnes) {
      setModalError(true);
      setModalErrorMessage(messages.error.accomodationLimit);
    } else if (!groupNumber) {
      setModalError(true);
      setModalErrorMessage(messages.missing.nbGuest);
    } else if (groupNumber < 7 || groupNumber > 50) {
      setModalError(true);
      setModalErrorMessage(messages.error.groupGuest);
    } else if (selectedDates.length === 0) {
      setModalError(true);
      setModalErrorMessage(messages.missing.date);
    } else {
      const groupPanier = {
        dateDebut: selectedDates[0],
        dateFin: selectedDates[1],
        parc: parcName,
        hebergement: bookedAccomodation.nom_hebergement || "Pas d'hébergement",
        typeHebergement: bookedAccomodation.nom_type_hebergement || "",
        nbPersonnes: groupNumber,
        activites: activiteFinal,
        groupe_commande: 1,
        pack: 0,
        prix: 0,
      };
      dispatch(updatePanier(groupPanier));
      history.push("/precommande");
    }
  };

  const removeOnePax = () => {
    if (filterRedux.nbPersonnes === 1) {
      dispatch(messagePopup("Il doit y avoir au minimum une personne"));
      dispatch(openPopup(true));
    } else {
      dispatch(
        filteredAccomodation({
          ...filterRedux,
          nbPersonnes: filterRedux.nbPersonnes - 1,
        })
      );
    }
  };

  const addOnePax = () => {
    if (
      filterRedux.nbPersonnes >= bookedAccomodation.nb_personnes_hebergement
    ) {
      dispatch(
        messagePopup(
          `Cet établissement n'accepte pas plus de ${bookedAccomodation.nb_personnes_hebergement} personnes`
        )
      );
      dispatch(openPopup(true));
    } else {
      dispatch(
        filteredAccomodation({
          ...filterRedux,
          nbPersonnes: filterRedux.nbPersonnes + 1,
        })
      );
    }
  };

  const handleGroupNumber = (event) => {
    const resultCheck = checkNumbers(event.target.value);
    if (!resultCheck) {
      dispatch(messagePopup("Ce champ n'accepte que des chiffres"));
      dispatch(openPopup(true));
    } else {
      setGroupNumber(event.target.value);
    }
  };

  return (
    <div className="form-container">
      <PopupMessage
        openModal={modalError}
        setOpenModal={setModalError}
        message={modalErrorMessage}
      />
      <div className="reservation">
        <h2 className="h2Body votreReserve">VOTRE RéSERVATION</h2>
      </div>
      <div className="recap-groupe">
        <div className="ligneDate">
          <h3 className="h3Body">Dates</h3>
          <p className="resultatTableau">
            {selectedDates.length > 0
              ? `Du ${new Date(
                  selectedDates[0]
                ).toLocaleDateString()} au ${new Date(
                  selectedDates[1]
                ).toLocaleDateString()}`
              : "Veuillez choisir vos dates"}
          </p>
          {isOpened ? (
            <Button
              text="Fermer"
              handleClick={openCalendar}
              marginValue="10px 0 0 0"
            />
          ) : (
            <Button
              text="Modifier"
              handleClick={openCalendar}
              marginValue="10px 0 0 0"
            />
          )}
          <span>
            {isOpened ? (
              <Calendar
                className="calendar"
                minDate={new Date()}
                selectRange={!!selectedAccomodation}
                onChange={handleChange}
              />
            ) : null}
          </span>
        </div>
        <div className="dividerCart" />
        <div className="containerCartParc">
          <h3 className="h3Body">Destination</h3>
          {!isLoadingParcName && <p className="resultatTableau">{parcName}</p>}
        </div>
        <div className="dividerCart" />
        <div className="containerCartParc">
          {selectedAccomodation ? (
            <>
              <h3 className="h3Body">Hébergement</h3>
              {!isLoadingData && (
                <p className="resultatTableau">
                  {bookedAccomodation.nom_hebergement}
                </p>
              )}
              <div className="dividerCart" />
            </>
          ) : null}
        </div>
        <div className="containerCartParc">
          <h3 className="h3Body">Nombre de personnes</h3>
          <div className="containerCartPax">
            {filterRedux.taille_groupe ? (
              <>
                <Input
                  name="groupNumber"
                  value={groupNumber}
                  type="text"
                  id="groupNumber"
                  placeholder="7,8,9..."
                  handleChange={handleGroupNumber}
                  placeholderDisplay={false}
                />
              </>
            ) : (
              <div className="containerCartPax">
                <button
                  type="button"
                  onClick={removeOnePax}
                  className="addRemoveBtn"
                >
                  &#8722;
                </button>
                <p className="resultatTableau">{filterRedux.nbPersonnes}</p>
                <button
                  type="button"
                  onClick={addOnePax}
                  className="addRemoveBtn"
                >
                  &#43;
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="dividerCart" />
        {activiteFinal.length !== 0 && (
          <>
            <div className="containerCartParc">
              <h3 className="h3Body">Activités</h3>
              <div className="resultatTableau">
                {activiteFinal.map((activitefin) => (
                  <p key={activitefin.idActivityCart}>{activitefin.name}</p>
                ))}
              </div>
            </div>
            <div className="dividerCart" />
          </>
        )}
        <Button text="Demande de devis" handleClick={submitCart} />
      </div>
    </div>
  );
};

export default ReservationForm;
