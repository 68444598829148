import React from "react";
import PropTypes from "prop-types";
import "./FilterAccomodation.scss";
import InputSearch from "../../../../0.Commun/InputSearch/InputSearch";
import Select from "../../../../0.Commun/Select/Select";

const FilterAccomodation = ({
  setIsGroup,
  isGroup,
  setSearchValue,
  searchValue,
  setFilteredType,
  accomodationTypes,
}) => {
  const updateGroup = (e) => {
    let result;
    if (e.target.checked) {
      result = 1;
    } else {
      result = 0;
    }
    setIsGroup(result);
  };

  const handleAccomodationTypeChoice = (event) => {
    setFilteredType(parseInt(event.target.value, 10));
    setSearchValue("");
  };

  return (
    <>
      <div className="accomodationAdminFiltre">
        <p>Filtrer :</p>
        <form className="formFiltre">
          <label htmlFor="filtre-groupe" className="LabelFiltre">
            <input
              type="checkbox"
              className="filtreCheckbox"
              id="filtre-groupe"
              checked={isGroup}
              value={isGroup}
              onChange={(event) => updateGroup(event)}
            />
            <p>Pour groupe</p>
          </label>
          <InputSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder="Rechercher un hébergement"
          />
          <div className="LabelFiltre">
            <p>Par type :</p>
            <Select
              datas={accomodationTypes}
              optionValue="nom_type_hebergement"
              onChange={(e) => handleAccomodationTypeChoice(e)}
              introValue="Type"
              widthvalue="auto"
              introHidden={false}
            />
          </div>
        </form>
      </div>
    </>
  );
};

FilterAccomodation.propTypes = {
  setIsGroup: PropTypes.func.isRequired,
  isGroup: PropTypes.number.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  setFilteredType: PropTypes.func.isRequired,
  accomodationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id_type_hebergement: PropTypes.number.isRequired,
      nom_type_hebergement: PropTypes.string.isRequired,
    })
  ).isRequired,
};
export default FilterAccomodation;
