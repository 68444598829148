import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import PopupContainer from "../../../../0.Commun/PopupContainer/PopupContainer";
import "./HandleCalendar.scss";
import { axiosPrivate } from "../../../../0.Commun/Axios/Axios";
import CalendrierPartenaire from "../../../../4.Pro/0.Commun/CalendrierPartenaire/CalendrierPartenaire";

function HandleCalendar({ idPartner, setClose }) {
  const [accomodations, setAccomodations] = useState([]);
  const [calendarIsVisible, setCalendarIsVisible] = useState(false);
  const [calendarId, setCalendarId] = useState(null);

  const getAccomodations = () => {
    axiosPrivate({
      method: "GET",
      url: `calendriers/userCalAdmin/${idPartner}`,
    }).then((data) => {
      setAccomodations(data);
    });
  };

  useEffect(() => {
    getAccomodations();
  }, []);

  const openCalendar = (idCalendar) => {
    setCalendarId(idCalendar);
    setCalendarIsVisible(true);
  };

  return (
    <PopupContainer setClose={setClose}>
      <div className="handleCalendarAdmin">
        {accomodations !== undefined ? (
          accomodations?.map((accomodation) => (
            <button
              type="button"
              className="cardAccomodationCalendar"
              key={accomodation.calPartenaire_id}
              onClick={() => openCalendar(accomodation.calPartenaire_id)}
            >
              <h3 className="h3Body">
                {accomodation.calPartenaire_hebergement_name}
              </h3>
            </button>
          ))
        ) : (
          <p>Aucun hébergement pour ce partenaire</p>
        )}
      </div>
      {calendarIsVisible && (
        <CalendrierPartenaire
          stateVisibility={setCalendarIsVisible}
          calendarId={calendarId}
          isPartenairePanel
        />
      )}
    </PopupContainer>
  );
}

HandleCalendar.propTypes = {
  idPartner: propTypes.number.isRequired,
  setClose: propTypes.func.isRequired,
};

export default HandleCalendar;
