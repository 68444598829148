import React from "react";
import propTypes from "prop-types";
import "./HandlePrices.scss";
import PriceList from "./Components/PriceList/PriceList";
import RoomList from "./Components/RoomList/RoomList";

function HandlePrices({ idAccomodation, accomodationType }) {
  return (
    <section className="handlePriceAccomodation">
      {accomodationType === 1 ? (
        <PriceList idAccomodation={idAccomodation} />
      ) : (
        <RoomList idAccomodation={idAccomodation} />
      )}
    </section>
  );
}

HandlePrices.propTypes = {
  idAccomodation: propTypes.number.isRequired,
  accomodationType: propTypes.number.isRequired,
};

export default HandlePrices;
