import React from "react";
import ReactQuill from "react-quill";
import PropTypes from "prop-types";
import "react-quill/dist/quill.snow.css";
import "./Quill.css";

function Quill({ text, setText, heightValue, widthvalue, marginValue }) {
  const toolbarOptions = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
      [{ list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };

  const style = {
    height: heightValue,
    width: widthvalue,
    margin: marginValue,
  };

  return (
    <div className="quill-container" style={style}>
      <ReactQuill
        theme="snow"
        modules={toolbarOptions}
        value={text}
        className="quill-display"
        onChange={setText}
      />
    </div>
  );
}

Quill.propTypes = {
  text: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  heightValue: PropTypes.string,
  widthvalue: PropTypes.string,
  marginValue: PropTypes.string,
};

Quill.defaultProps = {
  heightValue: "350px",
  widthvalue: "100%",
  marginValue: "30px 0 60px 0",
};

export default Quill;
