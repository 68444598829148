import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import "./HandleInfos.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
import {
  checkName,
  checkNumbers,
} from "../../../../../../0.Commun/Regex/Regex";
import { messages } from "../../../../../../0.Commun/Message/Message";
import Catch from "../../../../../0.Commun/Catch/Catch";
import Quill from "../../../../../../0.Commun/Quill/Quill";
import { notify } from "../../../../../../0.Commun/ToastNotif/ToastNotif";
import Input from "../../../../../../0.Commun/Input/Input";
import Select from "../../../../../../0.Commun/Select/Select";
import Button from "../../../../../../0.Commun/Button/Button";

const API_URL = process.env.REACT_APP_API_URL;

function HandleInfos({ infos }) {
  const [newInfos, setNewInfos] = useState({
    id_hebergement: "",
    nom_hebergement: "",
    description_hebergement: "",
    groupe_hebergement: "",
    calendrier: "",
    nb_personnes_hebergement: "",
    groupe_nb_personnes: "",
    parc_id: "",
    type_hebergement_id: "",
    nom_type_hebergement: "",
  });
  const [description, setDescription] = useState("");
  const [types, setTypes] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const getTypes = () => {
    axios
      .get(`${API_URL}/api/types_hebergement`)
      .then((res) => res.data)
      .then((data) => {
        setTypes(data);
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  useEffect(() => {
    getTypes();
    setNewInfos(infos);
    setDescription(infos.description_hebergement);
  }, []);

  const handleText = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setNewInfos({
        ...newInfos,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleNumber = (e) => {
    const resultRegex = checkNumbers(e.target.value);
    if (!resultRegex) {
      notify(messages.error.onlyNumbers, "error");
    } else {
      setNewInfos({
        ...newInfos,
        [e.target.name]: parseInt(e.target.value, 10),
      });
    }
  };

  const handleType = (e) => {
    setNewInfos({
      ...newInfos,
      type_hebergement_id: parseInt(e.target.value, 10),
    });
  };

  const handleGroup = (e) => {
    if (e.target.checked === true) {
      setNewInfos({ ...newInfos, [e.target.name]: 1 });
    } else {
      setNewInfos({ ...newInfos, [e.target.name]: 0 });
    }
  };

  const modify = () => {
    const token = localStorage.getItem("TOKEN");
    if (
      !newInfos.nom_hebergement ||
      !description ||
      !newInfos.nb_personnes_hebergement ||
      !newInfos.type_hebergement_id
    ) {
      notify(messages.missing.inputEmpty, "error");
    } else {
      axios
        .put(
          `${API_URL}/api/hebergements/${newInfos.id_hebergement}`,
          {
            nom_hebergement: newInfos.nom_hebergement,
            description_hebergement: description,
            groupe_hebergement: newInfos.groupe_hebergement,
            calendrier: newInfos.calendrier,
            nb_personnes_hebergement: newInfos.nb_personnes_hebergement,
            groupe_nb_personnes:
              newInfos.groupe_nb_personnes === ""
                ? null
                : newInfos.groupe_nb_personnes,
            parc_id: newInfos.parc_id,
            type_hebergement_id: newInfos.type_hebergement_id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          notify(messages.modify.success, "success");
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  return (
    <section className="handleInfoAccomodation">
      <form className="formHandleInfo">
        <Input
          name="nom_hebergement"
          value={newInfos.nom_hebergement}
          handleChange={(e) => handleText(e)}
          placeholder="Nom de l'hébergement"
        />
        {isLoading && (
          <Select
            datas={types}
            optionValue="nom_type_hebergement"
            onChange={(e) => handleType(e)}
            introValue={newInfos.nom_type_hebergement}
            marginValue="0 0 30px 0"
          />
        )}
        <Input
          name="nb_personnes_hebergement"
          value={newInfos.nb_personnes_hebergement.toString()}
          handleChange={(e) => handleNumber(e)}
          placeholder="Nb personnes max"
        />
        <label htmlFor="group" className="labelHandleInfo">
          <input
            type="checkbox"
            name="groupe_hebergement"
            className="inputCheckbox"
            checked={newInfos.groupe_hebergement}
            onChange={(event) => handleGroup(event)}
          />
          <p>Hébergement disponible pour les groupes</p>
        </label>
        {newInfos.groupe_hebergement === 1 && (
          <Input
            name="groupe_nb_personnes"
            value={newInfos.groupe_nb_personnes ? newInfos.groupe_nb_personnes.toString() : "0"}
            handleChange={(e) => handleNumber(e)}
            placeholder="Nb personnes max par groupe"
          />
        )}
        <div className="containerQuillAccomodation">
          <Quill setText={setDescription} text={description} />
        </div>
        <Button text="Modifier" handleClick={() => modify()} />
      </form>
    </section>
  );
}

HandleInfos.propTypes = {
  infos: propTypes.shape({
    id_hebergement: propTypes.number.isRequired,
    nom_hebergement: propTypes.string.isRequired,
    description_hebergement: propTypes.string.isRequired,
    groupe_hebergement: propTypes.number.isRequired,
    nb_personnes_hebergement: propTypes.number.isRequired,
    groupe_nb_personnes: propTypes.number,
    parc_id: propTypes.number.isRequired,
    type_hebergement_id: propTypes.number.isRequired,
    nom_type_hebergement: propTypes.string.isRequired,
  }).isRequired,
};

export default HandleInfos;
