import React from "react";
import logoPartirAuVert from "../../../../Assets/LogoPARTIRauVERT.jpg";
import "./Header.scss";

function Header() {
  return (
    <section className="containerHeader">
      <img src={logoPartirAuVert} alt="logo Partir au Vert" className="logo" />
      <div className="containerText">
        <p className="text">LE BLOG</p>
      </div>
    </section>
  );
}

export default Header;
