import React from "react";
import propTypes from "prop-types";
import "./Navbar.scss";

function Navbar({ setIdNavbar, accomodationType, inRoom }) {
  return (
    <section className="containerBtn">
      <button
        type="button"
        className="buttonGlobal"
        onClick={() => setIdNavbar(1)}
      >
        Informations
      </button>
      <button
        type="button"
        className="buttonGlobal"
        onClick={() => setIdNavbar(2)}
      >
        Images
      </button>
      <button
        type="button"
        className="buttonGlobal"
        onClick={() => setIdNavbar(3)}
      >
        {accomodationType === 1 ? "Tarification" : "Chambres"}
      </button>
      {!inRoom && (
        <button
          type="button"
          className="buttonGlobal"
          onClick={() => setIdNavbar(4)}
        >
          Options
        </button>
      )}
    </section>
  );
}

Navbar.propTypes = {
  setIdNavbar: propTypes.func.isRequired,
  accomodationType: propTypes.number,
  inRoom: propTypes.bool,
};

Navbar.defaultProps = {
  accomodationType: 1,
  inRoom: false,
};

export default Navbar;
