import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import NavBar from "../0.Commun/NavBar/NavBar";
import "./Profil.scss";
import verifyToken from "../0.Commun/VerifyToken/VerifyToken";
import MaintenanceImage from "./components/MaintenanceImage/MaintenanceImage";
import { notify } from "../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../0.Commun/Message/Message";
import Button from "../../0.Commun/Button/Button";
import Input from "../../0.Commun/Input/Input";
import { checkEmail } from "../../0.Commun/Regex/Regex";

const API_URL = process.env.REACT_APP_API_URL;

function Profil() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verifPassword, setVerifPassword] = useState("");
  const idAdmin = useSelector((state) => state.tasks.idAdmin);
  const history = useHistory();

  const getEmail = () => {
    const token = localStorage.getItem("TOKEN");
    if (idAdmin === 0) {
      localStorage.removeItem("TOKEN");
      history.push("/login-admin");
    } else {
      axios
        .get(`${API_URL}/api/admins/getById/${idAdmin}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data)
        .then((data) => {
          setEmail(data.email_admin);
        })
        .catch((err) => {
          notify(err.response.data.errorMessage, "error");
        });
    }
  };

  useEffect(() => {
    verifyToken(history);
    getEmail();
  }, []);

  const handleEmail = (e) => {
    const resultRegex = checkEmail(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setEmail(e.target.value);
    }
  };

  const putUser = () => {
    const token = localStorage.getItem("TOKEN");
    if (!email || !password || !verifPassword) {
      notify(messages.missing.inputEmpty, "warn");
    } else if (password === verifPassword) {
      axios
        .put(
          `${API_URL}/api/admins/${idAdmin}`,
          {
            email,
            password,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data)
        .then(() => {
          notify(messages.modify.success, "success");
        })
        .catch((err) => {
          notify(err.response.data.errorMessage, "error");
        });
    } else {
      notify(messages.error.passwordVerificationWrong, "error");
    }
  };

  return (
    <section>
      <Helmet>
        <title>Admin - Mon profil</title>
      </Helmet>
      <NavBar />
      <div className="profilAdminGlobal">
        <form className="profilAdminForm">
          <Input
            name="email"
            placeholder="Email"
            handleChange={(e) => handleEmail(e)}
            value={email}
            type="email"
          />
          <Input
            name="password"
            placeholder="Nouveau mot de passe"
            handleChange={(e) => setPassword(e.target.value)}
            value={password}
            type="password"
          />
          <Input
            name="verifPassword"
            placeholder="Confirmer votre nouveau mot de passe"
            handleChange={(e) => setVerifPassword(e.target.value)}
            value={verifPassword}
            type="password"
          />
          <Button text="Valider" handleClick={putUser} />
          <MaintenanceImage />
        </form>
      </div>
    </section>
  );
}

export default Profil;
