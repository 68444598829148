import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Recaptcha from "react-recaptcha";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import "./PreCommande.scss";
import Navbar from "../0.Commun/Navbar/Navbar";
import Footer from "../0.Commun/Footer/Footer";
import Loader from "../0.Commun/Loader/Loader";
import Button from "../../0.Commun/Button/Button";
import PopupMessage from "../../0.Commun/PopupMessage/PopupMessage";
import { messages } from "../../0.Commun/Message/Message";
import Formulaire from "./Components/Formulaire/Formulaire";
import { notify } from "../../0.Commun/ToastNotif/ToastNotif";
import Panier from "./Components/Panier/Panier";

const API_URL = process.env.REACT_APP_API_URL;

function PreCommande() {
  const [newGuest, setNewGuest] = useState({
    firstName: "",
    lastName: "",
    company: "",
    clientEmail: "",
    clientPhone: "",
    cp: "",
  });
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const panier = useSelector((state) => state.tasks.panier);
  const idDispo = useSelector((state) => state.tasks.idDispo);
  const history = useHistory();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const backToHome = () => {
    if (panier.prix === undefined) {
      history.push("/", window.scrollTo(0, 0));
    }
  };

  useEffect(() => {
    scrollToTop();
    backToHome();
  }, []);

  const messageSent = () => {
    if (isVerified) {
      setModalError(true);
      setModalErrorMessage(messages.success.precommande);
      history.push("/", window.scrollTo(0, 0));
      setLoader(false);
    } else {
      setModalError(true);
      setModalErrorMessage(messages.error.notARobot);
    }
  };

  const callback = () => {
    // console.log("Done!!!!");
  };

  const verifyCallback = () => {
    setIsVerified(true);
  };

  const onClickHandle = () => {
    if (isVerified === false) {
      notify(messages.error.notARobot, "error", 5);
    } else if (
      !newGuest.lastName ||
      !newGuest.firstName ||
      !newGuest.clientEmail ||
      !newGuest.clientPhone ||
      !address ||
      !newGuest.cp ||
      !city
    ) {
      notify(messages.missing.inputEmpty, "error", 5);
    } else {
      setLoader(true);
      axios
        .post(`${API_URL}/api/mailPrecommande`, {
          nameClient: newGuest.lastName,
          firstname: newGuest.firstName,
          societe: newGuest.company,
          email: newGuest.clientEmail,
          adresse: address,
          phone: newGuest.clientPhone,
          code_postal: newGuest.cp,
          ville: city,
          message,
          dateDebut: panier.dateDebut,
          dateFin: panier.dateFin,
          parc: panier.parc,
          hebergement: panier.hebergement,
          typeHebergement: panier.typeHebergement,
          chambre: panier.chambre,
          nbPersonnes: panier.nbPersonnes,
          activites: panier.activites,
          options: panier.options,
          prix: panier.prix,
          pack: panier.pack,
          packName: panier.packName,
          groupe_commande: panier.groupe_commande,
        })
        .then(() => {
          messageSent();
        })
        .catch((err) => {
          notify(err, "error", 5);
        });
      axios
        .post(`${API_URL}/api/mailPrecommandeClient`, {
          nameClient: newGuest.lastName,
          firstname: newGuest.firstName,
          societe: newGuest.company,
          email: newGuest.clientEmail,
          message,
          dateDebut: panier.dateDebut,
          dateFin: panier.dateFin,
          parc: panier.parc,
          hebergement: panier.hebergement,
          typeHebergement: panier.typeHebergement,
          chambre: panier.chambre,
          nbPersonnes: panier.nbPersonnes,
          activites: panier.activites,
          options: panier.options,
          prix: panier.prix,
          pack: panier.pack,
          packName: panier.packName,
          groupe_commande: panier.groupe_commande,
        })
        .then(() => {
          messageSent();
        })
        .catch((err) => {
          notify(err, "error", 5);
        });
      axios
        .post(`${API_URL}/api/commande`, {
          date_commande: new Date()
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("-"),
          nom_client: newGuest.lastName,
          prenom_client: newGuest.firstName,
          societe_client: newGuest.company,
          email_client: newGuest.clientEmail,
          telephone_client: newGuest.clientPhone,
          adresse_client: address,
          code_postal_client: newGuest.cp,
          ville_client: city,
          message_client: message,
          traite_commande: false,
          date_entree: new Date(panier.dateDebut)
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("-"),
          date_sortie: new Date(panier.dateFin)
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("-"),
          nom_hebergement_commande: panier.hebergement,
          nb_personnes_commande: panier.nbPersonnes,
          type_hebergement_commande: panier.typeHebergement,
          tarif_final_admin: panier.prix,
          chambre: panier.chambre,
          parc: panier.parc,
          activite: panier.activites,
          option: panier.options,
          pack: panier.pack,
          packName: panier.packName,
          groupe_commande: panier.groupe_commande,
        })
        .then(() => {
          if (idDispo > 0) {
            axios
              .get(`${API_URL}/api/packages/getDispo/${idDispo}`)
              .then((res) => res.data)
              .then((data) => {
                const newStock = data.stock - 1;
                axios
                  .put(`${API_URL}/api/packages/dispo/${idDispo}`, {
                    stock: newStock,
                  })
                  .catch((err) => {
                    notify(err, "error", 5);
                  });
              })
              .catch((err) => {
                notify(err, "error", 5);
              });
          }
        })
        .catch((err) => {
          notify(err, "error", 5);
        });
    }
  };

  return (
    <section>
      <Helmet>
        <title>Pré-commandez votre prochain séjour</title>
      </Helmet>
      <PopupMessage
        openModal={modalError}
        setOpenModal={setModalError}
        message={modalErrorMessage}
      />
      <Navbar />
      {loader && (
        <>
          <Loader />
        </>
      )}
      <div className="sectionPreorder">
        <div className="introPreorder">
          <p>Vous y êtes presque...!</p>
          <p>
            Veuillez remplir ce formulaire, un de nos collaborateurs entrera en
            contact avec vous dès que possible.
          </p>
          <h2 className="h2Body">Formulaire de Pré-commande</h2>
          <div className="traitDiviseur" />
        </div>
        <div className="formAndRecap">
          <div className="formPreorder">
            <Formulaire
              newGuest={newGuest}
              setNewGuest={setNewGuest}
              city={city}
              setCity={setCity}
              message={message}
              setMessage={setMessage}
              address={address}
              setAddress={setAddress}
            />
          </div>
          <div className="cart">
            <Panier />
          </div>
        </div>
        <div className="centrerCaptcha">
          <Recaptcha
            sitekey="6Lc3z5MaAAAAANwfmbYC7EPM_AOCg9QADc5lsk5w"
            render="explicit"
            verifyCallback={verifyCallback}
            onloadCallback={callback}
            hl="fr"
          />
        </div>
        <div className="centrerCaptcha">
          <Button text="Pré-Commander" handleClick={onClickHandle} />
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default PreCommande;
