export const messages = {
  ask: {
    accomodation: "Etes-vous certain de vouloir supprimer cet hébergement ?",
    activity: "Etes-vous sûr de vouloir supprimer cette activité ?",
    blog: "Etes-vous sûr de vouloir supprimer cet article ?",
    booking: "Etes-vous sûr de vouloir supprimer cette réservation ?",
    commande: "Etes-vous sûr de vouloir supprimer cette commande ?",
    dispo: "Etes-vous sûr de vouloir supprimer cette disponibilité ?",
    image: "Etes-vous certain de vouloir supprimer cette image ?",
    option: "Etes-vous sûr de vouloir supprimer cette option ?",
    package: "Etes-vous sûr de vouloir supprimer ce package ?",
    parc: "Etes-vous sûr de vouloir supprimer cette destination ?",
    partenaire: "Etes-vous sûr de vouloir supprimer ce partenaire ?",
    price: "Etes-vous certain de vouloir supprimer ce tarif ?",
    room: "Etes-vous certain de vouloir supprimer cette chambre ?",
    TarifFixe: "Etes-vous sûr de vouloir supprimer ce tarif ?",
  },
  create: {
    accomodation: "Hébergement créé avec succès",
    activity: "Activité créée avec succès",
    booking: "Réservation créée avec succès",
    destination: "Destination créée avec succès",
    externalCalendar: "Calendrier externe ajouté avec succès",
    image: "Image importée avec succès",
    keyword: "Mot-clé créé avec succès",
    option: "Option créée avec succès",
    optionInHebergement: "L'option a bien été ajouté à l'hébergement",
    optionInPackage: "L'option a bien été ajouté au package",
    package: "Package créé avec succès",
    partenaire: "Partenaire créé avec succès",
    partenaireEmail: "Email envoyé au partenaire",
    price: "Tarif créé avec succès",
    room: "Chambre créée avec succès",
  },
  delete: {
    accomodation: "Hébergement supprimé avec succès",
    activity: "Activité supprimée avec succès",
    activityInPackage: "L'activité a bien été supprimée du package",
    booking: "Réservation supprimée avec succès",
    commande: "Commande supprimée avec succès",
    disponibilityInPackage: "La disponibilité a bien été supprimée du package",
    externalCalendar: "Calendrier externe supprimé avec succès",
    image: "Image supprimée avec succès",
    option: "Option supprimée avec succès",
    optionInHebergement: "L'option a bien été supprimée de l'hébergement",
    optionInPackage: "L'option a bien été supprimée du package",
    package: "Package supprimé avec succès",
    partenaire: "Partenaire supprimé avec succès",
    price: "Tarif supprimé avec succès",
    room: "Chambre supprimée avec succès",
    success: "Suppression effectuée avec succès",
  },
  error: {
    accomodationLimit: "Cet hébergement ne peut accueillir autant de personnes",
    activityAllreadyInPackage: "Cette activité est déjà dans le package",
    addImageBeforeDelete: "Vous devez ajouter une image avant de supprimer",
    askAddOptionToPackage: "Vous devez ajouter une option au package",
    dateBooked: "Cette date est déjà réservée",
    dateIncoherent:
      "Veuillez sélectionner une date de fin après la date de début",
    email: "Veuillez renseigner un email valide",
    emailExist: "Cet email est déjà utilisé",
    globalError: "Une erreur est survenue, veuillez recommencer",
    groupGuest:
      "Cette section est destinée à des groupes de 7 personnes minimum et 50 personnes maximum",
    guestMax: "Cet établissement ne peut accueillir plus de personnes",
    guestMin: "Il doit y avoir au minimum 1 personne",
    keywordUsed: "Ce mot clé est déjà utilisé",
    noScriptUse: "Vous ne pouvez pas utiliser le mot 'script'",
    notARobot: "Veuillez vérifier que vous n'êtes pas un robot",
    onlyNumbers: "Veuillez saisir uniquement des chiffres",
    optionAllreadyInPackage: "Cette option est déjà présente",
    passwordVerificationWrong: "Les mots de passe ne correspondent pas",
    unauthaurizedCharacter: "Caractère non autorisé",
    wrongImgFormat: "L'image ne correspond pas à un format png, jpeg ou jpg",
    wrongEmailAndPassword: "Email ou mot de passe incorrect",
  },
  info: {
    noAccomodation: "Aucun hébergement n'a été trouvé",
    noActivity: "Aucune activité n'est disponible pour cette destination",
    waiting: "patientez ...",
  },
  missing: {
    accomodation: "Veuillez sélectionner un hébergement",
    activityInPackage: "Veuillez sélectionner une activité",
    champAndImage:
      "Veuillez renseigner tous les champs et ajouter au moins deux images",
    email: "Veuillez renseigner votre email",
    emailPassword: "Veuillez renseigner un email et un mot de passe",
    date: "Veuillez renseigner vos dates",
    missEmail: "Vous devez renseigner un email",
    nbGuest: "Veuillez renseigner le nombre de personnes",
    img: "Veuillez sélectionner une image",
    imgDesc: "Veuillez ajouter une image et un descriptif",
    inputEmpty: "Veuillez renseigner tous les champs",
    option: "Veuillez sélectionner une option",
    optionAdd: "Veuillez remplir tous les champs",
    room: "Veuillez sélectionner une chambre",
  },
  modify: {
    accomodation: "Hébergement modifié avec succès",
    activityInPackage: "L'activité a bien été ajoutée au package",
    diponibilityPackage: "La disponibilité du package a bien été ajoutée",
    email: "Email modifié avec succès",
    forgotPassword:
      "Un email vous a été envoyé pour réinitialiser votre mot de passe",
    img: "L'image a bien été modifiée",
    notes: "Notes modifiées avec succès",
    option: "L'option a bien été modifiée",
    optionInHebergement: "l'option a bien été ajoutée à l'hébergement",
    optionInPackage: "L'option a bien été ajoutée au package",
    package: "Package modifié avec succès",
    partenaire: "Partenaire modifié avec succès",
    password: "Votre mot de passe a bien été modifié",
    price: "Tarif modifié avec succès",
    room: "Chambre modifiée avec succès",
    success: "Vos modifications ont bien été enregistrées",
  },
  session: {
    connected: "Vous etes connecté",
    disconnected: "Vous etes déconnecté",
    expired: "Session expirée",
    email: "email envoyé avec succès",
  },
  success: {
    image: "Image ajoutée avec succès",
    mail: "Votre email a bien été envoyé",
    precommande: "La pré-commande a été envoyée avec succès !",
  },
};
