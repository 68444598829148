import { messages } from "../../../0.Commun/Message/Message";
import { notify } from "../../../0.Commun/ToastNotif/ToastNotif";

function Catch(err, history, partenaire) {
  if (err.response.status === 401) {
    if (partenaire) {
      history.push("/login-partenaire", window.scrollTo(0, 0));
    } else {
      history.push("/login-admin", window.scrollTo(0, 0));
    }

    notify(messages.session.expired, "info", 2);
    localStorage.removeItem("TOKEN");
  } else {
    notify(err.response.data.errorMessage, "error", 2);
  }
}

export default Catch;
