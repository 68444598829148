import React from "react";
import propTypes from "prop-types";
import "./AddButton.css";

function AddButton({ title, titleClass, handleClick, marginValue }) {
  const style = {
    margin: marginValue,
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className="addButton-button"
      style={style}
    >
      <p className={titleClass}>{title}</p>
    </button>
  );
}

AddButton.propTypes = {
  title: propTypes.string.isRequired,
  titleClass: propTypes.oneOf(["large", "small"]),
  handleClick: propTypes.func.isRequired,
  marginValue: propTypes.string,
};

AddButton.defaultProps = {
  titleClass: "large",
  marginValue: "",
};

export default AddButton;
