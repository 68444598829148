import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Catch from "../../../../../../../../../0.Commun/Catch/Catch";
import "./HandleRoomInfos.scss";
import {
  checkName,
  checkNumbers,
} from "../../../../../../../../../../0.Commun/Regex/Regex";
import { messages } from "../../../../../../../../../../0.Commun/Message/Message";
import { notify } from "../../../../../../../../../../0.Commun/ToastNotif/ToastNotif";
import Quill from "../../../../../../../../../../0.Commun/Quill/Quill";
import Input from "../../../../../../../../../../0.Commun/Input/Input";
import Button from "../../../../../../../../../../0.Commun/Button/Button";

const API_URL = process.env.REACT_APP_API_URL;

function HandleRoomInfos({ idRoom }) {
  const [room, setRoom] = useState({});
  const [roomDescription, setRoomDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const getRoom = () => {
    axios
      .get(`${API_URL}/api/chambre/getById/${idRoom}`)
      .then((res) => res.data)
      .then((data) => {
        setRoom(data);
        setRoomDescription(data.description_chambre);
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  useEffect(() => {
    getRoom();
  }, []);

  const handleText = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setRoom({ ...room, [e.target.name]: e.target.value });
    }
  };

  const handleNumber = (e) => {
    const resultRegex = checkNumbers(e.target.value);
    if (!resultRegex) {
      notify(messages.error.onlyNumbers, "error");
    } else {
      setRoom({ ...room, [e.target.name]: e.target.value });
    }
  };

  const submitRoom = () => {
    const token = localStorage.getItem("TOKEN");
    if (!room.nom_chambre || !room.nb_personnes_chambre || !roomDescription) {
      notify(messages.missing.inputEmpty, "error");
    } else {
      axios
        .put(
          `${API_URL}/api/chambre/${idRoom}`,
          {
            nom_chambre: room.nom_chambre,
            description_chambre: roomDescription,
            nb_personnes_chambre: room.nb_personnes_chambre,
            hebergement_id: room.hebergement_id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          notify(messages.modify.room, "success");
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  return (
    <section className="HandleRoomInfos">
      {isLoading && (
        <form className="formAddRoom">
          <Input
            name="nom_chambre"
            placeholder="Nom chambre"
            value={room.nom_chambre}
            handleChange={(e) => handleText(e)}
            marginValue="30px"
          />
          <Input
            name="nb_personnes_chambre"
            placeholder="Nb personnes"
            value={room.nb_personnes_chambre.toString()}
            handleChange={(e) => handleNumber(e)}
          />
          <Quill text={roomDescription} setText={setRoomDescription} />
          <Button text="Modifier" handleClick={submitRoom} />
        </form>
      )}
    </section>
  );
}

HandleRoomInfos.propTypes = {
  idRoom: propTypes.number.isRequired,
};

export default HandleRoomInfos;
