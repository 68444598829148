import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import Helmet from "react-helmet";
import Catch from "../../3.Admin/0.Commun/Catch/Catch";
import logo from "../../../Assets/logoPartirauvertFONDtransparent.png";
import { notify } from "../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../0.Commun/Message/Message";
import Button from "../../0.Commun/Button/Button";
import Input from "../../0.Commun/Input/Input";
import { checkEmail } from "../../0.Commun/Regex/Regex";
import "./LogPartenaire.scss";

const API_URL = process.env.REACT_APP_API_URL;

function LogPartenaire() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const handleEmail = (e) => {
    const resultRegex = checkEmail(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setEmail(e.target.value);
    }
  };

  const handleSubmit = () => {
    if (!email || !password) {
      notify(messages.missing.emailPassword, "warn", 2);
    } else {
      axios
        .post(`${API_URL}/api/partenaires/login`, {
          email,
          password_partenaire: password,
        })
        .then((res) => res.data)
        .then((data) => {
          localStorage.setItem("TOKEN", data.token);
          localStorage.setItem("userId", data.idPartenaire);

          history.push("/partenaire/calendrier");
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  return (
    <section className="logPartnerSection">
      <Helmet>
        <title>Partir au Vert - Login Partenaire</title>
      </Helmet>
      <form className="logPartnerForm" onSubmit={handleSubmit}>
        <img className="logPartnerLogo" src={logo} alt="logo partir au vert" />
        <Input
          name="email"
          placeholder="Email"
          value={email}
          handleChange={(e) => handleEmail(e)}
          placeholderDisplay={false}
          type="email"
        />
        <div className="logPartnerPword">
          <Input
            name="password"
            placeholder="Mot de passe"
            value={password}
            handleChange={(e) => setPassword(e.target.value)}
            placeholderDisplay={false}
            type="password"
            marginValue="unset"
          />
          <Link to="/forgot-password" className="logPartnerForgotPword">
            Mot de passe oublié
          </Link>
        </div>
        <Button text="Connexion" handleClick={handleSubmit} variant="variant" />
        <Link to="/">Retour au site</Link>
      </form>
    </section>
  );
}

export default LogPartenaire;
