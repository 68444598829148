import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../Accueil.scss";
import { useDispatch } from "react-redux";
import { chargementParcs, selectedParc } from "../../../5.Redux/Action";

const API_URL = process.env.REACT_APP_API_URL;

function MapParcs() {
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [parcs, setParcs] = useState({});
  const [images, setImages] = useState({});
  const [newParcs, setNewParcs] = useState({});
  const [isLoadingMatch, setIsLoadingMatch] = useState(false);
  const dispatch = useDispatch();

  const getRandom = (parcsWithImages) => {
    const parcsWithRandomImage = parcsWithImages;
    for (let k = 0; k < parcsWithRandomImage.length; k += 1) {
      const max = parcsWithRandomImage[k].imageParc.length;
      const random = Math.floor(Math.random() * Math.floor(max));
      parcsWithRandomImage[k].imageToDisplay = random;
    }
    setNewParcs(parcsWithRandomImage);
    setIsLoadingMatch(true);
  };

  const matchImage = () => {
    const parcsWithImages = [];
    for (let i = 0; i < parcs.length; i += 1) {
      for (let j = 0; j < images.length; j += 1) {
        if (parcs[i].id_parc === images[j].parc_id) {
          const idParc = parcs[i].id_parc;
          const parcExist = parcsWithImages.find(
            (element) => element.idParc === idParc
          );
          const parcIndex = parcsWithImages.findIndex(
            (element) => element.idParc === idParc
          );
          if (!parcExist || parcExist === undefined) {
            parcsWithImages.push({
              idParc: parcs[i].id_parc,
              nomParc: parcs[i].nom_parc,
              descriptionParc: parcs[i].description_parc,
              imageToDisplay: 0,
              imageParc: [
                {
                  nomImage: images[j].nom_image,
                  alt: images[j].description_image,
                },
              ],
            });
          } else {
            parcsWithImages[parcIndex].imageParc.push({
              nomImage: images[j].nom_image,
              alt: images[j].description_image,
            });
          }
        }
      }
    }
    getRandom(parcsWithImages);
  };

  const getImages = () => {
    axios
      .get(`${API_URL}/api/images/getByTheme?parc=all`)
      .then((res) => res.data)
      .then((data) => setImages(data))
      .then(() => setIsLoadingImages(true));
  };

  const getParcs = () => {
    axios
      .get(`${API_URL}/api/parc/getAll`)
      .then((res) => res.data)
      .then((data) => {
        setParcs(data);
        getImages();
        dispatch(chargementParcs(data));
      });
  };

  useEffect(() => {
    getParcs();
    if (isLoadingImages) {
      matchImage();
    }
  }, [isLoadingImages]);

  const handleClick = (idParc) => {
    dispatch(selectedParc(idParc));
  };

  return (
    <>
      {isLoadingMatch && (
        <div className="containerDesParcs">
          {newParcs.map((parc) => (
            <div key={parc.idParc} className="containerDuParc">
              <Link
                className="linkToParc"
                to={`/parc/${parc.idParc}/${parc.nomParc.replace(/\s/g, "_")}`}
                onClick={() => handleClick(parc.idParc)}
              >
                <div className="imgParcContainer">
                  <img
                    className="imgDoParc"
                    src={`${API_URL}/images/${
                      parc.imageParc[parc.imageToDisplay].nomImage
                    }`}
                    alt={parc.imageParc[parc.imageToDisplay].alt}
                  />
                </div>
              </Link>
              <div className="zoneTextParc">
                <h3 className="h3Body">{parc.nomParc}</h3>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default MapParcs;
