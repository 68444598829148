import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { messagePopup, openPopup, lactivite } from "../../../../5.Redux/Action";
import ModaleActivite from "../ModaleActivite/ModaleActivite";
import "./TabActivites.scss";
import Button from "../../../../0.Commun/Button/Button";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../../../0.Commun/Message/Message";

const API_URL = process.env.REACT_APP_API_URL;

const TabActivites = ({ activiteId, setActiviteId, setIsDisabled }) => {
  const [isLoadingActivites, setIsLoadingActivites] = useState(false);
  const [openDiscover, setOpenDiscover] = useState(false);
  const [groupActivites, setGroupActivites] = useState({});
  const [activityInitialState, setActivityInitialState] = useState({});
  const [images, setImages] = useState({});
  const dispatch = useDispatch();
  const filterRedux = useSelector((state) => state.tasks.filter);
  const activiteFinal = useSelector((state) => state.tasks.lactivite);
  const [noActivity, setNoActivity] = useState(messages.info.waiting);

  const attachImagesToActivities = (data) => {
    const activitiesWithImages = [];
    data.forEach((item) => {
      let priceToDisplay = {};
      if (!item.tarif_activite_1pax) {
        priceToDisplay = {
          tarif: item.tarif_groupe,
          suffixe: "",
        };
      } else {
        priceToDisplay = {
          tarif: item.tarif_activite_1pax,
          suffixe: "/ personne",
        };
      }
      const activityExists = activitiesWithImages.find(
        (element) => element.id_activite === item.id_activite
      );
      const activityIndex = activitiesWithImages.findIndex(
        (element) => element.id_activite === item.id_activite
      );
      if (!activityExists) {
        activitiesWithImages.push({
          id_activite: item.id_activite,
          nom_activite: item.nom_activite,
          description_activite: item.description_activite,
          imageToDisplay: 0,
          image_activite: [
            {
              nom_image: item.nom_image,
              alt: item.description_image,
            },
          ],
          price: priceToDisplay,
        });
      } else {
        activitiesWithImages[activityIndex].image_activite.push({
          nom_image: item.nom_image,
          alt: item.description_image,
        });
      }
    });
    return activitiesWithImages;
  };

  const getActivitiesImages = () => {
    axios
      .get(`${API_URL}/api/activites/getByParcImg/${filterRedux.parc}`)
      .then((res) => res.data)
      .then((data) => {
        setGroupActivites(
          attachImagesToActivities(
            data.filter((item) => item.groupe_activite === 1)
          )
        );
        setImages(data);
      })
      .then(() => setIsLoadingActivites(true))
      .catch((err) => {
        dispatch(messagePopup(err.response.data.errorMessage));
        dispatch(openPopup(true));
      });
  };

  const getGroupActivitesByParc = () => {
    axios
      .get(
        `${API_URL}/api/activites/getByParc/${filterRedux.parc}?isGroup=true`
      )
      .then((res) => res.data)
      .then((data) => {
        if (!data) {
          const noActivityMessage = messages.info.noActivity;
          notify(noActivityMessage);
          setGroupActivites([]);
          setIsDisabled(false);
          setNoActivity(noActivityMessage);
        } else {
          setGroupActivites(data);
          getActivitiesImages();
          // setIsLoadingActivites(true);
        }
      })
      .catch((err) => {
        setIsDisabled(false);
        notify("error", err.response.data.errorMessage);
      });
  };

  useEffect(() => {
    getGroupActivitesByParc();
  }, [filterRedux]);

  const putActivity = (idActivityCart, name, final) => {
    const newActivite = { idActivityCart, name };
    const activitySelected = [...final];
    const exist = final.find((item) => item.name === name);
    const activityIndex = activitySelected.findIndex(
      (item) => item.name === name
    );
    if (exist) {
      activitySelected.splice(activityIndex, 1);
      dispatch(lactivite(activitySelected));
    } else {
      const newPanier = [...final, newActivite];
      dispatch(lactivite(newPanier));
      setOpenDiscover(false);
    }
  };

  const handleClick = (idActivite) => {
    setActiviteId(parseInt(idActivite, 10));
    setActivityInitialState(
      groupActivites.find((item) => item.id_activite === idActivite)
    );
    setOpenDiscover(true);
  };

  return (
    <>
      {openDiscover ? (
        <ModaleActivite
          activiteId={activiteId}
          openDiscover={openDiscover}
          setOpenDiscover={setOpenDiscover}
          setActivityInitialState={setActivityInitialState}
          putActivity={putActivity}
          activityInitialState={activityInitialState}
        />
      ) : null}
      <div className="lesActivitesGroupe">
        {isLoadingActivites && images ? (
          groupActivites.map((activite) => (
            <div key={activite.id_activite} className="UneActivite">
              <div className="zoneImageActivite">
                {images && (
                  <img
                    className="testImage2"
                    src={`${API_URL}/images/${activite.image_activite[0].nom_image}`}
                    alt={activite.description_image}
                  />
                )}
              </div>
              <div className="zoneText1Activite">
                <h3 className="h3Body titreDeChaqueActivite">
                  {activite.nom_activite}
                </h3>
                <div className="infos-cart-container infos-activites">
                  {activiteFinal.find(
                    (item) => item.idActivityCart === activite.id_activite
                  ) ? (
                    <Button
                      text="Retirer"
                      handleClick={() =>
                        putActivity(
                          activite.id_activite,
                          activite.nom_activite,
                          activiteFinal
                        )
                      }
                    />
                  ) : (
                    <Button
                      text="Choisir"
                      handleClick={() =>
                        putActivity(
                          activite.id_activite,
                          activite.nom_activite,
                          activiteFinal
                        )
                      }
                    />
                  )}
                </div>
              </div>
              <div className="containerPrice">
                <p className="priceP">A partir de</p>
                <p className="priceP">{activite.price.tarif}€</p>
                <p className="priceP">{activite.price.suffixe}</p>
                <Button
                  text="Découvrir"
                  handleClick={() => handleClick(activite.id_activite)}
                  variant="variant"
                />
              </div>
            </div>
          ))
        ) : (
          <p>{noActivity}</p>
        )}
      </div>
    </>
  );
};

TabActivites.propTypes = {
  activiteId: PropTypes.number.isRequired,
  setActiviteId: PropTypes.func.isRequired,
  setIsDisabled: PropTypes.func.isRequired,
};

export default TabActivites;
