import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { axiosPartner } from "../../0.Commun/Axios/Axios";
import Input from "../../0.Commun/Input/Input";
import { messages } from "../../0.Commun/Message/Message";
import { notify } from "../../0.Commun/ToastNotif/ToastNotif";
import NavBarPartenaire from "../0.Commun/Navbar/NavBarPartenaire";
import Button from "../../0.Commun/Button/Button";
import "./MonCompte.scss";

function MonCompte() {
  const [newPword, setNewPword] = useState("");
  const [confirmPword, setConfirmPword] = useState("");

  const updatePword = () => {
    const idPartner = localStorage.getItem("userId");
    if (newPword === confirmPword) {
      axiosPartner({
        method: "PUT",
        url: `partenaires/modifyPassword/${idPartner}`,
        body: { newPassword: newPword },
      }).then(() => {
        notify(messages.modify.password, "success");
        setNewPword("");
        setConfirmPword("");
      });
    } else {
      notify(messages.error.passwordVerificationWrong, "error");
    }
  };

  return (
    <section className="myAccountPartner">
      <NavBarPartenaire />
      <Helmet>
        <title>Mon compte</title>
      </Helmet>
      <div className="containerAccountPartner">
        <h1 className="h1Admin">Mon compte</h1>
        <div className="containerformAccountPartner">
          <form className="formAccountPartner">
            <Input
              type="password"
              name="newPword"
              value={newPword}
              handleChange={(e) => setNewPword(e.target.value)}
              placeholder="Nouveau mot de passe"
            />
            <Input
              type="password"
              name="confirmPword"
              value={confirmPword}
              handleChange={(e) => setConfirmPword(e.target.value)}
              placeholder="Confirmation mot de passe"
            />
            <Button text="Modifier" handleClick={updatePword} />
          </form>
        </div>
      </div>
    </section>
  );
}

export default MonCompte;
