import React, { useState } from "react";
import propTypes from "prop-types";
import "./AddRoom.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import {
  checkName,
  checkNumbers,
} from "../../../../../../../../0.Commun/Regex/Regex";
import { notify } from "../../../../../../../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../../../../../../../0.Commun/Message/Message";
import Quill from "../../../../../../../../0.Commun/Quill/Quill";
import Catch from "../../../../../../../0.Commun/Catch/Catch";
import AddButton from "../../../../../../../../0.Commun/AddButton/AddButton";
import PopupContainer from "../../../../../../../../0.Commun/PopupContainer/PopupContainer";
import Input from "../../../../../../../../0.Commun/Input/Input";
import InputImage from "../../../../../../../../0.Commun/InputImage/InputImage";
import Button from "../../../../../../../../0.Commun/Button/Button";

const API_URL = process.env.REACT_APP_API_URL;

function AddRoom({ idAccomodation, closeModal, setCloseModal }) {
  const [newRoom, setNewRoom] = useState({
    name: "",
    nbGuest: "",
  });
  const [roomDescription, setRoomDescription] = useState("");
  const [newImg, setNewImg] = useState([]);
  const history = useHistory();

  const openAddModal = () => {
    setCloseModal(true);
  };

  const handleText = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setNewRoom({ ...newRoom, [e.target.name]: e.target.value });
    }
  };

  const handleNumber = (e) => {
    const resultRegex = checkNumbers(e.target.value);
    if (!resultRegex) {
      notify(messages.error.onlyNumbers, "error");
    } else {
      setNewRoom({ ...newRoom, [e.target.name]: e.target.value });
    }
  };

  const postImage = (roomId) => {
    const token = localStorage.getItem("TOKEN");
    for (let i = 0; i < newImg.length; i += 1) {
      const newImage = new FormData();
      newImage.append("file", newImg[i]);
      newImage.append("description_image", newImg.name);
      newImage.append("chambre_id", roomId);
      axios
        .post(`${API_URL}/api/images/new`, newImage, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  const submitRoom = () => {
    const token = localStorage.getItem("TOKEN");
    if (!newRoom.name || !newRoom.nbGuest || !roomDescription) {
      notify(messages.missing.inputEmpty, "error");
    } else {
      axios
        .post(
          `${API_URL}/api/chambre`,
          {
            nom_chambre: newRoom.name,
            description_chambre: roomDescription,
            nb_personnes_chambre: newRoom.nbGuest,
            hebergement_id: idAccomodation,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data)
        .then((data) => {
          postImage(data.id_chambre);
        })
        .then(() => {
          setNewRoom({
            name: "",
            nbGuest: "",
          });
          setRoomDescription("");
          setNewImg([]);
          setCloseModal(false);
          notify(messages.create.room, "success");
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  return (
    <section className="AddRoomAccomodation">
      <div className="containerBtnAddRoom">
        <AddButton
          title="Ajouter une chambre +"
          titleClass="small"
          handleClick={openAddModal}
        />
      </div>
      {closeModal && (
        <PopupContainer setClose={setCloseModal}>
          <h2 className="h2Admin">Ajouter une chambre</h2>
          <form className="formAddRoom">
            <Input
              name="name"
              placeholder="Nom chambre"
              value={newRoom.name}
              handleChange={(e) => handleText(e)}
            />
            <Input
              name="nbGuest"
              placeholder="Nb personnes"
              value={newRoom.nbGuest}
              handleChange={(e) => handleNumber(e)}
            />
            <Quill text={roomDescription} setText={setRoomDescription} />
            <InputImage newImg={newImg} setNewImg={setNewImg} multiple />
            <Button
              text="Ajouter"
              handleClick={submitRoom}
              marginValue="30px"
            />
          </form>
        </PopupContainer>
      )}
    </section>
  );
}

AddRoom.propTypes = {
  idAccomodation: propTypes.number.isRequired,
  closeModal: propTypes.bool.isRequired,
  setCloseModal: propTypes.func.isRequired,
};

export default AddRoom;
