import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import NavBar from "../0.Commun/NavBar/NavBar";
import verifyToken from "../0.Commun/VerifyToken/VerifyToken";
import "./Parc.scss";
import "react-toastify/dist/ReactToastify.css";
import AddButton from "../../0.Commun/AddButton/AddButton";
import AddParc from "./components/AddParc/AddParc";
import ListParc from "./components/ListParc/ListParc";
import ModifyParc from "./components/ModifyParc/ModifyParc";

function Parc() {
  const [openAdd, setOpenAdd] = useState(false);
  const [openModify, setOpenModify] = useState(false);
  const [parcToModify, setParcToModify] = useState(0);
  const history = useHistory();

  useEffect(() => {
    verifyToken(history);
  }, []);

  const openAddModal = () => {
    setOpenAdd(!openAdd);
  };

  const openModifyParc = (id) => {
    setParcToModify(id);
    setOpenModify(!openModify);
  };

  return (
    <section>
      <Helmet>
        <title>Admin - Destinations</title>
      </Helmet>
      <NavBar />
      <div className="parcAdminGlobal">
        <h1 className="h1Admin">Destinations</h1>
        <AddButton
          title="Ajouter une destination &#43;"
          titleClass="large"
          handleClick={openAddModal}
        />
        <AddParc openModal={openAdd} setOpenModal={setOpenAdd} />
        <ListParc
          openAdd={openAdd}
          openModify={openModify}
          openModifyParc={openModifyParc}
        />
        <ModifyParc
          openModify={openModify}
          setOpenModify={setOpenModify}
          parcToModify={parcToModify}
        />
      </div>
    </section>
  );
}

export default Parc;
