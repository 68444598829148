import React from "react";
import PropTypes from "prop-types";
import "./Input.css";

function Input({
  name,
  value,
  handleChange,
  placeholder,
  placeholderDisplay,
  widthvalue,
  heightValue,
  fontSizeValue,
  borderColor,
  type,
  dateMin,
  marginValue,
  disabled,
}) {
  const defineBorderColor = () => {
    if (borderColor === "greenLight") {
      return "#bfcfcb";
    }
    return "#587e61";
  };

  const labelStyle = {
    width: widthvalue,
    height: heightValue,
    margin: marginValue,
  };

  const inputStyle = {
    fontSize: fontSizeValue,
    border: `solid 1px ${defineBorderColor()}`,
  };

  return (
    <label htmlFor={name} className="input-label" style={labelStyle}>
      {placeholderDisplay && (
        <p
          className={
            value || type === "date"
              ? "input-placeholderDisplay"
              : "input-placeholderHidden"
          }
        >
          {placeholder}
        </p>
      )}
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className="input-input"
        style={inputStyle}
        min={dateMin}
        disabled={disabled}
      />
    </label>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  placeholderDisplay: PropTypes.bool,
  widthvalue: PropTypes.string,
  heightValue: PropTypes.string,
  fontSizeValue: PropTypes.string,
  borderColor: PropTypes.oneOf(["green", "greenLight"]),
  type: PropTypes.oneOf(["text", "password", "email", "date"]),
  dateMin: PropTypes.string,
  marginValue: PropTypes.string,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  placeholder: "",
  placeholderDisplay: true,
  widthvalue: "400px",
  heightValue: "40px",
  fontSizeValue: "inherit",
  borderColor: "green",
  type: "text",
  dateMin: "",
  marginValue: "",
  handleChange: () => {},
  disabled: false,
};

export default Input;
