import React, { useEffect, useState } from "react";
import "./AffichageVille.scss";
import PropTypes from "prop-types";
import axios from "axios";

const AffichageVille = ({ setCityIsVisible, setCity, cp, setCityCode }) => {
  const [cities, setCities] = useState([]);

  const getCities = () => {
    axios
      .get(
        `https://api-adresse.data.gouv.fr/search/?q=${cp}&type=municipality&autocomplete=1&limit=100`
      )
      .then((response) => {
        setCities(response.data.features);
      });
  };

  useEffect(() => {
    getCities();
  }, []);

  const handleClick = (cityInfo) => {
    setCity(cityInfo.name);
    setCityCode(cityInfo.citycode);
    setCityIsVisible(false);
  };

  return (
    <div className="containerListCities">
      {cities
        ?.filter((city) => city.properties.name.toLowerCase())
        ?.sort((a, b) => {
          if (
            a.properties.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
            b.properties.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ) {
            return -1;
          }
          return 1;
        })
        ?.map((city) => (
          <div className="listCities" key={city.properties.id}>
            <option onClick={() => handleClick(city.properties)}>
              {city.properties.name}
            </option>
          </div>
        ))}
    </div>
  );
};

AffichageVille.propTypes = {
  setCityIsVisible: PropTypes.func.isRequired,
  setCity: PropTypes.func.isRequired,
  cp: PropTypes.string.isRequired,
  setCityCode: PropTypes.func.isRequired,
};

export default AffichageVille;
