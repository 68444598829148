import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import PopupContainer from "../../../../0.Commun/PopupContainer/PopupContainer";
import "./ModifyPartenaire.scss";
import { axiosPrivate } from "../../../../0.Commun/Axios/Axios";
import Input from "../../../../0.Commun/Input/Input";
import { checkEmail, verifEmail } from "../../../../0.Commun/Regex/Regex";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../../../0.Commun/Message/Message";
import Button from "../../../../0.Commun/Button/Button";
import Select from "../../../../0.Commun/Select/Select";
import Catch from "../../../0.Commun/Catch/Catch";

function ModifyPartenaire({ idPartner, setClose }) {
  const [partner, setPartner] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [parcs, setParcs] = useState([]);
  const [accomodations, setAccomodations] = useState([]);

  const getPartner = () => {
    axiosPrivate({ method: "GET", url: `partenaires/${idPartner}` }).then(
      (data) => {
        setPartner(data);
      }
    );
  };

  const getParcs = () => {
    axiosPrivate({ method: "GET", url: "parc/getAll" }).then((data) => {
      setParcs(data);
    });
  };

  useEffect(() => {
    getPartner();
    getParcs();
  }, []);

  useEffect(() => {
    if (partner && parcs.length !== 0) {
      setIsLoading(true);
    }
  }, [partner, parcs]);

  const handleEmail = (e) => {
    const resultRegex = checkEmail(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setPartner({ ...partner, [e.target.name]: e.target.value });
    }
  };

  const changeEmail = () => {
    const resultRegex = verifEmail(partner.email_partenaire);
    if (!resultRegex) {
      notify(messages.error.email, "error");
    } else {
      axiosPrivate({
        method: "PUT",
        url: `partenaires/mail/${idPartner}`,
        body: partner,
      }).then((data) => {
        if (data) {
          notify(messages.modify.partenaire, "success");
        }
      });
    }
  };

  const handleAccomodation = (datas) => {
    const newAccomodation = [];
    datas.forEach((data) => {
      newAccomodation.push({
        id: data.id_hebergement,
        nom: data.nom_hebergement,
        checked: data.partenaire_id !== null && true,
      });
    });
    setAccomodations(newAccomodation);
  };

  const getAccomodation = (idParc) => {
    axiosPrivate({
      method: "GET",
      url: `hebergements/hebergementdispo/${idPartner}/${idParc}`,
    }).then((data) => {
      handleAccomodation(data);
    });
  };

  const updateAccomodationDB = (idAccomodation, isDelete) => {
    let partnerId;
    if (isDelete) {
      partnerId = null;
    } else {
      partnerId = idPartner;
    }
    try {
      axiosPrivate({
        method: "PUT",
        url: `hebergements/addPid/${idAccomodation}`,
        body: { partenaire_id: partnerId },
      });
    } catch (err) {
      Catch(err);
    }
  };

  const updateAccomodation = (idCheck) => {
    const allAccomodation = [...accomodations];
    const searchIndex = allAccomodation.findIndex(
      (item) => item.id === idCheck
    );
    if (!allAccomodation[searchIndex].checked) {
      updateAccomodationDB(idCheck);
      allAccomodation[searchIndex].checked = true;
    } else {
      updateAccomodationDB(idCheck, true);
      allAccomodation[searchIndex].checked = false;
    }
    setAccomodations(allAccomodation);
  };

  return (
    <PopupContainer setClose={setClose}>
      {isLoading && (
        <div className="containerModifyPartner">
          <h2 className="h2Admin">Modifier un partenaire</h2>
          <Input
            name="email_partenaire"
            value={partner.email_partenaire}
            placeholder="Email partenaire"
            handleChange={(e) => handleEmail(e)}
            marginValue="30px 0"
          />
          <Button text="Modifier" handleClick={changeEmail} />
          <Select
            datas={parcs}
            optionValue="nom_parc"
            onChange={(e) => getAccomodation(e.target.value)}
            introValue="Selectionner une destination"
            marginValue="30px 0"
          />
          <div className="listAccomodation">
            {accomodations?.map((accomodation) => (
              <div key={accomodation.id} className="oneAccomodation">
                <input
                  type="checkbox"
                  name="accomodation"
                  checked={accomodation.checked}
                  onChange={() => {
                    updateAccomodation(accomodation.id);
                  }}
                />
                {accomodation.nom}
              </div>
            ))}
          </div>
        </div>
      )}
    </PopupContainer>
  );
}

ModifyPartenaire.propTypes = {
  idPartner: propTypes.number.isRequired,
  setClose: propTypes.func.isRequired,
};

export default ModifyPartenaire;
