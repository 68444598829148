import axios from "axios";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./InfoCommande.scss";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../../../0.Commun/Message/Message";
import PopupContainer from "../../../../0.Commun/PopupContainer/PopupContainer";

const API_URL = process.env.REACT_APP_API_URL;

function InfoCommande({ setOpenModalInfo, cmdId }) {
  const [infoCommandes, setInfoCommandes] = useState({});
  const [notesAdmin, setNotesAdmin] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getInfos = () => {
    const token = localStorage.getItem("TOKEN");
    axios
      .get(`${API_URL}/api/commande/getAllInfo/${cmdId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setInfoCommandes(data);
        setNotesAdmin(data.note_admin);
        setIsLoading(true);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage);
      });
  };

  useEffect(() => {
    getInfos();
  }, [cmdId]);

  const commandeTraite = (idCommande) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .put(
        `${API_URL}/api/commande/changeTraite/${idCommande}`,
        {
          traite_commande: !infoCommandes.traite_commande,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .catch((err) => {
        notify(err.response.data.errorMessage);
      });
  };

  const saveNotes = (event, id) => {
    event.preventDefault();
    const token = localStorage.getItem("TOKEN");
    axios
      .put(
        `${API_URL}/api/commande/changeNotes/${id}`,
        {
          note_admin: notesAdmin,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data)
      .then(() => {
        notify(messages.modify.notes, "success");
      })
      .catch((err) => {
        notify(err.response.data.errorMessage);
        getInfos();
      });
  };

  return (
    <PopupContainer setClose={setOpenModalInfo}>
      {isLoading && (
        <div>
          <h2 className="h2Admin titleInfoCommande">{`Information sur la commande n°${infoCommandes.id_commande} au nom de ${infoCommandes.nom_client}`}</h2>
          <div>
            <div className="isPackage">
              {infoCommandes.package === 1 ? "Package" : ""}
              {infoCommandes.groupe_commande === 1 ? "Groupe" : ""}
            </div>
            <div className="commandeAdminDate">
              <form>
                <label
                  htmlFor="traiteCommande"
                  className="commandeAdminTraiteInfo"
                >
                  <p>Commande traitée :</p>
                  <input
                    type="checkbox"
                    id="traiteCommande"
                    defaultChecked={infoCommandes.traite_commande}
                    onChange={() => commandeTraite(infoCommandes.id_commande)}
                  />
                </label>
              </form>
              <p>
                Date commande :{" "}
                {new Date(infoCommandes.date_commande).toLocaleDateString()}
              </p>
            </div>
            <div className="commandeAdminInfoNom">
              <p>Nom :{infoCommandes.nom_client}</p>
              <p>Prénom :{infoCommandes.prenom_client}</p>
            </div>
          </div>
          <h3 className="h3Admin titleH3InfoCommande">Coordonnées</h3>
          <div>
            <div className="commandeAdminMail">
              <p>Email :{infoCommandes.email_client}</p>
              <p>Téléphone :{infoCommandes.telephone_client} </p>
              {infoCommandes.societe_client ? (
                <p>Société :{infoCommandes.societe_client}</p>
              ) : (
                ""
              )}
            </div>
            <div className="commandeAdminAdresseGlobale">
              <p>{infoCommandes.adresse_client}</p>
              <div className="commandeAdminAdresseVille">
                <p>{infoCommandes.code_postal_client}</p>
                <p>{infoCommandes.ville_client} </p>
              </div>
            </div>
          </div>
          <h3 className="h3Admin titleH3InfoCommande">Messages</h3>
          <div>
            <p className="commandeAdminInfoMessage">
              Message client : {infoCommandes.message_client}{" "}
            </p>
            <form>
              <label
                className="commandeAdminInfoContainerNotes"
                htmlFor="noteAdmin"
              >
                <textarea
                  className="commandeAdminInfoNotes"
                  value={!notesAdmin ? "" : notesAdmin}
                  onChange={(event) => {
                    setNotesAdmin(event.target.value);
                  }}
                  type="text"
                  id="noteAdmin"
                  placeholder={
                    !infoCommandes.note_admin
                      ? "Ajouter une note "
                      : infoCommandes.note_admin
                  }
                />
              </label>
              <div className="commandeAdminInfoContainerBtnSave">
                <button
                  type="button"
                  className="commandeAdminInfoSaveBtn"
                  onClick={(event) =>
                    saveNotes(event, infoCommandes.id_commande)
                  }
                >
                  &#128190;
                </button>
              </div>
            </form>
          </div>
          <h3 className="h3Admin titleH3InfoCommande">Détails commande</h3>
          <div>
            <div className="commandeAdminInfoNumber">
              <p>{`Nombre de personnes : ${infoCommandes.nb_personnes_commande}`}</p>
            </div>
            {infoCommandes.package_name !== null && (
              <div className="commandeAdminInfoNumber">
                <p>{infoCommandes.package_name}</p>
              </div>
            )}
            <div className="commandeAdminInfoNameAccomodation">
              <p>{`Hébergement : ${infoCommandes.nom_hebergement_commande}`}</p>
            </div>
            <div className="commandeAdminInfoAccomodationType">
              <p>{`Destination : ${infoCommandes.parc}`}</p>
              {infoCommandes.type_hebergement_commande && (
                <p>
                  {`Type d'hébergement : ${infoCommandes.type_hebergement_commande}`}
                </p>
              )}
              {infoCommandes.chambre && (
                <p>Chambre :{infoCommandes.chambre} </p>
              )}
            </div>
            {infoCommandes.activite && (
              <div className="commandeAdminInfoActivities">
                <p>
                  Activités : {infoCommandes.activite.split(",").join(", ")}
                </p>
              </div>
            )}
            {infoCommandes.optionCo && (
              <div className="commandeAdminInfoActivities">
                <p>Options : {infoCommandes.optionCo.split(",").join(", ")}</p>
              </div>
            )}
            <div className="commandeAdminInfoDateStay">
              <p>
                Du {new Date(infoCommandes.date_entree).toLocaleDateString()} au{" "}
                {new Date(infoCommandes.date_sortie).toLocaleDateString()}{" "}
              </p>
            </div>
          </div>
          <h3 className="h3Admin titleH3InfoCommande">Prix d&#39;appel</h3>
          <p className="commandeAdminInfoPrice">
            {infoCommandes.tarif_final_admin} €{" "}
          </p>
        </div>
      )}
    </PopupContainer>
  );
}

InfoCommande.propTypes = {
  setOpenModalInfo: PropTypes.func.isRequired,
  cmdId: PropTypes.number.isRequired,
};

export default InfoCommande;
