import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactHtmlParser from "html-react-parser";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  openPopup,
  messagePopup,
  filteredAccomodation,
  selectedParc,
} from "../../5.Redux/Action";
import HeaderConcept from "../1.Accueil/HeaderConcept/HeaderConcept";
import TabHebergements from "./components/TabHebergements/TabHebergements";
import TabActivites from "./components/TabActivites/TabActivites";
import Navbar from "../0.Commun/Navbar/Navbar";
import Footer from "../0.Commun/Footer/Footer";
import InputsGroupe from "./components/InputsGroupe/InputsGroupe";
import ReservationForm from "./components/ReservationForm/ReservationForm";
import "./Groupe.scss";

const API_URL = process.env.REACT_APP_API_URL;

const Groupe = () => {
  const [descriptionGroupe, setDescriptionGroupe] = useState("");
  const [groupSizeInput, setGroupSizeInput] = useState("Taille du groupe");
  const [parcs, setParcs] = useState({});
  const [parcName, setParcName] = useState("Destination");
  const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [isLoadingSearch, setIsLoadingSearch] = useState(true);
  const [accomodationId, setAccomodationId] = useState(0);
  const [activiteId, setActiviteId] = useState(0);
  const [activeButtonId, setActiveButtonId] = useState("lesHebergementsGroupe");
  const [groupFilter, setGroupFilter] = useState({
    parc: 0,
    taille_groupe: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();
  const parcId = useSelector((state) => state.tasks.selectedParc);
  const filter = useSelector((state) => state.tasks.filter)

  const parseHtml = (htmlData) => {
    const newData = ReactHtmlParser(htmlData);
    setDescriptionGroupe(newData);
  };

  const getConceptGroupe = () => {
    axios
      .get(`${API_URL}/api/concept/2`)
      .then((res) => res.data)
      .then((data) => {
        parseHtml(data.description_concept);
      })
      .catch((err) => {
        dispatch(messagePopup(err));
        dispatch(openPopup(true));
      });
  };
  const attachImagesToParcs = (data) => {
    const parcsWithImages = [];
    data.forEach((item) => {
      const parcExists = parcsWithImages.find(
        (element) => element.id_parc === item.id_parc
      );
      const parcIndex = parcsWithImages.findIndex(
        (element) => element.id_parc === item.id_parc
      );
      if (!parcExists || parcExists === undefined) {
        parcsWithImages.push({
          id_parc: item.id_parc,
          nom_parc: item.nom_parc,
          description_parc: item.description_parc,
          imageToDisplay: 0,
          image_parc: [
            {
              nom_image: item.nom_image,
              alt: item.description_image,
            },
          ],
        });
      } else {
        parcsWithImages[parcIndex].image_parc.push({
          nom_image: item.nom_image,
          alt: item.description_image,
        });
      }
    });
    return parcsWithImages;
  };

  const getSelectedParcName = (parcData) => {
    const parcFound = parcData.find((parc) => parc.id_parc === parcId);
    setParcName(parcFound.nom_parc);
  };

  const getParcsWithImages = () => {
    axios
      .get(`${API_URL}/api/parc/getAllWithImage`)
      .then((res) => res.data)
      .then((data) => {
        setParcs(attachImagesToParcs(data));
        if (parcId !== 0) {
          getSelectedParcName(data);
        }
      })
      .then(() => setIsLoadingImages(false));
  };

  useEffect(() => {
    getConceptGroupe();
    getParcsWithImages();
  }, [parcId]);

  const handleChangeTab = (string) => {
    setActiveButtonId(string);
  };

  const focusOnRef = () => {
    const el = document.getElementById("result");
    el.scrollIntoView()
  };

  const transferToInputs = (id, name, groupSize) => {
    if (isDisabled) {
      dispatch(
        messagePopup(
          "Ce bouton est désactivé une fois qu'une recherche a été effectuée"
        )
      );
      dispatch(openPopup(true));
    } else {
      dispatch(selectedParc(id));
      dispatch(
        filteredAccomodation({
          parc: id,
          taille_groupe: parseInt(groupSize, 10),
        })
      );
      focusOnRef();
      setIsLoadingSearch(false);
      setParcName(name);
      setGroupFilter({ parc: id, taille_groupe: groupSize });
      setGroupSizeInput("de 7 à 12 personnes");
    }
  };

  useEffect(() => {
    if (filter) {
      focusOnRef();
    }
  }, [filter]);

  return (
    <>
      <Helmet>
        <title>Partir au vert en groupe</title>
      </Helmet>
      <Navbar />
      <HeaderConcept />
      <InputsGroupe
        parcName={parcName}
        setParcName={setParcName}
        groupSizeInput={groupSizeInput}
        setGroupSizeInput={setGroupSizeInput}
        setIsLoadingSearch={setIsLoadingSearch}
        setGroupFilter={setGroupFilter}
        setActiveButtonId={setActiveButtonId}
        groupFilter={groupFilter}
      />
      <div id="conceptGroupe" className="concept-groupe">
        <div className="containerConceptGroupText">
          <div className="quillText">{descriptionGroupe}</div>
        </div>
      </div>
      <div id="result">
        {!isLoadingSearch ? (
          <div className="containerGroupGlobal">
            <div className="selection-container">
              <div className="btns-container">
                <button
                  id="groupAccomodations-btn"
                  onClick={() => handleChangeTab("lesHebergementsGroupe")}
                  type="button"
                  className={
                    activeButtonId === "lesHebergementsGroupe"
                      ? "selected"
                      : "unselected"
                  }
                >
                  <p>Choisissez</p>
                  <h2 className="h2Body">votre hébergement</h2>
                </button>

                <button
                  id="groupActivites-btn"
                  onClick={() => handleChangeTab("lesActivitesGroupe")}
                  type="button"
                  className={
                    activeButtonId === "lesActivitesGroupe"
                      ? "selected"
                      : "unselected"
                  }
                >
                  <p>Choisissez</p>
                  <h2 className="h2Body">Vos activités</h2>
                </button>
              </div>
              {activeButtonId === "lesHebergementsGroupe" ? (
                <div className="container-products">
                  <TabHebergements
                    setAccomodationId={setAccomodationId}
                    accomodationId={accomodationId}
                    setIsDisabled={setIsDisabled}
                  />
                </div>
              ) : null}

              {activeButtonId === "lesActivitesGroupe" ? (
                <div className="container-products">
                  <TabActivites
                    activiteId={activiteId}
                    setActiviteId={setActiviteId}
                    setIsDisabled={setIsDisabled}
                  />
                </div>
              ) : null}
            </div>
            <div className="toggle-mobile">
              <ReservationForm />
            </div>
          </div>
        ) : null}
      </div>

      {isLoadingSearch && (
        <div className="zoneTextTraitGroupe">
          <h2 className="h2Body titleConceptAndAccomodation">
            Nos destinations
          </h2>
          <hr className="trait" />
        </div>
      )}
      {!isLoadingImages && isLoadingSearch && (
        <div className="containerDesParcs">
          {parcs.map((parc) => (
            <button
              key={parc.id_parc}
              className={isDisabled ? "disabled" : "containerDuParc-btn"}
              type="button"
              onClick={() => transferToInputs(parc.id_parc, parc.nom_parc, "7")}
            >
              <div className="imgParcContainer">
                <img
                  className="imgDuParc"
                  src={`${API_URL}/images/${
                    parc.image_parc[parc.imageToDisplay].nom_image
                  }`}
                  alt={parc.image_parc[parc.imageToDisplay].alt}
                />
                <div className="zoneTextParc">
                  <h3 className="h3Body">{parc.nom_parc}</h3>
                </div>
              </div>
            </button>
          ))}
        </div>
      )}

      <Footer />
    </>
  );
};

export default Groupe;
