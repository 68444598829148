import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import propTypes from "prop-types";
import Catch from "../../../0.Commun/Catch/Catch";
import "./ListParc.scss";
import Button from "../../../../0.Commun/Button/Button";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../../../0.Commun/Message/Message";
import PopupConfirm from "../../../../0.Commun/PopupConfirm/PopupConfirm";
import { scrollLock } from "../../../../0.Commun/ScrollLock/ScrollLock";

const API_URL = process.env.REACT_APP_API_URL;

function ListParc({ openAdd, openModify, openModifyParc }) {
  const [parcs, setParcs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
  });
  const history = useHistory();

  const getParcs = () => {
    axios
      .get(`${API_URL}/api/parc/getAll`)
      .then((res) => {
        setParcs(res.data);
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  useEffect(() => {
    getParcs();
    scrollLock(openAdd || openModify);
  }, [openAdd, openModify]);

  const askDeleteParc = (idToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.parc,
      id: idToDelete,
    });
  };

  const deleteParc = (id) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .delete(`${API_URL}/api/parc/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then(() => {
        notify(messages.delete.success, "error");
        getParcs();
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  return (
    <section className="listParc">
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={deleteParc}
      />
      <h2 className="h2Admin">Liste des destinations</h2>
      {isLoading &&
        parcs.map((parc) => (
          <div className="containerOneParc" key={parc.id_parc}>
            <p>{parc.nom_parc}</p>
            <div>
              <Button
                text="Modifier"
                handleClick={() => openModifyParc(parc.id_parc)}
                marginValue="5px 5px 5px 0"
              />
              <Button
                text="Supprimer"
                handleClick={() => askDeleteParc(parc.id_parc)}
                marginValue="5px"
              />
            </div>
          </div>
        ))}
    </section>
  );
}

ListParc.propTypes = {
  openAdd: propTypes.bool.isRequired,
  openModify: propTypes.bool.isRequired,
  openModifyParc: propTypes.func.isRequired,
};

export default ListParc;
