import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import "./HandleOptions.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Catch from "../../../../../0.Commun/Catch/Catch";
import AddButton from "../../../../../../0.Commun/AddButton/AddButton";
import Select from "../../../../../../0.Commun/Select/Select";
import { notify } from "../../../../../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../../../../../0.Commun/Message/Message";
import PopupContainer from "../../../../../../0.Commun/PopupContainer/PopupContainer";
import DeleteButton from "../../../../../../0.Commun/DeleteButton/DeleteButton";
import PopupConfirm from "../../../../../../0.Commun/PopupConfirm/PopupConfirm";
import Button from "../../../../../../0.Commun/Button/Button";
import { axiosPrivate } from "../../../../../../0.Commun/Axios/Axios";

const API_URL = process.env.REACT_APP_API_URL;

function HandleOptions({ idAccomodation, idParc }) {
  const [allOptions, setAllOptions] = useState({});
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addOption, setAddOption] = useState(false);
  const [optionSelected, setOptionSelected] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
  });
  const history = useHistory();

  const getAllOptions = () => {
    axios
      .get(`${API_URL}/api/options/getByParc/${idParc}`)
      .then((res) => res.data)
      .then((data) => {
        setAllOptions(data);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  const getOptions = async () => {
    try {
      await axios
        .get(`${API_URL}/api/options/getByAccomodation/${idAccomodation}`)
        .then((res) => res.data)
        .then((data) => {
          if (!data) {
            setOptions([]);
            setIsLoading(false);
          } else {
            setOptions(data);
            setIsLoading(true);
          }
        });
    } catch (err) {
      Catch(err, history);
    }
  };

  useEffect(() => {
    getAllOptions();
    getOptions();
  }, []);

  const openAddOption = () => {
    setAddOption(!addOption);
  };

  const selectOption = (e) => {
    setOptionSelected(parseInt(e.target.value, 10));
  };

  const addOptionToAccomodation = () => {
    const optionExist = options.find(
      (option) => option.id_option === optionSelected
    );
    if (optionExist) {
      notify(messages.error.optionAllreadyInPackage, "error");
    } else {
      axiosPrivate({
        method: "POST",
        url: "options/linkOptionToAccomodation",
        body: {
          id_option: optionSelected,
          hebergement_id: idAccomodation,
        },
      }).then(() => {
        notify(messages.create.optionInHebergement, "success");
        setAddOption(false);
        getOptions();
      });
    }
  };

  const askDeleteOption = (optionId) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.option,
      id: optionId,
    });
  };

  const deleteOption = (optionId) => {
    const token = localStorage.getItem("TOKEN");
    axios
      .delete(
        `${API_URL}/api/options/unlinkWithAccomodation/${optionId}/${idAccomodation}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        notify(messages.delete.optionInHebergement, "success");
        getOptions();
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  return (
    <section className="handleOptionsAdmin">
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={deleteOption}
      />
      <div className="containerMenuOption">
        <AddButton
          title="Ajouter une option à cet hébergement +"
          titleClass="small"
          handleClick={openAddOption}
        />
      </div>
      {addOption && (
        <PopupContainer
          setClose={openAddOption}
          widthvalue={25}
          heightValue={20}
        >
          <div className="insidePopup">
            <h3 className="h3admin">Ajouter une option</h3>
            <Select
              datas={allOptions}
              optionValue="nom_option"
              onChange={(e) => selectOption(e)}
              marginValue="20px 0"
              introValue="Veuillez choisir une option"
            />
            <Button text="Ajouter" handleClick={addOptionToAccomodation} />
          </div>
        </PopupContainer>
      )}
      <div className="containerOptions">
        {isLoading &&
          options.map((option) => (
            <div className="optionSelected" key={option.id_option}>
              <p>{option.nom_option}</p>
              <DeleteButton onClick={() => askDeleteOption(option.id_option)} />
            </div>
          ))}
      </div>
    </section>
  );
}

HandleOptions.propTypes = {
  idAccomodation: propTypes.number.isRequired,
  idParc: propTypes.number.isRequired,
};

export default HandleOptions;
