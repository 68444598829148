import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  keywordsSelected,
  messagePopup,
  newDateBlog,
  openPopup,
} from "../../../../../5.Redux/Action";
import "./DateArticle.scss";

const API_URL = process.env.REACT_APP_API_URL;

function DateArticle() {
  const [years, setYears] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [yearSaved, setYearSaved] = useState("");
  const dispatch = useDispatch();

  const getYearsBlog = (data) => {
    const allYear = [];
    let count = 0;
    data.forEach((item) => {
      const yearToCompare = new Date(item.date).getFullYear();
      const monthToCompare = new Date(item.date).getMonth();
      const monthToLoad = new Date(item.date);
      const options = { month: "long" };
      const yearExist = allYear.find(
        (element) => element.year === yearToCompare
      );
      const yearIndex = allYear.findIndex(
        (element) => element.year === yearToCompare
      );
      if (!yearExist) {
        allYear.push({
          id: count,
          year: yearToCompare,
          monthes: [
            {
              id: monthToCompare,
              month: new Intl.DateTimeFormat("fr-FR", options).format(
                monthToLoad
              ),
            },
          ],
        });
        count += 1;
      } else {
        const monthExist = allYear[yearIndex].monthes.find(
          (element) => element.id === monthToCompare
        );
        if (!monthExist) {
          allYear[yearIndex].monthes.push({
            id: monthToCompare,
            month: new Intl.DateTimeFormat("fr-FR", options).format(
              monthToLoad
            ),
          });
        }
      }
    });
    setYears(allYear);
    setIsLoading(true);
  };

  const getAllBlog = () => {
    axios
      .get(`${API_URL}/api/blogs/getAll`)
      .then((res) => res.data)
      .then((data) => {
        getYearsBlog(data);
      })
      .catch((err) => {
        dispatch(messagePopup(err));
        dispatch(openPopup(true));
      });
  };

  useEffect(() => {
    getAllBlog();
  }, []);

  const yearSelected = (yearToCall) => {
    setYearSaved(yearToCall);
  };

  const monthSelected = (monthToCall) => {
    dispatch(newDateBlog({ yearSaved, monthToCall }));
    dispatch(keywordsSelected(""));
    document.documentElement.scrollTop = 0;
  };

  return (
    <section className="containerDate">
      <h2 className="h2Body titleBlogKeywords">Historique</h2>
      {isLoading && (
        <div className="containerYearBlog">
          {years.map((year) => (
            <div key={year.id} className="containerYearAndMonth">
              <div className="containerBtnYear">
                <button
                  type="button"
                  className="btnYear"
                  onClick={() => yearSelected(year.year)}
                >
                  {year.year}
                </button>
                <div
                  className={
                    yearSaved === year.year ? "containerMonthBlog" : "none"
                  }
                >
                  {year.monthes.map((month) => (
                    <button
                      type="button"
                      key={month.id}
                      onClick={() => monthSelected(month.id)}
                      className="btnMonth"
                    >
                      {month.month}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
}

export default DateArticle;
