import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { messagePopup, openPopup } from "../../../5.Redux/Action";
import "./Carousel.scss";

const API_URL = process.env.REACT_APP_API_URL;

function Hebergement() {
  const [images, setImages] = useState({});
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [indexSelected, setIndexSelected] = useState(0);
  const selectedParc = useSelector((state) => state.tasks.selectedParc);
  const dispatch = useDispatch;

  const getImages = () => {
    axios
      .get(`${API_URL}/api/images/getByTheme?parc=${selectedParc}`)
      .then((res) => res.data)
      .then((data) => {
        setImages(data);
        setIsLoadingImages(true);
      })
      .catch((err) => {
        dispatch(messagePopup(err));
        dispatch(openPopup(true));
      });
  };

  useEffect(() => {
    getImages();
  }, [selectedParc]);

  const increaseIndex = () => {
    if (indexSelected === images.length - 1) {
      setIndexSelected(0);
    } else {
      setIndexSelected(indexSelected + 1);
    }
  };

  const determineClass = (index) => {
    if (index === indexSelected) {
      return "activeParc";
    }
    return "unactiveParc";
  };

  useEffect(() => {
    const timer = setTimeout(increaseIndex, 30000);
    return () => clearTimeout(timer);
  }, [indexSelected]);

  return (
    <section>
      <div className="containerGlobalParc">
        {isLoadingImages && (
          <div className="containerMapParc">
            {images.map((image, index) => (
              <div className="containerImagesMapParc" key={image.id_image}>
                <img
                  className={`${determineClass(index)}`}
                  src={`${API_URL}/images/${image.nom_image}`}
                  alt={image.description_image}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default Hebergement;
