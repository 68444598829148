import React, { useState, useEffect } from "react";
import "./InfosPratiques.scss";
import axios from "axios";
import ReactHtmlParser from "html-react-parser";

const API_URL = process.env.REACT_APP_API_URL;

function InfosPratiques() {
  const [infos, setInfos] = useState({});
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    axios
      .get(`${API_URL}/api/footer/getAll`)
      .then((res) => res.data)
      .then((data) => {
        setInfos(data);
      })
      .then(() => {
        setIsloading(true);
      });
  }, []);

  return (
    <section className="sectionInfosPratiques">
      {isLoading && (
        <div className="containerInfosText">
          <div id="quiSommeNous" className="containerOneSection">
            <h2 className="h2Body titreInfosPratiques">
              {infos[0].titre_section_footer}
            </h2>
            <div className="paraphInfosPratiques">
              {ReactHtmlParser(infos[0].section_footer)}
            </div>
          </div>
          <div id="ConditionsGeneralesDeVente" className="containerOneSection">
            <h2 className="h2Body titreInfosPratiques">
              {infos[1].titre_section_footer}
            </h2>
            <div className="paraphInfosPratiques">
              {ReactHtmlParser(infos[1].section_footer)}
            </div>
          </div>
          <div id="mentionsLegales" className="containerOneSection">
            <h2 className="h2Body titreInfosPratiques">
              {infos[2].titre_section_footer}
            </h2>
            <div className="paraphInfosPratiques">
              {ReactHtmlParser(infos[2].section_footer)}
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default InfosPratiques;
