import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import "./ModifyAccomodation.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
import CloseButton from "../../../../0.Commun/CloseButton/CloseButton";
import Catch from "../../../0.Commun/Catch/Catch";
import Navbar from "./Components/Navbar/Navbar";
import HandleImages from "./Components/HandleImages/HandleImages";
import HandlePrices from "./Components/HandlePrices/HandlePrices";
import HandleInfos from "./Components/HandleInfos/HandleInfos";
import HandleOptions from "./Components/HandleOptions/HandleOptions";

const API_URL = process.env.REACT_APP_API_URL;

function ModifyAccomodation({ setCloseModal, idAccomodation }) {
  const [infos, setInfos] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [idNavbar, setIdNavbar] = useState(1);
  const history = useHistory();

  const getInfos = () => {
    axios
      .get(`${API_URL}/api/hebergements/getById/${idAccomodation}`)
      .then((res) => res.data)
      .then((data) => {
        setInfos(data);
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  useEffect(() => {
    getInfos();
  }, [idNavbar]);

  return (
    <section className="bgBlur">
      <div className="popupAdminOpen">
        <CloseButton setClose={setCloseModal} />
        <Navbar
          setIdNavbar={setIdNavbar}
          accomodationType={infos.type_hebergement_id}
        />
        {isLoading && (
          <>
            {idNavbar === 1 && <HandleInfos infos={infos} />}
            {idNavbar === 2 && <HandleImages idAccomodation={idAccomodation} />}
            {idNavbar === 3 && (
              <HandlePrices
                idAccomodation={idAccomodation}
                accomodationType={infos.type_hebergement_id}
              />
            )}
            {idNavbar === 4 && (
              <HandleOptions
                idAccomodation={idAccomodation}
                idParc={infos.parc_id}
              />
            )}
          </>
        )}
      </div>
    </section>
  );
}

ModifyAccomodation.propTypes = {
  setCloseModal: propTypes.func.isRequired,
  idAccomodation: propTypes.number.isRequired,
};

export default ModifyAccomodation;
