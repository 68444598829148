import React from "react";
import PropTypes from "prop-types";
import "./InputSearch.css";

const InputSearch = ({
  searchValue,
  setSearchValue,
  placeholder,
  widthvalue,
  marginValue,
}) => {
  const style = { width: widthvalue, display: "flex", margin: marginValue };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <input
        type="text"
        id="filtre"
        placeholder={placeholder}
        className="inputSearch-input"
        style={style}
        value={searchValue}
        onChange={(e) => handleChange(e)}
      />
    </>
  );
};

InputSearch.propTypes = {
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  widthvalue: PropTypes.string,
  marginValue: PropTypes.string,
};

InputSearch.defaultProps = {
  widthvalue: "250px",
  marginValue: "",
};

export default InputSearch;
