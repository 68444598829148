import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NavBar from "../0.Commun/NavBar/NavBar";
import verifyToken from "../0.Commun/VerifyToken/VerifyToken";
import "./OptionsAdmin.scss";
import AddButton from "../../0.Commun/AddButton/AddButton";
import Select from "../../0.Commun/Select/Select";
import AddOption from "./components/AddOption/AddOption";
import { axiosPrivate } from "../../0.Commun/Axios/Axios";
import ListOption from "./components/ListOption/ListOption";
import FiltreOption from "./components/FiltreOption/FiltreOption";
import { scrollLock } from "../../0.Commun/ScrollLock/ScrollLock";

function OptionsAdmin() {
  const [parcs, setParcs] = useState([]);
  const [isLoadingParcs, setIsLoadingParcs] = useState(false);
  const [selectedParc, setSelectedParc] = useState();
  const [options, setOptions] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModify, setOpenModify] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();

  const getParcs = () => {
    axiosPrivate({ method: "GET", url: "parc/getAll" }).then((data) => {
      setParcs(data);
      setIsLoadingParcs(true);
    });
  };

  const getOption = (parcId) => {
    axiosPrivate({
      method: "GET",
      url: `options/getByParc/${parcId || selectedParc}`,
    }).then((data) => {
      setOptions(data);
    });
  };

  useEffect(() => {
    verifyToken(history);
    getParcs();
    if (selectedParc) {
      getOption();
    }
    scrollLock(openModalAdd || openModify);
  }, [openModalAdd, openModify]);

  const handleSelectParc = (e) => {
    setSelectedParc(parseInt(e.target.value, 10));
    getOption(parseInt(e.target.value, 10));
  };

  const openAddModal = () => {
    setOpenModalAdd(!openModalAdd);
  };

  return (
    <section>
      <Helmet>
        <title>Admin - Options</title>
      </Helmet>
      <NavBar />
      {openModalAdd && (
        <AddOption setClose={setOpenModalAdd} selectedParc={selectedParc} />
      )}
      <div className="containerOptionAdmin">
        <h1 className="h1Admin">Options</h1>
        <div className="containerSelect">
          {isLoadingParcs && (
            <Select
              datas={parcs}
              optionValue="nom_parc"
              onChange={(e) => handleSelectParc(e)}
              introValue="Veuillez choisir une destination"
            />
          )}
        </div>
        {selectedParc && (
          <>
            <AddButton
              title="Ajouter une Option &#43;"
              handleClick={openAddModal}
            />
            <div className="containerFilter">
              <FiltreOption
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            </div>
            <ListOption
              options={options}
              getOptions={getOption}
              searchValue={searchValue}
              setOpenModify={setOpenModify}
              openModify={openModify}
            />
          </>
        )}
      </div>
    </section>
  );
}

export default OptionsAdmin;
