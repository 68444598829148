import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import Button from "../../../../0.Commun/Button/Button";
import PopupConfirm from "../../../../0.Commun/PopupConfirm/PopupConfirm";
import { messages } from "../../../../0.Commun/Message/Message";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import InputSearch from "../../../../0.Commun/InputSearch/InputSearch";
import "./ListPartner.scss";
import ModifyPartenaire from "../ModifyPartenaire/ModifyPartenaire";
import HandleCalendar from "../HandleCalendar/HandleCalendar";
import { axiosPrivate } from "../../../../0.Commun/Axios/Axios";
import { scrollLock } from "../../../../0.Commun/ScrollLock/ScrollLock";

function ListPartner({ partners, getPartners }) {
  const [deleteDialog, setDeleteDialog] = useState({
    isVisible: false,
    message: "",
    id: 0,
  });
  const [searchValue, setSearchValue] = useState("");
  const [openModify, setOpenModify] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [idToModify, setIdToModify] = useState(0);

  const askDeletePartner = (idToDelete) => {
    setDeleteDialog({
      ...deleteDialog,
      isVisible: true,
      message: messages.ask.partenaire,
      id: idToDelete,
    });
  };

  const deletePartner = (id) => {
    axiosPrivate({ method: "DELETE", url: `partenaires/${id}` }).then(() => {
      notify(messages.delete.partenaire, "success");
      getPartners();
    });
  };

  const openModifyFn = (id) => {
    setIdToModify(id);
    setOpenModify(true);
  };

  const openCalendarFn = (id) => {
    setIdToModify(id);
    setOpenCalendar(true);
  };

  useEffect(() => {
    getPartners();
    scrollLock(openModify);
  }, [openModify]);

  useEffect(() => {
    scrollLock(openCalendar);
  }, [openCalendar]);

  return (
    <section className="containerAllPartners">
      <PopupConfirm
        dialog={deleteDialog}
        setDialog={setDeleteDialog}
        onDelete={deletePartner}
      />
      {openModify && (
        <ModifyPartenaire idPartner={idToModify} setClose={setOpenModify} />
      )}
      {openCalendar && (
        <HandleCalendar idPartner={idToModify} setClose={setOpenCalendar} />
      )}
      <div className="containerSearch">
        <InputSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder="Rechercher un partenaire"
        />
      </div>
      {partners.length > 0 &&
        partners.filter((partner) =>
          partner.email_partenaire
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ).map}
      {partners.length > 0 &&
        partners
          .filter((partner) =>
            partner.email_partenaire
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          )
          .map((partner) => (
            <div className="onePartner" key={partner.id_partenaire}>
              <p>{partner.email_partenaire}</p>
              <div>
                <Button
                  text="Modifier"
                  handleClick={() => openModifyFn(partner.id_partenaire)}
                  marginValue="5px 5px 5px 0"
                />
                <Button
                  text="Calendrier"
                  handleClick={() => openCalendarFn(partner.id_partenaire)}
                />
                <Button
                  text="Supprimer"
                  handleClick={() => askDeletePartner(partner.id_partenaire)}
                  marginValue="5px"
                />
              </div>
            </div>
          ))}
    </section>
  );
}

ListPartner.propTypes = {
  partners: propTypes.arrayOf(
    propTypes.shape({
      id_partenaire: propTypes.number.isRequired,
      email_partenaire: propTypes.string.isRequired,
    })
  ).isRequired,
  getPartners: propTypes.func.isRequired,
};

export default ListPartner;
