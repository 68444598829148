import React from "react";
import PropTypes from "prop-types";
import InputSearch from "../../../../0.Commun/InputSearch/InputSearch";
import "./FiltreOption.scss";

const FiltreOption = ({ searchValue, setSearchValue }) => {
  return (
    <div className="filtreOptionAdmin">
      <p>Filtrer :</p>
      <InputSearch
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        placeholder="Rechercher une option"
      />
    </div>
  );
};

FiltreOption.propTypes = {
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
};
export default FiltreOption;
