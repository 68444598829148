import React from "react";
import PropTypes from "prop-types";
import "./CloseButton.css";

function CloseButton({ setClose }) {
  const closeModal = () => {
    setClose(false);
  };

  return (
    <button type="button" onClick={closeModal} className="closeButton-button">
      Fermer
    </button>
  );
}

CloseButton.propTypes = {
  setClose: PropTypes.func.isRequired,
};

export default CloseButton;
