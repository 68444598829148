import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import "./AddAccomodation.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Catch from "../../../0.Commun/Catch/Catch";
import { checkName, checkNumbers } from "../../../../0.Commun/Regex/Regex";
import { messages } from "../../../../0.Commun/Message/Message";
import Quill from "../../../../0.Commun/Quill/Quill";
import { notify } from "../../../../0.Commun/ToastNotif/ToastNotif";
import PopupContainer from "../../../../0.Commun/PopupContainer/PopupContainer";
import Input from "../../../../0.Commun/Input/Input";
import Select from "../../../../0.Commun/Select/Select";
import InputImage from "../../../../0.Commun/InputImage/InputImage";
import Button from "../../../../0.Commun/Button/Button";

const API_URL = process.env.REACT_APP_API_URL;

function AddAccomodation({ setCloseModal, idParc }) {
  const [types, setTypes] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [newAccomodation, setNewAccomodation] = useState({
    name: "",
    isGroup: 0,
    calendrier: "",
    nbGuest: "",
    nbGuestGroup: "",
    typeHebergement: 0,
  });
  const [description, setDescription] = useState("");
  const [newImg, setNewImg] = useState([]);
  const history = useHistory();

  const getTypes = () => {
    axios
      .get(`${API_URL}/api/types_hebergement`)
      .then((res) => res.data)
      .then((data) => {
        setTypes(data);
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  useEffect(() => {
    getTypes();
  }, []);

  const handleName = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setNewAccomodation({
        ...newAccomodation,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleType = (e) => {
    setNewAccomodation({
      ...newAccomodation,
      typeHebergement: parseInt(e.target.value, 10),
    });
  };

  const handleNumber = (e) => {
    const resultRegex = checkNumbers(e.target.value);
    if (!resultRegex) {
      notify(messages.error.onlyNumbers, "error");
    } else {
      setNewAccomodation({
        ...newAccomodation,
        [e.target.name]: parseInt(e.target.value, 10),
      });
    }
  };

  const handleGroup = (e) => {
    if (e.target.checked === true) {
      setNewAccomodation({ ...newAccomodation, [e.target.name]: 1 });
    } else {
      setNewAccomodation({ ...newAccomodation, [e.target.name]: 0 });
    }
  };

  const postImage = (accomodationId) => {
    const token = localStorage.getItem("TOKEN");
    for (let i = 0; i < newImg.length; i += 1) {
      const newImage = new FormData();
      newImage.append("file", newImg[i]);
      newImage.append("description_image", newImg.name);
      newImage.append("hebergement_id", accomodationId);
      axios
        .post(`${API_URL}/api/images/new`, newImage, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  const addNewAccomodation = () => {
    const token = localStorage.getItem("TOKEN");
    if (
      !newAccomodation.name ||
      !description ||
      !newAccomodation.nbGuest ||
      !newAccomodation.typeHebergement ||
      newImg.length < 2
    ) {
      notify(messages.missing.champAndImage, "error");
    } else {
      axios
        .post(
          `${API_URL}/api/hebergements`,
          {
            nom_hebergement: newAccomodation.name,
            description_hebergement: description,
            groupe_hebergement: newAccomodation.isGroup,
            calendrier: newAccomodation.calendrier,
            nb_personnes_hebergement: newAccomodation.nbGuest,
            groupe_nb_personnes:
              newAccomodation.nbGuestGroup === ""
                ? null
                : newAccomodation.nbGuestGroup,
            parc_id: idParc,
            type_hebergement_id: newAccomodation.typeHebergement,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data)
        .then((data) => {
          postImage(data.id_hebergement);
        })
        .then(() => {
          setNewAccomodation({
            name: "",
            isGroup: 0,
            calendrier: "",
            nbGuest: "",
            nbGuestGroup: 0,
            typeHebergement: 0,
          });
          setDescription("");
          notify(messages.create.accomodation, "success");
          setCloseModal(false);
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  return (
    <section className="AddAccomodationAdmin">
      <PopupContainer setClose={setCloseModal}>
        <h2 className="h2admin">Ajouter un hébergement</h2>
        <form className="newAccomodation">
          <Input
            name="name"
            value={newAccomodation.name}
            handleChange={(e) => handleName(e)}
            placeholder="Nom de l'hébergement"
          />
          {isLoading && (
            <Select
              datas={types}
              optionValue="nom_type_hebergement"
              onChange={(e) => handleType(e)}
              introValue="Veuillez choisir un type d'hébergement"
              marginValue="0 0 30px 0"
            />
          )}
          <Input
            name="nbGuest"
            value={newAccomodation.nbGuest}
            handleChange={(e) => handleNumber(e)}
            placeholder="Nb personnes max"
          />
          <label htmlFor="group" className="labelNew">
            <input
              type="checkbox"
              name="isGroup"
              className="inputCheckbox"
              checked={newAccomodation.isGroup}
              onChange={(event) => handleGroup(event)}
            />
            <p>Hébergement disponible pour les groupes</p>
          </label>
          {newAccomodation.isGroup === 1 && (
            <Input
              name="nbGuestGroup"
              value={newAccomodation.nbGuestGroup}
              handleChange={(e) => handleNumber(e)}
              placeholder="Taille du groupe"
            />
          )}
          <div className="containerQuillAccomodation">
            <Quill setText={setDescription} text={description} />
          </div>
          <InputImage
            newImg={newImg}
            setNewImg={setNewImg}
            marginValue="0 0 30px 0"
            multiple
          />
          <Button text="Ajouter" handleClick={() => addNewAccomodation()} />
        </form>
      </PopupContainer>
    </section>
  );
}

AddAccomodation.propTypes = {
  setCloseModal: propTypes.func.isRequired,
  idParc: propTypes.number.isRequired,
};

export default AddAccomodation;
