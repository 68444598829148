import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./VignettesHome.scss";
import { notify } from "../../../0.Commun/ToastNotif/ToastNotif";

const API_URL = process.env.REACT_APP_API_URL;

function VignettesHome() {
  const [firstDeparture, setFirstDeparture] = useState({});
  const [isLoadingFirstD, setIsLoadingFirstD] = useState(false);
  const [conceptBlog, setConceptBlog] = useState({});
  const [isLoadingConcept, setIsLoadingConcept] = useState(false);
  const [lastBlog, setLastBlog] = useState({});
  const [isLoadingLastBlog, setIsLoadingLastBlog] = useState(false);
  const [randomPackage, setRandomPackage] = useState({});
  const [isLoadingRandom, setIsLoadingRandom] = useState(false);

  const getRandom = (dataMatch) => {
    dataMatch.shift();
    const max = dataMatch.length;
    const random = Math.floor(Math.random() * Math.floor(max));
    setRandomPackage(dataMatch[random]);
    setIsLoadingRandom(true);
  };

  const matchDispo = (dataFromRandom) => {
    const packagesWithDispo = [];
    dataFromRandom.forEach((item) => {
      const packageExist = packagesWithDispo.find(
        (element) => element.id_package === item.id_package
      );
      const packageIndex = packagesWithDispo.findIndex(
        (element) => element.id_package === item.id_package
      );
      if (!packageExist) {
        const date = new Date(item.date_debut).toLocaleDateString().split("/");
        date.pop();
        packagesWithDispo.push({
          id_package: item.id_package,
          nom_package: item.nom_package,
          description_image: item.description_image,
          nom_image: item.nom_image,
          dispo: [
            {
              id_dispo: item.id_dispo,
              date_debut: date.join("/"),
            },
          ],
        });
      } else {
        const date = new Date(item.date_debut).toLocaleDateString().split("/");
        date.pop();
        packagesWithDispo[packageIndex].dispo.push({
          id_dispo: item.id_dispo,
          date_debut: date.join("/"),
        });
      }
    });
    getRandom(packagesWithDispo);
  };

  const getAllPackages = () => {
    axios
      .get(`${API_URL}/api/packages/getRandom`)
      .then((res) => res.data)
      .then((data) => {
        matchDispo(data);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const getLastBlog = () => {
    axios
      .get(`${API_URL}/api/blogs/getHome?last=true`)
      .then((res) => res.data)
      .then((data) => {
        setLastBlog(data);
        setIsLoadingLastBlog(true);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const getConcept = () => {
    axios
      .get(`${API_URL}/api/blogs/getHome?concept=true`)
      .then((res) => res.data)
      .then((data) => {
        setConceptBlog(data);
        setIsLoadingConcept(true);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  const getFirstDeparture = () => {
    axios
      .get(`${API_URL}/api/packages/getFirst`)
      .then((res) => res.data)
      .then((data) => {
        setFirstDeparture(data);
        setIsLoadingFirstD(true);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  useEffect(() => {
    getFirstDeparture();
    getConcept();
    getLastBlog();
    getAllPackages();
  }, []);

  return (
    <div className="containerSectionThumbnails">
      {isLoadingFirstD &&
      isLoadingConcept &&
      isLoadingLastBlog &&
      isLoadingRandom ? (
        <div className="containerThumbnailsGlobal">
          {/* First Line */}

          <div className="containerFirstLine">
            {/* First thumbnail => first departure package */}

            <Link
              to={`/package/${
                firstDeparture.id_package
              }/${firstDeparture.nom_package.replace(/\s/g, "_")}`}
              className="thumbnail"
            >
              <div className="containerImgThumbnail">
                <img
                  src={`${API_URL}/images/${firstDeparture.nom_image}`}
                  alt={firstDeparture.description_image}
                  className="imgFirstThumbnail"
                />
              </div>
              <h3 className="h3Body titleThumbnail">
                {firstDeparture.nom_package}
              </h3>
              <div>
                {"Notre prochain départ "}
                &#10140;
                {` ${new Date(firstDeparture.date_debut).toLocaleDateString()}`}
              </div>
            </Link>

            {/* Second thumbnail => concept of Partir au vert */}

            <Link
              to={`/blog/${
                conceptBlog.id_blog
              }/${conceptBlog.titre_blog.replace(/\s/g, "_")}`}
              className="thumbnail"
            >
              <div className="containerImgThumbnail">
                <img
                  src={`${API_URL}/images/${conceptBlog.nom_image}`}
                  alt={conceptBlog.description_image}
                  className="imgFirstThumbnail"
                />
              </div>
              <h3 className="h3Body titleThumbnail">
                {conceptBlog.titre_blog}
              </h3>
              <p>Découvrez notre concept</p>
            </Link>
          </div>

          {/* Second Line */}

          <div className="containerFirstLine">
            {/* Third thumbnail => latest blog article */}

            <Link
              to={`/blog/${lastBlog.id_blog}/${lastBlog.titre_blog.replace(
                /\s/g,
                "_"
              )}`}
              className="thumbnail"
            >
              <div className="containerImgThumbnail">
                <img
                  src={`${API_URL}/images/${lastBlog.nom_image}`}
                  alt={lastBlog.description_image}
                  className="imgFirstThumbnail"
                />
              </div>
              <h3 className="h3Body titleThumbnail">{lastBlog.titre_blog}</h3>
              <p>Découvrez notre dernier article</p>
            </Link>

            {/* Fourth thumbnail => random package */}

            {randomPackage !== undefined && (
              <Link
                to={`/package/${
                  randomPackage.id_package
                }/${randomPackage.nom_package.replace(/\s/g, "_")}`}
                className="thumbnail"
              >
                <div className="containerImgThumbnail">
                  <img
                    src={`${API_URL}/images/${randomPackage.nom_image}`}
                    alt={randomPackage.description_image}
                    className="imgFirstThumbnail"
                  />
                </div>
                <h3 className="h3Body titleThumbnail">
                  {randomPackage.nom_package}
                </h3>
                <div className="containerDates">
                  <p>
                    {randomPackage.dispo.length > 1
                      ? "Nos prochains départs "
                      : "Notre prochain départ "}
                    &#10140;
                  </p>
                  {randomPackage.dispo.map((disp) => (
                    <div key={disp.id_dispo} className="listDates">
                      {disp.date_debut}
                    </div>
                  ))}
                </div>
              </Link>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default VignettesHome;
