import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// fonction pour afficher les notifications en fonction du type de notif
// passer le message, le type de notif et la position en props pour exemple voir Parc.jsx ligne 132

export const notify = (message, type, position) => {
  switch (type) {
    case "success":
      switch (position) {
        case 1:
          toast.success(message, { position: toast.POSITION.TOP_LEFT });
          break;
        case 2:
          toast.success(message, { position: toast.POSITION.TOP_CENTER });
          break;
        case 3:
          toast.success(message, { position: toast.POSITION.TOP_RIGHT });
          break;
        case 4:
          toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
          break;
        case 5:
          toast.success(message, { position: toast.POSITION.BOTTOM_CENTER });
          break;
        case 6:
          toast.success(message, { position: toast.POSITION.BOTTOM_LEFT });
          break;
        default:
          toast.success(message, { position: toast.POSITION.TOP_RIGHT });
      }
      break;
    case "warn":
      switch (position) {
        case 1:
          toast.warn(message, { position: toast.POSITION.TOP_LEFT });
          break;
        case 2:
          toast.warn(message, { position: toast.POSITION.TOP_CENTER });
          break;
        case 3:
          toast.warn(message, { position: toast.POSITION.TOP_RIGHT });
          break;
        case 4:
          toast.warn(message, { position: toast.POSITION.BOTTOM_RIGHT });
          break;
        case 5:
          toast.warn(message, { position: toast.POSITION.BOTTOM_CENTER });
          break;
        case 6:
          toast.warn(message, { position: toast.POSITION.BOTTOM_LEFT });
          break;
        default:
          toast.warn(message, { position: toast.POSITION.TOP_RIGHT });
      }
      break;
    case "error":
      switch (position) {
        case 1:
          toast.error(message, {
            position: toast.POSITION.TOP_LEFT,
          });
          break;
        case 2:
          toast.error(message, { position: toast.POSITION.TOP_CENTER });
          break;
        case 3:
          toast.error(message, { position: toast.POSITION.TOP_RIGHT });
          break;
        case 4:
          toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
          break;
        case 5:
          toast.error(message, { position: toast.POSITION.BOTTOM_CENTER });
          break;
        case 6:
          toast.error(message, { position: toast.POSITION.BOTTOM_LEFT });
          break;
        default:
          toast.error(message, { position: toast.POSITION.TOP_RIGHT });
      }
      break;
    case "info":
      switch (position) {
        case 1:
          toast.info(message, {
            position: toast.POSITION.TOP_LEFT,
          });
          break;
        case 2:
          toast.info(message, { position: toast.POSITION.TOP_CENTER });
          break;
        case 3:
          toast.info(message, { position: toast.POSITION.TOP_RIGHT });
          break;
        case 4:
          toast.info(message, { position: toast.POSITION.BOTTOM_RIGHT });
          break;
        case 5:
          toast.info(message, { position: toast.POSITION.BOTTOM_CENTER });
          break;
        case 6:
          toast.info(message, { position: toast.POSITION.BOTTOM_LEFT });
          break;
        default:
          toast.info(message, { position: toast.POSITION.TOP_RIGHT });
      }
      break;
    default:
      switch (position) {
        case 1:
          toast.info(message, {
            position: toast.POSITION.TOP_LEFT,
          });
          break;
        case 2:
          toast.info(message, { position: toast.POSITION.TOP_CENTER });
          break;
        case 3:
          toast.info(message, { position: toast.POSITION.TOP_RIGHT });
          break;
        case 4:
          toast.info(message, { position: toast.POSITION.BOTTOM_RIGHT });
          break;
        case 5:
          toast.info(message, { position: toast.POSITION.BOTTOM_CENTER });
          break;
        case 6:
          toast.info(message, { position: toast.POSITION.BOTTOM_LEFT });
          break;
        default:
          toast.info(message, { position: toast.POSITION.TOP_RIGHT });
      }
      break;
  }
};
