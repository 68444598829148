import { GetPrice } from "../Calcul/GetPrice";

export const matchImageAndPrice = (data) => {
  const accommodationsWithImages = [];
  const imagesArray = [];
  const pricesArray = [];
  if (!data[0].id_chambre) {
    data.forEach((item) => {
      const accomodationExist = accommodationsWithImages.find(
        (element) => element.id_hebergement === item.id_hebergement
      );
      const accomodationIndex = accommodationsWithImages.findIndex(
        (element) => element.id_hebergement === item.id_hebergement
      );
      const imagesExist = imagesArray.find(
        (element) => element.id_image === item.id_image
      );
      const priceExist = pricesArray.find(
        (element) => element.id_tarif === item.id_tarif
      );
      if (!accomodationExist) {
        pricesArray.push({
          id_tarif: item.id_tarif,
          priceMonday: item.tarif_lundi,
          priceTuesday: item.tarif_mardi,
          priceWednesday: item.tarif_mercredi,
          priceThursday: item.tarif_jeudi,
          priceFriday: item.tarif_vendredi,
          priceSaturday: item.tarif_samedi,
          priceSunday: item.tarif_dimanche,
          priceDatein: item.debut_periode,
          priceDateout: item.fin_periode,
          tax: item.taxe_sejour,
        });
        const intermediatePrice = [
          {
            id_tarif: item.id_tarif,
            priceMonday: item.tarif_lundi,
            priceTuesday: item.tarif_mardi,
            priceWednesday: item.tarif_mercredi,
            priceThursday: item.tarif_jeudi,
            priceFriday: item.tarif_vendredi,
            priceSaturday: item.tarif_samedi,
            priceSunday: item.tarif_dimanche,
            priceDatein: item.debut_periode,
            priceDateout: item.fin_periode,
            tax: item.taxe_sejour,
          },
        ];
        accommodationsWithImages.push({
          id_hebergement: item.id_hebergement,
          nom_hebergement: item.nom_hebergement,
          groupe_hebergement: item.groupe_hebergement,
          description_hebergement: item.description_hebergement,
          groupe_nb_personnes: item.groupe_nb_personnes,
          type_hebergement_id: item.type_hebergement_id,
          imageHebergement: [
            {
              id_image: item.id_image,
              nom_image: item.nom_image,
              description_image: item.description_image,
            },
          ],
          prices: [
            {
              id_tarif: item.id_tarif,
              priceMonday: item.tarif_lundi,
              priceTuesday: item.tarif_mardi,
              priceWednesday: item.tarif_mercredi,
              priceThursday: item.tarif_jeudi,
              priceFriday: item.tarif_vendredi,
              priceSaturday: item.tarif_samedi,
              priceSunday: item.tarif_dimanche,
              priceDatein: item.debut_periode,
              priceDateout: item.fin_periode,
              tax: item.taxe_sejour,
            },
          ],
          // prixDappel: item.tarif,
          prixDappel: GetPrice(intermediatePrice),
        });
        imagesArray.push({
          id_image: item.id_image,
          nom_image: item.nom_image,
          description_image: item.description_image,
        });
        // pricesArray.push({
        //   id_tarif: item.id_tarif,
        //   price: item.tarif,
        // });
      } else if (!imagesExist && priceExist) {
        accommodationsWithImages[accomodationIndex].imageHebergement.push({
          id_image: item.id_image,
          nom_image: item.nom_image,
          description_image: item.description_image,
        });
        imagesArray.push({
          id_image: item.id_image,
          nom_image: item.nom_image,
          description_image: item.description_image,
        });
      } else if (imagesExist && !priceExist) {
        accommodationsWithImages[accomodationIndex].prices.push({
          id_tarif: item.id_tarif,
          priceMonday: item.tarif_lundi,
          priceTuesday: item.tarif_mardi,
          priceWednesday: item.tarif_mercredi,
          priceThursday: item.tarif_jeudi,
          priceFriday: item.tarif_vendredi,
          priceSaturday: item.tarif_samedi,
          priceSunday: item.tarif_dimanche,
          priceDatein: item.debut_periode,
          priceDateout: item.fin_periode,
          tax: item.taxe_sejour,
        });

        pricesArray.push({
          id_tarif: item.id_tarif,
          priceMonday: item.tarif_lundi,
          priceTuesday: item.tarif_mardi,
          priceWednesday: item.tarif_mercredi,
          priceThursday: item.tarif_jeudi,
          priceFriday: item.tarif_vendredi,
          priceSaturday: item.tarif_samedi,
          priceSunday: item.tarif_dimanche,
          priceDatein: item.debut_periode,
          priceDateout: item.fin_periode,
          tax: item.taxe_sejour,
        });
        accommodationsWithImages[accomodationIndex].prixDappel = GetPrice(
          accommodationsWithImages[accomodationIndex].prices
        );
      }
    });
  } else {
    data.forEach((item) => {
      const accomodationExist = accommodationsWithImages.find(
        (element) => element.id_chambre === item.id_chambre
      );
      const accomodationIndex = accommodationsWithImages.findIndex(
        (element) => element.id_chambre === item.id_chambre
      );
      const imagesExist = imagesArray.find(
        (element) => element.id_image === item.id_image
      );
      const priceExist = pricesArray.find(
        (element) => element.id_tarif === item.id_tarif
      );
      if (!accomodationExist) {
        pricesArray.push({
          id_tarif: item.id_tarif,
          priceMonday: item.tarif_lundi,
          priceTuesday: item.tarif_mardi,
          priceWednesday: item.tarif_mercredi,
          priceThursday: item.tarif_jeudi,
          priceFriday: item.tarif_vendredi,
          priceSaturday: item.tarif_samedi,
          priceSunday: item.tarif_dimanche,
          priceDatein: item.debut_periode,
          priceDateout: item.fin_periode,
          tax: item.taxe_sejour,
        });
        const intermediatePrice = [
          {
            id_tarif: item.id_tarif,
            priceMonday: item.tarif_lundi,
            priceTuesday: item.tarif_mardi,
            priceWednesday: item.tarif_mercredi,
            priceThursday: item.tarif_jeudi,
            priceFriday: item.tarif_vendredi,
            priceSaturday: item.tarif_samedi,
            priceSunday: item.tarif_dimanche,
            priceDatein: item.debut_periode,
            priceDateout: item.fin_periode,
            tax: item.taxe_sejour,
          },
        ];
        accommodationsWithImages.push({
          id_chambre: item.id_chambre,
          nom_chambre: item.nom_chambre,
          description_chambre: item.description_chambre,
          nb_personnes_chambre: item.nb_personnes_chambre,
          imageHebergement: [
            {
              id_image: item.id_image,
              nom_image: item.nom_image,
              description_image: item.description_image,
            },
          ],
          prices: [
            {
              id_tarif: item.id_tarif,
              priceMonday: item.tarif_lundi,
              priceTuesday: item.tarif_mardi,
              priceWednesday: item.tarif_mercredi,
              priceThursday: item.tarif_jeudi,
              priceFriday: item.tarif_vendredi,
              priceSaturday: item.tarif_samedi,
              priceSunday: item.tarif_dimanche,
              priceDatein: item.debut_periode,
              priceDateout: item.fin_periode,
              tax: item.taxe_sejour,
            },
          ],
          // prixDappel: item.tarif,
          prixDappel: GetPrice(intermediatePrice),
        });
        imagesArray.push({
          id_image: item.id_image,
          nom_image: item.nom_image,
          description_image: item.description_image,
        });
        // pricesArray.push({
        //   id_tarif: item.id_tarif,
        //   price: item.tarif,
        // });
      } else if (!imagesExist && priceExist) {
        accommodationsWithImages[accomodationIndex].imageHebergement.push({
          id_image: item.id_image,
          nom_image: item.nom_image,
          description_image: item.description_image,
        });
        imagesArray.push({
          id_image: item.id_image,
          nom_image: item.nom_image,
          description_image: item.description_image,
        });
      } else if (imagesExist && !priceExist) {
        accommodationsWithImages[accomodationIndex].prices.push({
          id_tarif: item.id_tarif,
          priceMonday: item.tarif_lundi,
          priceTuesday: item.tarif_mardi,
          priceWednesday: item.tarif_mercredi,
          priceThursday: item.tarif_jeudi,
          priceFriday: item.tarif_vendredi,
          priceSaturday: item.tarif_samedi,
          priceSunday: item.tarif_dimanche,
          priceDatein: item.debut_periode,
          priceDateout: item.fin_periode,
          tax: item.taxe_sejour,
        });

        pricesArray.push({
          id_tarif: item.id_tarif,
          priceMonday: item.tarif_lundi,
          priceTuesday: item.tarif_mardi,
          priceWednesday: item.tarif_mercredi,
          priceThursday: item.tarif_jeudi,
          priceFriday: item.tarif_vendredi,
          priceSaturday: item.tarif_samedi,
          priceSunday: item.tarif_dimanche,
          priceDatein: item.debut_periode,
          priceDateout: item.fin_periode,
          tax: item.taxe_sejour,
        });
        accommodationsWithImages[accomodationIndex].prixDappel = GetPrice(
          accommodationsWithImages[accomodationIndex].prices
        );
      }
    });
  }

  return accommodationsWithImages;
};
