import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Calendar from "react-calendar";
import differenceInDays from "date-fns/differenceInDays";
import {
  filteredAccomodation,
  selectedParc,
  messagePopup,
  openPopup,
  selectedAccomodation,
  lactivite,
} from "../../../5.Redux/Action";
import "./Inputs.scss";
import "react-calendar/dist/Calendar.css";

const API_URL = process.env.REACT_APP_API_URL;

function Inputs() {
  const [filter, setFilter] = useState({
    date: "",
    type: undefined,
    parc: 0,
    nbPersonnes: 2,
  });
  const [parc, setParc] = useState(0);
  const [types, setTypes] = useState({});
  const [parcs, setParcs] = useState({});
  const [parcName, setParcName] = useState("");
  const [isLoadingTypes, setIsLoadingTypes] = useState(false);
  const [isLoadingParcs, setIsLoadingParcs] = useState(false);
  const [openCal, setOpenCal] = useState(false);
  const [openInput, setOpenInput] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const resetBasket = () => {
    dispatch(selectedParc(0));
    dispatch(selectedAccomodation(0));
    dispatch(lactivite([]));
    dispatch(filteredAccomodation(0));
  };

  const getType = () => {
    axios
      .get(`${API_URL}/api/types_hebergement`)
      .then((res) => res.data)
      .then((data) => {
        setTypes(data);
        setIsLoadingTypes(true);
      })
      .catch((err) => {
        dispatch(messagePopup(err));
        dispatch(openPopup(true));
      });
  };

  const getParc = () => {
    axios
      .get(`${API_URL}/api/parc/getAll`)
      .then((res) => res.data)
      .then((data) => {
        setParcs(data);
        setIsLoadingParcs(true);
      })
      .catch((err) => {
        dispatch(messagePopup(err));
        dispatch(openPopup(true));
      });
  };

  useEffect(() => {
    resetBasket();
    getType();
    getParc();
  }, []);

  const handleChange = (dateVal) => {
    setFilter({ ...filter, date: dateVal });
    setOpenCal(!openCal);
  };

  const openCalendar = () => {
    setOpenCal(!openCal);
  };

  const handleParc = (event) => {
    setFilter({ ...filter, parc: parseInt(event.target.value, 10) });
    setParc(parseInt(event.target.value, 10));
    for (let i = 0; i < parcs.length; i += 1) {
      if (parseInt(event.target.value, 10) === parcs[i].id_parc) {
        setParcName(parcs[i].nom_parc);
      }
    }
  };

  const handleHebergement = (event) => {
    if (event.target.value !== "") {
      setFilter({ ...filter, type: parseInt(event.target.value, 10) });
    } else {
      setFilter({ ...filter, type: undefined });
    }
  };

  const handleNbPersonnes = (event) => {
    setFilter({ ...filter, nbPersonnes: parseInt(event.target.value, 10) });
  };

  const handleSubmit = () => {
    const nbJours = differenceInDays(filter.date[1], filter.date[0]);
    if (!filter.parc) {
      dispatch(messagePopup("Veuillez sélectionner un parc"));
      dispatch(openPopup(true));
    } else if (nbJours < 2 && filter.type === 1) {
      dispatch(
        messagePopup(
          "Un minimum de 2 nuits est requis pour la réservation d'un gîte"
        )
      );
      dispatch(openPopup(true));
    } else {
      dispatch(filteredAccomodation(filter));
      dispatch(selectedParc(parc));
      history.push(`/parc/${parc}/${parcName.replace(/\s/g, "_")}`);
    }
  };

  const openModal = () => {
    setOpenInput(!openInput);
  };

  return (
    <div className="sectionInputs">
      <div className="rechercher2">
        <button className="rechercherLink2" onClick={openModal} type="button">
          {openInput ? "Fermer" : "Rechercher"}
        </button>
      </div>
      <form className={openInput ? "inputsForm" : "inputsClose"}>
        <div className="containerCalendarInput">
          <button
            type="button"
            className="inputDates"
            placeholder="CHOISIR VOS DATES"
            onClick={openCalendar}
          >
            {filter.date
              ? `Du ${new Date(
                  filter.date[0]
                ).toLocaleDateString()} au ${new Date(
                  filter.date[1]
                ).toLocaleDateString()}`
              : "Dates"}
          </button>
          {openCal && (
            <span className="displayCalendar">
              <Calendar
                onChange={handleChange}
                minDate={new Date()}
                selectRange
              />
            </span>
          )}
        </div>
        <select
          className="inputParc"
          type="text"
          name="inputParc"
          placeholder="Région"
          onChange={handleParc}
        >
          <option value="">Destination</option>
          {isLoadingParcs && (
            <>
              {parcs.map((item) => (
                <option key={item.id_parc} value={item.id_parc}>
                  {item.nom_parc}
                </option>
              ))}
            </>
          )}
        </select>
        <select
          type="text"
          name="inputHebergement"
          className="inputHebergement"
          onChange={handleHebergement}
        >
          <option value="">Hébergement</option>
          {isLoadingTypes && (
            <>
              {types.map((type) => (
                <option
                  key={type.id_type_hebergement}
                  value={type.id_type_hebergement}
                >
                  {type.nom_type_hebergement}
                </option>
              ))}
            </>
          )}
        </select>
        <select
          className="inputNbPersonnes"
          type="number"
          name="inputNbPersonnes"
          onChange={handleNbPersonnes}
        >
          <option value="2">2 personnes</option>
          <option value="3">3 personnes</option>
          <option value="4">4 personnes</option>
          <option value="5">5 personnes</option>
          <option value="6">6 personnes</option>
        </select>
        <div className="rechercher">
          <button
            className="rechercherLink"
            onClick={handleSubmit}
            type="button"
          >
            Rechercher
          </button>
        </div>
      </form>
    </div>
  );
}

export default Inputs;
