import React from "react";
import "./HeaderContact.scss";

function HeaderContact() {
  return (
    <div className="containerHeaderContact">
      <div className="sectionHeaderContact">
        <h1 className="h1Body titreHeaderContact">
          Séjours nature en Ile de France
        </h1>
      </div>
    </div>
  );
}

export default HeaderContact;
