import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Dropdown.css";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { selectedParc, filteredAccomodation } from "../../../5.Redux/Action";

function Dropdown({ setClick }) {
  const [clicked, setClicked] = useState(false);
  const dispatch = useDispatch();
  const parcs = useSelector((state) => state.tasks.parcs);

  const handleClick = (idParc) => {
    dispatch(selectedParc(idParc));
    dispatch(filteredAccomodation(0));
    setClicked(false);
    setClick(false);
  };

  return (
    <>
      <ul
        role="presentation"
        aria-hidden
        className={clicked ? "dropdown-menu clicked" : "dropdown-menu"}
      >
        {parcs.map((item) => (
          <li key={item.id_parc}>
            <Link
              className="dropdown-link"
              to={`/parc/${item.id_parc}/${item.nom_parc.replace(/\s/g, "_")}`}
              onClick={() => handleClick(item.id_parc)}
            >
              {item.nom_parc}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
}

Dropdown.propTypes = {
  setClick: PropTypes.func.isRequired,
};

export default Dropdown;
