import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Catch from "../../../../../../../0.Commun/Catch/Catch";
import {
  checkName,
  checkNumbers,
} from "../../../../../../../../0.Commun/Regex/Regex";
import { messages } from "../../../../../../../../0.Commun/Message/Message";
import "./ModifyPrice.scss";
import { notify } from "../../../../../../../../0.Commun/ToastNotif/ToastNotif";
import PopupContainer from "../../../../../../../../0.Commun/PopupContainer/PopupContainer";
import Input from "../../../../../../../../0.Commun/Input/Input";
import Button from "../../../../../../../../0.Commun/Button/Button";

const API_URL = process.env.REACT_APP_API_URL;

function ModifyPrice({ idPrice, setCloseModal }) {
  const [price, setPrice] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const getPrice = () => {
    axios
      .get(`${API_URL}/api/tarifs_hebergement/getById/${idPrice}`)
      .then((res) => res.data)
      .then((data) => {
        setPrice(data);
        setIsLoading(true);
        // console.log(data);
      })
      .catch((err) => {
        Catch(err, history);
      });
  };

  useEffect(() => {
    getPrice();
  }, []);

  const handleText = (e) => {
    const resultRegex = checkName(e.target.value);
    if (!resultRegex) {
      notify(messages.error.unauthaurizedCharacter, "error");
    } else {
      setPrice({ ...price, [e.target.name]: e.target.value });
    }
  };

  const handleDate = (e) => {
    setPrice({ ...price, [e.target.name]: e.target.value });
  };

  const handleNumber = (e) => {
    const resultRegex = checkNumbers(e.target.value);
    if (!resultRegex) {
      notify(messages.error.onlyNumbers, "error");
    } else {
      setPrice({ ...price, [e.target.name]: parseInt(e.target.value, 10) });
    }
  };

  const modifyPrice = () => {
    const token = localStorage.getItem("TOKEN");
    if (
      !price.nom_tarif ||
      !price.debut_periode ||
      !price.fin_periode ||
      !price.tarif_lundi ||
      !price.taxe_sejour ||
      !price.tarif_mardi ||
      !price.tarif_mercredi ||
      !price.tarif_jeudi ||
      !price.tarif_vendredi ||
      !price.tarif_samedi ||
      !price.tarif_dimanche
    ) {
      notify(messages.missing.inputEmpty, "error");
    } else {
      // console.log(idPrice);
      axios
        .put(
          `${API_URL}/api/tarifs_hebergement/${idPrice}`,
          {
            nom_tarif: price.nom_tarif,
            debut_periode: new Date(price.debut_periode)
              .toLocaleDateString()
              .split("/")
              .reverse()
              .join("-"),
            fin_periode: new Date(price.fin_periode)
              .toLocaleDateString()
              .split("/")
              .reverse()
              .join("-"),
            tarif_lundi: price.tarif_lundi,
            taxe_sejour: price.taxe_sejour,
            tarif_mardi: price.tarif_mardi,
            tarif_mercredi: price.tarif_mercredi,
            tarif_jeudi: price.tarif_jeudi,
            tarif_vendredi: price.tarif_vendredi,
            tarif_samedi: price.tarif_samedi,
            tarif_dimanche: price.tarif_dimanche,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setCloseModal(false);
          notify(messages.modify.price, "success");
        })
        .catch((err) => {
          Catch(err, history);
        });
    }
  };

  return (
    <section className="modifyPrice">
      <PopupContainer setClose={setCloseModal} widthvalue={40} heightValue={55}>
        {isLoading && (
          <form className="formAddPrice">
            <Input
              name="nom_tarif"
              widthvalue="250px"
              className="inputGeneralAdmin"
              value={price.nom_tarif}
              placeholder="Nom tarif"
              handleChange={(e) => {
                handleText(e);
              }}
            />
            <Input
              name="debut_periode"
              widthvalue="250px"
              className="inputGeneralAdmin"
              value={new Date(price.debut_periode)
                .toLocaleDateString()
                .split("/")
                .reverse()
                .join("-")}
              handleChange={(e) => {
                handleDate(e);
              }}
            />
            <Input
              name="fin_periode"
              widthvalue="250px"
              className="inputGeneralAdmin"
              value={new Date(price.fin_periode)
                .toLocaleDateString()
                .split("/")
                .reverse()
                .join("-")}
              handleChange={(e) => {
                handleDate(e);
              }}
            />
            <Input
              name="tarif_lundi"
              widthvalue="250px"
              className="inputGeneralAdmin"
              value={price.tarif_lundi.toString()}
              placeholder="lundi"
              handleChange={(e) => {
                handleNumber(e);
              }}
            />
            <Input
              name="tarif_mardi"
              widthvalue="250px"
              className="inputGeneralAdmin"
              value={price.tarif_mardi.toString()}
              placeholder="mardi"
              handleChange={(e) => {
                handleNumber(e);
              }}
            />{" "}
            <Input
              name="tarif_mercredi"
              widthvalue="250px"
              className="inputGeneralAdmin"
              value={price.tarif_mercredi.toString()}
              placeholder="mercredi"
              handleChange={(e) => {
                handleNumber(e);
              }}
            />{" "}
            <Input
              name="tarif_jeudi"
              widthvalue="250px"
              className="inputGeneralAdmin"
              value={price.tarif_jeudi.toString()}
              placeholder="jeudi"
              handleChange={(e) => {
                handleNumber(e);
              }}
            />{" "}
            <Input
              name="tarif_vendredi"
              widthvalue="250px"
              className="inputGeneralAdmin"
              value={price.tarif_vendredi.toString()}
              placeholder="vendredi"
              handleChange={(e) => {
                handleNumber(e);
              }}
            />{" "}
            <Input
              name="tarif_samedi"
              widthvalue="250px"
              className="inputGeneralAdmin"
              value={price.tarif_samedi.toString()}
              placeholder="samedi"
              handleChange={(e) => {
                handleNumber(e);
              }}
            />{" "}
            <Input
              name="tarif_dimanche"
              widthvalue="250px"
              className="inputGeneralAdmin"
              value={price.tarif_dimanche.toString()}
              placeholder="dimanche"
              handleChange={(e) => {
                handleNumber(e);
              }}
            />{" "}
            <Input
              name="taxe_sejour"
              widthvalue="250px"
              className="inputGeneralAdmin"
              value={price.taxe_sejour.toString()}
              placeholder="Taxe locale"
              handleChange={(e) => {
                handleNumber(e);
              }}
            />
            <Button text="Modifier" handleClick={modifyPrice} />
          </form>
        )}
      </PopupContainer>
    </section>
  );
}

ModifyPrice.propTypes = {
  idPrice: propTypes.number.isRequired,
  setCloseModal: propTypes.func.isRequired,
};

export default ModifyPrice;
