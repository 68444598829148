import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import logo from "../../../../Assets/logoPartirauvertFONDtransparent.png";
import "./NavBar.scss";
import { notify } from "../../../0.Commun/ToastNotif/ToastNotif";
import { messages } from "../../../0.Commun/Message/Message";

const menus = [
  {
    id: 0,
    title: "CONFIGURATIONS",
    path: "/admin/profil",
  },
  {
    id: 1,
    title: "COMMANDES",
    path: "/admin/commandes",
  },
  {
    id: 2,
    title: "BLOG",
    path: "/admin/blog",
  },
  {
    id: 3,
    title: "DESTINATIONS",
    path: "/admin/parc",
  },
  {
    id: 4,
    title: "HEBERGEMENTS",
    path: "/admin/hebergements",
  },
  {
    id: 5,
    title: "PACKAGES",
    path: "/admin/package",
  },
  {
    id: 6,
    title: "ACTIVITES",
    path: "/admin/activites",
  },
  {
    id: 7,
    title: "OPTIONS",
    path: "/admin/options",
  },
  {
    id: 8,
    title: "PARTENAIRES",
    path: "/admin/partenaire",
  },
  {
    id: 9,
    title: "TARIFS FIXE",
    path: "/admin/tarif_fixe",
  },
  {
    id: 10,
    title: "CONCEPT/GROUPE",
    path: "/admin/concept",
  },
  {
    id: 11,
    title: "INFOS",
    path: "/admin/infos",
  },
];

function NavBar() {
  const history = useHistory();

  const handleClick = () => {
    notify(messages.session.disconnected, "success", 2);
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("userId");
    history.push("/login-admin");
  };

  return (
    <section className="navBarAdmin">
      <div>
        <div className="navBarAdminContainerLogo">
          <img
            className="navBarAdminLogo"
            src={logo}
            alt="logo partir au vert"
          />
        </div>
        <div className="navBarAdminContainerList">
          <ul className="navBarAdminLists">
            {menus.map((menu) => {
              return (
                <li key={menu.id} className="navBarAdminList">
                  <NavLink
                    to={menu.path}
                    className="navBarAdminLink"
                    activeClassName="navBarAdminSelected"
                  >
                    <p>{menu.title}</p>
                  </NavLink>
                </li>
              );
            })}
            <li className="navBarAdminList">
              <button
                className="navBarAdminBtnDeco"
                type="button"
                onClick={handleClick}
              >
                DECONNEXION
              </button>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default NavBar;
