import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import facebook from "../../../../Assets/Fb.png";
import insta from "../../../../Assets/instagramBlanc.png";
import linkedin from "../../../../Assets/linkedinblanc.png";

function Footer() {
  return (
    <div className="sectionFooter">
      <div className="containerFooter">
        <div className="div1">
          <h2 className="titreFooter">Informations Pratiques</h2>
          <HashLink
            className="paraphFooterContainerP"
            smooth
            to="/infos-pratiques#quiSommeNous"
          >
            <p className="paraphFooter">Qui sommes-nous</p>
          </HashLink>
          <HashLink
            className="paraphFooterContainerP"
            smooth
            to="/infos-pratiques#ConditionsGeneralesDeVente"
          >
            <p className="paraphFooter">Conditions générales de vente</p>
          </HashLink>
          <HashLink
            className="paraphFooterContainerP"
            smooth
            to="/infos-pratiques#mentionsLegales"
          >
            <p className="paraphFooter">Mentions légales</p>
          </HashLink>
        </div>
        <div className="dividerFooter" />
        <div className="divMilieu">
          <h2 className="titreFooter">Contact</h2>
          <p className="paraphFooter2">06 31 79 37 34</p>
          <p className="paraphFooter2">contact@partirauvert.fr</p>
          <div className="containerReseaux">
            <a
              href="https://www.facebook.com/Partir-au-Vert-100329378797678"
              target=" blank "
            >
              <img className="fb" src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/partir_au_vert/?hl=fr"
              target=" blank "
            >
              <img className="fb" src={insta} alt="instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/partir-au-vert"
              target=" blank "
            >
              <img className="fb" src={linkedin} alt="twitter" />
            </a>
          </div>
        </div>
        <div className="dividerFooter div3" />
        <div className="div3">
          <h2 className="titreFooter">Partenaires</h2>
          <Link className="paraphFooterContainerP" to="/login-admin">
            <p className="paraphFooter">Votre espace Pro</p>
          </Link>
          <Link className="paraphFooterContainerP" to="/login-partenaire">
            <p className="paraphFooter">Votre espace partenaire</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
