import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import intervalToDuration from "date-fns/intervalToDuration";
import "./ListePackages.scss";

const API_URL = process.env.REACT_APP_API_URL;

function ListePackages() {
  const [packs, setPacks] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const filter = useSelector((state) => state.tasks.filter);
  const idParc = useSelector((state) => state.tasks.selectedParc);
  const getSort = useSelector((state) => state.tasks.sort);

  const getDispos = (dataFromPackages) => {
    const packagesWithDispo = [];
    dataFromPackages.forEach((item) => {
      const packageExist = packagesWithDispo.find(
        (element) => element.id_package === item.id_package
      );
      const packageIndex = packagesWithDispo.findIndex(
        (element) => element.id_package === item.id_package
      );
      if (!packageExist) {
        const date = new Date(item.date_debut).toLocaleDateString().split("/");
        date.pop();
        const nbNight = intervalToDuration({
          start: new Date(item.date_debut),
          end: new Date(item.date_fin),
        });
        packagesWithDispo.push({
          id_package: item.id_package,
          nom_package: item.nom_package,
          description_image: item.description_image,
          nom_image: item.nom_image,
          dispo: [
            {
              id_dispo: item.dispo_id,
              date_debut: date.join("/"),
              duration: nbNight.days,
              tarif_package: item.tarif_package,
              nbPersonnes: item.nb_personnes_package,
            },
          ],
          prixDappel: item.tarif_package,
        });
      } else {
        const date = new Date(item.date_debut).toLocaleDateString().split("/");
        date.pop();
        const nbNight = intervalToDuration({
          start: new Date(item.date_debut),
          end: new Date(item.date_fin),
        });
        packagesWithDispo[packageIndex].dispo.push({
          id_dispo: item.dispo_id,
          date_debut: date.join("/"),
          duration: nbNight.days,
          tarif_package: item.tarif_package,
          nbPersonnes: item.nb_personnes_package,
        });
        if (item.tarif_package < packagesWithDispo[packageIndex].prixDappel) {
          packagesWithDispo[packageIndex].prixDappel = item.tarif_package;
        }
      }
    });
    if (getSort === 1) {
      packagesWithDispo.sort((a, b) => b.prixDappel - a.prixDappel);
    } else if (getSort === 2) {
      packagesWithDispo.sort((a, b) => a.prixDappel - b.prixDappel);
    }
    setPacks(packagesWithDispo);
    setIsLoading(true);
  };

  const getPacks = () => {
    let sql = "";
    if (filter.parc && filter.nbPersonnes && filter.type && filter.date) {
      sql = `${API_URL}/api/packages/getFiltered?parcId=${
        filter.parc
      }&nbGuest=${filter.nbPersonnes}&type=${filter.type}&startDate=${new Date(
        filter.date[0]
      )
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("-")}&endDate=${new Date(filter.date[1])
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("-")}`;
    } else if (filter.parc && filter.nbPersonnes && filter.date) {
      sql = `${API_URL}/api/packages/getFiltered?parcId=${
        filter.parc
      }&nbGuest=${filter.nbPersonnes}&startDate=${new Date(filter.date[0])
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("-")}&endDate=${new Date(filter.date[1])
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("-")}`;
    } else if (filter.parc && filter.nbPersonnes && filter.type) {
      sql = `${API_URL}/api/packages/getFiltered?parcId=${filter.parc}&nbGuest=${filter.nbPersonnes}&type=${filter.type}`;
    } else if (filter.parc && filter.nbPersonnes) {
      sql = `${API_URL}/api/packages/getFiltered?parcId=${filter.parc}&nbGuest=${filter.nbPersonnes}`;
    } else {
      sql = `${API_URL}/api/packages/getByParc/${idParc}`;
    }
    if (filter !== 0) {
      axios
        .get(sql)
        .then((res) => res.data)
        .then((data) => {
          getDispos(data);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      axios
        .get(`${API_URL}/api/packages/getByParc/${idParc}`)
        .then((res) => res.data)
        .then((data) => {
          getDispos(data);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getPacks();
  }, [idParc, filter, getSort]);

  const getPrice = (prices) => {
    let priceLess = 99999;
    for (let i = 0; i < prices.length; i += 1) {
      if (prices[i].tarif_package < priceLess) {
        priceLess = prices[i].tarif_package;
      }
    }
    return priceLess;
  };

  return (
    <div>
      <div className="listePackageGlobal">
        {isLoading
          ? packs.map((pack) => (
            <div key={pack.id_package} className="containerGlobalMap">
              <Link
                to={`/package/${pack.id_package}/${pack.nom_package.replace(
                    /\s/g,
                    "_"
                  )}`}
              >
                <div className="containerImagePackage">
                  <img
                    className="imagePackage"
                    src={`${API_URL}/images/${pack.nom_image}`}
                    alt={pack.description_image}
                  />
                </div>
              </Link>
              <div className="containerNomPackage">
                <h3 className="h3Body nomPackage">{pack.nom_package}</h3>
              </div>
              <div className="containerPrice">
                <p className="priceP">
                  {`${pack.dispo[0].nbPersonnes} personnes, ${
                      pack.dispo[0].duration
                    } ${pack.dispo[0].duration === 1 ? "nuit" : "nuits"}`}
                </p>
                <p className="priceP">{getPrice(pack.dispo)}€</p>
              </div>
              <div className="containerGlobalDate">
                <p className="nextDate">
                  {pack.dispo.length > 1
                      ? "Prochains départs"
                      : "Prochain départ"}
                </p>
                <div className="containerDate">
                  {pack.dispo.map((disp) => (
                    <p key={disp.id_dispo} className="dateDispo">
                      {disp.date_debut}
                    </p>
                    ))}
                </div>
              </div>
            </div>
            ))
          : "Nos prochains weekend clés en main arrivent bientôt..."}
      </div>
    </div>
  );
}

export default ListePackages;
