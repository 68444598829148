import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./Panier.scss";

function Panier() {
  const [isClicked, setIsClicked] = useState(false);
  const panier = useSelector((state) => state.tasks.panier);

  const displayBookingMobile = () => {
    setIsClicked(!isClicked);
  };
  return (
    <section className="globalCart">
      <button
        type="button"
        className="btnBookingDetails"
        onClick={displayBookingMobile}
      >
        <h2 className="h2Body">Détails de votre réservation</h2>
        <p className="h2Body questionMark">?</p>
      </button>
      <div className={isClicked ? "containerCartClose" : "containerCart"}>
        <div className="dateBooking">
          <h3 className="h3Body">Dates</h3>
          <p className="recapBooking">
            {`Du ${new Date(
              panier.dateDebut
            ).toLocaleDateString()} au ${new Date(
              panier.dateFin
            ).toLocaleDateString()}`}
          </p>
        </div>
        <div className="dividerBooking" />
        <div className="dateBooking">
          <h3 className="h3Body">Destination</h3>
          <p className="recapBooking">{panier.parc}</p>
        </div>
        <div className="dividerBooking" />
        <div className="dateBooking">
          <h3 className="h3Body">Nombre de personnes</h3>
          <p className="recapBooking">{panier.nbPersonnes}</p>
        </div>
        <div className="dividerBooking" />
        {panier.pack !== 0 ? (
          <>
            <div className="dateBooking">
              <h3 className="h3Body">Package</h3>
              <p className="recapBooking">{panier.packName}</p>
            </div>
            <div className="dividerBooking" />
          </>
        ) : null}
        {panier.hebergement !== undefined ? (
          <>
            <div className="dateBooking">
              <h3 className="h3Body">Hébergement</h3>
              <p className="recapBooking">{panier.hebergement}</p>
            </div>
            <div className="dividerBooking" />
          </>
        ) : null}
        {panier.chambre !== undefined ? (
          <>
            <div className="dateBooking">
              <h3 className="h3Body">Chambre</h3>
              <p className="recapBooking">{panier.chambre}</p>
            </div>
            <div className="dividerBooking" />
          </>
        ) : (
          <></>
        )}
        {panier.activites !== undefined ? (
          <>
            {panier.activites.length !== 0 && (
              <>
                <div className="dateBooking">
                  <h3 className="h3Body">
                    {panier.activites.length > 1 ? "Activités" : "Activité"}
                  </h3>
                  {panier.activites.map((activity) => (
                    <p key={activity.idActivityCart} className="recapBooking">
                      {activity.name}
                    </p>
                  ))}
                </div>
                <div className="dividerBooking" />
              </>
            )}
          </>
        ) : (
          <></>
        )}
        {panier.options !== undefined ? (
          <>
            {panier.options.length !== 0 && (
              <>
                <div className="dateBooking">
                  <h3 className="h3Body">
                    {panier.options.length > 1 ? "Options" : "Option"}
                  </h3>
                  {panier.options.map((op) => (
                    <p key={op.idOptionsCart} className="recapBooking">
                      {op.name}
                    </p>
                  ))}
                </div>
                <div className="dividerBooking" />
              </>
            )}
          </>
        ) : (
          <></>
        )}
        {panier.prix !== 0 ? (
          <div className="dateBooking">
            <h3 className="h3Body">Total</h3>
            <p className="recapBookingFinalPrice">{`${panier.prix} €`}</p>
          </div>
        ) : null}
      </div>
    </section>
  );
}

export default Panier;
