import React, { useEffect, useState } from "react";
import axios from "axios";
import Helmet from "react-helmet";
import ReactHtmlParser from "html-react-parser";
import { useParams, Link } from "react-router-dom";
import Navbar from "../../0.Commun/Navbar/Navbar";
import Footer from "../../0.Commun/Footer/Footer";
import "./OneArticle.scss";
import { notify } from "../../../0.Commun/ToastNotif/ToastNotif";

const API_URL = process.env.REACT_APP_API_URL;

function OneArticle() {
  const [article, setArticle] = useState({});
  const [isLoadingArticle, setIsLoadingArticle] = useState(false);
  const { id } = useParams();

  const matchKeyword = (data) => {
    const articleWithKeywords = [];
    data.forEach((item) => {
      const articleExist = articleWithKeywords.find(
        (element) => element.id_blog === item.id_blog
      );
      const articleIndex = articleWithKeywords.findIndex(
        (element) => element.id_blog === item.id_blog
      );
      if (!articleExist) {
        articleWithKeywords.push({
          id_blog: item.id_blog,
          titre_blog: item.titre_blog,
          article: item.article,
          date: item.date,
          nom_image: item.nom_image,
          description_image: item.description_image,
          keywords: [
            {
              id: item.id_mots_cles,
              keyword: item.keywords,
            },
          ],
        });
      } else {
        articleWithKeywords[articleIndex].keywords.push({
          id: item.id_mots_cles,
          keyword: item.keywords,
        });
      }
    });
    setArticle(articleWithKeywords[0]);
    setIsLoadingArticle(true);
  };

  const getArticle = () => {
    axios
      .get(`${API_URL}/api/blogs/getById/${id}`)
      .then((res) => res.data)
      .then((data) => {
        matchKeyword(data);
      })
      .catch((err) => {
        notify(err.response.data.errorMessage, "error");
      });
  };

  useEffect(() => {
    getArticle();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{article.titre_blog}</title>
        <meta name="keywords" content={article.keywords} />
      </Helmet>
      <Navbar />
      <Link to="/blog/all" className="btnBackAllBlog">
        <p className="arrowBackAllBlog">&#x2190;</p>
        <p className="textBackAllBlog">Liste des articles</p>
      </Link>
      {isLoadingArticle && (
        <div className="containerGlobalArticle">
          <div className="containerBlogArticle">
            <h2 className="h2Body">{article.titre_blog}</h2>
            <div className="dateAndKeywords">
              <p className="dateBlogArticle">
                {new Date(article.date).toLocaleDateString()}
              </p>
              <div className="containerBlogKeyword">
                {article.keywords.map((keyword) => (
                  <p key={keyword.id} className="blogKeywords">
                    #{keyword.keyword}
                  </p>
                ))}
              </div>
            </div>
            <div className="containerBlogImg">
              <img
                className="imageBlogArticle"
                src={`${API_URL}/images/${article.nom_image}`}
                alt={article.description_image}
              />
            </div>
            <div className="articleBlogComplet">
              {ReactHtmlParser(article.article)}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default OneArticle;
