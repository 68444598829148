import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filteredAccomodation, sortPrice } from "../../../5.Redux/Action";
import "./Filter.scss";
import filterLogo from "../../../../Assets/filter.png";

function Filter() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [filterDisplay, setFilterDisplay] = useState(false);
  const filterRedux = useSelector((state) => state.tasks.filter);
  const parcId = useSelector((state) => state.tasks.selectedParc);
  const types = useSelector((state) => state.tasks.types);
  const dispatch = useDispatch();

  const handleScroll = () => {
    const scroll = window.scrollY;
    setScrollPosition(scroll);
  };

  const listenScroll = () => {
    window.addEventListener("scroll", handleScroll);
  };

  const stopListenScroll = () => {
    window.removeEventListener("scroll", handleScroll);
  };

  useEffect(() => {
    listenScroll();
    return () => {
      stopListenScroll();
    };
  }, []);

  const determineClass = () => {
    const screenSize = window.screen.width;
    if ((scrollPosition > 700 && screenSize > 767) || filterDisplay === true) {
      return "containerFilterFixed";
    }
    return "containerFilter";
  };

  const defineName = (idType) => {
    for (let i = 0; i < types.length; i += 1) {
      if (idType === types[i].id_type_hebergement) {
        return types[i].nom_type_hebergement;
      }
    }
    return idType;
  };

  const handleAccomodation = (event) => {
    if (event.target.value === "reset") {
      dispatch(filteredAccomodation(0));
      setFilterDisplay(false);
    } else if (filterRedux === 0) {
      const filter = {
        parc: parseInt(parcId, 10),
        nbPersonnes: 2,
        type: parseInt(event.target.value, 10),
      };
      dispatch(filteredAccomodation(filter));
      setFilterDisplay(false);
    } else {
      dispatch(
        filteredAccomodation({
          ...filterRedux,
          type: parseInt(event.target.value, 10),
        })
      );
      setFilterDisplay(false);
    }
  };

  const handlePax = (event) => {
    if (filterRedux === 0) {
      const filter = {
        parc: parseInt(parcId, 10),
        nbPersonnes: parseInt(event.target.value, 10),
      };
      dispatch(filteredAccomodation(filter));
      setFilterDisplay(false);
    } else {
      dispatch(
        filteredAccomodation({
          ...filterRedux,
          nbPersonnes: parseInt(event.target.value, 10),
        })
      );
      setFilterDisplay(false);
    }
  };

  const priceOrder = (event) => {
    if (event.target.value === "plus") {
      dispatch(sortPrice(1));
      setFilterDisplay(false);
    } else {
      dispatch(sortPrice(2));
      setFilterDisplay(false);
    }
  };

  const determineClassLogo = () => {
    if (scrollPosition > 300) {
      return "btnFilter";
    }
    return "btnFilterNone";
  };

  const displayFilter = () => {
    setFilterDisplay(!filterDisplay);
  };

  return (
    <div className="containerGlobalFilter">
      <button
        type="button"
        className={`${determineClassLogo()}`}
        onClick={displayFilter}
      >
        <img src={filterLogo} alt="logo filtre" className="filterLogo" />
      </button>
      <div className={`${determineClass()}`}>
        <select
          name="AccomodationType"
          id="AccomodationType"
          className="selectFilter"
          onChange={handleAccomodation}
        >
          <option
            value={filterRedux.type !== undefined ? filterRedux.type : ""}
          >
            {filterRedux.type !== undefined
              ? `--${defineName(filterRedux.type)}--`
              : "Hébergement"}
          </option>
          {types.map((type) => (
            <option
              value={type.id_type_hebergement}
              key={type.id_type_hebergement}
            >
              {type.nom_type_hebergement}
            </option>
          ))}
          <option value="reset">Réinitialiser</option>
        </select>

        <select
          name="nbPax"
          id="nbPax"
          className="selectFilter"
          value={
            filterRedux.nbPersonnes === undefined
              ? "2"
              : filterRedux.nbPersonnes
          }
          onChange={handlePax}
        >
          <option value="2">2 personnes</option>
          <option value="3">3 personnes</option>
          <option value="4">4 personnes</option>
          <option value="5">5 personnes</option>
          <option value="6">6 personnes</option>
        </select>
        <select
          name="price"
          id="price"
          className="selectFilter"
          onChange={priceOrder}
        >
          <option value="">Trier</option>
          <option value="minus">Du - au + cher</option>
          <option value="plus">Du + au - cher</option>
        </select>
      </div>
    </div>
  );
}

export default Filter;
